import logger from "../../logger";
import db, { ILocalSauDto } from "../../db/database";
import { Dispatch } from "redux";
import { Action } from "../action";
import { call, put, select, takeEvery } from "redux-saga/effects";

import BackendClient from "../../api/backend-client";
import { ISauenResponseDto, IFunktionDto, ProzessDataFilterDto } from "../../api/backend-api-v7";

import {
  SauenActionType,
  loadSauenSuccess,
  setLastFetchedDate,
  loadSauenFailure,
  filterSauen as filterSauenAction,
  setSauen,
} from "./sauen.actions";
import { setProzesseForCurrentFunktion } from "../funktionen/funktionen.actions";

import { getCurrentFunktionId, getFunktionById } from "../funktionen/funktionen.selectors";
import { getLastFetchedDate } from "./sauen.selectors";

import { EntitiesType, filterProzessData } from "../../utils/prozess-data-filter";
import { handleProzessData } from "../../utils/prozess-data-mapper";
import SauService from "./sauen.utils";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { IFilteredLocalSau } from "../../utils/sau.utils";

const logError = logger.error("sauen.saga");
let backendClient: BackendClient;

// Load all sauen on init.
export function* loadSauen() {
  try {
    // Send undefined instead of the lastFetched date to get all existing sauen data.
    const response: ISauenResponseDto = yield call([backendClient, "getPreFilteredSauen"], undefined);
    yield put(loadSauenSuccess());

    if (response.sauen && response.sauen.length) {
      yield put(setLastFetchedDate());
      // Clear sauen db to prevent dexie errors.
      yield call([db, "deleteAllSauen"]);
      yield call([db, "addSauen"], response.sauen as ILocalSauDto[]);
    }
  } catch (e: any) {
    logError("Could not fetch sauen", e.message);
    yield put(loadSauenFailure(e.message));
  }
}

// Try to load updated sauen every minute.
export function* updateSauen() {
  try {
    const lastFetched: Date | undefined = yield select(getLastFetchedDate);
    const response: ISauenResponseDto = yield call([backendClient, "getPreFilteredSauen"], lastFetched);
    yield put(loadSauenSuccess());

    if (response.sauen && response.sauen.length) {
      yield put(setLastFetchedDate());
      yield call([db, "updateSauen"], response.sauen as ILocalSauDto[]);
    }
  } catch (e: any) {
    logError("Could not update sauen", e.message);
    yield put(loadSauenFailure(e.message));
  }
}

export function* updateSauenData(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    // Works if PE with Sau event category exists.
    if (prozessEvents.length) {
      const identificator = SauService.findSauIdentificator(prozessEvents);

      if (identificator) {
        const { tierSysId, belegNr, ammenObjectId } = identificator;
        const currentFunktionId: number = yield select(getCurrentFunktionId);

        const sauForUpdate: ILocalSauDto[] = yield call([db, "getSauenByQuery"], "tierSysId", tierSysId!);

        if (sauForUpdate.length) {
          const isAmmen = SauService.isAmmen(prozessEvents);

          if (isAmmen) {
            const ammen = SauService.createAmmen(sauForUpdate[0], prozessEvents);
            yield call([db, "updateSauen"], [ammen]);
          } else {
            const updatedSau = SauService.modifySau(sauForUpdate[0], prozessEvents, belegNr, ammenObjectId);
            yield call([db, "updateSauen"], [updatedSau]);
          }
          // Start filtering if Funktion is active.
          if (currentFunktionId) {
            const funktion: IFunktionDto = yield select(getFunktionById(currentFunktionId));
            yield put(filterSauenAction(funktion.prozessDataFilters!));
          }
        }
      }
    }
  } catch (e) {
    logError("Could not update sau entity", e);
  }
}

export function* updateSauenDataEditMode(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    // Works if PE with Sau event category exists.
    if (prozessEvents.length) {
      const currentFunktionId: number = yield select(getCurrentFunktionId);
      const identificator = SauService.findSauIdentificator(prozessEvents);
      let newSau: ILocalSauDto[] = [];

      if (identificator) {
        const { tierSysId } = identificator;
        newSau = yield call([db, "getSauenByQuery"], "tierSysId", tierSysId!);
      }

      yield call([db, "updateSauenData"], newSau[0], prozessEvents, identificator);

      // Start filtering if Funktion is active.
      if (currentFunktionId) {
        const funktion: IFunktionDto = yield select(getFunktionById(currentFunktionId));
        yield put(filterSauenAction(funktion.prozessDataFilters!));
      }
    }
  } catch (e) {
    logError("Could not update sauen object", e);
  }
}

export function* filterSauen(action: Action<ProzessDataFilterDto[]>) {
  try {
    const prozessDataFilters = action.payload;

    const funktionId: number = yield select(getCurrentFunktionId);
    const funktion: IFunktionDto = yield select(getFunktionById(funktionId));

    const allSauen: ILocalSauDto[] = yield call([db, "getSauen"]);
    yield put(setSauen(allSauen));

    const filteredSauen: IFilteredLocalSau[] = yield call(
      filterProzessData,
      prozessDataFilters,
      EntitiesType.SAU,
      allSauen
    );

    const prozesse = handleProzessData(funktion.prozesse!, filteredSauen);
    yield put(setProzesseForCurrentFunktion({ [funktionId]: prozesse }));
  } catch (e: any) {
    logError("Could not filter sauen", e.message);
  }
}

export default function* sauenSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(SauenActionType.LOAD_SAUEN, loadSauen);
  yield takeEvery(SauenActionType.UPDATE_LOCAL_SAUEN, updateSauen);
  yield takeEvery(SauenActionType.FILTER_SAUEN, filterSauen);

  yield takeEvery(SauenActionType.UPDATE_SAU_DATA, updateSauenData);
  yield takeEvery(SauenActionType.UPDATE_SAU_DATA_EDIT_MODE, updateSauenDataEditMode);
}
