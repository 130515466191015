import { Action } from "../action";
import { BluetoothDataFormat } from "./bluetooth-data-format";
import { GewichtConfiguration } from "../../components/prozesse/gewicht/gewicht.types";

export enum BluetoothActionType {
  REQUEST_BLUETOOTH_DEVICE = "REQUEST_BLUETOOTH_DEVICE",
  DISCONNECT_BLUETOOTH_DEVICE = "DISCONNECT_BLUETOOTH_DEVICE",
  BLUETOOTH_DEVICE_CONNECTED = "BLUETOOTH_DEVICE_CONNECTED",
  BLUETOOTH_DEVICE_DISCONNECTED = "BLUETOOTH_DEVICE_DISCONNECTED",
  BLUETOOTH_DEVICE_CONNECTING = "BLUETOOTH_DEVICE_CONNECTING",
  BLUETOOTH_DEVICE_ERROR = "BLUETOOTH_DEVICE_ERROR",
  RECEIVED_WAAGE_DATA = "RECEIVED_WAAGE_DATA",
  RECEIVED_TRANSPONDER_DATA = "RECEIVED_TRANSPONDER_DATA",
  RECEIVED_TIER_IDENT_DATA = "RECEIVED_TIER_IDENT_DATA",
  RESET_STABLE_WEIGHT = "RESET_STABLE_WEIGHT",
  RESET_TRANSPONDER_DATA = "RESET_TRANSPONDER_DATA",
  RESET_TIER_IDENT_DATA = "RESET_TIER_IDENT_DATA",
  RESET_BLUETOOTH_DATA = "RESET_BLUETOOTH_DATA",
  START_CAPTURE_STABLE_WEIGHT = "START_CAPTURE_STABLE_WEIGHT",
  STABLE_WEIGHT_CAPTURED = "STABLE_WEIGHT_CAPTURED",
  UPDATE_STABLE_CANDIDATES = "UPDATE_STABLE_CANDIDATES",
  SEND_DATA = "SEND_DATA",
  SET_SHOULD_USE_MANUAL_WAAGE_VALUE = "SET_SHOULD_USE_MANUAL_WAAGE_VALUE",
  INCREMENT_NUMBER_OF_SKIPPED_WAAGE_VALUES = "INCREMENT_NUMBER_OF_SKIPPED_WAAGE_VALUES",
  RESET_NUMBER_OF_SKIPPED_WAAGE_VALUES = "RESET_NUMBER_OF_SKIPPED_WAAGE_VALUES",
  INCREASE_TOTAL_AMOUNT_OF_GEWICHT = "INCREASE_TOTAL_AMOUNT_OF_GEWICHT",
  RESET_TOTAL_OF_GEWICHT = "RESET_TOTAL_OF_GEWICHT",
}

export const requestBluetoothDevice = (): Action<null> => ({
  type: BluetoothActionType.REQUEST_BLUETOOTH_DEVICE,
  payload: null,
});

export const disconnectBluetoothDevice = (): Action<null> => ({
  type: BluetoothActionType.DISCONNECT_BLUETOOTH_DEVICE,
  payload: null,
});

export const bluetoothDeviceConnected = (deviceName: string): Action<string> => ({
  type: BluetoothActionType.BLUETOOTH_DEVICE_CONNECTED,
  payload: deviceName,
});

export const bluetoothDeviceDisconnected = (): Action<null> => ({
  type: BluetoothActionType.BLUETOOTH_DEVICE_DISCONNECTED,
  payload: null,
});

export const bluetoothDeviceConnecting = (): Action<null> => ({
  type: BluetoothActionType.BLUETOOTH_DEVICE_CONNECTING,
  payload: null,
});

export const bluetoothDeviceError = (errorMessage: string): Action<string> => ({
  type: BluetoothActionType.BLUETOOTH_DEVICE_ERROR,
  payload: errorMessage,
});

export const receivedWaageData = (data: BluetoothDataFormat): Action<BluetoothDataFormat> => ({
  type: BluetoothActionType.RECEIVED_WAAGE_DATA,
  payload: data,
});

export const receivedTransponderData = (data: BluetoothDataFormat): Action<BluetoothDataFormat> => ({
  type: BluetoothActionType.RECEIVED_TRANSPONDER_DATA,
  payload: data,
});

export const receivedTierIdentData = (data: BluetoothDataFormat): Action<BluetoothDataFormat> => ({
  type: BluetoothActionType.RECEIVED_TIER_IDENT_DATA,
  payload: data,
});

export const startCaptureStableGewicht = (
  gewichtConfiguration: GewichtConfiguration
): Action<GewichtConfiguration> => ({
  type: BluetoothActionType.START_CAPTURE_STABLE_WEIGHT,
  payload: gewichtConfiguration,
});

export const stableGewichtCaptured = (stableGewicht: BluetoothDataFormat): Action<BluetoothDataFormat> => ({
  type: BluetoothActionType.STABLE_WEIGHT_CAPTURED,
  payload: stableGewicht,
});

export const resetStableGewicht = (): Action<null> => ({
  type: BluetoothActionType.RESET_STABLE_WEIGHT,
  payload: null,
});

export const resetTransponderData = (): Action<null> => ({
  type: BluetoothActionType.RESET_TRANSPONDER_DATA,
  payload: null,
});

export const resetTierIdentData = (): Action<null> => ({
  type: BluetoothActionType.RESET_TIER_IDENT_DATA,
  payload: null,
});

export const resetBluetoothData = (): Action<null> => ({
  type: BluetoothActionType.RESET_BLUETOOTH_DATA,
  payload: null,
});

export const updateStableGewichtCandidates = (
  stableGewichtCandidates: BluetoothDataFormat[]
): Action<BluetoothDataFormat[]> => ({
  type: BluetoothActionType.UPDATE_STABLE_CANDIDATES,
  payload: stableGewichtCandidates,
});

export const sendData = (data: string): Action<string> => ({
  type: BluetoothActionType.SEND_DATA,
  payload: data,
});

export const setShouldUseManualWaageValue = (shouldUseManualValue: boolean): Action<boolean> => ({
  type: BluetoothActionType.SET_SHOULD_USE_MANUAL_WAAGE_VALUE,
  payload: shouldUseManualValue,
});

export const incrementNumberOfSkippedWaageValues = (): Action<null> => ({
  type: BluetoothActionType.INCREMENT_NUMBER_OF_SKIPPED_WAAGE_VALUES,
  payload: null,
});

export const resetNumberOfSkippedWaageValues = (): Action<null> => ({
  type: BluetoothActionType.RESET_NUMBER_OF_SKIPPED_WAAGE_VALUES,
  payload: null,
});

export const increaseTotalAmountOfGewicht = (value: number): Action<number> => ({
  type: BluetoothActionType.INCREASE_TOTAL_AMOUNT_OF_GEWICHT,
  payload: value,
});

export const resetTotalOfGewicht = (): Action<null> => ({
  type: BluetoothActionType.RESET_TOTAL_OF_GEWICHT,
  payload: null,
});
