import { FunctionComponent, useState, useCallback, useEffect } from "react";
import useStyles from "./ferkel-abgesetzt.styles";
import classNames from "classnames";
import { OwnProps, ConnectedState } from "./ferkel-abgesetzt.types";
import { TextField } from "@mui/material";
import { ILocalFerkelDto } from "../../../db/database";

interface Props extends OwnProps, ConnectedState {}

const FerkelAbgesetztComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { prozess, filteredBy, ferkel, funktionId } = props;

  const [sortedFerkel, setSortedFerkel] = useState<ILocalFerkelDto[] | undefined>([]);
  const [weanedPigletsCount, setWeanedPigletsCount] = useState<number>(0);

  const sortFerkelByBuchtsau = useCallback(() => {
    if (filteredBy && filteredBy.data && ferkel) {
      const filteredFerkel = ferkel.filter(
        item => item.buchtsauDescriptor.tierSysId === filteredBy.data.tierSysId
      );

      /** Sort ferkel via life stage (Belegung or Amme) to display. */
      let sortedFerkel: ILocalFerkelDto[];

      /** Amme sau was selected,  */
      if (filteredBy.data.ammenObjectId) {
        sortedFerkel = filteredFerkel.filter(
          (f: ILocalFerkelDto) => f.buchtsauDescriptor.ammenObjectId === filteredBy.data.ammenObjectId
        );
      } else {
        sortedFerkel = filteredFerkel.filter((f: ILocalFerkelDto) => !f.buchtsauDescriptor.ammenObjectId);
      }

      setSortedFerkel(sortedFerkel);
      setWeanedPigletsCount(0);
    } else {
      setSortedFerkel([]);
      setWeanedPigletsCount(0);
    }
  }, [ferkel, filteredBy]);

  const calculateFerkelByFunktionenHistory = useCallback(() => {
    if (!!sortedFerkel && sortedFerkel.length !== 0) {
      let count: number = 0;
      sortedFerkel.forEach(item =>
        item.funktionenHistory?.forEach(history => history.funktionId === funktionId && count++)
      );
      setWeanedPigletsCount(count);
    }
  }, [funktionId, sortedFerkel]);

  useEffect(() => {
    sortFerkelByBuchtsau();
  }, [sortFerkelByBuchtsau]);

  useEffect(() => {
    calculateFerkelByFunktionenHistory();
  }, [calculateFerkelByFunktionenHistory]);

  const revealDisplayValue = () => {
    if (!!sortedFerkel && sortedFerkel.length !== 0) {
      return `${weanedPigletsCount} / ${sortedFerkel.length}`;
    }
    return "";
  };

  const isAllWeanedPigletsSet = () => {
    if (!!sortedFerkel && sortedFerkel?.length !== 0 && sortedFerkel?.length === weanedPigletsCount) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root} data-cy="FerkelAbgesetztComponent">
      <TextField
        id={prozess.prozessType}
        label={prozess.label!}
        variant="outlined"
        value={revealDisplayValue()}
        className={classNames(classes.textField, isAllWeanedPigletsSet() ? classes.validInput : "")}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
          classes: {
            notchedOutline: isAllWeanedPigletsSet() ? classes.validBorder : "",
          },
        }}
      />
    </div>
  );
};

export default FerkelAbgesetztComponent;
