import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import logger from "../../../logger";
import { GewichtConfiguration } from "./gewicht.types";

const logInfo = logger.info("gewicht.utils");

const gewichtInRange = (gewicht: number | undefined, min: number, max: number) =>
  gewicht ? !!(gewicht >= min && gewicht <= max) : false;

const verifyIfGewichtIsValid = (gewicht: number | undefined, gewichtConfiguration: GewichtConfiguration) => {
  const { validMinValue, validMaxValue } = gewichtConfiguration;

  // The range can start from zero.
  return validMinValue! >= 0 && validMaxValue
    ? gewichtInRange(gewicht, validMinValue!, validMaxValue)
    : false;
};

const verifyIfGewichtIsOptimal = (
  gewicht: number | undefined,
  gewichtConfiguration: GewichtConfiguration
) => {
  const { optimalMinValue, optimalMaxValue } = gewichtConfiguration;

  // The range can start from zero.
  return optimalMinValue! >= 0 && optimalMaxValue
    ? gewichtInRange(gewicht, optimalMinValue!, optimalMaxValue)
    : false;
};

const calculateAverageGewicht = (gewichte: BluetoothDataFormat[]) => {
  if (!gewichte || gewichte.length === 0) {
    return undefined;
  }

  return (
    gewichte.reduce(
      (previousValue: number, gewicht: BluetoothDataFormat) => previousValue + +gewicht.value,
      0
    ) / gewichte.length
  ).toFixed(2);
};

const calculateStableGewicht = (
  stableGewichtCandidates: BluetoothDataFormat[],
  gewichtConfiguration: GewichtConfiguration
) => {
  logInfo("Calculate stable gewicht from", stableGewichtCandidates, gewichtConfiguration);

  if (!stableGewichtCandidates || stableGewichtCandidates.length === 0) {
    return undefined;
  }

  const allValid = stableGewichtCandidates.every(candidate =>
    verifyIfGewichtIsValid(candidate ? +candidate.value : undefined, gewichtConfiguration)
  );
  if (!allValid) {
    return undefined;
  }

  const maxDifference = gewichtConfiguration.gewichtMaxDifference;
  const allValidDifference = stableGewichtCandidates.every(
    (candidate: BluetoothDataFormat, index: number, candidates: BluetoothDataFormat[]) => {
      if (index === 0) {
        return true;
      }

      const currentValue = +candidate.value;
      const previousValue = +candidates[index - 1].value;
      const difference = Math.abs(((currentValue - previousValue) / previousValue) * 100);

      return difference <= maxDifference!;
    }
  );

  if (!allValidDifference) {
    return undefined;
  }

  return {
    ...stableGewichtCandidates[stableGewichtCandidates.length - 1],
    value: calculateAverageGewicht(stableGewichtCandidates),
  };
};

export {
  verifyIfGewichtIsValid,
  verifyIfGewichtIsOptimal,
  calculateAverageGewicht,
  calculateStableGewicht,
  gewichtInRange,
};
