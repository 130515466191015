import { testBluetoothData } from "../../test-data";

import { EventChannel, eventChannel } from "redux-saga";
import { takeEvery, put, call, takeLatest, select, delay } from "redux-saga/effects";
import { Action } from "../action";
import { processData, processGewicht } from "../bluetooth/bluetooth-data.saga";
import {
  bluetoothDeviceConnected,
  disconnectBluetoothDevice,
  resetBluetoothData,
} from "../bluetooth/bluetooth.actions";
import {
  BluetoothSimulationActionType,
  removeChannel,
  setActiveChannel,
  SimulationData,
} from "./bluetooth-data-simulation.actions";
import { getChannel } from "./bluetooth-data-simulation.selectors";
import { getShouldUseManualGewichtValue } from "./bluetooth.selectors";
import { BLUETOOTH_SIMULATION_NAME } from "../../components/bluetooth-simulator/bluetooth-simulator.component";

function sendGewichtData(ms: number) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter({});
    }, ms);
    return () => {
      clearInterval(iv);
    };
  });
}

export function* simulateConnect() {
  yield put(disconnectBluetoothDevice());
  yield delay(1000);
  yield put(bluetoothDeviceConnected(BLUETOOTH_SIMULATION_NAME));
}

export function* simulateDisconnect() {
  yield put(disconnectBluetoothDevice());
  yield delay(1000);
  yield put(resetBluetoothData());
}

export function* setSimulationData(action: Action<SimulationData>) {
  const { transponder, tierIdent, gewichtRange } = action.payload;
  const manualGewichtValue: boolean = yield select(getShouldUseManualGewichtValue);
  const activeChannel: EventChannel<{}> | undefined = yield select(getChannel);

  if (transponder) {
    const data = {
      ...testBluetoothData[1],
      value: transponder,
    };
    yield call(processData, data);
  }

  if (tierIdent) {
    const data = {
      ...testBluetoothData[2],
      value: tierIdent,
    };
    yield call(processData, data);
  }

  if (gewichtRange && !manualGewichtValue) {
    const [min, max] = gewichtRange.trim().split("-");

    if (activeChannel) {
      activeChannel.close();
    }

    const waageChannel: EventChannel<{}> = yield call(sendGewichtData, 1000);
    yield put(setActiveChannel(waageChannel));

    yield takeEvery(waageChannel, function* () {
      const data = {
        ...testBluetoothData[0],
        value: (Math.random() * (+min - +max) + +max).toFixed(2),
      };
      yield call(processGewicht, data);
    });
  }
}

export function* closeGewightChannel(action: Action<null>) {
  const channel: EventChannel<{}> | undefined = yield select(getChannel);
  if (channel) {
    channel.close();
    yield put(removeChannel());
  }
}

export default function* bluetoothDataSimulationSaga() {
  yield takeEvery(BluetoothSimulationActionType.START_SIMULATION, simulateConnect);
  yield takeEvery(BluetoothSimulationActionType.FINISH_SIMULATION, simulateDisconnect);

  yield takeLatest(BluetoothSimulationActionType.SET_SIMULATION_DATA, setSimulationData);
  yield takeLatest(BluetoothSimulationActionType.CLOSE_ACTIVE_CHANNEL, closeGewightChannel);
}
