import I18n from "i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/app.state";

import SynchronizationComponent from "./synchronization.component";
import { ConnectedState, ConnectedDispatch } from "./synchronization.types";

import {
  confirmAppSynchronization,
  syncProzessEvents,
} from "../../store/synchronization/synchronization.actions";
import { showFailedRequest } from "../../store/notifications/notifications.actions";

import {
  getForceToUpdate,
  getShouldRemoveObsoleteData,
  getShouldSynchronizeApp,
} from "../../store/synchronization/synchronization.selectors";
import { reportProblem } from "../../store/problem-report/problem-report.actions";
import { getIsForceAppUpdate } from "../../store/common/common.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  shouldSynchronizeApp: getShouldSynchronizeApp(state),
  forceToUpdate: getForceToUpdate(state),
  isDataObsolete: getShouldRemoveObsoleteData(state),
  isAppNeedForceUpdate: getIsForceAppUpdate(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  synchronize: () => {
    dispatch(syncProzessEvents());
    dispatch(confirmAppSynchronization());
  },
  postponeSynchronization: () => dispatch(confirmAppSynchronization()),
  showNetworkError: () => dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.NO_INTERNET_CONNECTION"))),
  sendReport: () =>
    dispatch(
      reportProblem({
        message: "The app was not synchronized over 2 weeks now",
        shouldRemoveData: true,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SynchronizationComponent);
