import BackendClient from "../../api/backend-client";
import logger from "../../logger";
import { UserSettingsDto, UserDto } from "../../api/backend-api-v7";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { Dispatch } from "redux";

import {
  loadUserFailure,
  loadUserSuccess,
  updateUserSettingsFailure,
  updateUserSettingsSuccess,
  UserActionType,
} from "./user.actions";
import { getUserSettings } from "./user.selectors";
import { LocalStorageItems } from "../../utils/local-storage.enum";

const logInfo = logger.info("user.saga");
const logError = logger.error("user.saga");

let backendClient: BackendClient;

export function* updateUserSettings() {
  try {
    const userSettings: UserSettingsDto = yield select(getUserSettings);
    yield call([backendClient, "updateUserSettings"], userSettings);
    yield put(updateUserSettingsSuccess());
    logInfo("User settings successfully updated");
  } catch (e: any) {
    // TODO: show notification
    yield put(updateUserSettingsFailure(e.message));
    logError("Could not save user setting", e.message);
  }
}

export function* loadUser() {
  try {
    const user: UserDto = yield call([backendClient, "getUser"]);
    yield put(loadUserSuccess(user));
    yield call([localStorage, "setItem"], LocalStorageItems.USER_ID, user.id!);
  } catch (e: any) {
    yield put(loadUserFailure(e.message));
    logError("Could not fetch user setting", e.message);
  }
}

export default function* userSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(UserActionType.UPDATE_USER_SETTINGS, updateUserSettings);
  yield takeEvery(UserActionType.LOAD_USER, loadUser);
}
