import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { EventCategory, ProzessType } from "../../../api/backend-api-v7";

export const verkaufsdatumProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const verkaufsdatum = prozessEvents.find(pe => pe.prozessType === ProzessType.VERKAUFSDATUM);
  const shouldCancellVerkaufAction = prozessEvents.some(
    pe => pe.eventCategory === EventCategory.VerkaufCancellationAction
  );

  if (shouldCancellVerkaufAction) {
    const ferkelWithoutVerkaufsdatum: ILocalFerkelDto = {
      ...ferkel,
      verkaufsdatum: undefined,
      funktionenHistory: ferkel.funktionenHistory?.filter(history => !history.verkaufsdatum),
    };
    return next(ferkelWithoutVerkaufsdatum, prozessEvents);
  }

  if (verkaufsdatum) {
    const { recordId, funktionId, data } = verkaufsdatum;
    const date = new Date(data).getTime();

    const updatedFerkel: ILocalFerkelDto = {
      ...ferkel,
      verkaufsdatum: date,
      funktionenHistory: ferkel.funktionenHistory?.map(history => {
        if (history.funktionId === funktionId && history.recordId === recordId) {
          return { ...history, verkaufsdatum: date };
        }
        return history;
      }),
    };

    return next(updatedFerkel, prozessEvents);
  }
  return next(ferkel, prozessEvents);
};
