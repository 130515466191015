import {
  receivedTierIdentData,
  receivedTransponderData,
  receivedWaageData,
  stableGewichtCaptured,
  updateStableGewichtCandidates,
  incrementNumberOfSkippedWaageValues,
  resetNumberOfSkippedWaageValues,
} from "./bluetooth.actions";
import { call, fork, put, select, take, delay } from "redux-saga/effects";
import {
  getCaptureStableGewicht,
  getGewichtConfiguration,
  getStableGewichtCandidates,
  getCountOfSkippedValues,
  getTotalAmountOfGewicht,
} from "./bluetooth.selectors";
import logger from "../../logger";
import { BluetoothDataFormat } from "./bluetooth-data-format";
import { BluetoothDataTypes } from "./bluetooth-data-types";

import { calculateStableGewicht } from "../../components/prozesse/gewicht/gewicht.utils";
import { Channel } from "redux-saga";
import { getIsDifferentialWeightingActive } from "../common/common.selectors";
import { GewichtConfiguration } from "../../components/prozesse/gewicht/gewicht.types";

const logInfo = logger.info("bluetooth-data.saga");
// const logWarn = logger.warn('bluetooth-data.saga')
const logError = logger.error("bluetooth-data.saga");

const AVERAGE_COUNT = process.env.REACT_APP_MIN_AVERAGE_COUNT ? +process.env.REACT_APP_MIN_AVERAGE_COUNT : 5;
let lastReceived: number;
let isLastGewichtFromWaage = false;

export function* processData(data: BluetoothDataFormat) {
  try {
    switch (data.type) {
      case BluetoothDataTypes.Waage:
        yield call(processGewicht, data);
        break;
      case BluetoothDataTypes.Transponder:
        yield call(processTransponder, data);
        break;
      case BluetoothDataTypes.TierIdent:
        yield put(receivedTierIdentData(data));
        break;
    }
  } catch (e) {
    logError("Unknown error occured", e);
  }
}

export function* processTransponder(data: BluetoothDataFormat) {
  if (data && data.value) {
    const adjustedTransponder = data.value.replace(" ", "").replace("_", "").trim();
    yield put(receivedTransponderData({ ...data, value: adjustedTransponder }));
  }
}

export function* weightTimer() {
  while (true) {
    if (isLastGewichtFromWaage && lastReceived <= Date.now() - 1000) {
      yield put(receivedWaageData({ value: "0", type: BluetoothDataTypes.Waage } as BluetoothDataFormat));
      isLastGewichtFromWaage = false;
    }
    yield delay(1000);
  }
}

export function* processGewicht(data: BluetoothDataFormat) {
  const isDifferentialWeightingActive: boolean = yield select(getIsDifferentialWeightingActive);
  lastReceived = Date.now();
  isLastGewichtFromWaage = true;

  if (isDifferentialWeightingActive) {
    const totalAmountOfGewicht: number = yield select(getTotalAmountOfGewicht);

    if (totalAmountOfGewicht) {
      yield put(
        stableGewichtCaptured({
          ...data,
          value: (Math.abs(+data.value) - totalAmountOfGewicht).toFixed(2).toString(),
        })
      );
    } else {
      yield put(
        stableGewichtCaptured({
          ...data,
          value: Math.abs(+data.value)
            .toFixed(2)
            .toString(),
        })
      );
    }
    logInfo("Save gewicht as stable value");
  } else {
    const captureStableGewicht: boolean = yield select(getCaptureStableGewicht);

    if (captureStableGewicht) {
      const gewichtConfiguration: GewichtConfiguration = yield select(getGewichtConfiguration);
      const numberOfSkippedValues: number = yield select(getCountOfSkippedValues);

      const { skipNumberOfValidGewichtValues, gewichtAverageCount } = gewichtConfiguration!;

      if (numberOfSkippedValues < skipNumberOfValidGewichtValues!) {
        yield put(incrementNumberOfSkippedWaageValues());
      } else {
        yield put(receivedWaageData(data));
        const stableGewichtCandidates: BluetoothDataFormat[] = [
          // @ts-ignore
          ...(yield select(getStableGewichtCandidates)),
        ];

        logInfo("Add received data for calculating stable gewicht");
        stableGewichtCandidates.push(data);

        if (stableGewichtCandidates.length >= (gewichtAverageCount ? gewichtAverageCount : AVERAGE_COUNT)) {
          logInfo("Got enough data to calculate stable gewicht");
          const stableGewicht: BluetoothDataFormat = yield calculateStableGewicht(
            stableGewichtCandidates,
            gewichtConfiguration
          );
          if (stableGewicht) {
            logInfo("Stable gewicht successfully calculated", stableGewicht);
            yield put(stableGewichtCaptured(stableGewicht));
            yield put(resetNumberOfSkippedWaageValues());
          } else {
            logInfo("Remove first element from array");
            stableGewichtCandidates.shift();
          }
        }
        yield put(updateStableGewichtCandidates(stableGewichtCandidates));
      }
    }
  }
}

export default function* bluetoothDataSaga(dataChannel: Channel<{}>) {
  yield fork(weightTimer);
  while (true) {
    const data: BluetoothDataFormat = yield take(dataChannel);
    yield call(processData, data);
  }
}
