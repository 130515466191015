import { IProzessEventDto } from "../api/backend-api-v7";

/** Enshure that all required PE values exist. */
export const isProzessEventValid = (pe: IProzessEventDto) =>
  !!(
    pe.transactionId &&
    pe.recordId &&
    pe.recordsGroupId &&
    pe.eventCategory &&
    pe.createdAtUtc &&
    pe.workflowId &&
    pe.funktionId &&
    pe.prozessType &&
    pe.eventType &&
    pe.data &&
    pe.userId
  );
