import DetailsInfoComponent from "./details-info.component";
import { ConnectedDispatch, ConnectedState } from "./details-info.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getDetailsFerkelInfo,
  removeDetailsFerkelInfo,
} from "../../../../store/transitory-prozess-data/transitory-prozess-data.actions";
import { ICapturedRecordsByIdsRequestDto } from "../../../../api/backend-api-v7";
import { AppState } from "../../../../store/app.state";
import { getFerkelInfo } from "../../../../store/transitory-prozess-data/transitory-prozess-data.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  ferkelInfo: getFerkelInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  getDetailsFerkelInfo: (ids: ICapturedRecordsByIdsRequestDto) => dispatch(getDetailsFerkelInfo(ids)),
  removeDetailsFerkelInfo: () => dispatch(removeDetailsFerkelInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsInfoComponent);
