import { FunctionComponent, useState, useEffect, ChangeEvent } from "react";
import useStyles from "./change-gewicht-mode.dialog.styles";
import { useTranslation } from "react-i18next";

import { FormControl, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import AppButton from "../common/app-button/app-button";
import CloseButton from "../common/close-button/close-button.component";
import TransitionsModal from "../common/modal/modal-window";

enum RadioGroupValue {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
}

export interface ConnectedState {
  shouldUseManualGewichtValue: boolean;
}

export interface ConnectedDispatch {
  setShouldUseManualGewichtValue: (shouldUseManualValue: boolean) => void;
}

interface Props extends ConnectedState, ConnectedDispatch {
  open: boolean;
  closeDialogHandler: () => void;
}

const ChangeGewichtModeDialogComponent: FunctionComponent<Props> = props => {
  const { open, closeDialogHandler, shouldUseManualGewichtValue, setShouldUseManualGewichtValue } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState<string | undefined>(undefined);

  const radioGroupChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const onButtonClickHandler = () => {
    if (value === RadioGroupValue.MANUAL) {
      setShouldUseManualGewichtValue(true);
    } else {
      setShouldUseManualGewichtValue(false);
    }
    closeDialogHandler();
  };

  useEffect(() => {
    if (shouldUseManualGewichtValue) {
      setValue(RadioGroupValue.MANUAL);
    } else {
      setValue(RadioGroupValue.AUTOMATIC);
    }
  }, [shouldUseManualGewichtValue]);

  return (
    <TransitionsModal closeModal={closeDialogHandler} open={open} size="900px">
      <div className={classes.flexWrapper}>
        <div className={classes.header}>
          <Typography className={classes.title}>{t("MODAL.CHANGE_GEWICHT_MODE.TITLE")}</Typography>
          <CloseButton onCloseHandler={closeDialogHandler} className={classes.closeIcon} />
        </div>
        <Typography className={classes.description}>{t("MODAL.CHANGE_GEWICHT_MODE.TEXT_1")}</Typography>
        <Typography className={classes.description}>{t("MODAL.CHANGE_GEWICHT_MODE.TEXT_2")}</Typography>
        <FormControl className={classes.formControl} component="fieldset">
          <RadioGroup
            aria-label="gewicht-settings"
            name="gewicht-settings"
            value={value}
            onChange={radioGroupChangeHandler}
          >
            <FormControlLabel
              value={RadioGroupValue.MANUAL}
              control={<Radio />}
              label={t("MODAL.CHANGE_GEWICHT_MODE.MANUAL") as string}
            />
            <FormControlLabel
              value={RadioGroupValue.AUTOMATIC}
              control={<Radio />}
              label={t("MODAL.CHANGE_GEWICHT_MODE.AUTOMATIC") as string}
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.buttonWrapper}>
          <AppButton handler={onButtonClickHandler} className={classes.button}>
            {t("COMMON.ÜBERNEHMEN").toUpperCase()}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default ChangeGewichtModeDialogComponent;
