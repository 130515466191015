import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/app.state";

import NotificationComponent, { ConnectedState, ConnectedDispatch } from "./notification.component";

import {
  setShouldOpenValidationOverviewDialog,
  toggleDataImportSnackbar,
} from "../../store/common/common.actions";

import { getNotification } from "../../store/notifications/notifications.selectors";
import {
  getIsAppDeprecated,
  getIsDataImportSnackbarOpen,
  getIsForceAppUpdate,
  isAppUpdateAvailable,
} from "../../store/common/common.selectors";
import { getShouldSynchronizeApp } from "../../store/synchronization/synchronization.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  notification: getNotification(state),
  isAppUpdateAvailable: isAppUpdateAvailable(state),
  isAppDeprecated: getIsAppDeprecated(state),
  isAppNeedToSync: getShouldSynchronizeApp(state),
  isAppNeedForceUpdate: getIsForceAppUpdate(state),
  shouldOpenDataImportSnackbar: getIsDataImportSnackbarOpen(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  openValidationOverviewDialog: () => dispatch(setShouldOpenValidationOverviewDialog(true)),
  toggleDataImportSnackbar: (isOpen: boolean) => dispatch(toggleDataImportSnackbar(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
