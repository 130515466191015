import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      position: "static",
      backgroundColor: theme.palette.primary.light,
      borderBottom: `${theme.spacing(1.25)} solid ${theme.palette.primary.light}`,
    },
    centered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    actionIcon: {
      marginLeft: theme.spacing(),
      cursor: "pointer",
      color: "#000000",
    },
    icon: {
      height: 60,
      padding: "0 10px",
    },
    blackIcon: {
      color: theme.palette.common.black,
    },
    usernameMenuItem: {
      fontSize: theme.typography.fontSize,
    },
    usernameListItemIcon: {
      minWidth: theme.spacing(5),
    },
    menuItem: {
      justifyContent: "center",
    },
    licencesLink: {
      color: "black",
      textDecoration: "none",
    },
    logoutButton: {
      backgroundColor: theme.additionalColors.ampelRot,
      color: "white",
      "&:hover": {
        backgroundColor: theme.additionalColors.ampelRot,
      },
    },
    infoIcon: {
      fontSize: "2rem",
    },
    helpIcon: {
      fontSize: "30px",
      marginLeft: "5px",
      paddingTop: "3px",
    },
    link: {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  })
);

export default useStyles;
