import { FunctionComponent } from "react";
import useStyles from "./funktion-info.styles";

import { AppBar, Dialog, Toolbar, Typography } from "@mui/material";
import { IFunktionDto } from "../../api/backend-api-v7";
import CloseButton from "../common/close-button/close-button.component";

export interface Props {
  open: boolean;
  onClose: () => void;
  funktion?: IFunktionDto;
}

const FunktionInfoComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { funktion, open, onClose } = props;

  return (
    <Dialog fullScreen={true} open={open} onClose={onClose}>
      <div className={classes.flexWrapper}>
        <AppBar className={classes.appBar} position={"static"}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {funktion!.name!}
            </Typography>
            <CloseButton onCloseHandler={onClose} />
          </Toolbar>
        </AppBar>
        <div className={classes.description}>
          <span dangerouslySetInnerHTML={{ __html: funktion!.description! }}></span>
        </div>
      </div>
    </Dialog>
  );
};

export default FunktionInfoComponent;
