import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./logout-dialog.styles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface Props {
  open: boolean;
  onClose: () => void;
  logout: () => void;
}

const LogoutDialogComponent: FunctionComponent<Props> = props => {
  const { open, onClose, logout } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} PaperProps={{ className: classes.dialog }}>
      <DialogTitle classes={{ root: classes.title }}>{t("MODAL.LOG_OUT.TITLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("MODAL.LOG_OUT.TEXT")}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("COMMON.ABBRECHEN").toUpperCase()}
        </Button>
        <Button variant="contained" className={classes.acceptButton} onClick={logout}>
          {t("COMMON.ABMELDEN").toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialogComponent;
