import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalSauLifeStageDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const abferkeldatumProzessEventHandler = (
  lifeStage: ILocalSauLifeStageDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const abferkeldatum = prozessEvents.find(pe => pe.prozessType === ProzessType.ABFERKELDATUM);

  if (abferkeldatum) {
    const updatedSauLifeStage: ILocalSauLifeStageDto = {
      ...lifeStage,
      abferkeldatum: new Date(abferkeldatum.data).getTime(),
    };
    return next(updatedSauLifeStage, prozessEvents);
  }

  return next(lifeStage, prozessEvents);
};
