import logger from "../../../logger";
import { FerkelGridAction, FerkelGridActionType, FerkelGridState } from "./ferkel-grid.types";
import { ILocalFerkelDto } from "../../../db/database";

const logInfo = logger.info("ferkel-grid.reducer");

export const initialState: FerkelGridState = {
  ferkel: undefined,
  isError: false,
  isValid: false,
  shouldSaveProzess: true,
};

const isError = (value: ILocalFerkelDto | undefined, isRequired?: boolean) => !!(value ? false : isRequired);

export const ferkelGridReducer = (state: FerkelGridState, action: FerkelGridAction): FerkelGridState => {
  const {
    type,
    payload: { value, isRequired, shouldKeepValue, isValid },
  } = action;

  switch (type) {
    case FerkelGridActionType.INIT: {
      logInfo("FERKEL-GRID INIT");
      return { ...initialState, isError: isError(undefined, isRequired) };
    }
    case FerkelGridActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        ferkel: value,
        isError: isError(value, isRequired),
        isValid: !!isValid,
        shouldSaveProzess: false,
      };
    }
    case FerkelGridActionType.TOGGLE: {
      const ferkel = state.ferkel === value ? undefined : value;

      return {
        ...state,
        ferkel,
        isError: isError(ferkel, isRequired),
        isValid: !!isValid,
      };
    }
    case FerkelGridActionType.RESET: {
      logInfo("FERKEL-GRID RESET");
      const ferkel = shouldKeepValue ? state.ferkel : undefined;
      return { ...initialState, ferkel, isError: isError(ferkel, isRequired) };
    }
  }

  return state;
};
