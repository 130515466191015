import classNames from "classnames";
import { FunctionComponent } from "react";

import { SwipeableDrawer, Theme } from "@mui/material";

import MenuContainer from "../menu/menu.container";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: theme.mixins.toolbar,
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up("md")]: {
        position: "relative",
        height: "100%",
      },
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    content: {
      height: "100%",
    },
  })
);

export interface ConnectedState {
  drawerOpen: boolean;
}

export interface ConnectedDispatch {
  toggleDrawer: (drawerOpen: boolean) => void;
}

interface OwnProps extends ConnectedState, ConnectedDispatch {}

const DrawerComponent: FunctionComponent<OwnProps> = props => {
  const { drawerOpen, toggleDrawer } = props;
  const classes = useStyles();

  return (
    <>
      <SwipeableDrawer
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        data-cy="drawer"
      >
        <div className={classes.content} onClick={() => toggleDrawer(false)}>
          <MenuContainer />
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerComponent;
