import "./app.css";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AppBarContainer from "./components/app-bar/app-bar.container";
import DrawerContainer from "./components/drawer/drawer.container";
import logger from "./logger";
import routes from "./routes/routes";
import withRoot from "./withRoot";
import { LoadingStatus } from "./store/loading-status";
import NotificationContainer from "./components/notification/notification.container";
import SynchronizationContainer from "./components/synchronization/synchronization.container";
import ForceUpdateContainer from "./components/modal-windows/force-update/force-update.container";
import env from "@beam-australia/react-env";
import i18next from "i18next";

import * as Sentry from "@sentry/browser";

const logWarn = logger.warn("app.component");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {},
    root: {
      width: "100%",
      height: "100%",
      zIndex: 1,
      overflow: "hidden",
    },
    appFrame: {
      display: "flex",
      height: "100%",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "fixed",
      top: 0,
    },
    progress: {
      margin: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
  })
);

export interface ConnectedState {
  isLoggedIn: boolean;
  userSettingsStatus: LoadingStatus;
  funktionenStatus: LoadingStatus;
  ferkelStatus: LoadingStatus;
  sauenStatus: LoadingStatus;
  buchtenStatus: LoadingStatus;
  planFerkelStatus: LoadingStatus;
}

export interface ConnectedDispatch {
  syncProzessEvents: () => void;
  setIsAppUpdateAvailable: (isAvailable: boolean) => void;
  initTranslations: () => void;
  updateAppData: () => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

export const App: FunctionComponent<Props> = props => {
  const {
    syncProzessEvents,
    setIsAppUpdateAvailable,
    initTranslations,
    userSettingsStatus,
    funktionenStatus,
    ferkelStatus,
    sauenStatus,
    buchtenStatus,
    isLoggedIn,
    updateAppData,
    planFerkelStatus,
  } = props;
  const classes = useStyles();

  const {
    REACT_APP_APP_VERSION,
    REACT_APP_SYNC_INTERVAL,
    REACT_APP_UPDATE_FERKEL_AND_SAUEN_INTERVAL,
    REACT_APP_UPDATE_SERVICE_WORKER_INTERVAL,
  } = process.env;

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  const registerMessageEventListener = useCallback(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", event => {
        if (event.data.type === "APP_UPDATE") {
          setIsAppUpdateAvailable(true);
        }
      });
    } else {
      logWarn("No serviceWorker available to register event-listener for app updates");
    }
  }, [setIsAppUpdateAvailable]);

  const tryToUpdateServiceworker = useCallback(() => {
    const id = setInterval(
      () => {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({ type: "UPDATE_SERVICE_WORKER" });
        }
      },
      REACT_APP_UPDATE_SERVICE_WORKER_INTERVAL ? +REACT_APP_UPDATE_SERVICE_WORKER_INTERVAL : 60000
    );
    return () => clearInterval(id);
  }, [REACT_APP_UPDATE_SERVICE_WORKER_INTERVAL]);

  useEffect(() => {
    setHeight((ref.current! as any).clientHeight);
  }, []);

  useEffect(() => {
    registerMessageEventListener();
  }, [registerMessageEventListener]);

  useEffect(() => {
    tryToUpdateServiceworker();
  }, [tryToUpdateServiceworker]);

  useEffect(() => {
    if (!i18next.isInitialized) {
      initTranslations();
    }
  }, [initTranslations]);

  useEffect(() => {
    if (isLoggedIn) {
      syncProzessEvents();
      const id = setInterval(
        () => {
          syncProzessEvents();
        },
        REACT_APP_SYNC_INTERVAL ? +REACT_APP_SYNC_INTERVAL : 10000
      );
      return () => clearInterval(id);
    }
  }, [isLoggedIn, REACT_APP_SYNC_INTERVAL, syncProzessEvents]);

  useEffect(() => {
    if (isLoggedIn) {
      const id = setInterval(
        () => {
          updateAppData();
        },
        REACT_APP_UPDATE_FERKEL_AND_SAUEN_INTERVAL ? +REACT_APP_UPDATE_FERKEL_AND_SAUEN_INTERVAL : 60000
      );
      return () => clearInterval(id);
    }
  }, [isLoggedIn, updateAppData, REACT_APP_UPDATE_FERKEL_AND_SAUEN_INTERVAL]);

  useEffect(() => {
    const APP_ENVIRONMENT = env("ENVIRONMENT");
    const SENTRY_URL = env("SENTRY_URL");

    if (APP_ENVIRONMENT !== "#ENVIRONMENT#") {
      Sentry.init({
        dsn: SENTRY_URL,
        release: REACT_APP_APP_VERSION,
        environment: APP_ENVIRONMENT,
      });
    }
  }, [REACT_APP_APP_VERSION]);

  const isPending = () =>
    userSettingsStatus.pending ||
    funktionenStatus.pending ||
    ferkelStatus.pending ||
    sauenStatus.pending ||
    buchtenStatus.pending ||
    planFerkelStatus.pending ||
    !i18next.isInitialized;

  return (
    <div className={classes.root} ref={ref}>
      {isPending() && (
        <>
          <AppBarContainer />
          <div className={classes.center}>
            <CircularProgress className={classes.progress} />
          </div>
        </>
      )}

      {!isPending() && (
        <div className={classes.rootContainer} style={{ height: `${height - 95}px` }}>
          {routes}
        </div>
      )}

      {isLoggedIn && !isPending() && <DrawerContainer />}
      {!isPending() && (
        <>
          <NotificationContainer />
          <SynchronizationContainer />
          <ForceUpdateContainer />
        </>
      )}
    </div>
  );
};

export default withRoot(App);
