import { Action } from "../action";

export enum InitActionType {
  INIT = "INIT",
  REFRESH = "REFRESH",
}

export const init = (): Action<null> => ({
  type: InitActionType.INIT,
  payload: null,
});

export const refresh = (): Action<null> => ({
  type: InitActionType.REFRESH,
  payload: null,
});
