import { createSelector } from "reselect";

import { AppState } from "../app.state";
import { IProzessDto, IFunktionDto } from "../../api/backend-api-v7";
import { DEFAULT_MAX_CAPTURED_AMOUNT } from "../../constants";

export const getCurrentFunktionId = (state: AppState): number | undefined =>
  state.funktionen.currentFunktionId;

export const getFunktionen = (state: AppState): { [funktionId: number]: IFunktionDto } =>
  state.funktionen.funktionen;

export const getProzesse = (state: AppState): { [funktionId: number]: IProzessDto[] } =>
  state.funktionen.prozesse;

export const getFunktionById = (funktionId: number) =>
  createSelector(
    [getFunktionen],
    (funktionen: { [funktionId: number]: IFunktionDto }) => funktionen[funktionId]
  );

export const getFunktionenStatus = (state: AppState) => state.funktionen.loadStatus;

export const getProzesseByFunktionId = (funktionId: number) =>
  createSelector([getProzesse], (prosezze: { [funktionId: number]: IProzessDto[] }) => prosezze[funktionId]);

export const getProzessById = (funktionId: number, workflowId: number) =>
  createSelector([getFunktionById(funktionId)], (funktion: IFunktionDto) =>
    funktion.prozesse!.find(prozess => prozess.workflowId === workflowId)
  );

export const getProzessByIdForCurrentFunktion = (funktionId: number, workflowId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse ? prozesse.find(item => item.workflowId === workflowId) : undefined
  );

export const getMaxCapturedRecordsAmountByFunktionId = (funktionId: number) =>
  createSelector(
    [getFunktionById(funktionId)],
    (funktion: IFunktionDto | undefined) =>
      funktion?.funktionConfiguration?.maxCapturedRecordsAmount || DEFAULT_MAX_CAPTURED_AMOUNT
  );

export const getProzessWhichCanCreateMultipleEvents = (funktionId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse ? prozesse.find(item => item.shouldCreateMultipleProzessEvents) : undefined
  );

export const isProzessCanCreateMultipleEvents = (funktionId: number, workflowId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse
      ? !!prozesse
          .filter(prozess => prozess.workflowId === workflowId)
          .find(item => item.shouldCreateMultipleProzessEvents)
      : false
  );

export const getProzesseWhichListenOnChangesOfCurrentProzess = (funktionId: number, workflowId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse
      ? prozesse
          .filter(prozess => prozess.workflowId !== workflowId)
          .filter(prozess => prozess.shouldListenForChangesOnWorkflowId === workflowId)
      : []
  );

export const getProzesseWhichAreFilteredByOtherProzesse = (funktionId: number, workflowId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse
      ? prozesse
          .filter(prozess => prozess.workflowId !== workflowId)
          .filter(prozess => prozess.filteredByWorkflowId === workflowId)
      : []
  );

export const getComparisonProzesse = (funktionId: number, workflowId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse
      ? prozesse
          .filter(prozess => prozess.workflowId !== workflowId)
          .filter(prozess => prozess.shouldHighlightDifferenceToWorkflowId === workflowId)
      : []
  );
