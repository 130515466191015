import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: 800,
  },
  dialogActions: {
    justifyContent: "space-between",
    padding: "20px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
  },
  acceptButton: {
    backgroundColor: theme.additionalColors.ampelRot,
    color: "white",
    width: "200px",
  },
}));

export default useStyles;
