import { Dispatch } from "redux";
import { Action } from "../action";
import { takeEvery, call, delay, put } from "redux-saga/effects";
import { EventCategory } from "../../api/backend-api-v7";

import db, { ISyncedProzessEvent } from "../../db/database";
import { RestoreActionType } from "./restore.actions";
import { updateFerkelData, updateFerkelDataEditMode } from "../ferkel/ferkel.actions";
import { updateSauData, updateSauDataEditMode } from "../sauen/sauen.actions";

import { filterProzessEventsByEventCategory } from "../../utils/event-category.utils";
import { updateBuchtData, updateBuchtDataEditMode } from "../buchten/buchten.actions";

interface SortedRecordsToRestore {
  creationMode: ISyncedProzessEvent[];
  editMode: ISyncedProzessEvent[];
}

const groupByRecordId = (prozessEvents: ISyncedProzessEvent[]): ISyncedProzessEvent[][] => {
  const result: { [key: string]: ISyncedProzessEvent[] } = prozessEvents.reduce(
    (acc: { [key: string]: ISyncedProzessEvent[] }, curr: ISyncedProzessEvent) => {
      acc[curr.recordId] = [...(acc[curr.recordId] || []), curr];
      return acc;
    },
    {}
  );

  return Object.values(result);
};

const sortByCreationType = (prozessEvents: ISyncedProzessEvent[]): SortedRecordsToRestore =>
  prozessEvents.reduce(
    (acc: SortedRecordsToRestore, curr: ISyncedProzessEvent) => {
      if (curr.additionalData.createdInEditMode) {
        acc.editMode.push(curr);
      } else {
        acc.creationMode.push(curr);
      }
      return acc;
    },
    { creationMode: [], editMode: [] }
  );

export function* restoreData(action: Action<null>) {
  // To be sure that all data were fetched and stored in local DB.
  yield delay(3000);
  const prozessEvents: ISyncedProzessEvent[] = yield call([db, "getProzessEventsToRestoreDb"]);

  if (prozessEvents.length) {
    const sortedData = sortByCreationType(prozessEvents);

    if (sortedData.creationMode.length) {
      yield put(
        updateFerkelData(
          filterProzessEventsByEventCategory(sortedData.creationMode, [
            EventCategory.Ferkel,
            EventCategory.VerkaufCancellationAction,
          ])
        )
      );

      const result = groupByRecordId(sortedData.creationMode);

      for (const group of result) {
        yield put(updateSauData(filterProzessEventsByEventCategory(group, [EventCategory.Sau])));
        yield put(updateBuchtData(filterProzessEventsByEventCategory(group, [EventCategory.Bucht])));
      }
    }

    if (sortedData.editMode.length) {
      const result = groupByRecordId(sortedData.editMode);

      for (const group of result) {
        yield put(
          updateFerkelDataEditMode(
            filterProzessEventsByEventCategory(group, [
              EventCategory.Ferkel,
              EventCategory.VerkaufCancellationAction,
            ])
          )
        );
        yield put(updateSauDataEditMode(filterProzessEventsByEventCategory(group, [EventCategory.Sau])));

        yield put(updateBuchtDataEditMode(filterProzessEventsByEventCategory(group, [EventCategory.Bucht])));
      }
    }
  }
}

export default function* restoreSaga(dispatch: Dispatch) {
  yield takeEvery(RestoreActionType.RESTORE_LOCAL_DATA, restoreData);
}
