import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "80px",
      marginRight: 0,
      borderBottom: "1px solid #b0b0b0",
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
    headerCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    detailsButton: {
      height: "40px",
    },
    notFound: {
      position: "absolute",
      top: "200px",
      left: "35%",
    },
  })
);

export default useStyles;
