import absetzen from "./img/absetzen.svg";
import datenexport from "./img/datenexport.svg";
import ersterfassung from "./img/ersterfassung.svg";
import p3 from "./img/p3.png";
import fuetterung from "./img/fütterung.svg";
import muttersau from "./img/muttersau.svg";
import nachkennzeichnen from "./img/nachkennzeichnen.svg";
import verkauf from "./img/verkauf.svg";
import wiegen from "./img/wiegen.svg";
import wurfausgleich from "./img/wurfausgleich.svg";
import logo from "./img/IQ_PIG-MONITOR_Logo_100_farbig_randlos.svg";
import verluste from "./img/verluste.svg";
import lokalisation from "./img/lokalisation.svg";
import balanceScales from "./img/balance-scales.svg";
import daten_senden from "./img/mail-outline.svg";
import tierinfo from "./img/tierinfo.svg";
import datenuebersicht from "./img/datenuebersicht.svg";
import futter_ferkel from "./img/futter-ferkel.svg";
import futter_sauen from "./img/futter-sauen.svg";
import auge from "./img/auge.svg";
import bonitur1 from "./img/bonitur1.svg";
import bonitur2 from "./img/bonitur2.svg";
import suchen from "./img/suchen.svg";
import umstallen from "./img/umstallen.svg";
import datenimport from "./img/datenimport.svg";

const iqImages = {
  absetzen,
  datenexport,
  ersterfassung,
  p3,
  fuetterung,
  muttersau,
  nachkennzeichnen,
  verkauf,
  wiegen,
  logo,
  wurfausgleich,
  verluste,
  lokalisation,
  balanceScales,
  daten_senden,
  tierinfo,
  datenuebersicht,
  futter_ferkel,
  futter_sauen,
  auge,
  bonitur1,
  bonitur2,
  suchen,
  umstallen,
  datenimport,
};

export default iqImages;
