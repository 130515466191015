import { IProzessEventDto } from "../../api/backend-api-v7";
import { Action } from "../action";

export enum SynchronizationActionType {
  CONFIRM_APP_SYNCHRONIZATION = "CONFIRM_APP_SYNCHRONIZATION",
  CONFIRM_SAVE_PROZESS_EVENTS = "COMFIRM_SAVE_PROZESS_EVENTS",
  PROZESS_EVENTS_ARE_SAVED = "PROZESS_EVENTS_ARE_SAVED",
  PROZESS_EVENTS_ARE_SYNCED = "PROZESS_EVENTS_ARE_SYNCED",
  REMOVE_OBSOLETE_DATA = "REMOVE_OBSOLETE_DATA",
  SYNCHRONIZE_APP = "SYNCHRONIZE_APP",
  SYNC_PROZESS_EVENTS = "SYNC_PROZESS_EVENTS",
  SYNC_PROZESS_EVENTS_SUCCESS = "SYNC_PROZESS_EVENTS_SUCCESS",
  SYNC_PROZESS_EVENTS_FAILURE = "SYNC_PROZESS_EVENTS_FAILURE",
}

export interface SyncInfo {
  forceToUpdate: boolean;
}

export const prozessEventsAreSynced = (isSynced: boolean): Action<boolean> => ({
  type: SynchronizationActionType.PROZESS_EVENTS_ARE_SYNCED,
  payload: isSynced,
});

export const prozessEventsAreSaved = (): Action<null> => ({
  type: SynchronizationActionType.PROZESS_EVENTS_ARE_SAVED,
  payload: null,
});

export const confirmSaveProzessEvents = (): Action<null> => ({
  type: SynchronizationActionType.CONFIRM_SAVE_PROZESS_EVENTS,
  payload: null,
});

export const syncProzessEvents = (): Action<null> => ({
  type: SynchronizationActionType.SYNC_PROZESS_EVENTS,
  payload: null,
});

export const syncProzessEventsSuccess = (prozessEvents: IProzessEventDto[]): Action<IProzessEventDto[]> => ({
  type: SynchronizationActionType.SYNC_PROZESS_EVENTS_SUCCESS,
  payload: prozessEvents,
});

export const syncProzessEventsFailure = (): Action<null> => ({
  type: SynchronizationActionType.SYNC_PROZESS_EVENTS_FAILURE,
  payload: null,
});

export const synchronizeApp = (info: SyncInfo): Action<SyncInfo> => ({
  type: SynchronizationActionType.SYNCHRONIZE_APP,
  payload: info,
});

export const confirmAppSynchronization = (): Action<null> => ({
  type: SynchronizationActionType.CONFIRM_APP_SYNCHRONIZATION,
  payload: null,
});

export const removeObsoleteData = (shouldRemove: boolean): Action<boolean> => ({
  type: SynchronizationActionType.REMOVE_OBSOLETE_DATA,
  payload: shouldRemove,
});
