import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { KommentarDto, ProzessType } from "../../../api/backend-api-v7";

export const kommentareProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const { recordId, funktionId } = prozessEvents[0];
  const kommentare = prozessEvents.filter(pe => pe.prozessType === ProzessType.KOMMENTARE);

  if (kommentare.length) {
    const newKommentare = kommentare.reduce(
      (total: KommentarDto[], current: IProzessEventsWithAdditionalData) => {
        const { workflowId, data, label } = current;
        return [...total, { id: +data[0], name: label, workflowId } as KommentarDto];
      },
      []
    );

    const updatedFerkel = {
      ...ferkel,
      funktionenHistory: ferkel.funktionenHistory?.map(history => {
        if (history.funktionId === funktionId && history.recordId === recordId) {
          return { ...history, kommentare: [...(history.kommentare || []), ...newKommentare] };
        }
        return history;
      }),
    };

    return next(updatedFerkel, prozessEvents);
  }

  return next(ferkel, prozessEvents);
};
