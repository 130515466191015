import { FunctionComponent, useEffect, useState } from "react";
import { ConnectedState, OwnProps } from "./info-neue-buchtsau.types";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import useStyles from "./info-neue-buchtsau.styles";
import { useTranslation } from "react-i18next";
import { ISauLifeStageDescriptorDto } from "../../../api/backend-api-v7";

interface Props extends OwnProps, ConnectedState {}

const InfoNeueBuchtsauComponent: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { prozess, filteredBy, ferkel } = props;
  const [ferkelCount, setFerkelCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (filteredBy?.data && ferkel) {
      const { tierSysId, ammenObjectId, belegNr } = filteredBy.data as ISauLifeStageDescriptorDto;
      let filteredFerkel = [];

      if (ammenObjectId) {
        filteredFerkel = ferkel.filter(
          item =>
            item.buchtsauDescriptor.tierSysId === tierSysId &&
            item.buchtsauDescriptor.ammenObjectId === ammenObjectId
        );
      } else {
        filteredFerkel = ferkel.filter(
          item =>
            item.buchtsauDescriptor.tierSysId === tierSysId && item.buchtsauDescriptor.belegNr === belegNr
        );
      }

      setFerkelCount(filteredFerkel.length);
    } else {
      setFerkelCount(undefined);
    }
  }, [filteredBy, ferkel]);

  return (
    <div style={{ gridArea: prozess.position }} data-cy="InfoNeueBuchtsauComponent">
      <div className={classes.infoFerkel}>{prozess.label!}</div>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              {t("COMMON.PIGLETS_COUNT")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" data-cy="anzahl-ferkel">
              {ferkelCount}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default InfoNeueBuchtsauComponent;
