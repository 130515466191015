import { FunctionComponent, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

import AppContainer from "./app.container";
import configureStore from "./store/configureStore";
import { CircularProgress, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const logger = (createLogger as any)();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let middleware = applyMiddleware(logger);

if (process.env.NODE_ENV === "development") {
  middleware = composeWithDevTools(middleware);
}

const store = configureStore();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "fixed",
      top: 0,
    },
    progress: {
      margin: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
  })
);

const ReduxRoot: FunctionComponent<{}> = props => {
  const classes = useStyles();

  return (
    <Suspense
      fallback={
        <div className={classes.center}>
          <CircularProgress className={classes.progress} />
        </div>
      }
    >
      <Provider store={store}>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

export default ReduxRoot;
