import { Action } from "../action";
import { UserSettingsDto, UserDto } from "../../api/backend-api-v7";

export enum UserActionType {
  LOAD_USER = "LOAD_USER",
  LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE = "LOAD_USER_FAILURE",
  UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS",
  UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS",
  UPDATE_USER_SETTINGS_FAILURE = "UPDATE_USER_SETTINGS_FAILURE",
}

export const loadUser = (): Action<null> => ({
  type: UserActionType.LOAD_USER,
  payload: null,
});

export const loadUserSuccess = (user: UserDto): Action<UserDto> => ({
  type: UserActionType.LOAD_USER_SUCCESS,
  payload: user,
});

export const loadUserFailure = (errorMessage: string): Action<string> => ({
  type: UserActionType.LOAD_USER_FAILURE,
  payload: errorMessage,
});

export const updateUserSettings = (settings: Partial<UserSettingsDto>): Action<Partial<UserSettingsDto>> => ({
  type: UserActionType.UPDATE_USER_SETTINGS,
  payload: settings,
});

export const updateUserSettingsSuccess = (): Action<null> => ({
  type: UserActionType.UPDATE_USER_SETTINGS_SUCCESS,
  payload: null,
});

export const updateUserSettingsFailure = (errorMessage: string): Action<string> => ({
  type: UserActionType.UPDATE_USER_SETTINGS_FAILURE,
  payload: errorMessage,
});
