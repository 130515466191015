import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalSauLifeStageDto } from "../../../db/database";

export const funktionHistoryHandler = (
  lifeStage: ILocalSauLifeStageDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const { recordId, funktionId } = prozessEvents[0];

  const updatedSauLifeStage: ILocalSauLifeStageDto = {
    ...lifeStage,
    funktionenHistory: [...lifeStage.funktionenHistory!, { recordId, funktionId, previousData: lifeStage }],
  };

  return next(updatedSauLifeStage, prozessEvents);
};
