import { EventCategory, ProzessType } from "../../api/backend-api-v7";
import { ILocalFerkelDto } from "../../db/database";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { defineFerkelProperty } from "../../utils/ferkel.utils";

// Ferkel handlers.
import { buchtsauProzessEventHandler } from "./handlers/buchtsau.handler";
import { funktionHistoryHandler } from "./handlers/funktion-history.handler";
import { geburtsgewichtProzessEventHandler } from "./handlers/geburtsgewicht.handler";
import { genetischesauProzessEventHandler } from "./handlers/genetischesau.handler";
import { geschlechtProzessEventHandler } from "./handlers/geschlecht.handler";
import { kommentareProzessEventHandler } from "./handlers/kommentare.handler";
import { tierIdentProzessEventHandler } from "./handlers/tierident.handler";
import { totgeburtProzessEventHandler } from "./handlers/totgeburt.handler";
import { transponderProzessEventHandler } from "./handlers/transponder.handler";
import { verkaufsdatumProzessEventHandler } from "./handlers/verkaufsdatum.handler";
import { verlustgrundProzessEventHandler } from "./handlers/verlustgrund.handler";

type Handler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => void | ILocalFerkelDto;

interface FerkelHandlers {
  funktionHistoryHandler: (
    ferkel: ILocalFerkelDto | undefined,
    prozessEvents: IProzessEventsWithAdditionalData[],
    next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
  ) => void;
  geburtsgewichtProzessEventHandler: Handler;
  buchtsauProzessEventHandler: Handler;
  genetischesauProzessEventHandler: Handler;
  geschlechtProzessEventHandler: Handler;
  kommentareProzessEventHandler: Handler;
  tierIdentProzessEventHandler: Handler;
  totgeburtProzessEventHandler: Handler;
  transponderProzessEventHandler: Handler;
  verkaufsdatumProzessEventHandler: Handler;
  verlustgrundProzessEventHandler: Handler;
}

export interface FerkelIdentificators {
  transponder: number | undefined;
  tierIdent: string | undefined;
}

const ferkelHandlers: FerkelHandlers = {
  funktionHistoryHandler,
  kommentareProzessEventHandler,
  geburtsgewichtProzessEventHandler,
  buchtsauProzessEventHandler,
  genetischesauProzessEventHandler,
  geschlechtProzessEventHandler,
  tierIdentProzessEventHandler,
  totgeburtProzessEventHandler,
  transponderProzessEventHandler,
  verkaufsdatumProzessEventHandler,
  verlustgrundProzessEventHandler,
};

class FerkelService {
  private handlers: ((
    ferkel: ILocalFerkelDto | undefined,
    prozessEvents: IProzessEventsWithAdditionalData[]
  ) => ILocalFerkelDto)[];

  constructor(handlers: FerkelHandlers) {
    this.handlers = Object.values(handlers).map(
      (handler, index) =>
        (ferkel: ILocalFerkelDto | undefined, prozessEvents: IProzessEventsWithAdditionalData[]) =>
          handler(ferkel, prozessEvents, this.handlers[index + 1])
    );
  }

  shouldCancellVerkaufAction(prozessEvents: IProzessEventsWithAdditionalData[]) {
    return prozessEvents.some(pe => pe.eventCategory === EventCategory.VerkaufCancellationAction);
  }

  modifyFerkel(ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) {
    return this.handlers[0](ferkel, prozessEvents);
  }

  createFerkel(ferkel: ILocalFerkelDto | undefined, prozessEvents: IProzessEventsWithAdditionalData[]) {
    return this.handlers[0](ferkel, prozessEvents);
  }

  modifyFerkelEditMode(ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) {
    return this.handlers[1](ferkel, prozessEvents);
  }

  findFerkelIdentificators(prozessEvents: IProzessEventsWithAdditionalData[]): FerkelIdentificators {
    return prozessEvents
      .filter(pe => pe.prozessType === ProzessType.TRANSPONDER || pe.prozessType === ProzessType.TIER_IDENT)
      .reduce(
        (acc: FerkelIdentificators, curr: IProzessEventsWithAdditionalData) => ({
          ...acc,
          [defineFerkelProperty(curr.prozessType!)]: curr.data,
        }),
        { transponder: undefined, tierIdent: undefined }
      );
  }

  findFunktionToModifyRecord(ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) {
    if (this.shouldCancellVerkaufAction(prozessEvents)) {
      return ferkel.funktionenHistory?.find(history => history.verkaufsdatum)?.funktionId;
    }
  }
}

export default new FerkelService(ferkelHandlers);
