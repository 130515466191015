import { Action } from "../action";
import createReducer from "../createReducer";
import { WurfuebersichtActionType, WurfuebersichtFerkelDto, WurfFerkelDto } from "./wurfuebersicht.actions";
import { ProzessDataDto } from "../../api/backend-api-v7";

export interface WurfuebersichtState {
  selectedBuchtsau: ProzessDataDto | undefined;
  ferkel: { [buchtsauId: string]: WurfuebersichtFerkelDto[] | undefined };
  lebendeFerkel: WurfuebersichtFerkelDto[] | undefined;
  totgeburtFerkel: WurfuebersichtFerkelDto[] | undefined;
}

export const initialState: WurfuebersichtState = {
  selectedBuchtsau: undefined,
  ferkel: {},
  lebendeFerkel: undefined,
  totgeburtFerkel: undefined,
};

export default createReducer(initialState, {
  [WurfuebersichtActionType.SET_BUCHTSAU](
    state: WurfuebersichtState,
    action: Action<ProzessDataDto>
  ): WurfuebersichtState {
    return { ...state, selectedBuchtsau: action.payload };
  },
  [WurfuebersichtActionType.SET_FERKEL](
    state: WurfuebersichtState,
    action: Action<WurfuebersichtFerkelDto[]>
  ): WurfuebersichtState {
    return {
      ...state,
      ferkel: { ...state.ferkel, [state.selectedBuchtsau!.id]: action.payload },
    };
  },
  [WurfuebersichtActionType.SET_NEW_FERKEL](
    state: WurfuebersichtState,
    action: Action<WurfuebersichtFerkelDto>
  ): WurfuebersichtState {
    return {
      ...state,
      ferkel: {
        ...state.ferkel,
        [state.selectedBuchtsau!.id]: [...state.ferkel[state.selectedBuchtsau!.id]!, action.payload],
      },
    };
  },
  [WurfuebersichtActionType.SET_WURF_FERKEL](
    state: WurfuebersichtState,
    action: Action<WurfFerkelDto>
  ): WurfuebersichtState {
    return {
      ...state,
      ...action.payload,
    };
  },
  [WurfuebersichtActionType.DELETE_WURF_FERKEL](
    state: WurfuebersichtState,
    action: Action<null>
  ): WurfuebersichtState {
    return {
      ...state,
      lebendeFerkel: undefined,
      totgeburtFerkel: undefined,
    };
  },
  [WurfuebersichtActionType.UPDATE_FERKEL](
    state: WurfuebersichtState,
    action: Action<WurfuebersichtFerkelDto>
  ): WurfuebersichtState {
    const { id } = action.payload;
    return {
      ...state,
      ferkel: {
        ...state.ferkel,
        [state.selectedBuchtsau!.id]: state.ferkel[state.selectedBuchtsau!.id]!.map(item => {
          if (item.id === id) {
            return { ...item, ...action.payload };
          }
          return item;
        }),
      },
    };
  },
  [WurfuebersichtActionType.UPDATE_FERKEL_WITH_DIFFERENT_SAU](
    state: WurfuebersichtState,
    action: Action<{
      prevSau: string;
      editedFerkel: WurfuebersichtFerkelDto;
      updatedFerkel: WurfuebersichtFerkelDto;
    }>
  ): WurfuebersichtState {
    const { prevSau, editedFerkel, updatedFerkel } = action.payload;
    return {
      ...state,
      ferkel: {
        ...state.ferkel,
        [prevSau]: state.ferkel[prevSau]?.filter(item => item.id !== editedFerkel.id),
        [state.selectedBuchtsau!.id]: [...state.ferkel[state.selectedBuchtsau!.id]!, updatedFerkel],
      },
    };
  },
  [WurfuebersichtActionType.RESET_WURFUEBERSICHT_TABLE](
    state: WurfuebersichtState,
    action: Action<null>
  ): WurfuebersichtState {
    return {
      ...initialState,
    };
  },
  [WurfuebersichtActionType.REMOVE_DELETED_FERKEL](
    state: WurfuebersichtState,
    action: Action<{ id: string; buchtsauId: string }>
  ): WurfuebersichtState {
    const { id, buchtsauId } = action.payload;
    return {
      ...state,
      ferkel: {
        ...state.ferkel,
        [buchtsauId]: state.ferkel[buchtsauId]?.filter(ferkel => ferkel.id !== id),
      },
      lebendeFerkel: state.lebendeFerkel ? state.lebendeFerkel.filter(ferkel => ferkel.id !== id) : undefined,
      totgeburtFerkel: state.totgeburtFerkel
        ? state.totgeburtFerkel.filter(ferkel => ferkel.id !== id)
        : undefined,
    };
  },
});
