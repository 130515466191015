import { Action } from "../action";
import createReducer from "../createReducer";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { BuchtenActionType } from "./buchten.actions";

export interface BuchtenState {
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
  shouldUpdateBuchtenTable: boolean;
}

export const initialState: BuchtenState = {
  loadStatus: loadInitial(),
  lastFetched: undefined,
  shouldUpdateBuchtenTable: false,
};

export default createReducer(initialState, {
  [BuchtenActionType.LOAD_BUCHTEN](state: BuchtenState): BuchtenState {
    return { ...state, loadStatus: loadPending() };
  },
  [BuchtenActionType.LOAD_BUCHTEN_SUCCESS](state: BuchtenState): BuchtenState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [BuchtenActionType.SET_LAST_FETCHED_DATE](state: BuchtenState): BuchtenState {
    return {
      ...state,
      lastFetched: new Date(Date.now()),
    };
  },
  [BuchtenActionType.LOAD_BUCHTEN_FAILURE](state: BuchtenState, action: Action<string>): BuchtenState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [BuchtenActionType.SHOULD_UPDATE_BUCHTEN_TABLE](
    state: BuchtenState,
    action: Action<boolean>
  ): BuchtenState {
    return {
      ...state,
      shouldUpdateBuchtenTable: action.payload,
    };
  },
});
