import WurfuebersichtComponent from "./wurfuebersicht.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { OwnProps, ConnectedState, ConnectedDispatch } from "./wurfuebersicht.types";
import { AppState } from "../../../store/app.state";
import { getFilteredByWorkflowId } from "../../../store/prozess-events/prozess-events.selectors";
import { setBuchtsau } from "../../../store/wurfuebersicht/wurfuebersicht.actions";
import { ProzessDataDto } from "../../../api/backend-api-v7";
import {
  getLebendeFerkel,
  getTotgeburtFerkel,
  getFerkel,
  getSelectedBuchtsau,
} from "../../../store/wurfuebersicht/wurfuebersicht.selectors";
import { getIsManuallyEmpty, isEditModeActivated } from "../../../store/common/common.selectors";
import { getEditedCapturedRecord } from "../../../store/captured-records/captured-records.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    filteredBy: getFilteredByWorkflowId(workflowId)(state),
    buchtsau: getSelectedBuchtsau(state),
    ferkel: getFerkel(state),
    lebendeFerkel: getLebendeFerkel(state),
    totgeburtFerkel: getTotgeburtFerkel(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    editedCapturedRecord: getEditedCapturedRecord(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setBuchtsau: (buchtsau: ProzessDataDto | undefined) => dispatch(setBuchtsau(buchtsau)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WurfuebersichtComponent);
