import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: 0,
      "& div": {
        height: "100%",
      },
    },
    textField: {
      minWidth: "150px",
      width: "100%",
      "& input": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        direction: "rtl",
        textAlign: "left",
        "&:focus": {
          direction: "ltr",
        },
      },
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    validBorder: {
      borderColor: `${theme.additionalColors.ampelGruen}!important`,
    },
    validLabel: {
      "& > .MuiInputLabel-root": {
        color: theme.additionalColors.ampelGruen,
      },
    },
  })
);

export default useStyles;
