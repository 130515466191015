import db, { ILocalFerkelDto } from "../db/database";
import { IndexableType } from "dexie";
import { ProzessType } from "../api/backend-api-v7";

export const defineFerkelProperty = (prozessType: string): keyof ILocalFerkelDto => {
  switch (prozessType) {
    case ProzessType.TIER_IDENT: {
      return "tierIdent";
    }
    case ProzessType.TRANSPONDER: {
      return "transponder";
    }
    case ProzessType.BUCHTSAU: {
      return "buchtsauDescriptor";
    }
    case ProzessType.GENETISCHESAU: {
      return "genetischeSauDescriptor";
    }
    default:
      return prozessType.toLowerCase() as any;
  }
};

export const findFerkelByQuery = async (query: string, value: IndexableType) => {
  if (value) {
    const ferkel = await db.getFerkelByQuery(query, value);
    return ferkel[0];
  }
  return;
};
