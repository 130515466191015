import logger from "../../logger";
import db from "../../db/database";
import { call, takeEvery, takeLeading, put, select } from "redux-saga/effects";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";

import { SynchronizationActionType, synchronizeApp, removeObsoleteData } from "./synchronization.actions";
import { reportProblem } from "../problem-report/problem-report.actions";

import { getIsLoggedIn } from "../authentication/authentication.selectors";
import { trySendProzessEvents } from "../prozess-events/prozess-events.saga";

import { LocalStorageItems } from "../../utils/local-storage.enum";
import { checkLastSyncDate, LastSyncPeriod, isOneDayGone } from "../../utils/datetime.utils";
import { checkInternetConnection } from "../../utils/network-status";

const logError = logger.error("synchronization.saga");
const logInfo = logger.info("synchronization.saga");
const { LAST_SYNC, TRY_TO_SYNC } = LocalStorageItems;

export function* verifyLastSyncDate() {
  try {
    const lastSync: string | undefined = yield call([localStorage, "getItem"], LAST_SYNC);
    if (lastSync) {
      const lastSyncPeriod = checkLastSyncDate(+lastSync);
      switch (lastSyncPeriod) {
        case LastSyncPeriod.MORE_TWO_WEEK: {
          logInfo("Last sync:", LastSyncPeriod.MORE_TWO_WEEK);
          yield put(synchronizeApp({ forceToUpdate: true }));

          const isOnline = checkInternetConnection();
          if (isOnline) {
            yield put(
              reportProblem({
                message: "The app was not synchronized over 2 weeks now",
                shouldRemoveData: true,
              })
            );
          } else {
            yield put(removeObsoleteData(true));
          }
          break;
        }
        case LastSyncPeriod.MORE_ONE_WEEK: {
          logInfo("Last sync:", LastSyncPeriod.MORE_ONE_WEEK);
          yield put(synchronizeApp({ forceToUpdate: true }));
          break;
        }
        default: {
          const dayHasGone = isOneDayGone(+lastSync);
          if (dayHasGone) {
            const tryToSync: string | undefined = yield call([localStorage, "getItem"], TRY_TO_SYNC);

            if (!tryToSync) {
              yield call(tryToSyncApp);
            } else {
              const nextDayHasGone = isOneDayGone(+tryToSync);

              if (nextDayHasGone) {
                yield call(tryToSyncApp);
              }
            }
          }
          break;
        }
      }
    }
  } catch (e) {
    logError("Could not verify last sync date:", e);
  }
}

export function* tryToSyncApp() {
  try {
    yield call([localStorage, "setItem"], TRY_TO_SYNC, JSON.stringify(new Date().getTime()));
    yield put(synchronizeApp({ forceToUpdate: false }));

    logInfo(`Last sync: ${LastSyncPeriod.LESS_ONE_WEEK} and 1 day has gone`);
  } catch (e) {
    logError("Try to sync app failed:", e);
  }
}

export function* trySyncProzessEvents() {
  try {
    const isOnline = checkInternetConnection();
    const isLoggedIn: boolean = yield select(getIsLoggedIn);
    const isSyncDisabled: string = yield call(
      [localStorage, "getItem"],
      LocalStorageItems.DISABLE_SYNCHRONISATION
    );

    if (!isLoggedIn) {
      return;
    }

    if (isSyncDisabled && JSON.parse(isSyncDisabled)) {
      return;
    }

    const unsyncedProzessEvents: IProzessEventsWithAdditionalData[] = yield call(db.getUnsyncedProzessEvents);
    if (unsyncedProzessEvents && unsyncedProzessEvents.length > 0) {
      yield call(trySendProzessEvents, unsyncedProzessEvents);
    } else {
      if (isOnline) {
        yield call([localStorage, "setItem"], LAST_SYNC, JSON.stringify(new Date().getTime()));
        yield call([localStorage, "removeItem"], TRY_TO_SYNC);
      } else {
        yield call(verifyLastSyncDate);
      }
    }

    yield call([db, "deleteHandledProzessEvents"]);
  } catch (e) {
    logError("Could not sync prozess events", e);
  }
}

export default function* synchronizationSaga() {
  yield takeEvery(SynchronizationActionType.SYNC_PROZESS_EVENTS_FAILURE, verifyLastSyncDate);
  yield takeLeading(SynchronizationActionType.SYNC_PROZESS_EVENTS, trySyncProzessEvents);
}
