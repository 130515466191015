import { connect } from "react-redux";

import { AppState } from "../../store/app.state";
import { getFunktionen } from "../../store/funktionen/funktionen.selectors";
import MenuComponent, { ConnectedState } from "./menu.component";
import { Dispatch } from "redux";
import { ConnectedDispatch } from "../menu/menu.component";
import { changeLanguage } from "../../store/i18n/i18n.actions";
import { getCurrentLanguage } from "../../store/user/user.selectors";
import { getAppVersion } from "../../store/prozess-events/prozess-events.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  funktionen: getFunktionen(state),
  appVersion: getAppVersion(state),
  currentLanguage: getCurrentLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  changeLanguage: (language: string) => dispatch(changeLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
