import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: "2px dashed rgba(0, 0, 0, 0.6)",
      borderRadius: "5px",
    },
    dropzone: {
      width: "100%",
      height: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: theme.additionalColors.gray,
      fontWeight: "700",
    },
    text: {
      marginBottom: "10px",
      fontWeight: "500",
    },
    icon: {
      color: "black",
      fontSize: "80px",
    },
    fileName: {
      color: "black",
      fontSize: "28px",
      fontWeight: "500",
      marginTop: "15px",
    },
    error: {
      border: "2px dashed #d32f2f",
    },
  })
);

export default useStyles;
