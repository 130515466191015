import {
  IProzessDataDto,
  IProzessDto,
  ProzessType,
  ISauBuchtDto,
  SauLifeStageTypeEnum,
} from "../api/backend-api-v7";
import { IFilteredLocalSau } from "./sau.utils";

const SAUEN_PROZESS_TYPES = [
  ProzessType.SAU,
  ProzessType.BUCHTSAU,
  ProzessType.GENETISCHESAU,
  ProzessType.WURFUEBERSICHT,
  ProzessType.INFO_FERKEL,
];

export const sauenProzessDataMapper = (prozess: IProzessDto, entities: IFilteredLocalSau[]) => {
  const prozessDataDtos = entities.map((item: IFilteredLocalSau) => {
    const {
      sauNrBetrieb,
      tierSysId,
      abferkeldatum,
      absetzdatum,
      mumien,
      buchten,
      belegNr,
      lifeStageType,
      ammenObjectId,
      sauTransponder,
    } = item;
    return {
      id: tierSysId,
      label: sauNrBetrieb,
      value: tierSysId,
      additional: {
        abferkeldatum,
        absetzdatum,
        genetischesau: lifeStageType === SauLifeStageTypeEnum.Belegung ? tierSysId : null,
        mumien,
        buchten,
        belegNr,
        ammenObjectId,
        sauTransponder: sauTransponder,
      },
    };
  });
  return prozessDataDtos as IProzessDataDto[];
};

const revealLatestDate = (current: ISauBuchtDto, next: ISauBuchtDto) =>
  next.createdAtUtc! > current.createdAtUtc!;

const findExistingBuchtInfo = (tierSysId: string, buchtId: number, sauen: IFilteredLocalSau[]) => {
  const sau = sauen.find(sau => sau.tierSysId === tierSysId);
  return sau!.buchten!.find(bucht => bucht.buchtId === buchtId);
};

export const buchtenProzessDataMapper = (
  prozess: IProzessDto,
  entities: IFilteredLocalSau[]
): IProzessDto => {
  if (entities && entities.length) {
    entities.forEach((sau: IFilteredLocalSau) =>
      sau.buchten!.forEach((bucht: ISauBuchtDto) => {
        prozess.data!.forEach(prozessData => {
          if (prozessData.id === bucht.buchtId) {
            if (!prozessData.additional?.sau) {
              prozessData.additional = { ...prozessData.additional, sau: sau.tierSysId };
            } else {
              const buchtInfo = findExistingBuchtInfo(prozessData.additional.sau, bucht.buchtId!, entities);
              const shouldUpdateDate = buchtInfo ? revealLatestDate(buchtInfo!, bucht) : false;
              if (shouldUpdateDate) {
                prozessData.additional = { ...prozessData.additional, sau: sau.tierSysId };
              }
            }
          }
        });
      })
    );
  }
  return prozess;
};

export const handleProzessData = (prozesse: IProzessDto[], entities: IFilteredLocalSau[]): IProzessDto[] =>
  prozesse.map(prozess => {
    if (prozess.prozessType === ProzessType.BUCHT) {
      // Edge case for bucht prozess.
      return buchtenProzessDataMapper(prozess, entities);
    }
    if (entities && SAUEN_PROZESS_TYPES.includes(prozess.prozessType!)) {
      const data = sauenProzessDataMapper(prozess, entities)!;
      return { ...prozess, data } as IProzessDto;
    }
    return prozess;
  });
