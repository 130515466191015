import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      overflowX: "scroll",
      border: `1px solid ${theme.additionalColors.gray}`,
      borderRadius: "6px",
    },
    cell: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      height: "20%",
      width: "25%",
      minWidth: "25%",
      minHeight: "20%",
      border: `1px solid ${theme.additionalColors.gray}`,
    },
    index: {
      position: "absolute",
      left: "5px",
      color: theme.additionalColors.gray,
    },
    transponder: {
      fontWeight: "bold",
    },
    deleteButton: {
      position: "absolute",
      right: "10px",
      width: "35px",
      height: "30px",
      backgroundColor: "rgb(227,35,56)",
      borderRadius: "4px",
      color: "white",
      "&:focus": {
        outline: "none",
        border: "none",
      },
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    editingValue: {
      backgroundColor: theme.additionalColors.ampelGelb,
    },
  })
);

export default useStyles;
