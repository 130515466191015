import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalSauLifeStageDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const absetzdatumProzessEventHandler = (
  lifeStage: ILocalSauLifeStageDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const absetzdatum = prozessEvents.find(pe => pe.prozessType === ProzessType.ABSETZDATUM);

  if (absetzdatum) {
    const updatedSauLifeStage: ILocalSauLifeStageDto = {
      ...lifeStage,
      absetzdatum: new Date(absetzdatum.data).getTime(),
    };
    return next(updatedSauLifeStage, prozessEvents);
  }

  return next(lifeStage, prozessEvents);
};
