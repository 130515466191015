import { FunctionComponent } from "react";
import { Props } from "./force-update.types";
import { useTranslation } from "react-i18next";
import useStyles from "./force-update.styles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ForceUpdateComponent: FunctionComponent<Props> = props => {
  const { open } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth={true} PaperProps={{ className: classes.dialog }}>
      <DialogTitle classes={{ root: classes.title }}>{t("MODAL.FORCE_UPDATE.TITLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("MODAL.FORCE_UPDATE.TEXT")}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          data-cy="update"
          variant="contained"
          color="secondary"
          onClick={() => window.location.reload()}
        >
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForceUpdateComponent;
