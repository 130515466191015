import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { initialState, uploadReducer } from "./upload.reducer";
import { useCallback, useReducer } from "react";
import { ITextFileDto, UploadActionType } from "./upload.types";

export const useUploadState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean
) => {
  const [state, dispatch] = useReducer(uploadReducer, initialState);
  const { isRequired } = prozess;

  const reset = useCallback(
    () =>
      dispatch({
        type: UploadActionType.RESET,
        payload: { file: null, isRequired, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired]
  );

  const onProzessDataChanged = useCallback(
    (file: ITextFileDto) => {
      dispatch({
        type: UploadActionType.PROZESS_DATA_CHANGED,
        payload: {
          file,
          isRequired,
          isValid: !!file,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired]
  );

  const save = useCallback(
    (file: ITextFileDto | null) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: file,
        additionalData: undefined,
        operation: file ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!file : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.changeEventType,
      prozess.eventType,
      prozess.eventCategory,
      prozess.isRequired,
      prozess.prozessType,
      isEditing,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: UploadActionType.PROZESS_DATA_EDITED,
        payload: {
          file: null,
          isRequired,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired]
  );

  return { state, reset, onProzessDataChanged, save, shouldEditProzessData };
};
