import ChangeGewichtModeDialogComponent, {
  ConnectedDispatch,
  ConnectedState,
} from "./change-gewicht-mode.dialog.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/app.state";
import { getShouldUseManualGewichtValue } from "../../store/bluetooth/bluetooth.selectors";
import { setShouldUseManualWaageValue } from "../../store/bluetooth/bluetooth.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  shouldUseManualGewichtValue: getShouldUseManualGewichtValue(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setShouldUseManualGewichtValue: (shouldUseManualValue: boolean) =>
    dispatch(setShouldUseManualWaageValue(shouldUseManualValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeGewichtModeDialogComponent);
