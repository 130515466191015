import logger from "../../logger";
import {
  TransitoryProzessDataActionType,
  setTransponder,
  TransponderForDeletion,
  setDetailsFerkelInfo,
  setDataExport,
  IDataExportDto,
} from "./transitory-prozess-data.actions";
import { put, select, takeEvery } from "redux-saga/effects";
import { Action } from "../action";
import { TransponderDto } from "../../components/prozesse/gruppenerfassung/gruppenerfassung.types";
import { checkInternetConnection } from "../../utils/network-status";
import { showFailedRequest } from "../notifications/notifications.actions";
import {
  getCurrentFunktionId,
  getProzesseByFunktionId,
  getProzessWhichCanCreateMultipleEvents,
} from "../funktionen/funktionen.selectors";
import { getTranspondersForDeletion } from "./transitory-prozess-data.selectors";
import {
  ICapturedRecordsByIdsRequestDto,
  ICapturedRecordsResponseDto,
  IDataOverviewDto,
  IProzessDto,
} from "../../api/backend-api-v7";
import { call } from "redux-saga-test-plan/matchers";
import BackendClient from "../../api/backend-client";
import { Dispatch } from "redux";
import { mapFerkelInfoToCapturedRecords } from "./transitory-prozess-data.utils";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { getIsDifferentialWeightingActive } from "../common/common.selectors";
import { increaseTotalAmountOfGewicht } from "../bluetooth/bluetooth.actions";
import { ControlTypes } from "../../pages/funktion/controltypes.enum";

const logError = logger.error("transitory-prozess-data.saga");
let backendClient: BackendClient;

export interface TransitoryDataForDeletion {
  valueForDeletion: TransponderForDeletion[];
  idForDeletion: number | undefined;
}

export function* trySetTransponder(action: Action<TransponderDto>) {
  try {
    const transponder = action.payload;
    const isOnline = checkInternetConnection();
    if (isOnline) {
      yield put(setTransponder(transponder));
    } else {
      yield put(showFailedRequest("NOTIFICATIONS.FAILED_CONNECT_TO_SERVER"));
    }
  } catch (e) {
    logError("Could not save deleted transponder from gruppenerfassung table", e);
  }
}

export function* revealDataForDeletion() {
  const funktionId: number = yield select(getCurrentFunktionId);
  const dataForDeletion: TransponderForDeletion[] = yield select(getTranspondersForDeletion);

  const prozessWhichCanCreateMultipleEvents: IProzessDto = yield select(
    getProzessWhichCanCreateMultipleEvents(funktionId)
  );

  return {
    valueForDeletion: dataForDeletion ? dataForDeletion : undefined,
    idForDeletion: prozessWhichCanCreateMultipleEvents
      ? prozessWhichCanCreateMultipleEvents.workflowId
      : undefined,
  };
}

export function* getDetailsFerkelInfo(action: Action<ICapturedRecordsByIdsRequestDto>) {
  try {
    const ids = action.payload;
    const response: ICapturedRecordsResponseDto = yield call([backendClient, "getDetailsFerkelInfo"], ids);

    const ferkelInfoAsCapturedRecords = mapFerkelInfoToCapturedRecords(response.records!);
    yield put(setDetailsFerkelInfo(ferkelInfoAsCapturedRecords));
  } catch (e) {
    logError("Could not get details information about ferkel", e);
  }
}

export function* uptateTotalAmountOfGewicht(prozessEvents: IProzessEventsWithAdditionalData[]) {
  try {
    const isDifferentialWeightingActive: boolean = yield select(getIsDifferentialWeightingActive);
    const funktionId: number = yield select(getCurrentFunktionId);
    const prozesse: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));

    if (isDifferentialWeightingActive) {
      const gewichtDifferentialProzess: IProzessDto | undefined = prozesse.find(
        prozess => prozess.controlType === ControlTypes.GEWICHT_DIFFERENTIAL
      );

      const transitoryValue = prozessEvents.find(
        prozessEvent => prozessEvent.workflowId === gewichtDifferentialProzess!.workflowId
      )?.data;

      if (transitoryValue) {
        yield put(increaseTotalAmountOfGewicht(+transitoryValue));
      }
    }
  } catch (e) {
    logError("Could not uptate total amount of gewicht", e);
  }
}

export function* getDataExport(action: Action<IDataExportDto>) {
  try {
    const { funktionId, dataExportConfigurationId } = action.payload;

    const response: IDataOverviewDto | undefined = yield call(
      [backendClient, "getDataExport"],
      funktionId,
      dataExportConfigurationId
    );
    yield put(setDataExport(response));
  } catch (e) {
    logError("Could not fetch data export", e);
    yield put(setDataExport(undefined));
  }
}

export default function* transitoryProzessDataSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(TransitoryProzessDataActionType.TRY_SET_TRANSPONDER, trySetTransponder);
  yield takeEvery(TransitoryProzessDataActionType.GET_DETAILS_FERKEL_INFO, getDetailsFerkelInfo);
  yield takeEvery(TransitoryProzessDataActionType.GET_DATA_EXPORT, getDataExport);
}
