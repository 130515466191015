import GewichtSettingsComponent, { ConnectedState } from "./gewicht-settings.component";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getGewichtConfiguration } from "../../../store/bluetooth/bluetooth.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  gewichtConfiguration: getGewichtConfiguration(state),
});

export default connect(mapStateToProps)(GewichtSettingsComponent);
