import { FunctionComponent, useCallback, useState } from "react";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import BluetoothSimulatorSettingsContainer from "./bluetooth-simulator-settings.container";
import useStyles from "./bluetooth-simulator.styles";
import { BluetoothConnectionStatus } from "../../store/bluetooth/bluetooth.reducer";

export interface ConnectedState {
  bluetoothConnectionStatus: BluetoothConnectionStatus;
  connectedBluetoothDevice: string | undefined;
}

export interface ConnectedDispatch {
  startSimulation: () => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

export const BLUETOOTH_SIMULATION_NAME = "simulated-bt-device";

const BluetoothSimulatorComponent: FunctionComponent<Props> = props => {
  const { bluetoothConnectionStatus, startSimulation, connectedBluetoothDevice } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const classes = useStyles();

  const onClickHandler = () => {
    if (
      bluetoothConnectionStatus === BluetoothConnectionStatus.DISCONNECTED ||
      connectedBluetoothDevice !== BLUETOOTH_SIMULATION_NAME
    ) {
      startSimulation();
    }
    setIsDialogOpen(true);
  };

  const closeDialogHandler = () => {
    setIsDialogOpen(false);
  };

  const revealClassName = useCallback(
    (active: string, inactive: string) => {
      if (
        bluetoothConnectionStatus === BluetoothConnectionStatus.CONNECTED &&
        connectedBluetoothDevice &&
        connectedBluetoothDevice === BLUETOOTH_SIMULATION_NAME
      ) {
        return active;
      } else {
        return inactive;
      }
    },
    [bluetoothConnectionStatus, connectedBluetoothDevice]
  );

  return (
    <>
      <SettingsRemoteIcon
        className={revealClassName(classes.active, classes.inactive)}
        onClick={onClickHandler}
        data-cy="settings-icon"
      />
      {isDialogOpen && (
        <BluetoothSimulatorSettingsContainer open={isDialogOpen} closeDialogHandler={closeDialogHandler} />
      )}
    </>
  );
};

export default BluetoothSimulatorComponent;
