import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";

export const funktionHistoryHandler = (
  ferkel: ILocalFerkelDto | undefined,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const { recordId, funktionId } = prozessEvents[0];

  if (ferkel) {
    const updatedFerkel = {
      ...ferkel,
      funktionenHistory: [...ferkel.funktionenHistory!, { recordId, funktionId, previousData: ferkel }],
    };

    return next(updatedFerkel, prozessEvents);
  }

  // In case Ferkel doesn't exists start to create it.
  const newFerkel = {
    creationRecordId: recordId,
    funktionenHistory: [{ recordId, funktionId }],
    createdAtUtc: new Date().getTime(),
    modifiedAtUtc: new Date().getTime(),
  } as ILocalFerkelDto;

  return next(newFerkel, prozessEvents);
};
