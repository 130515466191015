import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalSauLifeStageDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const mumienProzessEventHandler = (
  lifeStage: ILocalSauLifeStageDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const mumien = prozessEvents.find(pe => pe.prozessType === ProzessType.MUMIEN);

  if (mumien) {
    const updatedSauLifeStage: ILocalSauLifeStageDto = { ...lifeStage, mumien: mumien.data[0] };
    return next(updatedSauLifeStage, prozessEvents);
  }

  return next(lifeStage, prozessEvents);
};
