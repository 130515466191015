import "./wdyr";
import "./index.css";

import { createRoot } from "react-dom/client";

import { LocalStorageItems } from "./utils/local-storage.enum";
import { DEBUG_BASE } from "./logger";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ThemeComponent from "./theme-root";

const { DEBUG, USE_BLUETOOTH_SIMULATION, DISABLE_SYNCHRONISATION } = LocalStorageItems;

if (process.env.NODE_ENV !== "production") {
  localStorage.setItem(DEBUG, `${DEBUG_BASE}*`);
  localStorage.setItem(USE_BLUETOOTH_SIMULATION, "true");
  localStorage.setItem(DISABLE_SYNCHRONISATION, "false");
} else {
  localStorage.setItem(USE_BLUETOOTH_SIMULATION, "false");
  localStorage.setItem(DISABLE_SYNCHRONISATION, "false");
}

const root = createRoot(document.getElementById("root")!);
root.render(<ThemeComponent />);

// if (module.hot) {
//   module.hot.accept("./redux-root", () => {
//     const NextApp = require("./redux-root").default;
//     ReactDOM.render(<NextApp />, rootEl);
//   });
// }

serviceWorkerRegistration.register();
