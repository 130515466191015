import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { ProzessDataDto, IProzessDto } from "../../../api/backend-api-v7";

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  givenDataApplied: (workflowId: number) => void;
}

export interface ConnectedState {
  givenData: ProzessInputData | undefined;
  prevRecordId: string;
  isManuallyEmpty: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
}

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  data: ProzessDataDto[];
  shouldValidate: boolean;
}

export interface GeschlechtState {
  geschlecht: string;
  isError: boolean;
  isValid: boolean;
  isProzessEdited?: boolean;
}

export interface GeschlechtAction {
  type: GeschlechtActionType;
  payload: {
    value: string | string[] | undefined;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
    isProzessEdited?: boolean;
  };
}

export enum GeschlechtActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  TOGGLE,
  RESET,
  PROZESS_DATA_EDITED,
}
