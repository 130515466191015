import { Action } from "../action";
import { IRemoteCapturedRecordsState } from "./captured-records.reducer";
import { ICapturedRecordRequest } from "../../api/api-interfaces";
import { ISyncedCapturedRecord } from "../../db/database";
import { ISendDataExportRequestDto } from "../../api/backend-api-v7";

export enum CapturedRecordsActionType {
  FETCH_LOCAL_CAPTURED_RECORDS = "FETCH_LOCAL_CAPTURED_RECORDS",
  SET_LOCAL_CAPTURED_RECORDS = "SET_LOCAL_CAPTURED_RECORDS",
  FETCH_REMOTE_CAPTURED_RECORDS = "FETCH_REMOTE_CAPTURED_RECORDS",
  SET_REMOTE_CAPTURED_RECORDS = "SET_REMOTE_CAPTURED_RECORDS",
  SET_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE = "SET_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE",
  REMOVE_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE = "REMOVE_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE",
  FAILED_TO_FETCH_REMOTE_CAPTURED_RECORDS = "FAILED_TO_FETCH_REMOTE_CAPTURED_RECORDS",
  CLEAR_CAPTURED_RECORDS = "CLEAR_CAPTURED_RECORDS",
  GET_CAPTURED_RECORD_FOR_EDIT = "GET_CAPTURED_RECORD_FOR_EDIT",
  START_EDIT_CAPTURED_RECORD = "START_EDIT_CAPTURED_RECORD",
  DELETE_CAPTURED_RECORD = "DELETE_CAPTURED_RECORD",
  FINISH_EDIT_CAPTURED_RECORD = "FINISH_EDIT_CAPTURED_RECORD",
  RESEND_DATA_EXPORT_FOR_CAPTURED_RECORD = "RESEND_DATA_EXPORT_FOR_CAPTURED_RECORD",
}

export const fetchLocalCapturedRecords = (funktionId: number): Action<number> => ({
  type: CapturedRecordsActionType.FETCH_LOCAL_CAPTURED_RECORDS,
  payload: funktionId,
});

export const setLocalCapturedRecords = (
  funktionId: number,
  localRecords: ISyncedCapturedRecord[]
): Action<{ funktionId: number; localRecords: ISyncedCapturedRecord[] }> => ({
  type: CapturedRecordsActionType.SET_LOCAL_CAPTURED_RECORDS,
  payload: { funktionId, localRecords },
});

export const fetchRemoteCapturedRecords = (
  funktionId: number,
  limit: number,
  type: string
): Action<Partial<ICapturedRecordRequest>> => ({
  type: CapturedRecordsActionType.FETCH_REMOTE_CAPTURED_RECORDS,
  payload: { funktionId, limit, type },
});

export const setRemoteCapturedRecords = (
  funktionId: number,
  remote: IRemoteCapturedRecordsState
): Action<{ funktionId: number; remote: IRemoteCapturedRecordsState }> => ({
  type: CapturedRecordsActionType.SET_REMOTE_CAPTURED_RECORDS,
  payload: { funktionId, remote },
});

export const setNoMoreRemoteCapturedRecordsAvailable = (
  funktionId: number,
  lastFetched: Date
): Action<{ funktionId: number; lastFetched: Date }> => ({
  type: CapturedRecordsActionType.SET_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE,
  payload: { funktionId, lastFetched },
});

export const removeNoMoreRemoteCapturedRecordsAvailable = (funktionId: number): Action<number> => ({
  type: CapturedRecordsActionType.REMOVE_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE,
  payload: funktionId,
});

export const failedToFetchRemoteCapturedRecords = (message: string): Action<string> => ({
  type: CapturedRecordsActionType.FAILED_TO_FETCH_REMOTE_CAPTURED_RECORDS,
  payload: message,
});

export const clearCapturedRecords = (): Action<null> => ({
  type: CapturedRecordsActionType.CLEAR_CAPTURED_RECORDS,
  payload: null,
});

export const getCapturedRecordForEdit = (recordId: string): Action<string> => ({
  type: CapturedRecordsActionType.GET_CAPTURED_RECORD_FOR_EDIT,
  payload: recordId,
});

export const startEditCapturedRecord = (record: ISyncedCapturedRecord): Action<ISyncedCapturedRecord> => ({
  type: CapturedRecordsActionType.START_EDIT_CAPTURED_RECORD,
  payload: record,
});

export const finishEditCapturedRecord = (): Action<null> => ({
  type: CapturedRecordsActionType.FINISH_EDIT_CAPTURED_RECORD,
  payload: null,
});

export const deleteCapturedRecord = (record: ISyncedCapturedRecord): Action<ISyncedCapturedRecord> => ({
  type: CapturedRecordsActionType.DELETE_CAPTURED_RECORD,
  payload: record,
});

export const resendDataExportForCapturedRecord = (
  recordData: ISendDataExportRequestDto
): Action<ISendDataExportRequestDto> => ({
  type: CapturedRecordsActionType.RESEND_DATA_EXPORT_FOR_CAPTURED_RECORD,
  payload: recordData,
});
