import { AppState } from "../app.state";
import { createSelector } from "reselect";
import { UserSettingsDto } from "../../api/backend-api-v7";

export const FALLBACK_LANGUAGE = "de";

export const getUserSettings = (state: AppState): any => state.user.settings;

export const getUserSettingsStatus = (state: AppState) => state.user.loadStatus;

export const getCurrentLanguage = createSelector([getUserSettings], (userSettings: UserSettingsDto) =>
  userSettings && userSettings.language ? userSettings.language : FALLBACK_LANGUAGE
);
