import { AppState } from "../app.state";
import { createSelector } from "reselect";
import { WurfuebersichtFerkelDto } from "./wurfuebersicht.actions";
import { ProzessDataDto } from "../../api/backend-api-v7";

export const getLebendeFerkel = (state: AppState): WurfuebersichtFerkelDto[] | undefined =>
  state.wurfuebersicht.lebendeFerkel;

export const getTotgeburtFerkel = (state: AppState): WurfuebersichtFerkelDto[] | undefined =>
  state.wurfuebersicht.totgeburtFerkel;

export const getFerkel = (state: AppState): any => state.wurfuebersicht.ferkel;

export const getFerkelByBuchtsauId = (buchtsauId: string) =>
  createSelector([getFerkel], (ferkel: { [buchtsauId: string]: WurfuebersichtFerkelDto[] | undefined }) =>
    ferkel ? ferkel[buchtsauId] : undefined
  );

export const getSelectedBuchtsau = (state: AppState): ProzessDataDto | undefined =>
  state.wurfuebersicht.selectedBuchtsau;
