export enum Colors {
  SchmuckfarbeCyan = "#229bd3",
  SchmuckfarbeViolett = "#b067a2",
  AmpelGruen = "#3ba858",
  AmpelGelb = "#fecc00",
  AmpelRot = "#e32118",
  PrimaryLight = "#f3f3f4",
  PrimaryMain = "#e0e0e0",
  PrimaryDark = "#4b4b4d",
  White = "#FFFFFF",
  BluetoothBlue = "#1854fb",
  Gray = "#808080",
  DimGray = "#696969",
  LightGray = "#9a9a9a",
  Black = "#000000",
}
