import {
  ICapturedRecordsByIdsRequestDto,
  IDataOverviewDto,
  IProzessEventsValidationResultDto,
} from "../../api/backend-api-v7";
import { TransponderDto } from "../../components/prozesse/gruppenerfassung/gruppenerfassung.types";
import { ISyncedCapturedRecord } from "../../db/database";
import { Action } from "../action";

export interface TransponderForDeletion {
  value: string;
  recordId: string | undefined;
}

export interface IDataExportDto {
  dataExportConfigurationId: number;
  funktionId: number;
}

export enum TransitoryProzessDataActionType {
  TRY_SET_TRANSPONDER = "TRY_SET_TRANSPONDER",
  SET_TRANSPONDER = "SET_TRANSPONDER",
  CLEAR_TRANSPONDERS = "CLEAR_TRANSPONDERS",
  GET_DETAILS_FERKEL_INFO = "GET_DETAILS_FERKEL_INFO",
  SET_DETAILS_FERKEL_INFO = "SET_DETAILS_FERKEL_INFO",
  REMOVE_DETAILS_FERKEL_INFO = "REMOVE_DETAILS_FERKEL_INFO",
  GET_DATA_EXPORT = "GET_DATA_EXPORT",
  SET_DATA_EXPORT = "SET_DATA_EXPORT",
  SET_DATA_IMPORT_VALIDATION_ERRORS = "SET_DATA_IMPORT_VALIDATION_ERRORS",
}

export const trySetTransponder = (transponder: TransponderDto): Action<TransponderDto> => ({
  type: TransitoryProzessDataActionType.TRY_SET_TRANSPONDER,
  payload: transponder,
});

export const setTransponder = (transponder: TransponderDto): Action<TransponderDto> => ({
  type: TransitoryProzessDataActionType.SET_TRANSPONDER,
  payload: transponder,
});

export const clearTransponders = (): Action<null> => ({
  type: TransitoryProzessDataActionType.CLEAR_TRANSPONDERS,
  payload: null,
});

export const getDetailsFerkelInfo = (
  ids: ICapturedRecordsByIdsRequestDto
): Action<ICapturedRecordsByIdsRequestDto> => ({
  type: TransitoryProzessDataActionType.GET_DETAILS_FERKEL_INFO,
  payload: ids,
});

export const setDetailsFerkelInfo = (data: ISyncedCapturedRecord[]): Action<ISyncedCapturedRecord[]> => ({
  type: TransitoryProzessDataActionType.SET_DETAILS_FERKEL_INFO,
  payload: data,
});

export const removeDetailsFerkelInfo = (): Action<null> => ({
  type: TransitoryProzessDataActionType.REMOVE_DETAILS_FERKEL_INFO,
  payload: null,
});

export const getDataExport = (data: IDataExportDto): Action<IDataExportDto> => ({
  type: TransitoryProzessDataActionType.GET_DATA_EXPORT,
  payload: data,
});

export const setDataExport = (data: IDataOverviewDto | undefined): Action<IDataOverviewDto | undefined> => ({
  type: TransitoryProzessDataActionType.SET_DATA_EXPORT,
  payload: data,
});

export const setValidationErrors = (
  validationErrors: IProzessEventsValidationResultDto | undefined
): Action<IProzessEventsValidationResultDto | undefined> => ({
  type: TransitoryProzessDataActionType.SET_DATA_IMPORT_VALIDATION_ERRORS,
  payload: validationErrors,
});
