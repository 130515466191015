import { Action } from "../action";

export enum PlanFerkelActionType {
  LOAD_PLAN_FERKEL = "LOAD_PLAN_FERKEL",
  LOAD_PLAN_FERKEL_SUCCESS = "LOAD_PLAN_FERKEL_SUCCESS",
  LOAD_PLAN_FERKEL_FAILURE = "LOAD_PLAN_FERKEL_FAILURE",
  UPDATE_LOCAL_PLAN_FERKEL = "UPDATE_LOCAL_PLAN_FERKEL",
  SET_LAST_FETCHED_DATE = "SET_LAST_FETCHED_DATE",
}

export const loadPlanFerkel = (): Action<null> => ({
  type: PlanFerkelActionType.LOAD_PLAN_FERKEL,
  payload: null,
});

export const loadPlanFerkelSuccess = (): Action<null> => ({
  type: PlanFerkelActionType.LOAD_PLAN_FERKEL_SUCCESS,
  payload: null,
});

export const loadPlanFerkelFailure = (errorMessage: string): Action<string> => ({
  type: PlanFerkelActionType.LOAD_PLAN_FERKEL_FAILURE,
  payload: errorMessage,
});

export const updatePlanFerkel = (): Action<null> => ({
  type: PlanFerkelActionType.UPDATE_LOCAL_PLAN_FERKEL,
  payload: null,
});

export const setLastFetchedDate = (): Action<null> => ({
  type: PlanFerkelActionType.SET_LAST_FETCHED_DATE,
  payload: null,
});
