import { Action } from "../action";
import createReducer from "../createReducer";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { ILocalFerkelDto } from "../../db/database";
import { FerkelActionType } from "./ferkel.actions";

export interface FerkelState {
  loadStatus: LoadingStatus;
  filteredFerkel: ILocalFerkelDto[];
  lastFetched: Date | undefined;
}

export const initialState: FerkelState = {
  filteredFerkel: [],
  loadStatus: loadInitial(),
  lastFetched: undefined,
};

export default createReducer(initialState, {
  [FerkelActionType.LOAD_FERKEL](state: FerkelState): FerkelState {
    return { ...state, loadStatus: loadPending() };
  },
  [FerkelActionType.LOAD_FERKEL_SUCCESS](state: FerkelState): FerkelState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [FerkelActionType.SET_LAST_FETCHED_DATE](state: FerkelState): FerkelState {
    return {
      ...state,
      lastFetched: new Date(Date.now()),
    };
  },
  [FerkelActionType.LOAD_FERKEL_FAILURE](state: FerkelState, action: Action<string>): FerkelState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [FerkelActionType.SET_FILTERED_FERKEL](state: FerkelState, action: Action<ILocalFerkelDto[]>): FerkelState {
    const filteredFerkel = action.payload;
    return {
      ...state,
      filteredFerkel,
    };
  },
});
