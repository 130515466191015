import { AppState } from "../app.state";
import { createSelector } from "reselect";
import { ICapturedRecords, ICapturedRecordsState } from "./captured-records.reducer";
import { ISyncedCapturedRecord } from "../../db/database";

const getCapturedRecordsStateByFunktionId = (funktionId: number) =>
  createSelector(
    (state: AppState) => state.capturedRecords,
    (state: ICapturedRecordsState) => state[funktionId]
  );

export const getLastLocalCapturedRecordsByFunktionId = (funktionId: number, amount: number) =>
  createSelector(getCapturedRecordsStateByFunktionId(funktionId), (capturedRecords: ICapturedRecords) =>
    !!capturedRecords && !!capturedRecords.localRecords ? capturedRecords.localRecords.slice(0, amount) : []
  );

export const getLocalCapturedRecordsByFunktionId = (funktionId: number) =>
  createSelector(getCapturedRecordsStateByFunktionId(funktionId), (capturedRecords: ICapturedRecords) =>
    capturedRecords ? capturedRecords.localRecords : []
  );

export const getRemoteCapturedRecordsByFunktionId = (funktionId: number) =>
  createSelector(getCapturedRecordsStateByFunktionId(funktionId), (capturedRecords: ICapturedRecords) =>
    capturedRecords ? capturedRecords.remote : undefined
  );

export const getRemoteCapturedRecordsOffset = (funktionId: number) =>
  createSelector(getCapturedRecordsStateByFunktionId(funktionId), (capturedRecords: ICapturedRecords) =>
    capturedRecords && capturedRecords.remote ? capturedRecords.remote.offset : 0
  );

export const getEditedCapturedRecord = (state: AppState) => state.capturedRecords.editedRecord;

export const getRecordsLoadStatus = (state: AppState) => state.capturedRecords.loadStatus;

export const getEditedValueByWorkflowId = (workFlowId: number) =>
  createSelector([getEditedCapturedRecord], (editedRecord: ISyncedCapturedRecord | undefined) =>
    editedRecord ? editedRecord.data![workFlowId] : undefined
  );
