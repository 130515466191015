import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ISauLifeStageDescriptorDto, ProzessType } from "../../../api/backend-api-v7";

export const buchtsauProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const buchtsau = prozessEvents.find(pe => pe.prozessType === ProzessType.BUCHTSAU);

  if (buchtsau) {
    const { ammenObjectId, belegNr, tierSysId } = buchtsau.data as ISauLifeStageDescriptorDto;

    const updateDescriptor = (): ISauLifeStageDescriptorDto =>
      belegNr
        ? { tierSysId, belegNr, ammenObjectId: undefined }
        : { tierSysId, ammenObjectId, belegNr: undefined };

    const updatedFerkel: ILocalFerkelDto = {
      ...ferkel,
      buchtsauDescriptor: updateDescriptor(),
    };
    return next(updatedFerkel, prozessEvents);
  }
  return next(ferkel, prozessEvents);
};
