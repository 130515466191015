import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "../../store/app.state";
import {
  isBluetoothConnected,
  isBluetoothConnecting,
  isBluetoothRequired,
} from "../../store/bluetooth/bluetooth.selectors";
import { getFunktionById, getProzesseByFunktionId } from "../../store/funktionen/funktionen.selectors";
import {
  createNewRecord,
  saveProzessEvents,
  clearProzessEvents,
} from "../../store/prozess-events/prozess-events.actions";
import FunktionComponent from "./funktion.component";
import { resetBluetoothData, resetTotalOfGewicht } from "../../store/bluetooth/bluetooth.actions";
import { setCurrentFunktionId } from "../../store/funktionen/funktionen.actions";
import {
  getallProzessEventsValidInCreationMode,
  getCurrentRecordId,
  getSynchronousProcessingStatus,
  getRecordProcessingStatus,
} from "../../store/prozess-events/prozess-events.selectors";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./funktion.types";
import { getEditedCapturedRecord } from "../../store/captured-records/captured-records.selectors";
import { finishEditCapturedRecord } from "../../store/captured-records/captured-records.actions";
import { setIsManuallyEmpty, toggleEditMode } from "../../store/common/common.actions";
import { IFunktionDto, ProzessDataFilterDto } from "../../api/backend-api-v7";
import { filterFerkel } from "../../store/ferkel/ferkel.actions";
import { closeActiveChannel } from "../../store/bluetooth/bluetooth-data-simulation.actions";
import { clearTransponders } from "../../store/transitory-prozess-data/transitory-prozess-data.actions";
import { filterSauen } from "../../store/sauen/sauen.actions";
import { isEditModeActivated } from "../../store/common/common.selectors";

const mapStateToProps = (state: AppState, props: OwnProps): ConnectedState => {
  const funktionId = props.params.funktionId;

  return {
    funktion: funktionId ? getFunktionById(+funktionId)(state) : undefined,
    prozesse: funktionId ? getProzesseByFunktionId(+funktionId)(state) : undefined,
    bluetoothConnected: isBluetoothConnected(state),
    bluetoothConnecting: isBluetoothConnecting(state),
    bluetoothRequired: funktionId ? isBluetoothRequired(+funktionId)(state) : false,
    recordId: getCurrentRecordId(state),
    allProzessEventsValidInCreationMode: getallProzessEventsValidInCreationMode(state),
    editedCapturedRecord: getEditedCapturedRecord(state),
    isEditing: isEditModeActivated(state),
    synchronousProcessingStatus: getSynchronousProcessingStatus(state),
    recordProcessingStatus: getRecordProcessingStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  saveProzessEvents: () => dispatch(saveProzessEvents()),
  resetBluetoothData: () => {
    dispatch(closeActiveChannel());
    dispatch(resetBluetoothData());
  },
  setCurrentFunktionId: (funktionId: number | undefined) => dispatch(setCurrentFunktionId(funktionId)),
  createNewRecord: () => dispatch(createNewRecord()),
  finishEditCapturedRecord: () => {
    dispatch(finishEditCapturedRecord());
    dispatch(clearTransponders());
    dispatch(toggleEditMode(false));
    dispatch(resetTotalOfGewicht());
  },
  filterFerkel: (funktion: IFunktionDto) => dispatch(filterFerkel(funktion)),
  filterSauen: (prozessDataFilters: ProzessDataFilterDto[]) => dispatch(filterSauen(prozessDataFilters)),
  setIsManuallyEmpty: (shouldBeEmpty: boolean) => dispatch(setIsManuallyEmpty(shouldBeEmpty)),
  clearProzessEvents: () => dispatch(clearProzessEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunktionComponent);
