import { useCallback, useEffect, FunctionComponent, useState } from "react";
import useStyles from "./wurfuebersicht.styles";
import { useTranslation } from "react-i18next";
import WurfuebersichtDetailsContainer from "./details-info/wurfuebersicht-details.container";
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { OwnProps, ConnectedState, ConnectedDispatch } from "./wurfuebersicht.types";
import { WurfuebersichtFerkelDto } from "../../../store/wurfuebersicht/wurfuebersicht.actions";
import { Colors } from "../../../theme/colors";

enum GeschlechtType {
  EBER = "Eber",
  SAU = "Sau",
  KASTRAT = "Kastrat",
}

const geschlechtDto = [
  { id: 1, geschlecht: GeschlechtType.EBER },
  { id: 2, geschlecht: GeschlechtType.SAU },
  { id: 3, geschlecht: GeschlechtType.KASTRAT },
];

interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

const WurfuebersichtComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    prozess,
    setBuchtsau,
    filteredBy,
    buchtsau,
    lebendeFerkel,
    totgeburtFerkel,
    ferkel,
    isManuallyEmpty,
    funktionId,
    editedCapturedRecord,
    isEditing,
  } = props;
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState<boolean>(false);

  const setSelectedBuchtsau = useCallback(() => {
    if (filteredBy) {
      const selectedBuchtsau = prozess.data?.find(item => item.id === filteredBy.data.tierSysId);
      setBuchtsau(selectedBuchtsau);
    } else {
      setBuchtsau(undefined);
    }
  }, [filteredBy, prozess.data, setBuchtsau]);

  const calculateFerkelByGeschlecht = (ferkelData: WurfuebersichtFerkelDto[], geschlecht: string) => {
    if (ferkelData) {
      let count = 0;
      ferkelData.forEach(item => item.geschlecht === geschlecht && count++);
      return count;
    }
  };

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedCapturedRecord) {
      // Find buchtsau data for displaying table with info in edit mode.
      const buchtsauEditedValue = editedCapturedRecord.data![prozess.filteredByWorkflowId!];
      const editingBuchtsauData = prozess.data?.find(item => item.label === buchtsauEditedValue);
      setBuchtsau(editingBuchtsauData);
    }
  }, [editedCapturedRecord, isEditing, prozess.data, prozess.filteredByWorkflowId, setBuchtsau]);

  const detailsDialogClickHandler = useCallback(() => {
    setIsDetailsDialogOpen(!isDetailsDialogOpen);
  }, [isDetailsDialogOpen]);

  useEffect(() => {
    setSelectedBuchtsau();
  }, [setSelectedBuchtsau, isManuallyEmpty]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  return (
    <div style={{ gridArea: props.prozess.position }} data-cy="WurfuebersichtComponent">
      <div className={classes.header}>
        <div className={classes.wurfuebersicht}>
          {`${prozess.label!}: ${buchtsau ? buchtsau.label : "-"}`}
        </div>
        <Button className={classes.detailsButton} onClick={detailsDialogClickHandler} disabled={!buchtsau}>
          ...
        </Button>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} style={{ color: Colors.AmpelGruen }} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.GESAMT")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.EBER")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.SAU")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.KASTRAT")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.GESAMT_TOTAL")}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              style={{ color: Colors.AmpelGruen }}
              align="center"
              data-cy="gesamt-ferkel"
            >
              {ferkel[buchtsau?.id]?.length}
            </TableCell>
            {geschlechtDto.map(item => (
              <TableCell
                key={item.id}
                className={classes.tableCell}
                align="center"
                data-cy={`geshlecht-${item.geschlecht}`}
              >
                {calculateFerkelByGeschlecht(ferkel[buchtsau?.id], item.geschlecht)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.LEBEND_TOTAL")}
            </TableCell>
            <TableCell className={classes.tableCell} style={{ color: Colors.AmpelGruen }} align="center">
              {lebendeFerkel?.length}
            </TableCell>
            {geschlechtDto.map(item => (
              <TableCell key={item.id} className={classes.tableCell} align="center">
                {calculateFerkelByGeschlecht(lebendeFerkel!, item.geschlecht)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell} align="center">
              {t("COMPONENTS.WURFÜBERSICHT.TOTGEBURT_TOTAL")}
            </TableCell>
            <TableCell className={classes.tableCell} style={{ color: Colors.AmpelGruen }} align="center">
              {totgeburtFerkel?.length}
            </TableCell>
            {geschlechtDto.map(item => (
              <TableCell key={item.id} className={classes.tableCell} align="center">
                {calculateFerkelByGeschlecht(totgeburtFerkel!, item.geschlecht)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      {isDetailsDialogOpen && (
        <WurfuebersichtDetailsContainer
          open={isDetailsDialogOpen}
          closeModal={detailsDialogClickHandler}
          currentProzess={prozess}
          funktionId={funktionId}
        />
      )}
    </div>
  );
};

export default WurfuebersichtComponent;
