import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "14pt",
    },
    powerSettingsIcon: {
      color: theme.additionalColors.ampelRot,
      fontSize: "50px",
    },
    activeSettingsIcon: {
      color: theme.additionalColors.bluetoothBlue,
    },
    settingsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      margin: "30px",
    },
    gewichtWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "30px",
    },
  })
);

export default useStyles;
