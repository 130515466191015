import HiddenComponent from "./hidden.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./hidden.types";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { Dispatch } from "redux";
import {
  getCurrentRecordId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import { getIsManuallyEmpty, isEditModeActivated } from "../../../store/common/common.selectors";
import {
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    prevRecordId: getCurrentRecordId(state),
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HiddenComponent);
