export interface ICapturedRecordRequest {
  funktionId: number;
  offset: number;
  limit: number;
  skipRecordsGroupsIds: string[];
  type: string;
}

export const LAST_PROZESS_EVENTS_API_VERSION = "v7";
export const EXISTING_PROZESS_EVENTS_API = { v7: [] };
