import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    display: "flex",
    fontSize: "2rem",
    justifyContent: "center",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  description: {
    fontSize: "18px",
  },
  textarea: {
    width: "100%",
    marginTop: "20px",
    outline: "none",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
  },
  saveButton: {
    display: "flex",
    fontSize: "18pt",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "250px",
    "&:hover": {
      backgroundColor: "rgb(23, 108, 147)",
    },
  },
  cancelButton: {
    display: "flex",
    fontSize: "18pt",
    borderRadius: "4px",
    backgroundColor: "rgb(213,213,213)",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(166,166,166)",
    },
  },
}));

export default useStyles;
