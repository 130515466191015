import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableCell: {
      fontSize: "1.2rem",
      whiteSpace: "nowrap",
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
      border: "1px solid #b0b0b0",
      width: "50%",
    },
    tableRow: {
      backgroundColor: "#e1e1e1",
    },
    infoFerkel: {
      backgroundColor: "#e1e1e1",
      width: "100%",
      height: "50px",
      textAlign: "center",
      lineHeight: "50px",
      fontWeight: "bold",
    },
  })
);

export default useStyles;
