import { FunctionComponent } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: "absolute",
    width: "40px",
    top: "20%",
    right: "0",
    color: "white",
  },
}));

interface Props {
  onCloseHandler: () => void;
  className?: string;
}

const CloseButton: FunctionComponent<Props> = ({ onCloseHandler, className }) => {
  const classes = useStyles();
  return (
    <IconButton className={`${classes.closeIcon} ${className}`} onClick={onCloseHandler}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
