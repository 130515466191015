import logger from "../../../logger";

import { HiddenAction, HiddenActionType, HiddenState } from "./hidden.types";

const logInfo = logger.info("hidden.reducer");

export const initialState: HiddenState = {
  value: undefined,
  isError: false,
  isValid: false,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const hiddenReducer = (state: HiddenState, action: HiddenAction): HiddenState => {
  const {
    type,
    payload: { value, isRequired, isValid, shouldKeepValue },
  } = action;

  switch (type) {
    case HiddenActionType.INIT: {
      logInfo("Hidden component INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case HiddenActionType.PROZESS_DATA_CHANGED: {
      const data = value ? (value as string) : "";

      if (Array.isArray(value) && !value.length) {
        return {
          ...state,
          value: "[]",
          isError: isError(!!isValid, isRequired),
          isValid: !!isValid,
        };
      }

      if (state.value === data && state.isError === isError(!!isValid, isRequired)) {
        return state;
      }

      return {
        ...state,
        value: data,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case HiddenActionType.RESET: {
      logInfo("Hidden component RESET");
      const data = shouldKeepValue ? state.value : "";
      return {
        ...initialState,
        value: data,
        isError: isError(Array.isArray(data) ? !!data.length : !!data, isRequired),
      };
    }
  }

  return state;
};
