import { Action } from "../action";
import { IUserDto } from "../../api/backend-api-v7";

export enum AuthenticationActionType {
  AUTH_USER = "AUTH_USER",
  AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS",
  AUTH_USER_FAILURE = "AUTH_USER_FAILURE",
  SET_DEVICE_ID = "SET_DEVICE_ID",
  UNREGISTER_DEVICE = "UNREGISTER_DEVICE",
  SET_TOKEN = "SET_TOKEN",
  SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER",
  FETCH_USERS = "FETCH_USERS",
  SET_USERS = "SET_USERS",
  LOGOUT = "LOGOUT",
}

export const authUser = (
  username: string,
  password: string
): Action<{ username: string; password: string }> => ({
  type: AuthenticationActionType.AUTH_USER,
  payload: { username, password },
});

export const authUserSuccess = (
  loggedInUser: string,
  token: string,
  userId: string
): Action<{ loggedInUser: string; token: string; userId: string }> => ({
  type: AuthenticationActionType.AUTH_USER_SUCCESS,
  payload: { loggedInUser, token, userId },
});

export const authUserFailure = (): Action<null> => ({
  type: AuthenticationActionType.AUTH_USER_FAILURE,
  payload: null,
});

export const setDeviceId = (deviceId: string): Action<string> => ({
  type: AuthenticationActionType.SET_DEVICE_ID,
  payload: deviceId,
});

export const unregisterDevice = (): Action<null> => ({
  type: AuthenticationActionType.UNREGISTER_DEVICE,
  payload: null,
});

export const setToken = (token: string): Action<string> => ({
  type: AuthenticationActionType.SET_TOKEN,
  payload: token,
});

export const setLoggedInUser = (userInfo: {
  loggedInUser: string;
  userId: string;
}): Action<{ loggedInUser: string; userId: string }> => ({
  type: AuthenticationActionType.SET_LOGGED_IN_USER,
  payload: userInfo,
});

export const fetchUsers = (): Action<null> => ({
  type: AuthenticationActionType.FETCH_USERS,
  payload: null,
});

export const setUsers = (users: IUserDto[]): Action<IUserDto[]> => ({
  type: AuthenticationActionType.SET_USERS,
  payload: users,
});

export const logout = (): Action<null> => ({
  type: AuthenticationActionType.LOGOUT,
  payload: null,
});
