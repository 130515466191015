import { Action } from "../action";
import createReducer from "../createReducer";
import { BluetoothActionType } from "./bluetooth.actions";
import { BluetoothDataFormat } from "./bluetooth-data-format";
import { GewichtConfiguration } from "../../components/prozesse/gewicht/gewicht.types";

export enum BluetoothConnectionStatus {
  CONNECTED,
  CONNECTING,
  DISCONNECTED,
}

export interface WaageBluetoothDataFormat {
  lastReceived: BluetoothDataFormat | undefined;
  stableCandidates: BluetoothDataFormat[];
  stable: BluetoothDataFormat | undefined;
  captureStable: {
    enabled: boolean;
    gewichtConfiguration: GewichtConfiguration | undefined;
  };
  shouldUseManualValue: boolean;
  numberOfSkippedValues: number;
  totalAmountOfGewicht: number;
}

export interface ReceivedBluetoothData {
  waage: WaageBluetoothDataFormat;
  transponder: BluetoothDataFormat | undefined;
  tierIdent: BluetoothDataFormat | undefined;
}

export interface BluetoothState {
  connectionStatus: BluetoothConnectionStatus;
  connectedDevice: string | undefined;
  data: ReceivedBluetoothData;
}

const initialBluetoothDataState = {
  waage: {
    stableCandidates: [],
    stable: undefined,
    lastReceived: undefined,
    captureStable: {
      enabled: false,
      gewichtConfiguration: undefined,
    },
    shouldUseManualValue: false,
    numberOfSkippedValues: 0,
    totalAmountOfGewicht: 0,
  },
  transponder: undefined,
  tierIdent: undefined,
};

export const initialState: BluetoothState = {
  connectionStatus: BluetoothConnectionStatus.DISCONNECTED,
  connectedDevice: undefined,
  data: { ...initialBluetoothDataState },
};

export default createReducer(initialState, {
  [BluetoothActionType.BLUETOOTH_DEVICE_CONNECTED](state: BluetoothState, action: Action<string>) {
    return {
      ...state,
      connectionStatus: BluetoothConnectionStatus.CONNECTED,
      connectedDevice: action.payload,
    };
  },
  [BluetoothActionType.BLUETOOTH_DEVICE_DISCONNECTED](state: BluetoothState) {
    return {
      ...state,
      connectionStatus: BluetoothConnectionStatus.DISCONNECTED,
      connectedDevice: undefined,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          shouldUseManualValue: false,
        },
      },
    };
  },
  [BluetoothActionType.BLUETOOTH_DEVICE_CONNECTING](state: BluetoothState) {
    return {
      ...state,
      connectionStatus: BluetoothConnectionStatus.CONNECTING,
      connectedDevice: undefined,
    };
  },
  [BluetoothActionType.RECEIVED_WAAGE_DATA](state: BluetoothState, action: Action<BluetoothDataFormat>) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: { ...state.data.waage, lastReceived: action.payload },
      },
    };
  },
  [BluetoothActionType.RECEIVED_TRANSPONDER_DATA](
    state: BluetoothState,
    action: Action<BluetoothDataFormat>
  ) {
    return { ...state, data: { ...state.data, transponder: action.payload } };
  },
  [BluetoothActionType.RECEIVED_TIER_IDENT_DATA](state: BluetoothState, action: Action<BluetoothDataFormat>) {
    return { ...state, data: { ...state.data, tierIdent: action.payload } };
  },
  [BluetoothActionType.RESET_STABLE_WEIGHT](state: BluetoothState) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          stable: undefined,
          stableCandidates: [],
        },
      },
    };
  },
  [BluetoothActionType.RESET_TRANSPONDER_DATA](state: BluetoothState) {
    return {
      ...state,
      data: {
        ...state.data,
        transponder: undefined,
      },
    };
  },
  [BluetoothActionType.RESET_TIER_IDENT_DATA](state: BluetoothState) {
    return {
      ...state,
      data: {
        ...state.data,
        tierIdent: undefined,
      },
    };
  },
  [BluetoothActionType.RESET_BLUETOOTH_DATA](state: BluetoothState, action: Action<null>) {
    const shouldUseManualValue = state.data.waage.shouldUseManualValue;
    const newState = {
      ...state,
      data: {
        ...state.data,
        ...initialBluetoothDataState,
        waage: {
          ...initialBluetoothDataState.waage,
          shouldUseManualValue,
        },
      },
    };
    return newState;
  },
  [BluetoothActionType.START_CAPTURE_STABLE_WEIGHT](
    state: BluetoothState,
    action: Action<GewichtConfiguration>
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          captureStable: {
            enabled: true,
            gewichtConfiguration: action.payload,
          },
        },
      },
    };
  },
  [BluetoothActionType.STABLE_WEIGHT_CAPTURED](state: BluetoothState, action: Action<BluetoothDataFormat>) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          stable: action.payload,
          stableCandidates: [],
          captureStable: {
            ...state.data.waage.captureStable,
            enabled: false,
          },
        },
      },
    };
  },
  [BluetoothActionType.UPDATE_STABLE_CANDIDATES](
    state: BluetoothState,
    action: Action<BluetoothDataFormat[]>
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          stableCandidates: action.payload,
        },
      },
    };
  },
  [BluetoothActionType.SET_SHOULD_USE_MANUAL_WAAGE_VALUE](state: BluetoothState, action: Action<boolean>) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          shouldUseManualValue: action.payload,
        },
      },
    };
  },
  [BluetoothActionType.INCREMENT_NUMBER_OF_SKIPPED_WAAGE_VALUES](
    state: BluetoothState,
    action: Action<null>
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          numberOfSkippedValues: state.data.waage.numberOfSkippedValues + 1,
        },
      },
    };
  },
  [BluetoothActionType.RESET_NUMBER_OF_SKIPPED_WAAGE_VALUES](state: BluetoothState, action: Action<null>) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          numberOfSkippedValues: 0,
        },
      },
    };
  },
  [BluetoothActionType.INCREASE_TOTAL_AMOUNT_OF_GEWICHT](state: BluetoothState, action: Action<number>) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          totalAmountOfGewicht: state.data.waage.totalAmountOfGewicht + action.payload,
        },
      },
    };
  },
  [BluetoothActionType.RESET_TOTAL_OF_GEWICHT](state: BluetoothState) {
    return {
      ...state,
      data: {
        ...state.data,
        waage: {
          ...state.data.waage,
          totalAmountOfGewicht: 0,
        },
      },
    };
  },
});
