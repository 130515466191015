import logger from "../../../logger";

import {
  SelectWithDialogAction,
  SelectWithDialogActionType,
  SelectWithDialogState,
} from "./select-with-dialog.types";

const logInfo = logger.info("selectWithDialog.reducer");

export const initialState: SelectWithDialogState = {
  value: "",
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const selectWithDialogReducer = (
  state: SelectWithDialogState,
  action: SelectWithDialogAction
): SelectWithDialogState => {
  const {
    type,
    payload: { value, isRequired, isValid, shouldKeepValue, isProzessEdited },
  } = action;

  switch (type) {
    case SelectWithDialogActionType.INIT: {
      logInfo("SelectWithDialog INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case SelectWithDialogActionType.PROZESS_DATA_CHANGED: {
      const data = value ? (value as string) : "";

      // Check if multiple selection prozess was removed in edit mode.
      if (Array.isArray(value) && !value.length) {
        return {
          ...state,
          value: "[]",
          isError: isError(!!isValid, isRequired),
          isValid: !!isValid,
        };
      }

      if (state.value === data && state.isError === isError(!!isValid, isRequired)) {
        return state;
      }

      return {
        ...state,
        value: data,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case SelectWithDialogActionType.RESET: {
      logInfo("SelectWithDialog RESET");

      const data = shouldKeepValue ? state.value : "";
      return { ...initialState, value: data, isError: isError(!!data && data.length !== 0, isRequired) };
    }
    case SelectWithDialogActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
