import { FunctionComponent, ReactNode, MouseEventHandler } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      width: "600px",
      maxHeight: "80%",
      overflowY: "scroll",
      lineHeight: "30px",
      padding: theme.spacing(2, 3, 3),
      zIndex: theme.zIndex.drawer + 1,
      "&:focus": {
        outline: "none",
      },
    },
  })
);

interface Props {
  open: boolean;
  closeModal?: MouseEventHandler;
  children?: ReactNode;
  size?: string;
}

const TransitionsModal: FunctionComponent<Props> = ({ open, closeModal, children, size }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={closeModal}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ width: size }}>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
