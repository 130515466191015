import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: 0,
      "& div": {
        height: "100%",
      },
      "& span:first-child": {
        paddingLeft: 0,
      },
      "& span :first-child": {
        marginLeft: 0,
      },
      "& span :last-child": {
        marginRight: 0,
      },
    },
    touchIconButton: {
      marginLeft: theme.spacing(1.25),
      marginRight: theme.spacing(1.25),
      padding: `${theme.spacing(1.25)}px ${theme.spacing(3.25)}px`,
      "& svg": {
        fontSize: theme.typography.h5.fontSize,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    activeButton: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },
    },
    touchButtonsParent: {
      display: "flex",
      borderRadius: theme.spacing(0.5),
      borderWidth: theme.spacing(0.125),
      borderStyle: "solid",
      padding: theme.spacing(0.625),
    },
    valid: {
      borderColor: "transparent",
    },
    error: {
      borderColor: theme.palette.error.main,
    },
  })
);

export default useStyles;
