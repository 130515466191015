import { Action } from "../action";

export enum RestoreActionType {
  RESTORE_LOCAL_DATA = "RESTORE_LOCAL_DATA",
}

export const restoreLocalData = (): Action<null> => ({
  type: RestoreActionType.RESTORE_LOCAL_DATA,
  payload: null,
});
