import { Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import classNames from "classnames";
import useStyles from "./data-overview.styles";
import { Props } from "./data-overview.types";

const DataOverviewComponent: FunctionComponent<Props> = props => {
  const { prozess, getDataExport, data, removeDataExport } = props;
  const { shouldFixHeader, shouldFixLeftColumn } = prozess;
  const classes = useStyles();

  useEffect(() => {
    getDataExport(prozess.dataExportConfigurationId!);
  }, [getDataExport, prozess.dataExportConfigurationId]);

  useEffect(
    () => () => {
      removeDataExport();
    },
    [removeDataExport]
  );

  if (!data || !data.rows) {
    return (
      <div className={classes.center} data-cy="loading-indicator">
        <CircularProgress className={classes.progress} />
      </div>
    );
  }

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root} data-cy="DataOverviewComponent">
      <Table className={classes.table} stickyHeader={shouldFixHeader}>
        {data.headers && (
          <TableHead>
            <TableRow>
              {data.headers.map((item: string, index: number) => (
                <TableCell
                  key={index}
                  align="center"
                  className={classNames(classes.cell, {
                    [classes.fixedHeaderCell]: shouldFixLeftColumn && index === 0,
                    [classes.scrollableHeader]: shouldFixHeader && !shouldFixLeftColumn,
                  })}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.rows.map((row: string[], rowIndex: number) => (
            <TableRow key={rowIndex}>
              {row.map((cell: string, index: number) => (
                <TableCell
                  key={index}
                  align="center"
                  className={classNames(classes.cell, {
                    [classes.fixedLeftCell]: shouldFixLeftColumn && index === 0,
                    [classes.coloredCell]: !(rowIndex % 2),
                  })}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataOverviewComponent;
