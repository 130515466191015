import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ConnectedState, OwnProps } from "./info-ferkel.types";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import useStyles from "./info-ferkel.styles";
import { useTranslation } from "react-i18next";
import { ILocalFerkelDto } from "../../../db/database";

interface Props extends OwnProps, ConnectedState {}

const InfoFerkelComponent: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { prozess, filteredBy, filteredFerkel } = props;
  const [ferkel, setFerkel] = useState<ILocalFerkelDto>();
  const [ferkelVerwiegung, setFerkelVerwiegung] = useState<string>();
  const [ferkelGenetischeSauNr, setFerkelGenetischeSauNr] = useState<string>();

  const setFerkelByTierIdent = useCallback(() => {
    setFerkel(undefined);
    setFerkelVerwiegung("");
    setFerkelGenetischeSauNr("");

    if (filteredBy?.data && filteredFerkel) {
      const selectedFerkel = filteredFerkel.find(item => item.tierIdent === filteredBy.data);
      const genetischeSau = prozess.data?.find(
        item => item.value === selectedFerkel?.genetischeSauDescriptor.tierSysId
      );
      setFerkel(selectedFerkel);
      setFerkelGenetischeSauNr(genetischeSau?.label);
      setFerkelVerwiegung(
        selectedFerkel?.geburtsgewicht ? selectedFerkel.geburtsgewicht.toLocaleString() + " kg" : ""
      );
    }
  }, [filteredBy, filteredFerkel, prozess.data]);

  useEffect(() => {
    setFerkelByTierIdent();
  }, [setFerkelByTierIdent]);

  return (
    <div style={{ gridArea: prozess.position }} data-cy="InfoFerkelComponent">
      <div className={classes.infoFerkel}>{prozess.label!}</div>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              {t("COMMON.BIRTH_DATE")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" data-cy="geburtsdatum">
              {ferkel ? new Date(ferkel.createdAtUtc!).toLocaleDateString() : ""}
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell} align="left">
              {t("PROZESS.LABEL.GENETISCHE_SAU")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" data-cy="genetische-sau">
              {ferkelGenetischeSauNr}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              {t("COMMON.BIRTH_WEIGHT")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" data-cy="geburtsgewicht">
              {ferkelVerwiegung}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default InfoFerkelComponent;
