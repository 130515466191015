import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.additionalColors.lightGray,
    padding: theme.spacing(1.5),
    marginBottom: "10px",
  },
  title: {
    color: "white",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "10px",
    position: "absolute",
    bottom: "10px",
    padding: theme.spacing(0, 2),
  },
  closeButton: {
    display: "flex",
    fontSize: "18pt",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(23, 108, 147)",
    },
  },
  emptyRecords: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  recordsTable: {
    height: "80%",
    overflowY: "scroll",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
  },
  progress: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
