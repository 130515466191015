import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface ITextFileDto {
  fileName: string;
  content: string;
}

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
}

export interface ConnectedState {
  prevRecordId: string;
  isManuallyEmpty: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
}

export interface Props extends OwnProps, ConnectedDispatch, ConnectedState {}

export interface UploadState {
  file: ITextFileDto | null;
  isError: boolean;
  isProzessEdited?: boolean;
}

export interface UploadAction {
  type: UploadActionType;
  payload: {
    file: ITextFileDto | null;
    isRequired?: boolean;
    isValid?: boolean;
    status: ProzessEventOperationTypes;
    isProzessEdited?: boolean;
  };
}

export enum UploadActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}
