import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { useCallback, useReducer } from "react";
import { geschlechtReducer, initialState } from "./geschlecht.reducer";
import { GeschlechtActionType } from "./geschlecht.types";

export const useGeschlechtState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  shouldIgnoreEventType: boolean
) => {
  const [state, dispatch] = useReducer(geschlechtReducer, initialState);
  const { isRequired, shouldKeepValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: GeschlechtActionType.INIT,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: string | string[] | undefined) => {
      dispatch({
        type: GeschlechtActionType.PROZESS_DATA_CHANGED,
        payload: { value, isRequired, shouldKeepValue, status: ProzessEventOperationTypes.CREATE },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const toggle = useCallback(
    (value: string | string[] | undefined) => {
      dispatch({
        type: GeschlechtActionType.TOGGLE,
        payload: { value, isRequired, shouldKeepValue, status: ProzessEventOperationTypes.CREATE },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: GeschlechtActionType.RESET,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const save = useCallback(
    (selectedGeschlecht: string | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing && !shouldIgnoreEventType ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: selectedGeschlecht,
        metaData: null,
        additionalData: undefined,
        operation: selectedGeschlecht ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!selectedGeschlecht : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.changeEventType,
      prozess.eventType,
      prozess.eventCategory,
      prozess.isRequired,
      prozess.prozessType,
      isEditing,
      shouldIgnoreEventType,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: GeschlechtActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, reset, onProzessDataChanged, toggle, save, shouldEditProzessData };
};
