import { IFunktionDto, IProzessDto, IWorkflowConfigurationDto } from "../../api/backend-api-v7";
import { Action } from "../action";

export interface FunktionenEntities {
  funktionen: { [funktionId: number]: IFunktionDto };
}

export enum FunktionenActionType {
  LOAD_FUNKTIONEN = "LOAD_FUNKTIONEN",
  LOAD_FUNKTIONEN_SUCCESS = "LOAD_FUNKTIONEN_SUCCESS",
  LOAD_FUNKTIONEN_FAILURE = "LOAD_FUNKTIONEN_FAILURE",
  SET_CURRENT_FUNKTION_ID = "SET_CURRENT_FUNKTION_ID",
  SET_PROZESSE = "SET_PROZESSE",
  UPDATE_PROZESS_CONFIGURATION = "UPDATE_PROZESS_CONFIGURATION",
  UPDATE_FUNKTION = "UPDATE_FUNKTION",
}

export const loadFunktionen = (): Action<null> => ({
  type: FunktionenActionType.LOAD_FUNKTIONEN,
  payload: null,
});

export const loadFunktionenSuccess = (entities: FunktionenEntities): Action<FunktionenEntities> => ({
  type: FunktionenActionType.LOAD_FUNKTIONEN_SUCCESS,
  payload: entities,
});

export const loadFunktionenFailure = (errorMessage: string): Action<string> => ({
  type: FunktionenActionType.LOAD_FUNKTIONEN_FAILURE,
  payload: errorMessage,
});

export const setCurrentFunktionId = (funktionId: number | undefined): Action<number | undefined> => ({
  type: FunktionenActionType.SET_CURRENT_FUNKTION_ID,
  payload: funktionId,
});

export const setProzesseForCurrentFunktion = (prozesse: {
  [funktionId: string]: IProzessDto[];
}): Action<{ [funktionId: string]: IProzessDto[] }> => ({
  type: FunktionenActionType.SET_PROZESSE,
  payload: prozesse,
});

export const updateProzessConfiguration = (
  settings: IWorkflowConfigurationDto[]
): Action<IWorkflowConfigurationDto[]> => ({
  type: FunktionenActionType.UPDATE_PROZESS_CONFIGURATION,
  payload: settings,
});

export const updateFunktion = (funktion: IFunktionDto): Action<IFunktionDto> => ({
  type: FunktionenActionType.UPDATE_FUNKTION,
  payload: funktion,
});
