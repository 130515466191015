import { FunctionComponent } from "react";

import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Bluetooth } from "@mui/icons-material";

import { BluetoothConnectionStatus } from "../../store/bluetooth/bluetooth.reducer";
import { Colors } from "../../theme/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionIcon: {
      marginLeft: theme.spacing(),
      cursor: "pointer",
    },
  })
);

export interface ConnectedState {
  bluetoothConnectionStatus: BluetoothConnectionStatus;
  connectedBluetoothDevice: string | undefined;
}

export interface ConnectedDispatch {
  requestBluetoothDevice: () => void;
  disconnectBluetoothDevice: () => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const BluetoothConnectionComponent: FunctionComponent<Props> = props => {
  const { bluetoothConnectionStatus, requestBluetoothDevice, disconnectBluetoothDevice } = props;
  const classes = useStyles();

  const clickHandler =
    bluetoothConnectionStatus === BluetoothConnectionStatus.DISCONNECTED
      ? requestBluetoothDevice
      : disconnectBluetoothDevice;

  const revealColor = () => {
    switch (bluetoothConnectionStatus) {
      case BluetoothConnectionStatus.CONNECTED:
        return Colors.BluetoothBlue;
      case BluetoothConnectionStatus.CONNECTING:
        return Colors.AmpelGelb;
      case BluetoothConnectionStatus.DISCONNECTED:
      default:
        return Colors.AmpelRot;
    }
  };

  return (
    <Bluetooth
      data-cy="bluetooth-icon"
      className={classes.actionIcon}
      style={{ color: revealColor() }}
      onClick={clickHandler}
    />
  );
};

export default BluetoothConnectionComponent;
