import { connect } from "react-redux";
import { Dispatch } from "redux";

import AppComponent, { ConnectedDispatch, ConnectedState } from "./app.component";
import { syncProzessEvents } from "./store/synchronization/synchronization.actions";
import { getUserSettingsStatus } from "./store/user/user.selectors";
import { AppState } from "./store/app.state";
import { getFunktionenStatus } from "./store/funktionen/funktionen.selectors";
import { getIsLoggedIn } from "./store/authentication/authentication.selectors";
import { toggleIsAppUpdateAvailable } from "./store/common/common.actions";
import { getFerkelStatus } from "./store/ferkel/ferkel.selectors";
import { updateFerkel } from "./store/ferkel/ferkel.actions";
import { updateSauen } from "./store/sauen/sauen.actions";
import { getSauenStatus } from "./store/sauen/sauen.selectors";
import { updateBuchten } from "./store/buchten/buchten.actions";
import { getBuchtenStatus } from "./store/buchten/buchten.selectors";
import { getPlanFerkelStatus } from "./store/plan-ferkel/plan-ferkel.selectors";
import { updatePlanFerkel } from "./store/plan-ferkel/plan-ferkel.actions";
import { initTranslations } from "./store/i18n/i18n.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  isLoggedIn: getIsLoggedIn(state),
  userSettingsStatus: getUserSettingsStatus(state),
  funktionenStatus: getFunktionenStatus(state),
  ferkelStatus: getFerkelStatus(state),
  sauenStatus: getSauenStatus(state),
  buchtenStatus: getBuchtenStatus(state),
  planFerkelStatus: getPlanFerkelStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  syncProzessEvents: () => dispatch(syncProzessEvents()),
  setIsAppUpdateAvailable: (isAvailable: boolean) => dispatch(toggleIsAppUpdateAvailable(isAvailable)),
  initTranslations: () => dispatch(initTranslations()),
  updateAppData: () => {
    dispatch(updateFerkel());
    dispatch(updateSauen());
    dispatch(updateBuchten());
    dispatch(updatePlanFerkel());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
