import { FunctionComponent, useState, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Typography from "@mui/material/Typography";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(1),
      fontSize: "1.2rem",
    },
    rest: {
      color: theme.additionalColors.bluetoothBlue,
      fontSize: "0.9rem",
      marginLeft: "10px",
    },
    tooltip: {
      maxWidth: "800px",
      maxHeight: "800px",
      overflowY: "scroll",
    },
  })
);

interface Props {
  prozessData: string[];
}

const TooltipComponent: FunctionComponent<Props> = ({ prozessData }) => {
  const [first, ...rest] = prozessData;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const renderTooltipData = useCallback(
    () => (
      <div>
        {rest.map((item, index) => (
          <Typography key={index} className={classes.typography}>
            {item}
          </Typography>
        ))}
      </div>
    ),
    [classes.typography, rest]
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          title={renderTooltipData()}
          leaveTouchDelay={7000}
          classes={{ tooltip: classes.tooltip }}
        >
          <span aria-describedby="tooltip" onClick={handleTooltipOpen}>
            {first}
            <span className={classes.rest}>{`(+${rest.length})`}</span>
          </span>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipComponent;
