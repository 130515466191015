import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: 0,
      "& div": {
        height: "100%",
      },
    },
    textField: {
      width: "100%",
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    validBorder: {
      borderColor: `${theme.additionalColors.ampelGruen}!important`,
    },
    validLabel: {
      "& > .MuiInputLabel-root": {
        color: theme.additionalColors.ampelGruen,
      },
    },
    disabled: {
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
  })
);

export default useStyles;
