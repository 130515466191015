import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperFullScreen: {
      width: "calc(100% - 64px)",
      height: "calc(100% - 64px)",
    },
    paper: {
      minWidth: "70%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
    dialogContent: {
      paddingBottom: theme.spacing(3),
      position: "relative",
    },
    dialogActions: {
      justifyContent: "space-between",
      padding: "10px 35px 25px",
    },
    grid: {
      overflowY: "scroll",
    },
    fullScreenGrid: {
      marginLeft: 0.1,
      display: "grid",
      gridTemplateColumns: "repeat(3, 0.5fr)",
      gridTemplateRows: "auto",
      overflowY: "scroll",
    },
    activeButton: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.main}!important`,
        color: "white",
      },
    },
    touchButton: {
      margin: theme.spacing(1.25),
      padding: "18px 20px",
      fontSize: "1.5rem",
      textTransform: "initial",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    applyButton: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    divider: {
      height: "4px",
      margin: "10px",
    },
  })
);

export default useStyles;
