import { Action } from "../action";
import createReducer from "../createReducer";
import { SynchronizationActionType, SyncInfo } from "./synchronization.actions";

export interface SynchronizationState {
  allProzessEventsSynced: boolean | undefined;
  allProzessEventsSaved: boolean | undefined;
  forceToUpdate: boolean;
  shouldRemoveObsoleteData: boolean;
  shouldSynchronizeApp: boolean;
}

export const initialState: SynchronizationState = {
  allProzessEventsSynced: false,
  allProzessEventsSaved: undefined,
  forceToUpdate: false,
  shouldRemoveObsoleteData: false,
  shouldSynchronizeApp: false,
};

export default createReducer(initialState, {
  [SynchronizationActionType.PROZESS_EVENTS_ARE_SYNCED](
    state: SynchronizationState,
    action: Action<boolean>
  ) {
    return { ...state, allProzessEventsSynced: action.payload };
  },
  [SynchronizationActionType.PROZESS_EVENTS_ARE_SAVED](state: SynchronizationState) {
    return { ...state, allProzessEventsSaved: true };
  },
  [SynchronizationActionType.CONFIRM_SAVE_PROZESS_EVENTS](state: SynchronizationState) {
    return { ...state, allProzessEventsSaved: undefined };
  },
  [SynchronizationActionType.SYNCHRONIZE_APP](state: SynchronizationState, action: Action<SyncInfo>) {
    const { forceToUpdate } = action.payload;
    return { ...state, shouldSynchronizeApp: true, forceToUpdate };
  },
  [SynchronizationActionType.CONFIRM_APP_SYNCHRONIZATION](state: SynchronizationState) {
    return { ...state, shouldSynchronizeApp: false, forceToUpdate: false };
  },
  [SynchronizationActionType.REMOVE_OBSOLETE_DATA](state: SynchronizationState, action: Action<boolean>) {
    if (state.shouldRemoveObsoleteData === action.payload) {
      return state;
    }

    return { ...state, shouldRemoveObsoleteData: action.payload };
  },
});
