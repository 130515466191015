import I18n from "i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "../../store/app.state";
import { setCurrentFunktionId } from "../../store/funktionen/funktionen.actions";
import { getFunktionen } from "../../store/funktionen/funktionen.selectors";
import { showFailedRequest } from "../../store/notifications/notifications.actions";
import DrawerComponent, { ConnectedState, ConnectedDispatch } from "./dashboard.component";

const mapStateToProps = (state: AppState): ConnectedState => ({
  funktionen: getFunktionen(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setCurrentFunktionId: () => dispatch(setCurrentFunktionId(undefined)),
  showNetworkError: () => dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.NETWORK_ERROR"))),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);
