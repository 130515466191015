import { FunctionComponent, useEffect, useState } from "react";
import useStyles from "./prozess-events-counter.styles";
import { Props } from "./prozess-events-counter.types";
import { TextField } from "@mui/material";

const ProzessEventsCounterComponent: FunctionComponent<Props> = ({ prozess, countOfProzessEvents }) => {
  const classes = useStyles();

  const [count, setCount] = useState<number | undefined>(countOfProzessEvents);

  useEffect(() => {
    setCount(countOfProzessEvents);
  }, [countOfProzessEvents]);

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root} data-cy="ProzessEventsCounter">
      <TextField
        id={prozess.prozessType}
        label={prozess.label!}
        variant="outlined"
        value={count ? count : ""}
        className={classes.textField}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
      />
    </div>
  );
};

export default ProzessEventsCounterComponent;
