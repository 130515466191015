import { Action } from "../action";
import { ILocalFerkelDto } from "../../db/database";
import { IFunktionDto } from "../../api/backend-api-v7";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";

export enum FerkelActionType {
  LOAD_FERKEL = "LOAD_FERKEL",
  LOAD_FERKEL_SUCCESS = "LOAD_FERKEL_SUCCESS",
  LOAD_FERKEL_FAILURE = "LOAD_FERKEL_FAILURE",
  UPDATE_LOCAL_FERKEL = "UPDATE_LOCAL_FERKEL",
  UPDATE_FERKEL_DATA = "UPDATE_FERKEL_DATA",
  UPDATE_FERKEL_DATA_EDIT_MODE = "UPDATE_FERKEL_DATA_EDIT_MODE",
  FILTER_FERKEL = "FILTER_FERKEL",
  SET_FILTERED_FERKEL = "SET_FILTERED_FERKEL",
  SET_LAST_FETCHED_DATE = "SET_LAST_FETCHED_DATE",
}

export const loadFerkel = (): Action<null> => ({
  type: FerkelActionType.LOAD_FERKEL,
  payload: null,
});

export const loadFerkelSuccess = (): Action<null> => ({
  type: FerkelActionType.LOAD_FERKEL_SUCCESS,
  payload: null,
});

export const loadFerkelFailure = (errorMessage: string): Action<string> => ({
  type: FerkelActionType.LOAD_FERKEL_FAILURE,
  payload: errorMessage,
});

export const updateFerkel = (): Action<null> => ({
  type: FerkelActionType.UPDATE_LOCAL_FERKEL,
  payload: null,
});

export const updateFerkelData = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): Action<IProzessEventsWithAdditionalData[]> => ({
  type: FerkelActionType.UPDATE_FERKEL_DATA,
  payload: prozessEvents,
});

export const updateFerkelDataEditMode = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): Action<IProzessEventsWithAdditionalData[]> => ({
  type: FerkelActionType.UPDATE_FERKEL_DATA_EDIT_MODE,
  payload: prozessEvents,
});

export const filterFerkel = (funktion: IFunktionDto): Action<IFunktionDto> => ({
  type: FerkelActionType.FILTER_FERKEL,
  payload: funktion,
});

export const setFilteredFerkel = (ferkel: ILocalFerkelDto[]): Action<ILocalFerkelDto[]> => ({
  type: FerkelActionType.SET_FILTERED_FERKEL,
  payload: ferkel,
});

export const setLastFetchedDate = (): Action<null> => ({
  type: FerkelActionType.SET_LAST_FETCHED_DATE,
  payload: null,
});
