import { ProzessDataFilterDto } from "../../api/backend-api-v7";
import { ILocalSauDto } from "../../db/database";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { Action } from "../action";

export enum SauenActionType {
  LOAD_SAUEN = "LOAD_SAUEN",
  LOAD_SAUEN_SUCCESS = "LOAD_SAUEN_SUCCESS",
  LOAD_SAUEN_FAILURE = "LOAD_SAUEN_FAILURE",
  UPDATE_LOCAL_SAUEN = "UPDATE_LOCAL_SAUEN",
  UPDATE_SAU_DATA = "UPDATE_SAU_DATA",
  UPDATE_SAU_DATA_EDIT_MODE = "UPDATE_SAU_DATA_EDIT_MODE",
  SET_LAST_FETCHED_DATE = "SET_LAST_FETCHED_DATE",
  FILTER_SAUEN = "FILTER_SAUEN",
  SET_SAUEN = "SET_SAUEN",
}

export const loadSauen = (): Action<null> => ({
  type: SauenActionType.LOAD_SAUEN,
  payload: null,
});

export const loadSauenSuccess = (): Action<null> => ({
  type: SauenActionType.LOAD_SAUEN_SUCCESS,
  payload: null,
});

export const loadSauenFailure = (errorMessage: string): Action<string> => ({
  type: SauenActionType.LOAD_SAUEN_FAILURE,
  payload: errorMessage,
});

export const updateSauen = (): Action<null> => ({
  type: SauenActionType.UPDATE_LOCAL_SAUEN,
  payload: null,
});

export const updateSauData = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): Action<IProzessEventsWithAdditionalData[]> => ({
  type: SauenActionType.UPDATE_SAU_DATA,
  payload: prozessEvents,
});

export const updateSauDataEditMode = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): Action<IProzessEventsWithAdditionalData[]> => ({
  type: SauenActionType.UPDATE_SAU_DATA_EDIT_MODE,
  payload: prozessEvents,
});

export const setLastFetchedDate = (): Action<null> => ({
  type: SauenActionType.SET_LAST_FETCHED_DATE,
  payload: null,
});

export const filterSauen = (prozessDataFilters: ProzessDataFilterDto[]): Action<ProzessDataFilterDto[]> => ({
  type: SauenActionType.FILTER_SAUEN,
  payload: prozessDataFilters,
});

export const setSauen = (sauen: ILocalSauDto[]): Action<ILocalSauDto[]> => ({
  type: SauenActionType.SET_SAUEN,
  payload: sauen,
});
