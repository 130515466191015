import { FunctionComponent, ChangeEvent, useState, useCallback, useEffect } from "react";
import { useCheckboxState } from "./checkbox.hooks";
import { Props } from "./checkbox.types";
import useStyles from "./checkbox.styles";
import classnames from "classnames";
import { IProzessDataDto, ProzessType } from "../../../api/backend-api-v7";
import { Checkbox, FormControlLabel } from "@mui/material";
import { EMPTY_DATA } from "../tierident/tierident.component";
import { gewichtInRange } from "../gewicht/gewicht.utils";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (value: string | number[]) => void,
  value: string | number[],
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;
  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(value);
    }
  } else {
    if (isRecordActive) {
      save(value);
    }
  }
};

const CheckboxComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    prevRecordId,
    recordId,
    isManuallyEmpty,
    onChanged,
    isEditing,
    editedValue,
    shouldCreateProzessEventForMultipleProzess,
    setIsValidationError,
    shouldValidate,
    givenData,
    givenDataApplied,
    listeningProzess,
    manualInput,
    stableGewicht,
  } = props;
  const classes = useStyles();
  const [shouldIgnoreEventType, setShouldIgnoreEventType] = useState(false);

  const { state, init, reset, onProzessDataChanged, save, shouldEditProzessData } = useCheckboxState(
    prozess,
    onChanged,
    isEditing,
    shouldIgnoreEventType
  );

  const [checkboxData, setCheckboxData] = useState<IProzessDataDto>(prozess.data![0]);

  const revealCheckboxData = useCallback(() => {
    if (prozess.data?.length) {
      setCheckboxData(prozess.data[0]);
    }
  }, [prozess.data]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isEditing) {
        shouldEditProzessData(true);
      }

      if (event.target.checked && checkboxData) {
        onProzessDataChanged([checkboxData.id]);
      } else {
        onProzessDataChanged([]);
      }
    },
    [checkboxData, isEditing, onProzessDataChanged, shouldEditProzessData]
  );

  const revealCheckboxValue = useCallback(() => {
    if (state.value === EMPTY_DATA) {
      return false;
    }
    if (Array.isArray(state.value) && state.value.length) {
      return true;
    }

    return !!state.value;
  }, [state.value]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      if (editedValue === EMPTY_DATA) {
        onProzessDataChanged("");
      } else {
        onProzessDataChanged([checkboxData.id]);
      }
    } else {
      setShouldIgnoreEventType(false);
      shouldEditProzessData(false);
      reset();
    }
  }, [checkboxData, editedValue, isEditing, onProzessDataChanged, reset, shouldEditProzessData]);

  const checkOnGivenData = useCallback(() => {
    if (givenData) {
      if (givenData.data) {
        if (listeningProzess?.prozessType === ProzessType.GEWICHT) {
          // Check if value in optimal range.
          const { optimalMinValue, optimalMaxValue } = listeningProzess;
          const isInRange = gewichtInRange(givenData.data.data, optimalMinValue!, optimalMaxValue!);

          if (isInRange) {
            onProzessDataChanged([checkboxData.id]);
          }
        } else {
          onProzessDataChanged([checkboxData.id]);
        }
        givenDataApplied(prozess.workflowId!);
        return;
      }

      onProzessDataChanged("");
      givenDataApplied(prozess.workflowId!);
    }
  }, [checkboxData, givenData, givenDataApplied, listeningProzess, onProzessDataChanged, prozess.workflowId]);

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      setShouldIgnoreEventType(true);
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  useEffect(() => {
    init();
  }, [init, prozess, isManuallyEmpty]);

  useEffect(() => {
    reset();
  }, [recordId, reset]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.value, state.isProzessEdited, isEditing);
    // Should listen only state.value changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value]);

  useEffect(() => {
    revealCheckboxData();
  }, [revealCheckboxData]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    if (listeningProzess?.prozessType === ProzessType.GEWICHT && !manualInput && !stableGewicht) {
      reset();
    }
  }, [listeningProzess?.prozessType, manualInput, reset, stableGewicht]);

  return (
    <div
      style={{ gridArea: prozess.position }}
      className={classnames(
        classes.container,
        shouldValidate && state.isError ? classes.errorBorder : "",
        state.isValid ? classes.validBorder : ""
      )}
      data-cy="CheckboxComponent"
    >
      <FormControlLabel
        label={prozess.label!}
        labelPlacement="start"
        control={
          <Checkbox
            classes={{ root: state.isValid ? classes.checkbox : "", checked: classes.checked }}
            onChange={handleChange}
            checked={revealCheckboxValue()}
          />
        }
        classes={{ root: classnames(classes.formControlLabel, state.isValid ? classes.validLabel : "") }}
      />
    </div>
  );
};

export default CheckboxComponent;
