import debug from "debug";

export const DEBUG_BASE = "pig-monitor";

enum COLORS {
  trace = "lightblue",
  info = "green",
  warn = "yellow",
  error = "red",
}

class Logger {
  generateMessage(color: string, messages: any[], source?: string) {
    const namespace = `${DEBUG_BASE}${source ? `:${source}` : ""}`;
    const createDebug = debug(namespace) as any;

    createDebug.color = color;
    createDebug(...messages);
  }

  trace =
    (source: string) =>
    (...messages: any[]) =>
      this.generateMessage(COLORS.trace, messages, source);

  info =
    (source: string) =>
    (...messages: any[]) =>
      this.generateMessage(COLORS.info, messages, source);

  warn =
    (source: string) =>
    (...messages: any[]) =>
      this.generateMessage(COLORS.warn, messages, source);

  error =
    (source: string) =>
    (...messages: any[]) =>
      this.generateMessage(COLORS.error, messages, source);
}

export default new Logger();
