import { ChangeEvent, FunctionComponent, useState, useCallback } from "react";
import { Typography, TextareaAutosize } from "@mui/material";

import TransitionsModal from "../../common/modal/modal-window";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./problem-report.styles";
import { useTranslation } from "react-i18next";

export interface ConnectedDispatch {
  reportProblem: (message: string) => void;
}

interface Props extends ConnectedDispatch {
  open: boolean;
  closeModal: () => void;
}

const ProblemReportComponent: FunctionComponent<Props> = props => {
  const { closeModal, open, reportProblem } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("");

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  }, []);

  const handleReportClick = useCallback(() => {
    reportProblem(value);
    closeModal();
  }, [closeModal, reportProblem, value]);

  return (
    <TransitionsModal closeModal={closeModal} open={open} size="800px">
      <div className={classes.flexWrapper} data-cy="report-dialog">
        <Typography className={classes.title}> {t("MODAL.PROBLEM_REPORT.TITLE")}</Typography>
        <div>
          <Typography className={classes.description}> {t("MODAL.PROBLEM_REPORT.TEXT_1")}</Typography>
          <Typography className={classes.description}> {t("MODAL.PROBLEM_REPORT.TEXT_2")}</Typography>
          <TextareaAutosize
            minRows={8}
            placeholder={t("MODAL.PROBLEM_REPORT.PLACEHOLDER")}
            value={value}
            onChange={handleChange}
            className={classes.textarea}
          />
        </div>
        <div className={classes.buttonGroup}>
          <AppButton className={classes.cancelButton} handler={closeModal} dataCy="cancel-button">
            {t("COMMON.ABBRECHEN").toUpperCase()}
          </AppButton>
          <AppButton className={classes.saveButton} handler={handleReportClick} dataCy="report-button">
            {t("MODAL.PROBLEM_REPORT.TITLE").toUpperCase()}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default ProblemReportComponent;
