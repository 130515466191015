import { FunctionComponent, useCallback, useEffect } from "react";
import { useHiddenState } from "./hidden.hooks";
import { Props } from "./hidden.types";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (value: any | undefined) => void,
  value: any | undefined
) => {
  const isRecordActive = prevRecordId === recordId;

  if (isRecordActive) {
    save(value);
  }
};

const HiddenComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    onChanged,
    isEditing,
    isManuallyEmpty,
    prevRecordId,
    recordId,
    givenData,
    givenDataApplied,
  } = props;
  const { state, init, onProzessDataChanged, save, reset } = useHiddenState(prozess, onChanged, isEditing);

  const checkOnEditMode = useCallback(() => {
    if (isEditing) {
      reset();
    }
  }, [isEditing, reset]);

  const checkOnGivenData = useCallback(() => {
    if (givenData) {
      if (givenData.data) {
        const value = typeof givenData.data === "object" ? givenData.data.data : givenData.data;
        onProzessDataChanged(value);
      } else {
        reset();
      }
      givenDataApplied(prozess.workflowId!);
    }
  }, [givenData, givenDataApplied, onProzessDataChanged, prozess.workflowId, reset]);

  useEffect(() => {
    init();
  }, [init, isManuallyEmpty]);

  useEffect(() => {
    reset();
  }, [recordId, reset]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.value);
  }, [prevRecordId, recordId, save, state]);

  return null;
};

export default HiddenComponent;
