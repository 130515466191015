import { FunctionComponent, useEffect, useRef, useState, useCallback } from "react";
import { AppBar, Button, CircularProgress, Dialog, Toolbar, Typography } from "@mui/material";
import RecordsTableComponent from "../records-table/records-table.component";
import AppButton from "../../../common/app-button/app-button";
import useStyles from "./records-page.styles";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { Props } from "./records-page.types";
import { useTranslation } from "react-i18next";

const RecordsPageComponent: FunctionComponent<Props> = props => {
  const {
    currentProzess,
    prozesse,
    localRecords,
    remote,
    fetchAllRecords,
    fetchRemoteRecords,
    funktionId,
    isEditing,
    deleteRecord,
    activateEditMode,
    funktion,
    recordsGroupId,
    loadStatus,
    resetRemoteRecordsAvailableStatus,
    shouldUseActionBar,
    resendData,
    validationErrors,
    shouldOpenValidationOverviewDialog,
    setShouldOpenValidationOverviewDialog,
    setValidationErrors,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const buttonRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView();
    }
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    fetchAllRecords(funktionId);
  }, [fetchAllRecords, funktionId]);

  useEffect(() => {
    if (remote?.noMoreCapturedRecordsAvailable && isDialogOpen) {
      enqueueSnackbar(t("Keine weiteren Daten vorhanden"), {
        variant: "info",
        autoHideDuration: 2000,
      });
      resetRemoteRecordsAvailableStatus(funktionId);
    }

    scrollToBottom();
  }, [enqueueSnackbar, funktionId, isDialogOpen, remote, resetRemoteRecordsAvailableStatus, t]);

  useEffect(() => {
    if (isEditing) {
      setIsDialogOpen(false);
    }
  }, [isEditing, isDialogOpen, setIsDialogOpen]);

  const renderRecords = useCallback(() => {
    const records = [];
    if (localRecords?.length > 0) {
      records.push(localRecords);
    }

    if (remote?.records?.length) {
      records.push(remote.records);
    }

    if (records.length === 0) {
      return <div className={classes.emptyRecords}>{t("COMMON.NO_DATA")}</div>;
    }

    return (
      <div className={classes.recordsTable}>
        <RecordsTableComponent
          isDialogOpen={isDialogOpen}
          currentProzess={currentProzess}
          prozesse={prozesse}
          records={records}
          funktionId={funktionId}
          isEditing={isEditing}
          maxMinutesDeletable={
            funktion?.funktionConfiguration ? funktion.funktionConfiguration.maxMinutesDeletable : undefined
          }
          deleteRecord={deleteRecord}
          activateEditMode={activateEditMode}
          recordsGroupId={recordsGroupId}
          shouldUseActionBar={shouldUseActionBar}
          resendData={resendData}
          validationErrors={validationErrors}
          shouldOpenValidationOverviewDialog={shouldOpenValidationOverviewDialog}
          setShouldOpenValidationOverviewDialog={setShouldOpenValidationOverviewDialog}
          setValidationErrors={setValidationErrors}
        />
      </div>
    );
  }, [
    localRecords,
    remote,
    classes.recordsTable,
    classes.emptyRecords,
    isDialogOpen,
    currentProzess,
    prozesse,
    funktionId,
    isEditing,
    funktion.funktionConfiguration,
    deleteRecord,
    activateEditMode,
    recordsGroupId,
    shouldUseActionBar,
    resendData,
    validationErrors,
    shouldOpenValidationOverviewDialog,
    setShouldOpenValidationOverviewDialog,
    setValidationErrors,
    t,
  ]);

  if (!currentProzess) {
    return null;
  }

  return (
    <div style={{ gridArea: currentProzess.position }} data-cy="AllRecordsComponent">
      {isDialogOpen && (
        <Dialog
          fullScreen={true}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          data-cy="AllRecordsComponent-Dialog"
        >
          <AppBar className={classes.appBar} position={"static"}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {currentProzess.label!}
              </Typography>
            </Toolbar>
          </AppBar>

          {!loadStatus.pending ? (
            renderRecords()
          ) : (
            <div className={classes.center} data-cy="loading-indicator">
              <CircularProgress className={classes.progress} />
            </div>
          )}

          <div className={classes.buttonContainer} ref={buttonRef}>
            <AppButton
              dataCy="fetch-remote-records"
              className={classNames(classes.fetchButton, classes.loadRemoteButton)}
              handler={() => fetchRemoteRecords(funktionId, 10, currentProzess.prozessType!)}
            >
              {t("COMPONENTS.RECORDS.LOAD_MORE")}
            </AppButton>
            <Button
              variant="contained"
              color="secondary"
              data-cy="AllRecordsComponent-close-button"
              onClick={() => setIsDialogOpen(false)}
              className={classes.closeButton}
            >
              {t("COMMON.CLOSE")}
            </Button>
          </div>
        </Dialog>
      )}

      <Button className={classes.stretchButton} variant="contained" onClick={() => setIsDialogOpen(true)}>
        {currentProzess.label!}
      </Button>
    </div>
  );
};

export default RecordsPageComponent;
