import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "../../store/app.state";
import { toggleDrawer } from "../../store/common/common.actions";
import { isDrawerOpen } from "../../store/common/common.selectors";
import DrawerComponent, { ConnectedDispatch, ConnectedState } from "./drawer.component";

const mapStateToProps = (state: AppState): ConnectedState => ({
  drawerOpen: isDrawerOpen(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  toggleDrawer: (drawerOpen: boolean) => dispatch(toggleDrawer(drawerOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);
