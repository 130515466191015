import DataOverviewComponent from "./data-overview.component";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./data-overview.types";

import {
  getDataExport,
  setDataExport,
} from "../../../store/transitory-prozess-data/transitory-prozess-data.actions";
import { getExportedData } from "../../../store/transitory-prozess-data/transitory-prozess-data.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  data: getExportedData(state),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): ConnectedDispatch => {
  const funktionId = ownProps.funktionId;

  return {
    getDataExport: (dataExportConfigurationId: number) =>
      dispatch(getDataExport({ dataExportConfigurationId, funktionId })),
    removeDataExport: () => dispatch(setDataExport(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataOverviewComponent);
