import { IProzessDto } from "../../../api/backend-api-v7";
import { ILocalFerkelDto } from "../../../db/database";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface ConnectedState {
  prevRecordId: string;
  ferkel: ILocalFerkelDto[];
  isManuallyEmpty: boolean;
  transponder: BluetoothDataFormat | undefined;
  tierIdent: BluetoothDataFormat | undefined;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  resetTransponderData: () => void;
  resetTierIdentData: () => void;
  createFilteredBy: (prozessInputData: ProzessInputData) => void;
}

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
  funktionId: number;
  manualInput: boolean;
}

export interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface ComboBoxState {
  value: string;
  metaData: BluetoothDataFormat | null;
  isError: boolean;
  isValid: boolean;
  isProzessEdited?: boolean;
}

export interface ComboBoxAction {
  type: ComboBoxActionType;
  payload: {
    value: string | string[] | undefined;
    metaData?: BluetoothDataFormat | null;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
    isProzessEdited?: boolean;
  };
}

export enum ComboBoxActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}
