import { FunctionComponent, useEffect } from "react";
import { IProzessDto, IProzessEventsValidationResultDto } from "../../../../api/backend-api-v7";
import RecordsTableComponent from "../records-table/records-table.component";
import { ISyncedCapturedRecord } from "../../../../db/database";

export const LAST_RECORDS_AMOUNT = 3;

export interface ConnectedState {
  recordsGroupId: string;
  lastRecords: ISyncedCapturedRecord[];
  isEditing: boolean;
  shouldHighlightRecord: boolean;
  editedCapturedRecord: ISyncedCapturedRecord | undefined;
  validationErrors: IProzessEventsValidationResultDto | undefined;
  shouldOpenValidationOverviewDialog: boolean;
}

export interface ConnectedDispatch {
  fetchLastRecords: (funktionId: number, amount: number) => void;
  setShouldOpenValidationOverviewDialog: (shouldOpen: boolean) => void;
  setValidationErrors: (validationErrors: IProzessEventsValidationResultDto | undefined) => void;
}

export interface OwnProps {
  prozesse: { [workflowId: number]: IProzessDto };
  currentProzess: IProzessDto;
  funktionId: number;
}

interface Props extends ConnectedState, ConnectedDispatch, OwnProps {}

const LastRecordsComponent: FunctionComponent<Props> = props => {
  const {
    currentProzess,
    prozesse,
    lastRecords,
    fetchLastRecords,
    funktionId,
    isEditing,
    shouldHighlightRecord,
    recordsGroupId,
    editedCapturedRecord,
    validationErrors,
    shouldOpenValidationOverviewDialog,
    setShouldOpenValidationOverviewDialog,
    setValidationErrors,
  } = props;

  useEffect(() => {
    fetchLastRecords(funktionId, LAST_RECORDS_AMOUNT);
  }, [fetchLastRecords, funktionId]);

  if (!currentProzess) {
    return null;
  }

  return (
    <div style={{ gridArea: currentProzess.position }} data-cy="LastRecordsComponent">
      <RecordsTableComponent
        currentProzess={currentProzess}
        prozesse={prozesse}
        records={[lastRecords]}
        funktionId={funktionId}
        isEditing={isEditing}
        shouldHighlightRecord={shouldHighlightRecord}
        recordsGroupId={recordsGroupId}
        editedCapturedRecord={editedCapturedRecord}
        shouldDisableSorting={true}
        validationErrors={validationErrors}
        shouldOpenValidationOverviewDialog={shouldOpenValidationOverviewDialog}
        setShouldOpenValidationOverviewDialog={setShouldOpenValidationOverviewDialog}
        setValidationErrors={setValidationErrors}
      />
    </div>
  );
};

export default LastRecordsComponent;
