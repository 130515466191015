import { FieldFilterDto, FilterDto, ProzessDataFilterDto } from "../api/backend-api-v7";
import { ILocalFerkelDto, ILocalSauDto, ILocalSauLifeStageDto } from "../db/database";
import { calculateFilteringInterval } from "./datetime.utils";
import { IFilteredLocalSau, mapFilteredLocalSau } from "./sau.utils";

enum OperatorType {
  BETWEEN_AGE_IN_DAYS = "between_age_in_days",
}

enum ProzessDataFilterType {
  FERKEL = "ferkel",
  SAU = "sau",
}

enum FieldName {
  CREATED_AT_UTC = "createdAtUtc",
  VERLUSTGRUND = "verlustgrund",
  BELEG_DATUM = "belegDatum",
  TOTGEBURT = "totgeburt",
  VERKAUFSDATUM = "verkaufsdatum",
}

export enum EntitiesType {
  FERKEL = "ferkel",
  SAU = "sau",
}

interface FerkelFilterHandlers {
  filterByLifetime: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => void
  ) => void;
  filterByVerlust: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => void
  ) => void;
  filterByVerkaufsdatum: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => ILocalFerkelDto[]
  ) => void;
  filterByTotgeburt: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => void
  ) => ILocalFerkelDto[];
}

interface SauFilterHandlers {
  filterByLifetime: (
    entities: ILocalSauDto[],
    filters: FilterDto[],
    next: (entities: IFilteredLocalSau[]) => void
  ) => IFilteredLocalSau[];
}

const ferkelFilterHandlers: FerkelFilterHandlers = {
  filterByLifetime: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => void
  ) => {
    const filter = filters.find((item: FilterDto) => item.fieldName === FieldName.CREATED_AT_UTC);

    if (filter) {
      const fieldFilter = filter.fieldFilters?.find(
        (item: FieldFilterDto) => item.operator === OperatorType.BETWEEN_AGE_IN_DAYS
      );

      if (fieldFilter) {
        const [startDate, endDate] = fieldFilter.value;
        const { startInterval, endInterval } = calculateFilteringInterval(startDate, endDate);

        const filteredFerkel = entities.filter(
          ferkel => ferkel.createdAtUtc! >= startInterval && ferkel.createdAtUtc! <= endInterval
        );
        return next(filteredFerkel);
      }
      return next(entities);
    }
    return next(entities);
  },
  filterByVerlust: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => void
  ) => {
    const filter = filters.find((item: FilterDto) => item.fieldName === FieldName.VERLUSTGRUND);

    if (filter) {
      const { fieldName, fieldFilters } = filter;
      const filteredFerkel = entities.filter(
        item => !!item[fieldName as keyof ILocalFerkelDto] !== fieldFilters![0].value
      );
      return next(filteredFerkel);
    }
    return next(entities);
  },
  filterByVerkaufsdatum: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => ILocalFerkelDto[]
  ) => {
    const filter = filters.find((item: FilterDto) => item.fieldName === FieldName.VERKAUFSDATUM);

    if (filter) {
      const { fieldName, fieldFilters } = filter;
      const filteredFerkel = entities.filter((item: any) => !!item[fieldName!] !== fieldFilters![0].value);
      return next(filteredFerkel);
    }
    return next(entities);
  },
  filterByTotgeburt: (
    entities: ILocalFerkelDto[],
    filters: FilterDto[],
    next: (entities: ILocalFerkelDto[]) => void
  ) => {
    const filter = filters.find((item: FilterDto) => item.fieldName === FieldName.TOTGEBURT);
    if (filter) {
      const { fieldName, fieldFilters } = filter;
      const filteredFerkel = entities.filter(
        item => !!item[fieldName as keyof ILocalFerkelDto] !== fieldFilters![0].value
      );
      return filteredFerkel;
    }
    return entities;
  },
};

const sauFilterHandlers: SauFilterHandlers = {
  filterByLifetime: (
    entities: ILocalSauDto[],
    filters: FilterDto[],
    next: (entities: IFilteredLocalSau[]) => void
  ) => {
    const filter = filters.find((item: FilterDto) => item.fieldName === FieldName.BELEG_DATUM);

    if (filter) {
      const fieldFilter = filter.fieldFilters!.find(
        (item: FieldFilterDto) => item.operator === OperatorType.BETWEEN_AGE_IN_DAYS
      );
      if (fieldFilter) {
        const [startDate, endDate] = fieldFilter.value;
        const { startInterval, endInterval } = calculateFilteringInterval(startDate, endDate);

        const filteredSauen = entities.reduce((acc: IFilteredLocalSau[], current: ILocalSauDto) => {
          const suitableLifeStages: ILocalSauLifeStageDto[] = current.lifeStages.filter(
            stage => stage.belegdatum! >= startInterval && stage.belegdatum! <= endInterval
          );
          if (suitableLifeStages.length) {
            return [...acc, ...mapFilteredLocalSau(suitableLifeStages, current)];
          }
          return acc;
        }, []);
        return filteredSauen;
      }
      return [];
    }
    return [];
  },
};

class FilteringClient {
  private handlers: ((
    entities: ILocalFerkelDto[] | ILocalSauDto[]
  ) => ILocalFerkelDto[] | IFilteredLocalSau[])[];
  private entities: ILocalFerkelDto[] | ILocalSauDto[];

  constructor(
    handlers: FerkelFilterHandlers | SauFilterHandlers,
    filters: FilterDto[],
    entities: ILocalFerkelDto[] | ILocalSauDto[]
  ) {
    this.entities = entities;
    this.handlers = Object.values(handlers).map(
      (handler, index) => (entities: ILocalFerkelDto[] | ILocalSauDto[]) =>
        handler(entities, filters, this.handlers[index + 1])
    );
  }

  startFiltering() {
    return this.handlers[0](this.entities);
  }
}

export const filterProzessData = (
  prozessDataFilters: ProzessDataFilterDto[],
  entitiesType: EntitiesType,
  entities: ILocalFerkelDto[] | ILocalSauDto[]
) => {
  if (entitiesType === EntitiesType.FERKEL) {
    const prozessDataFerkelFilter = prozessDataFilters.find(
      item => item.type === ProzessDataFilterType.FERKEL
    );
    if (prozessDataFerkelFilter) {
      const filteringClient = new FilteringClient(
        ferkelFilterHandlers,
        prozessDataFerkelFilter.filters!,
        entities
      );
      return filteringClient.startFiltering();
    }
    return [];
  } else {
    const prozessDataSauenFilter = prozessDataFilters.find(item => item.type === ProzessDataFilterType.SAU);

    if (prozessDataSauenFilter) {
      const filteringClient = new FilteringClient(
        sauFilterHandlers,
        prozessDataSauenFilter.filters!,
        entities
      );
      return filteringClient.startFiltering();
    }
    return [];
  }
};
