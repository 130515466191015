import ComboBoxComponent from "./combo-box.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./combo-box.types";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { Dispatch } from "redux";
import { getCurrentRecordId } from "../../../store/prozess-events/prozess-events.selectors";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { getTierIdent, getTransponder } from "../../../store/bluetooth/bluetooth.selectors";
import { resetTierIdentData, resetTransponderData } from "../../../store/bluetooth/bluetooth.actions";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";
import {
  createFilteredBy,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    prevRecordId: getCurrentRecordId(state),
    ferkel: getFilteredFerkel(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    transponder: getTransponder(state),
    tierIdent: getTierIdent(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  resetTransponderData: () => dispatch(resetTransponderData()),
  resetTierIdentData: () => dispatch(resetTierIdentData()),
  createFilteredBy: (prozessInputData: ProzessInputData) => dispatch(createFilteredBy(prozessInputData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComboBoxComponent);
