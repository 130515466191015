import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { TableRow, TableCell, IconButton, Box, TableBody } from "@mui/material";
import { makeStyles } from "@mui/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import RecordsTableComponent from "../../records/records-table/records-table.component";
import { ISyncedCapturedRecord } from "../../../../db/database";
import { IFunktionDto, IProzessDto } from "../../../../api/backend-api-v7";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

interface Props {
  funktionId: number;
  row: ISyncedCapturedRecord[];
  funktion: IFunktionDto;
  currentProzess: IProzessDto;
}

const DetailsInfoRowComponent: FunctionComponent<Props> = props => {
  const { row, funktion, funktionId, currentProzess } = props;
  const [open, setOpen] = useState(false);
  const [prozesse, setProzesse] = useState<IProzessDto[]>([]);
  const classes = useRowStyles();

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const filterProzesseForTable = useCallback(() => {
    if (open) {
      const filteredProzesse = funktion.prozesse!.filter(item => item.shouldShowInTierInfoDetails);
      setProzesse(filteredProzesse);
    }
  }, [funktion.prozesse, open]);

  useEffect(() => {
    filterProzesseForTable();
  }, [filterProzesseForTable]);

  return (
    <TableBody>
      <TableRow className={classes.root} onClick={handleClick}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ fontWeight: "bolder" }}>
          {funktion.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <RecordsTableComponent
                prozesse={prozesse}
                records={[row]}
                funktionId={funktionId}
                currentProzess={currentProzess}
                shouldHideSyncedColumn={true}
                shouldDisableSorting={true}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default DetailsInfoRowComponent;
