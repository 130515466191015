import createReducer from "../createReducer";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { Action } from "../action";
import { CapturedRecordsActionType } from "./captured-records.actions";
import { ISyncedCapturedRecord } from "../../db/database";

export interface IRemoteCapturedRecordsState {
  records: ISyncedCapturedRecord[];
  offset: number;
  totalCount: number;
  lastFetched: Date | undefined;
  noMoreCapturedRecordsAvailable: boolean;
}

export interface ICapturedRecords {
  localRecords: ISyncedCapturedRecord[];
  remote?: IRemoteCapturedRecordsState;
}

export interface ICapturedRecordsState {
  [funktionId: number]: ICapturedRecords;
  editedRecord: ISyncedCapturedRecord | undefined;
  loadStatus: LoadingStatus;
}

export const initialState: ICapturedRecordsState = {
  editedRecord: undefined,
  loadStatus: loadInitial(),
};

export default createReducer(initialState, {
  [CapturedRecordsActionType.SET_LOCAL_CAPTURED_RECORDS](
    state: ICapturedRecordsState,
    action: Action<{ funktionId: number; localRecords: ISyncedCapturedRecord[] }>
  ): ICapturedRecordsState {
    const { funktionId, localRecords } = action.payload;
    return { ...state, [funktionId]: { ...state[funktionId], localRecords } };
  },
  [CapturedRecordsActionType.FETCH_REMOTE_CAPTURED_RECORDS](
    state: ICapturedRecordsState
  ): ICapturedRecordsState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [CapturedRecordsActionType.SET_REMOTE_CAPTURED_RECORDS](
    state: ICapturedRecordsState,
    action: Action<{ funktionId: number; remote: IRemoteCapturedRecordsState }>
  ): ICapturedRecordsState {
    const { funktionId, remote } = action.payload;
    return { ...state, [funktionId]: { ...state[funktionId], remote }, loadStatus: loadSuccess() };
  },
  [CapturedRecordsActionType.SET_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE](
    state: ICapturedRecordsState,
    action: Action<{ funktionId: number; lastFetched: Date }>
  ): ICapturedRecordsState {
    const { funktionId, lastFetched } = action.payload;
    return {
      ...state,
      [funktionId]: {
        ...state[funktionId],
        remote: { ...state[funktionId].remote!, lastFetched, noMoreCapturedRecordsAvailable: true },
      },
      loadStatus: loadSuccess(),
    };
  },
  [CapturedRecordsActionType.REMOVE_NO_MORE_REMOTE_CAPTURED_RECORDS_AVAILABLE](
    state: ICapturedRecordsState,
    action: Action<number>
  ): ICapturedRecordsState {
    const funktionId = action.payload;
    return {
      ...state,
      [funktionId]: {
        ...state[funktionId],
        remote: {
          ...state[funktionId].remote!,
          lastFetched: undefined,
          noMoreCapturedRecordsAvailable: false,
        },
      },
    };
  },
  [CapturedRecordsActionType.FAILED_TO_FETCH_REMOTE_CAPTURED_RECORDS](
    state: ICapturedRecordsState,
    action: Action<string>
  ): ICapturedRecordsState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [CapturedRecordsActionType.CLEAR_CAPTURED_RECORDS](
    state: ICapturedRecordsState,
    action: Action<null>
  ): ICapturedRecordsState {
    return initialState;
  },
  [CapturedRecordsActionType.START_EDIT_CAPTURED_RECORD](
    state: ICapturedRecordsState,
    action: Action<ISyncedCapturedRecord>
  ): ICapturedRecordsState {
    const editedRecord = action.payload;
    return {
      ...state,
      editedRecord,
    };
  },
  [CapturedRecordsActionType.FINISH_EDIT_CAPTURED_RECORD](
    state: ICapturedRecordsState
  ): ICapturedRecordsState {
    return {
      ...state,
      editedRecord: undefined,
    };
  },
});
