import { Navigate, Route, Routes } from "react-router-dom";

import LoginContainer from "../pages/login/login.container";
import DashboardContainer from "../pages/dashboard/dashboard.container";
import FunktionContainer from "../pages/funktion/funktion.container";
import KontaktComponent from "../pages/kontakt/kontakt.component";
import ImpressumComponent from "../pages/impressum/impressum.component";
import PrivacyPolicyComponent from "../pages/privacy-policy/privacy-policy.component";
import TermsAndConditionsComponent from "../pages/terms-and-conditions/terms-and-conditions.component";
import PrivateRouteContainer from "./private-route.container";

import RoutePaths from "./route-paths";

export default (
  <Routes>
    <Route path="/" element={<Navigate to={RoutePaths.Dashboard} replace />} />
    <Route path={RoutePaths.Login} element={<LoginContainer />} />
    <Route
      path={RoutePaths.Dashboard}
      element={<PrivateRouteContainer component={DashboardContainer} isLoggedIn={false} />}
    />
    <Route
      path={`${RoutePaths.Funktionen}/:funktionId`}
      element={<PrivateRouteContainer component={FunktionContainer} isLoggedIn={false} />}
    />
    <Route path={RoutePaths.Kontakt} element={<KontaktComponent />} />
    <Route path={RoutePaths.Impressum} element={<ImpressumComponent />} />
    <Route path={RoutePaths.PrivacyPolicy} element={<PrivacyPolicyComponent />} />
    <Route path={RoutePaths.TermsAndConditions} element={<TermsAndConditionsComponent />} />
  </Routes>
);
