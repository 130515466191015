import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalBuchtDto } from "../../../db/database";
import { IBuchtKommentarDto, ProzessType } from "../../../api/backend-api-v7";
import { EMPTY_DATA } from "../../../components/prozesse/tierident/tierident.component";

export const kommentareProzessEventHandler = (
  bucht: ILocalBuchtDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (bucht: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
): ILocalBuchtDto => {
  const kommentare = prozessEvents.filter(pe => pe.prozessType === ProzessType.KOMMENTARE);

  if (kommentare.length) {
    const { recordId, funktionId } = kommentare[0];

    const kommentareToUpdate = prozessEvents.reduce(
      (total: IBuchtKommentarDto[], current: IProzessEventsWithAdditionalData) => {
        if (current.data === EMPTY_DATA) {
          return [...total, { kommentarId: 0, workflowId: current.workflowId }];
        }
        if (Array.isArray(current.data)) {
          return [...total, { kommentarId: +current.data[0], workflowId: current.workflowId }];
        }
        return total;
      },
      []
    );

    const updatedBucht: ILocalBuchtDto = {
      ...bucht,
      funktionenHistory: bucht.funktionenHistory!.map(history => {
        if (history.funktionId === funktionId && history.recordId === recordId) {
          return {
            ...history,
            kommentare: history.kommentare?.length
              ? history.kommentare.map((kommentar: IBuchtKommentarDto) => {
                  const kommentarToUpdate = kommentareToUpdate.find(
                    (item: IBuchtKommentarDto) => item.workflowId === kommentar.workflowId
                  );
                  if (kommentarToUpdate) {
                    return { ...kommentar, ...kommentarToUpdate };
                  }
                  return kommentar;
                })
              : [...(history.kommentare || []), ...kommentareToUpdate],
          };
        }
        return history;
      }),
    };

    return updatedBucht;
  }

  return bucht;
};
