import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableCell: {
      fontSize: "1.2rem",
      whiteSpace: "nowrap",
      padding: theme.spacing(),
    },
    icon: {
      fontSize: theme.typography.fontSize,
      display: "flex",
    },
    syncedIcon: {
      color: theme.additionalColors.ampelGruen,
    },
    unsyncedIcon: {
      color: theme.additionalColors.lightGray,
    },
    cancelIcon: {
      cursor: "pointer",
    },
    iconCell: {
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
    dividerContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      "&:last-child": {
        paddingRight: 0,
      },
      padding: theme.spacing(3),
    },
    divider: {
      padding: 2,
    },
    editButton: {
      width: "40px",
      height: "30px",
      backgroundColor: "rgb(42,154,211)",
      borderRadius: "4px",
      color: "white",
      "&:focus": {
        outline: "none",
        border: "none",
      },
    },
    deleteButton: {
      width: "40px",
      height: "30px",
      backgroundColor: "rgb(227,35,56)",
      borderRadius: "4px",
      color: "white",
      "&:focus": {
        outline: "none",
        border: "none",
      },
    },
    edited: {
      backgroundColor: theme.additionalColors.ampelGelb,
    },
    additionalCell: {
      width: "70px",
    },
    greenCell: {
      backgroundColor: theme.additionalColors.ampelGruen,
      padding: 0,
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
    redCell: {
      backgroundColor: theme.additionalColors.ampelRot,
      padding: 0,
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
    mailButton: {
      width: "40px",
      height: "30px",
      backgroundColor: theme.palette.secondary.main,
    },
    mailIcon: {
      fontSize: theme.typography.fontSize,
      color: "white",
    },
    sortLabel: {
      color: "rgba(0, 0, 0, 0.87)!important",
      "&:hover": {
        color: "inherit",
      },
    },
    flexWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        margin: 0,
      },
    },
    validationOverviewTable: {
      width: "95%",
      borderCollapse: "collapse",
      "& .MuiTableCell-root	": {
        border: "1px solid gray",
        maxWidth: "10px",
        wordWrap: "break-word",
      },
    },
  })
);

export default useStyles;
