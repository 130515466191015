import { Action } from "../action";

export enum ReportProblemActionType {
  REPORT_PROBLEM = "REPORT_PROBLEM",
}

export interface ReportProblem {
  message: string;
  shouldRemoveData: boolean;
}

export const reportProblem = (data: ReportProblem): Action<ReportProblem> => ({
  type: ReportProblemActionType.REPORT_PROBLEM,
  payload: data,
});
