import { createSelector } from "reselect";

import { AppState } from "../app.state";
import {
  BluetoothConnectionStatus,
  ReceivedBluetoothData,
  WaageBluetoothDataFormat,
} from "./bluetooth.reducer";
import { BluetoothDataFormat } from "./bluetooth-data-format";
import { getProzesseByFunktionId } from "../funktionen/funktionen.selectors";
import { IProzessDto } from "../../api/backend-api-v7";

export const getConnectedBluetoothDevice = (state: AppState): string | undefined =>
  state.bluetooth.connectedDevice;

export const getBluetoothConnectionStatus = (state: AppState): BluetoothConnectionStatus =>
  state.bluetooth.connectionStatus;

export const getBluetoothData = (state: AppState): ReceivedBluetoothData => state.bluetooth.data;

export const isBluetoothConnected = createSelector(
  [getBluetoothConnectionStatus],
  (connectionStatus: BluetoothConnectionStatus) => connectionStatus === BluetoothConnectionStatus.CONNECTED
);

export const isBluetoothConnecting = createSelector(
  [getBluetoothConnectionStatus],
  (connectionStatus: BluetoothConnectionStatus) => connectionStatus === BluetoothConnectionStatus.CONNECTING
);

export const isBluetoothRequired = (funktionId: number) =>
  createSelector([getProzesseByFunktionId(funktionId)], (prozesse: IProzessDto[] | undefined) =>
    prozesse ? prozesse.some(prozess => prozess.isBluetoothRequired) : false
  );

export const getCaptureStableGewicht = (state: AppState): boolean =>
  state.bluetooth.data.waage.captureStable.enabled;

export const getStableGewichtCandidates = (state: AppState): BluetoothDataFormat[] =>
  state.bluetooth.data.waage.stableCandidates;

export const getWaage = (state: AppState): WaageBluetoothDataFormat => state.bluetooth.data.waage;

export const getStableGewicht = createSelector([getWaage], (waage: WaageBluetoothDataFormat) => waage.stable);

export const getLastReceivedGewicht = createSelector(
  [getWaage],
  (waage: WaageBluetoothDataFormat) => waage.lastReceived
);

export const getTransponder = (state: AppState): BluetoothDataFormat | undefined =>
  state.bluetooth.data.transponder;

export const getGewichtConfiguration = (state: AppState) =>
  state.bluetooth.data.waage.captureStable.gewichtConfiguration;

export const getTierIdent = (state: AppState): BluetoothDataFormat | undefined =>
  state.bluetooth.data.tierIdent;

export const getShouldUseManualGewichtValue = (state: AppState): boolean =>
  state.bluetooth.data.waage.shouldUseManualValue;

export const getCountOfSkippedValues = (state: AppState): number =>
  state.bluetooth.data.waage.numberOfSkippedValues;

export const getTotalAmountOfGewicht = (state: AppState): number =>
  state.bluetooth.data.waage.totalAmountOfGewicht;
