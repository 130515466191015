import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useStyles from "./gewicht-settings.styles";
import classnames from "classnames";
import {
  createRandomGewichtRange,
  createStableGewichtRange,
  isGewichtValid,
} from "../bluetooth-simulator-settings.utils";
import { GewichtConfiguration } from "../../prozesse/gewicht/gewicht.types";
import { Button } from "@mui/material";

export interface ConnectedState {
  gewichtConfiguration: GewichtConfiguration | undefined;
}

interface Props extends ConnectedState {
  gewichtRange: string;
  setGewichtRange: Dispatch<SetStateAction<string>>;
  shouldValidate: boolean;
}

const GewichtSettingsComponent: FunctionComponent<Props> = props => {
  const { gewichtConfiguration, gewichtRange, setGewichtRange, shouldValidate } = props;
  const classes = useStyles();
  const [gewichtData, setGewichtData] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  const extractGewichtRange = useCallback(() => {
    if (gewichtConfiguration) {
      const { validMaxValue, validMinValue } = gewichtConfiguration;

      if (validMaxValue && validMinValue && validMinValue >= 0) {
        setGewichtData([`${validMinValue} - ${validMaxValue}`]);
      }
    }
  }, [gewichtConfiguration]);

  useEffect(() => {
    extractGewichtRange();
  }, [extractGewichtRange]);

  const inputHandler = (event: ChangeEvent<{}>, newValue: string) => {
    setGewichtRange(newValue);
    if (isGewichtValid(gewichtRange) || !newValue) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const randomButtonHandler = () => {
    if (gewichtConfiguration) {
      const randomRange = createRandomGewichtRange(gewichtConfiguration);
      if (randomRange) {
        setGewichtRange(randomRange);
      }
    }
  };

  const stableButtonHandler = () => {
    if (gewichtConfiguration) {
      setGewichtRange(createStableGewichtRange(gewichtConfiguration));
    }
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id="gewicht-settings"
        options={gewichtData}
        fullWidth={true}
        freeSolo={true}
        inputValue={gewichtRange}
        onInputChange={(event: ChangeEvent<{}>, newValue: string) => inputHandler(event, newValue)}
        classes={{
          endAdornment: classes.endAdornment,
          clearIndicator: classes.clearIndicator,
        }}
        renderInput={params => (
          <TextField
            {...params}
            className={classnames(classes.textField, isGewichtValid(gewichtRange) && classes.validField)}
            variant="outlined"
            label="Gewicht"
            type="string"
            InputLabelProps={{ shrink: true }}
            error={shouldValidate && isError}
          />
        )}
      />
      <Button variant="contained" color="secondary" onClick={stableButtonHandler}>
        STABLE
      </Button>
      <Button variant="contained" color="secondary" onClick={randomButtonHandler}>
        RANDOM
      </Button>
    </div>
  );
};

export default GewichtSettingsComponent;
