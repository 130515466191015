import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessInputData,
  ProzessEventOperationTypes,
} from "../../../store/prozess-events/prozess-events.actions";

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  givenDataApplied: (workflowId: number) => void;
  showWarning: (message: string) => void;
  setCanProcessMultipleProzessEvents: (canProcess: boolean) => void;
}

export interface ConnectedState {
  prevRecordId: string;
  givenData: ProzessInputData | undefined;
  isManuallyEmpty: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
  isProzessEventEditable: boolean;
}

export interface Props extends OwnProps, ConnectedDispatch, ConnectedState {}

export interface SelectWithDateState {
  date: string | undefined;
  isError: boolean;
  isValid: boolean;
  isProzessEdited: boolean | undefined;
}

export enum SelectWithDateActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}

export interface SelectWithDateAction {
  type: SelectWithDateActionType;
  payload: {
    value: string | undefined;
    isRequired?: boolean;
    isValid?: boolean;
    shouldUsePreselectedValue: boolean | undefined;
    status: ProzessEventOperationTypes;
    sauId?: string | undefined;
    isProzessEdited?: boolean;
  };
}
