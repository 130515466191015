import {
  BackendApiV7,
  ProzessEventsRequestDto as ProzessEventsRequestDtoV7,
  ProzessEventDto as ProzessEventDtoV7,
  IFunktionenResponseDto,
  ISendDataExportRequestDto,
  LoginRequestDto,
  SendDataExportRequestDto,
  UserSettingsDto,
  UserSettingsRequestDto,
  DeleteProzessEventsByRecordsGroupRequestDto,
  IDeleteProzessEventsByRecordsGroupRequestDto,
  DeleteProzessEventsByRecordRequestDto,
  IDeleteProzessEventsByRecordRequestDto,
  ICapturedRecordsByIdsRequestDto,
  CapturedRecordsByIdsRequestDto,
  ProblemReportDto,
  IProblemReportDto,
  CapturedRecordsGroupsRequestDto,
  ICapturedRecordsGroupsRequestDto,
  IFerkelResponseDto,
  ISauenResponseDto,
  WorkflowConfigurationDto,
  WorkflowConfigurationRequestDto,
  IWorkflowConfigurationDto,
  ITokenRefreshRequestDto,
  TokenRefreshRequestDto,
} from "./backend-api-v7";
import axios, { AxiosError, AxiosInstance } from "axios";
import { Dispatch } from "redux";
import handleBackendException from "./backend-exception-handler";
import handleBackendResponse from "./backend-response-handler";
import { IProzessEventsWithAdditionalData } from "../pages/funktion/funktion.types";
import { LocalStorageItems } from "../utils/local-storage.enum";

const { TOKEN, REFRESH_TOKEN, DEVICE_ID } = LocalStorageItems;

export default class BackendClient {
  static backendClient: BackendClient;

  static getInstance(dispatch: Dispatch) {
    // eslint-disable-next-line eqeqeq
    if (BackendClient.backendClient == null) {
      BackendClient.backendClient = new BackendClient(dispatch);
    }

    return this.backendClient;
  }

  private apiV7: BackendApiV7;
  private axiosInstance: AxiosInstance;

  constructor(private dispatch: Dispatch) {
    this.axiosInstance = this.createAxiosInstance();
    this.apiV7 = new BackendApiV7("", this.axiosInstance);
  }

  getClient = () => this.apiV7;

  getFunktionen = async () => {
    const funktionenResponse: IFunktionenResponseDto = await this.apiV7.getFunktionen();
    return funktionenResponse.funktionen;
  };

  sendProzessEvents = async (prozessEvents: { [key: string]: IProzessEventsWithAdditionalData[] }) => {
    if (prozessEvents["v7"].length) {
      await this.apiV7.postProzessEvents(
        new ProzessEventsRequestDtoV7({
          prozessEvents: prozessEvents["v7"].map(pe => new ProzessEventDtoV7(pe)),
        })
      );
    }
  };

  sendProzessEventsSynchronously = async (prozessEvents: IProzessEventsWithAdditionalData[]) =>
    await this.apiV7.postProzessEventsSynchronously(
      new ProzessEventsRequestDtoV7({
        prozessEvents: prozessEvents.map(pe => new ProzessEventDtoV7(pe)),
      })
    );

  updateUserSettings = async (userSettings: any) =>
    await this.apiV7.putUserSettings(
      new UserSettingsRequestDto({ userSettings: new UserSettingsDto({ ...userSettings }) })
    );

  getUser = async () => {
    const response = await this.apiV7.getCurrentUser();
    return response.user;
  };

  getCapturedRecords = async (request: ICapturedRecordsGroupsRequestDto) => {
    const { funktionId, offset, limit, skipRecordsGroupIds } = request;

    return await this.apiV7.getCapturedRecordsGroups(
      new CapturedRecordsGroupsRequestDto({
        funktionId,
        offset,
        limit,
        skipRecordsGroupIds,
      })
    );
  };

  getCapturedRecordsForDataExports = async (request: ICapturedRecordsGroupsRequestDto) => {
    const { funktionId, offset, limit, skipRecordsGroupIds } = request;

    return await this.apiV7.getCapturedRecordsGroupsForDataExports(
      new CapturedRecordsGroupsRequestDto({
        funktionId,
        offset,
        limit,
        skipRecordsGroupIds,
      })
    );
  };

  getFerkel = async (timestamp: Date | undefined): Promise<IFerkelResponseDto> => {
    const response = await this.apiV7.getFerkel(timestamp);
    return response.toJSON();
  };

  getPreFilteredSauen = async (timestamp: Date | undefined): Promise<ISauenResponseDto> => {
    const response = await this.apiV7.getFilteredSauen(timestamp);
    return response.toJSON();
  };

  getBuchten = async (timestamp: Date | undefined) => await this.apiV7.getBuchten(timestamp);

  getPlanFerkel = async (timestamp: Date | undefined) => await this.apiV7.getPlanFerkel(timestamp);

  /**
   * Führt die Authentifizierung eines Benutzers durch,
   * wenn das verwendete Device bereits registriert ist
   *
   * @param username
   * @param password
   * @param deviceId
   *
   * @returns token
   */
  authenticate = async (username: string, password: string, deviceId: string) => {
    const response = await this.apiV7.postAuthenticate(
      deviceId,
      new LoginRequestDto({
        username,
        password,
      })
    );

    return response;
  };

  /**
   * Registriert eine neue Device-ID für das verwendete Gerät
   *
   * @param username
   * @param password
   *
   * @returns deviceId
   */
  registerDevice = async (username: string, password: string) => {
    const response = await this.apiV7.postDevice(
      new LoginRequestDto({
        username,
        password,
      })
    );

    return response.deviceId;
  };

  /**
   * Gibt eine Liste aller verfügbaren Nutzer zurück
   *
   * @param deviceId
   *
   * @returns users
   */
  getUserList = async (deviceId: string) => {
    const response = await this.apiV7.getUsersList(deviceId);
    return response.users;
  };

  deleteRecordByGroupId = async (recordData: IDeleteProzessEventsByRecordsGroupRequestDto) => {
    const request = new DeleteProzessEventsByRecordsGroupRequestDto(recordData);
    return await this.apiV7.deleteProzessEventsByRecordsGroup(request);
  };

  sendDataExport = async (dataExportInfo: ISendDataExportRequestDto) => {
    const request = new SendDataExportRequestDto(dataExportInfo);
    return await this.apiV7.sendDataExport(request);
  };

  deleteRecordByRecordId = async (recordData: IDeleteProzessEventsByRecordRequestDto) => {
    const request = new DeleteProzessEventsByRecordRequestDto(recordData);
    return await this.apiV7.deleteProzessEventsByRecord(request);
  };

  getDetailsFerkelInfo = async (ids: ICapturedRecordsByIdsRequestDto) => {
    const request = new CapturedRecordsByIdsRequestDto(ids);
    return await this.apiV7.getCapturedRecordsByIds(request);
  };

  reportProblem = async (report: IProblemReportDto) => {
    const request = new ProblemReportDto(report);
    return await this.apiV7.saveProblemReport(request);
  };

  getDataExport = async (funktionId: number, dataExportConfigurationId: number) =>
    await this.apiV7.getDataOverview(funktionId, dataExportConfigurationId);

  updateWokflowConfigurations = async (configuration: IWorkflowConfigurationDto[]) => {
    const request = new WorkflowConfigurationRequestDto({
      workflowConfigurationDtos: configuration.map(conf => new WorkflowConfigurationDto(conf)),
    });
    return await this.apiV7.putWokflowConfigurations(request);
  };

  updateAccessToken = async (deviceId: string, data: ITokenRefreshRequestDto) => {
    const response = await this.apiV7.refreshToken(deviceId, new TokenRefreshRequestDto(data));
    const { token, refreshToken } = response;

    localStorage.setItem(TOKEN, token!);
    localStorage.setItem(REFRESH_TOKEN, refreshToken!);

    return token;
  };

  private createAxiosInstance() {
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
      config => {
        const token = localStorage.getItem(TOKEN);
        if (token) {
          config.headers!.Authorization = `Bearer ${token}`;
        }

        const deviceId = localStorage.getItem(DEVICE_ID);
        if (deviceId) {
          config.headers!["device-id"] = deviceId;
        }

        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      response => handleBackendResponse(response, this.dispatch),
      async (error: AxiosError) => {
        if (
          error.response?.status === 401 &&
          error.response.headers["www-authenticate"].includes('error="invalid_token"')
        ) {
          const config = error.config;
          const refreshToken = localStorage.getItem(REFRESH_TOKEN)!;
          const deviceId = localStorage.getItem(DEVICE_ID)!;

          const token = await this.updateAccessToken(deviceId, { refreshToken });

          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };

          return axiosInstance(config);
        } else {
          handleBackendException(error, this.dispatch);
        }
        return Promise.reject(error);
      }
    );
    return axiosInstance;
  }
}
