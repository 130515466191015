import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridAutoFlow: "row",
      gridTemplateRows: "repeat(4, 1fr)",
      gridTemplateColumns: "repeat(4, 1fr)",
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(2),
      alignContent: "stretch",
      borderRadius: theme.spacing(0.5),
      borderWidth: theme.spacing(0.125),
      borderStyle: "solid",
      padding: theme.spacing(0.625),
    },
    dialogRoot: {
      display: "grid",
      gridAutoFlow: "row",
      gridTemplateRows: "repeat(8, 1fr)",
      gridTemplateColumns: "repeat(8, 1fr)",
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(2),
      alignContent: "stretch",
      height: "100%",
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    dialogContent: {
      "& > div": {
        height: "100%",
      },
      padding: 0,
    },
    dialogActions: {
      padding: theme.spacing(1.25),
      alignItems: "center",
      justifyContent: "space-between",
    },
    tabAppBar: {
      height: "62px",
      marginBottom: theme.spacing(0.75),
    },
    tab: {
      height: "62px",
      padding: theme.spacing(1.25),
      zIndex: 1,
    },
    largeButton: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    showMoreButton: {
      gridArea: "4 / 4 / 4 / 4",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    message: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      color: theme.palette.primary.main,
    },
    ferkelBtn: {
      paddingRight: "0",
      paddingLeft: "0",
      "& > .MuiButton-label": {
        display: "block",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        direction: "rtl",
        fontSize: "0.8em",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    activeButton: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },
    },
    valid: {
      borderColor: "transparent",
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    selectedFerkel: {
      color: "#FFFFFF !important",
      backgroundColor: `${theme.additionalColors.gray} !important`,
    },
    inactiveFerkel: {
      color: theme.additionalColors.dimGray,
      "&:hover": {
        backgroundColor: "#e0e0e0",
        boxShadow: "none",
      },
    },
    tabIndicator: {
      height: "100%",
    },
  })
);

export default useStyles;
