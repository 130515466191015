import logger from "../../logger";
import { ILocalFerkelDto, ISyncedCapturedRecord } from "../../db/database";
import { call, put, takeLeading, select } from "redux-saga/effects";
import { ProzessDataDto, IProzessDto, ProzessType } from "../../api/backend-api-v7";
import { Action } from "../action";
import { ProzessEventsRecord } from "../prozess-events/prozess-events.reducer";
import { getCurrentFunktionId, getProzesseByFunktionId } from "../funktionen/funktionen.selectors";
import { isEditModeActivated } from "../common/common.selectors";
import {
  WurfuebersichtActionType,
  setFerkel,
  setWurfFerkel,
  deleteWurfFerkel,
  setNewFerkel,
  WurfuebersichtFerkelDto,
  updateFerkel,
  removeDeletedFerkel,
  updateFerkelWithDifferentSau,
} from "./wurfuebersicht.actions";
import {
  separateLebendeAndTotgeburtFerkel,
  extractGeschlechtAndVerlustData,
  extractBuchtsauDataFromRecord,
  recalculateWurfFerkel,
  findGeschlechtAndTotgeburtWorkflowId,
  extractGeschlechtAndTotgeburtDataFromProzessEvents,
  findGeschlechtAndTotgeburtValue,
  createNewFerkel,
  WurfuebersichtProzesseId,
  WurfuebersichtFerkelData,
  findEditedFerkel,
  createUpdatedFerkel,
} from "./wurfuebersicht.utils";
import { getFerkelByBuchtsauId, getSelectedBuchtsau } from "./wurfuebersicht.selectors";
import { getEditedCapturedRecord } from "../captured-records/captured-records.selectors";
import { getFilteredFerkel } from "../ferkel/ferkel.selectors";

const logError = logger.error("wurfuebersicht.saga");

export function* fillWurfuebersichtTable(action: Action<ProzessDataDto | undefined>) {
  try {
    // Called when you choose or remove buchtsau value.
    const buchtsau = action.payload;
    if (buchtsau) {
      const ferkelForCurrentBuchtsau: WurfuebersichtFerkelDto[] | undefined = yield select(
        getFerkelByBuchtsauId(buchtsau.id)
      );
      if (!ferkelForCurrentBuchtsau) {
        // Get filtered ferkel by funktion prozessDataFilters.
        const ferkel: ILocalFerkelDto[] = yield select(getFilteredFerkel);
        const ferkelByBuchtsauId = ferkel.filter(item => item.buchtsauDescriptor.tierSysId === buchtsau.id);
        const wurfuebersichtFerkelData = extractGeschlechtAndVerlustData(ferkelByBuchtsauId);
        yield put(setFerkel(wurfuebersichtFerkelData));

        const wurfFerkel = separateLebendeAndTotgeburtFerkel(ferkelByBuchtsauId);
        yield put(setWurfFerkel(wurfFerkel!));
      } else {
        const recalculatedWurfFerkel = recalculateWurfFerkel(ferkelForCurrentBuchtsau);
        yield put(setWurfFerkel(recalculatedWurfFerkel!));
      }
    } else {
      yield put(deleteWurfFerkel());
    }
  } catch (e: any) {
    logError(e.message);
  }
}

export function* updateWurfuebersichtTable(action: Action<ProzessEventsRecord>) {
  try {
    // Called when you try to save new record or save edited record.
    const funktionId: number = yield select(getCurrentFunktionId);
    const isEditMode: boolean = yield select(isEditModeActivated);
    const prozesseByCurrentFunktion: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));
    const funktionHasWurfuebersichtProzess = prozesseByCurrentFunktion.some(
      prozess => prozess.prozessType === ProzessType.WURFUEBERSICHT
    );

    // Check if the new record includes wurfuebersicht prozess and extract geschlecht and totgeburt data for table.
    if (funktionHasWurfuebersichtProzess) {
      const prozessEvents = action.payload;
      const workflowIds = findGeschlechtAndTotgeburtWorkflowId(prozesseByCurrentFunktion);
      const wurfuebersichtFerkelData = extractGeschlechtAndTotgeburtDataFromProzessEvents(
        prozessEvents,
        workflowIds
      );
      const ferkel: WurfuebersichtFerkelDto = createNewFerkel(wurfuebersichtFerkelData);
      if (!isEditMode) {
        // Add new ferkel in the table if you save new record.
        yield put(setNewFerkel(ferkel));
      } else {
        // Check what prozesse were edited.
        yield call(fillWurfuebersichtTableForEditMode, {
          workflowIds,
          wurfuebersichtFerkelData,
          prozesseByCurrentFunktion,
        });
      }
    }
  } catch (e: any) {
    logError(e.message);
  }
}

export function* fillWurfuebersichtTableForEditMode(data: {
  workflowIds: WurfuebersichtProzesseId;
  wurfuebersichtFerkelData: WurfuebersichtFerkelData;
  prozesseByCurrentFunktion: IProzessDto[];
}) {
  // Called when you try to edited record.
  const { workflowIds, wurfuebersichtFerkelData, prozesseByCurrentFunktion } = data;

  const editedCapturedRecord: ISyncedCapturedRecord = yield select(getEditedCapturedRecord);

  const selectedBuchtsau: ProzessDataDto = yield select(getSelectedBuchtsau);
  const buchtsauFromEditedRecord = extractBuchtsauDataFromRecord(
    prozesseByCurrentFunktion,
    editedCapturedRecord
  );

  const editedWurfuebersichtFerkelData = findGeschlechtAndTotgeburtValue(editedCapturedRecord, workflowIds);

  // Check if buchtsau value was changed in edit mode.
  if (selectedBuchtsau.id === buchtsauFromEditedRecord!.id) {
    const ferkelForCurrentBuchtsau: WurfuebersichtFerkelDto[] | undefined = yield select(
      getFerkelByBuchtsauId(selectedBuchtsau.id)
    );
    const editedFerkel = findEditedFerkel(ferkelForCurrentBuchtsau, editedWurfuebersichtFerkelData!);
    // If buchtsau didn't change in edit mode, we need only update existing ferkel
    if (editedFerkel) {
      const updatedFerkel = createUpdatedFerkel(editedFerkel, wurfuebersichtFerkelData);
      yield put(updateFerkel(updatedFerkel));
    }
  } else {
    const ferkelForEditedBuchtsau: WurfuebersichtFerkelDto[] | undefined = yield select(
      getFerkelByBuchtsauId(buchtsauFromEditedRecord!.id)
    );
    const editedFerkel = findEditedFerkel(ferkelForEditedBuchtsau, editedWurfuebersichtFerkelData!);
    // If buchtsau was changed in edit mode, we need to remove ferkel for old buchtsau and add this ferkel for new buchtsau.
    if (editedFerkel) {
      const updatedFerkel = createUpdatedFerkel(editedFerkel, wurfuebersichtFerkelData);
      yield put(
        updateFerkelWithDifferentSau({
          prevSau: buchtsauFromEditedRecord!.id,
          editedFerkel,
          updatedFerkel,
        })
      );
    }
  }
}

export function* removeFerkelFromWurfuebersichtTable(action: Action<ISyncedCapturedRecord>) {
  try {
    // Called when you delete the record.
    const record = action.payload;
    const { funktionId } = record;
    const currentFunktionId: number = yield select(getCurrentFunktionId);

    if (currentFunktionId === funktionId) {
      const prozesseByCurrentFunktion: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));
      const buchtsau = extractBuchtsauDataFromRecord(prozesseByCurrentFunktion, record);
      const ferkelForCurrentBuchtsau: WurfuebersichtFerkelDto[] | undefined = yield select(
        getFerkelByBuchtsauId(buchtsau!.id)
      );
      const workflowIds = findGeschlechtAndTotgeburtWorkflowId(prozesseByCurrentFunktion);
      const wurfuebersichtFerkelData = findGeschlechtAndTotgeburtValue(record, workflowIds);

      const { geschlecht, totgeburt } = wurfuebersichtFerkelData!;
      const deletedFerkel = ferkelForCurrentBuchtsau?.find(
        item => item.geschlecht === geschlecht && item.totgeburt === totgeburt
      );
      if (deletedFerkel) {
        const { id } = deletedFerkel;
        yield put(removeDeletedFerkel({ id, buchtsauId: buchtsau!.id }));
      }
    }
  } catch (e: any) {
    logError(e.message);
  }
}

export default function* wurfuebersichtSaga() {
  yield takeLeading(WurfuebersichtActionType.SET_BUCHTSAU, fillWurfuebersichtTable);
  yield takeLeading(WurfuebersichtActionType.GET_WURFUEBERSICHT_DATA, updateWurfuebersichtTable);
  yield takeLeading(WurfuebersichtActionType.CHECK_DELETED_RECORD, removeFerkelFromWurfuebersichtTable);
}
