import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const transponderProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const transponder = prozessEvents.find(pe => pe.prozessType === ProzessType.TRANSPONDER);

  if (transponder) {
    const updatedFerkel = { ...ferkel, transponder: +transponder.data };
    return next(updatedFerkel, prozessEvents);
  }
  return next(ferkel, prozessEvents);
};
