import { EventChannel } from "redux-saga";
import { Action } from "../action";

export interface SimulationData {
  transponder: string;
  tierIdent: string;
  gewichtRange: string;
}

export enum BluetoothSimulationActionType {
  START_SIMULATION = "START_SIMULATION",
  FINISH_SIMULATION = "FINISH_SIMULATION",
  SET_SIMULATION_DATA = "SET_SIMULATION_DATA",
  SET_ACTIVE_CHANNEL = "SET_ACTIVE_CHANNEL",
  CLOSE_ACTIVE_CHANNEL = "CLOSE_ACTIVE_CHANNEL",
  REMOVE_CHANNEL = "REMOVE_CHANNEL",
}

export const startSimulation = (): Action<null> => ({
  type: BluetoothSimulationActionType.START_SIMULATION,
  payload: null,
});

export const finishSimulation = (): Action<null> => ({
  type: BluetoothSimulationActionType.FINISH_SIMULATION,
  payload: null,
});

export const setSimulationData = (data: SimulationData): Action<SimulationData> => ({
  type: BluetoothSimulationActionType.SET_SIMULATION_DATA,
  payload: data,
});

export const setActiveChannel = (channel: EventChannel<{}>): Action<EventChannel<{}>> => ({
  type: BluetoothSimulationActionType.SET_ACTIVE_CHANNEL,
  payload: channel,
});

export const closeActiveChannel = (): Action<null> => ({
  type: BluetoothSimulationActionType.CLOSE_ACTIVE_CHANNEL,
  payload: null,
});

export const removeChannel = (): Action<null> => ({
  type: BluetoothSimulationActionType.REMOVE_CHANNEL,
  payload: null,
});
