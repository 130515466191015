import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
  type: string;
}

export interface ConnectedState {
  prevRecordId: string;
  isManuallyEmpty: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
}

export interface Props extends OwnProps, ConnectedDispatch, ConnectedState {}

export interface TextFieldState {
  value: number | string | undefined;
  isError: boolean;
  isValid: boolean;
  isOptimal: boolean;
  isProzessEdited?: boolean;
}

export interface TextFieldAction {
  type: TextFieldActionType;
  payload: {
    value: number | string | undefined;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
    isOptimal?: boolean;
    isProzessEdited?: boolean;
  };
}

export enum TextFieldActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}
