import { connect } from "react-redux";
import { Dispatch } from "redux";
import ConnectToBluetoothDialogComponent, {
  ConnectedDispatch,
} from "./connect-to-bluetooth.dialog.component";
import { requestBluetoothDevice } from "../../store/bluetooth/bluetooth.actions";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  requestBluetoothDevice: () => dispatch(requestBluetoothDevice()),
});

export default connect(null, mapDispatchToProps)(ConnectToBluetoothDialogComponent);
