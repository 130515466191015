import { connect } from "react-redux";
import { Dispatch } from "redux";
import I18n from "i18next";

import { AppState } from "../../store/app.state";
import { requestBluetoothDevice } from "../../store/bluetooth/bluetooth.actions";

import { toggleDrawer } from "../../store/common/common.actions";
import { getCurrentFunktion, isDrawerOpen } from "../../store/common/common.selectors";
import AppBarComponent from "./app-bar.component";
import { ConnectedDispatch, ConnectedState } from "./app-bar.types";
import { isBluetoothConnected } from "../../store/bluetooth/bluetooth.selectors";
import { logout } from "../../store/authentication/authentication.actions";
import { getIsLoggedIn, getLoggedInUser } from "../../store/authentication/authentication.selectors";
import { isAllEventsSynced } from "../../store/synchronization/synchronization.selectors";
import { refresh } from "../../store/init/init.actions";
import { showFailedRequest } from "../../store/notifications/notifications.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  drawerOpen: isDrawerOpen(state),
  currentFunktion: getCurrentFunktion(state),
  isBluetoothConnected: isBluetoothConnected(state),
  isLoggedIn: getIsLoggedIn(state),
  loggedInUser: getLoggedInUser(state),
  isAllEventsSynced: isAllEventsSynced(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  toggleDrawer: (drawerOpen: boolean) => dispatch(toggleDrawer(drawerOpen)),
  requestBluetoothDevice: () => dispatch(requestBluetoothDevice()),
  logout: () => dispatch(logout()),
  refreshAppData: () => dispatch(refresh()),
  showNetworkError: () => dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.NO_INTERNET_CONNECTION"))),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComponent);
