import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./warning-dialog.styles";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  acceptHandler: () => void;
  title: string;
  children: string;
}

const WarningModalWindow: FunctionComponent<Props> = ({ acceptHandler, open, children, title }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={acceptHandler} open={open} size="800px">
      <div className={classes.container}>
        <p className={classes.title}>{t(`${title}`)}</p>
        <p className={classes.description}>{t(`${children}`)}</p>
        <div className={classes.buttonContainer}>
          <AppButton
            className={classes.acceptButton}
            handler={acceptHandler}
            type="button"
            dataCy="accept-warning"
          >
            OK
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default WarningModalWindow;
