import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridTemplateRows: "repeat(8, 1fr)",
      rowGap: theme.spacing(1.75),
      columnGap: theme.spacing(1.75),
      width: "100%",
      height: "calc(100% - 75px)", // minus Header
      position: "absolute",
      padding: "0.7em",
    },
    stretchButton: {
      height: "100%",
      width: "max-content",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    saveButton: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
      "&:disabled": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    editSnackbar: {
      "& div": {
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        fontSize: "14px",
      },
    },
    cancelButton: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      margin: theme.spacing(),
      color: "black",
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "fixed",
      top: 0,
    },
    progress: {
      margin: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
    synchronousProcessingContainer: {
      gridArea: "1 / 1 / 5 / 5",
      position: "relative",
      background: "white",
      opacity: "0.7",
      zIndex: 1,
      marginTop: "-5px",
    },
    synchronousProcessingIndicator: {
      position: "absolute",
      top: "calc(50% - 40px)",
      left: "calc(50% - 40px)",
      color: theme.palette.secondary.main,
    },
  })
);

export default useStyles;
