import logger from "../../logger";
import db, { ILocalFerkelDto, ISyncedCapturedRecord } from "../../db/database";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { Dispatch } from "redux";
import { Action } from "../action";

import BackendClient from "../../api/backend-client";
import { IFerkelResponseDto, IPlanFerkelDto, IFunktionDto } from "../../api/backend-api-v7";

import {
  FerkelActionType,
  loadFerkelSuccess,
  loadFerkelFailure,
  setLastFetchedDate,
  setFilteredFerkel,
  filterFerkel as filterFerkelAction,
} from "./ferkel.actions";

import { getLastFetchedDate } from "./ferkel.selectors";
import { getCurrentFunktionId, getFunktionById } from "../funktionen/funktionen.selectors";

import { EntitiesType, filterProzessData } from "../../utils/prozess-data-filter";
import FerkelService, { FerkelIdentificators } from "./ferkel.utils";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { groupByRecordId } from "../prozess-events/prozess-events.utils";

const logError = logger.error("ferkel.saga");
let backendClient: BackendClient;

// Load all ferkel on init.
export function* loadFerkel() {
  try {
    // Send undefined instead of the lastFetched date to get all existing ferkel data.
    const response: IFerkelResponseDto = yield call([backendClient, "getFerkel"], undefined);
    yield put(loadFerkelSuccess());

    if (response.ferkel && response.ferkel.length) {
      yield put(setLastFetchedDate());
      // Clear ferkel db to prevent dexie errors.
      yield call([db, "deleteAllFerkel"]);
      yield call([db, "addFerkel"], response.ferkel as ILocalFerkelDto[]);
    }
  } catch (e: any) {
    logError("Could not fetch ferkel", e.message);
    yield put(loadFerkelFailure(e.message));
  }
}

// try to load created ferkel every minute.
export function* updateFerkel() {
  try {
    const lastFetched: Date | undefined = yield select(getLastFetchedDate);
    const response: IFerkelResponseDto = yield call([backendClient, "getFerkel"], lastFetched);
    yield put(loadFerkelSuccess());

    if (response.ferkel && response.ferkel.length) {
      yield put(setLastFetchedDate());
      yield call([db, "updateFerkel"], response.ferkel as ILocalFerkelDto[]);
    }
  } catch (e: any) {
    logError("Could not update ferkel", e.message);
    yield put(loadFerkelFailure(e.message));
  }
}

export function* filterFerkel(action: Action<IFunktionDto>) {
  try {
    const { prozessDataFilters, funktionConfiguration } = action.payload;
    const allFerkel: ILocalFerkelDto[] = yield call([db, "getFerkel"]);
    const filterdFerkel: ILocalFerkelDto[] = yield call(
      filterProzessData,
      prozessDataFilters!,
      EntitiesType.FERKEL,
      allFerkel
    );

    if (funktionConfiguration?.shouldUseFerkelPlan) {
      yield call(updateFerkelPlanInfo, filterdFerkel);
    } else {
      yield put(setFilteredFerkel(filterdFerkel));
    }
  } catch (e: any) {
    logError("Could not filter ferkel", e.message);
  }
}

export function* updateFerkelPlanInfo(ferkel: ILocalFerkelDto[]) {
  try {
    const planFerkel: IPlanFerkelDto[] = yield call([db, "getPlanFerkel"]);

    const ferkelWithPlan: ILocalFerkelDto[] = ferkel.map(ferkel => {
      const plan = planFerkel.find(plan => plan.transponder === ferkel.transponder);
      return { ...ferkel, plan };
    });

    yield put(setFilteredFerkel(ferkelWithPlan));
  } catch (e: any) {
    logError("Could not update plan info for ferkel", e.message);
  }
}

export function* modifyVerkaufRecord(funktionId: number, transponder: number, recordsGroupId: string) {
  try {
    const records: ISyncedCapturedRecord[] = yield call([db, "getCapturedRecords"], funktionId);

    const modifiedRecords = records.reduce(
      (total: ISyncedCapturedRecord[], current: ISyncedCapturedRecord) => {
        let recordData = current.data;
        let shouldCreatePreviousData = false;

        for (let key in recordData) {
          if (Array.isArray(recordData[key])) {
            shouldCreatePreviousData =
              recordData[key].findIndex((transponderInfo: any) => transponderInfo.value === transponder) >= 0;
            recordData = {
              ...recordData,
              [key]: recordData[key].filter((transponderInfo: any) => transponderInfo.value !== transponder),
            };
          }
        }

        const updatedRecord: ISyncedCapturedRecord = {
          ...current,
          data: recordData,
          previousData: shouldCreatePreviousData ? current : undefined,
          dependsOnRecordGroupId: shouldCreatePreviousData ? recordsGroupId : undefined,
        };
        return [...total, updatedRecord];
      },
      []
    );

    yield call([db, "applyModifiedRecords"], modifiedRecords);
  } catch (e) {
    logError("Could not modify record data", e);
  }
}

/**
 * Update Ferkel information in local DB (single Ferkel or group of Ferkel).
 */
export function* updateFerkelData(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    // Works if PE with Ferkel event category exists.
    if (prozessEvents.length) {
      const currentFunktionId: number = yield select(getCurrentFunktionId);
      const ferkelToUpdate: ILocalFerkelDto[] = [];

      // Define count of handled Ferkel and update it one by one.
      const groupedFerkelInfo = groupByRecordId(prozessEvents);

      for (const ferkelInfo of groupedFerkelInfo) {
        const { recordsGroupId } = ferkelInfo[0];
        const identificators: FerkelIdentificators = FerkelService.findFerkelIdentificators(ferkelInfo);
        const { transponder, tierIdent } = identificators;

        const existingFerkel: ILocalFerkelDto[] = yield call(
          [db, "getFerkelToUpdate"],
          transponder,
          tierIdent
        );

        let ferkel;

        if (existingFerkel.length) {
          ferkel = FerkelService.modifyFerkel(existingFerkel[0], ferkelInfo);

          // VerkaufCancellationAction handler for Verfauf local records.
          const funktionIdToModifyRecord = FerkelService.findFunktionToModifyRecord(
            existingFerkel[0],
            ferkelInfo
          );
          if (funktionIdToModifyRecord && transponder) {
            yield call(modifyVerkaufRecord, funktionIdToModifyRecord, transponder, recordsGroupId!);
          }
        } else {
          ferkel = FerkelService.createFerkel(undefined, ferkelInfo);
        }

        ferkelToUpdate.push(ferkel);
      }

      yield call([db, "updateFerkel"], ferkelToUpdate);

      // Start filtering if Funktion is active.
      if (currentFunktionId) {
        const funktion: IFunktionDto = yield select(getFunktionById(currentFunktionId));
        yield put(filterFerkelAction(funktion));
      }
    }
  } catch (e) {
    logError("Could not update or create ferkel object", e);
  }
}

export function* updateFerkelDataEditMode(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    // Works if PE with Ferkel event category exists.
    if (prozessEvents.length) {
      const currentFunktionId: number = yield select(getCurrentFunktionId);
      const shouldCancellVerkaufAction = FerkelService.shouldCancellVerkaufAction(prozessEvents);

      const identificators: FerkelIdentificators = FerkelService.findFerkelIdentificators(prozessEvents);
      const { transponder, tierIdent } = identificators;

      const existingFerkel: ILocalFerkelDto[] = yield call([db, "getFerkelToUpdate"], transponder, tierIdent);

      let updatedFerkel: ILocalFerkelDto | undefined = undefined;
      // VerkaufCancellationAction handler for Verfauf local records.
      const funktionIdToModifyRecord = FerkelService.findFunktionToModifyRecord(
        existingFerkel[0],
        prozessEvents
      );

      if (existingFerkel.length) {
        updatedFerkel = shouldCancellVerkaufAction
          ? FerkelService.modifyFerkel(existingFerkel[0], prozessEvents)
          : FerkelService.modifyFerkelEditMode(existingFerkel[0], prozessEvents);
      }

      yield call([db, "updateFerkelDataEditMode"], updatedFerkel, prozessEvents, funktionIdToModifyRecord);

      // Start filtering if Funktion is active.
      if (currentFunktionId) {
        const funktion: IFunktionDto = yield select(getFunktionById(currentFunktionId));
        yield put(filterFerkelAction(funktion));
      }
    }
  } catch (e) {
    logError("Could not update ferkel object in edit mode", e);
  }
}

export default function* ferkelSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(FerkelActionType.LOAD_FERKEL, loadFerkel);
  yield takeEvery(FerkelActionType.UPDATE_LOCAL_FERKEL, updateFerkel);
  yield takeEvery(FerkelActionType.FILTER_FERKEL, filterFerkel);

  yield takeEvery(FerkelActionType.UPDATE_FERKEL_DATA, updateFerkelData);
  yield takeEvery(FerkelActionType.UPDATE_FERKEL_DATA_EDIT_MODE, updateFerkelDataEditMode);
}
