import { call, put, select, takeEvery } from "redux-saga/effects";

import logger from "../../logger";
import {
  FunktionenActionType,
  FunktionenEntities,
  loadFunktionenFailure,
  loadFunktionenSuccess,
  updateFunktion,
  setProzesseForCurrentFunktion,
} from "./funktionen.actions";
import { IFunktionDto, IProzessDto, IWorkflowConfigurationDto, ProzessDto } from "../../api/backend-api-v7";
import BackendClient from "../../api/backend-client";
import { Dispatch } from "redux";
import { Action } from "../action";
import { normalize, NormalizedSchema } from "normalizr";
import { funktionSchema } from "./funktionen.schema";
import { restoreLocalData } from "../restore/restore.actions";
import { getCurrentFunktionId, getProzesseByFunktionId, getFunktionById } from "./funktionen.selectors";

const logError = logger.error("funktionen.saga");

let backendClient: BackendClient;

export function* loadFunktionen() {
  try {
    const funktionen: IFunktionDto[] = yield call([backendClient, "getFunktionen"]);

    const normalizedData: NormalizedSchema<FunktionenEntities, number[]> = normalize(funktionen, [
      funktionSchema,
    ]);

    yield put(loadFunktionenSuccess(normalizedData.entities));
    yield put(restoreLocalData());
  } catch (e: any) {
    logError("Could not fetch funktionen", e.message);
    yield put(loadFunktionenFailure(e.message));
  }
}

export function* updateConfiguration(action: Action<IWorkflowConfigurationDto[]>) {
  try {
    const configuration = action.payload;
    const funktionId: number = yield select(getCurrentFunktionId);

    const currentFunktion: IFunktionDto = yield select(getFunktionById(funktionId));
    const prozesse: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));

    yield call([backendClient, "updateWokflowConfigurations"], configuration);

    const updatedProzesse = currentFunktion!.prozesse!.map(prozess =>
      configuration.reduce((total: IProzessDto, current: IWorkflowConfigurationDto) => {
        const { workflowId, configurationKey, configurationValue } = current;
        if (prozess.workflowId === workflowId) {
          return { ...total, [configurationKey!]: configurationValue };
        }
        return total;
      }, prozess)
    );

    yield put(updateFunktion({ ...currentFunktion, prozesse: updatedProzesse as ProzessDto[] }));
    if (prozesse?.length) {
      yield put(setProzesseForCurrentFunktion({ [funktionId]: prozesse }));
    }
  } catch (e: any) {
    logError("Could not update configuration for workflow", e.message);
  }
}

export default function* funktionenSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(FunktionenActionType.LOAD_FUNKTIONEN, loadFunktionen);
  yield takeEvery(FunktionenActionType.UPDATE_PROZESS_CONFIGURATION, updateConfiguration);
}
