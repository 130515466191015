import { FunctionComponent, useEffect, useCallback, useState } from "react";
import DetailsInfoRow from "./details-info-row.component";
import AppButton from "../../../common/app-button/app-button";
import useStyles from "./details-info.styles";
import { useTranslation } from "react-i18next";
import { Table, TableContainer, Paper, Typography, Dialog, CircularProgress } from "@mui/material";
import { ILocalFerkelFunktionHistoryDto, ISyncedCapturedRecord } from "../../../../db/database";
import { ICapturedRecordsByIdsRequestDto, IFunktionDto } from "../../../../api/backend-api-v7";
import { Props } from "./details-info.types";
import { normalize, NormalizedSchema } from "normalizr";
import { FunktionenEntities } from "../../../../store/funktionen/funktionen.actions";
import { funktionSchema } from "../../../../store/funktionen/funktionen.schema";

const DetailsInfoComponent: FunctionComponent<Props> = props => {
  const {
    open,
    closeModal,
    selectedFerkel,
    getDetailsFerkelInfo,
    removeDetailsFerkelInfo,
    ferkelInfo,
    currentProzess,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [recordIds, setRecordIds] = useState<ICapturedRecordsByIdsRequestDto | undefined>(undefined);
  const [detailsFerkelInfo, setdetailsFerkelInfo] = useState<ISyncedCapturedRecord[][]>([]);
  const [funktionen, setFunktionen] = useState<{ [funktionId: number]: IFunktionDto }>({});

  const normalizeFunktionen = useCallback(() => {
    if (currentProzess.metadata?.funktionen) {
      const { funktionen } = currentProzess.metadata;
      const normalizedData: NormalizedSchema<FunktionenEntities, number[]> = normalize(funktionen, [
        funktionSchema,
      ]);
      setFunktionen(normalizedData.entities.funktionen);
    }
  }, [currentProzess.metadata]);

  const extractRecordIds = useCallback(() => {
    if (selectedFerkel && selectedFerkel.funktionenHistory) {
      const ids = selectedFerkel.funktionenHistory?.reduce(
        (
          total: ICapturedRecordsByIdsRequestDto,
          current: ILocalFerkelFunktionHistoryDto
        ): ICapturedRecordsByIdsRequestDto => ({ ...total, ids: [...total.ids, current.recordId!] }),
        { ids: [] }
      );
      setRecordIds(ids);
    }
  }, [selectedFerkel]);

  const groupFerkelInfoByFunktionId = useCallback(() => {
    if (ferkelInfo) {
      const result: ISyncedCapturedRecord[][] = Object.values(
        ferkelInfo.reduce((acc: any, curr: ISyncedCapturedRecord) => {
          acc[curr.funktionId!] = [...(acc[curr.funktionId!] || []), curr];
          return acc;
        }, {})
      );

      setdetailsFerkelInfo(result);
    }
  }, [ferkelInfo]);

  const sortFerkelInfo = useCallback(
    (detailsFerkelInfo: ISyncedCapturedRecord[][]) =>
      detailsFerkelInfo.sort(
        (a: ISyncedCapturedRecord[], b: ISyncedCapturedRecord[]) =>
          funktionen![a[0].funktionId!].displayOrder! - funktionen![b[0].funktionId!].displayOrder!
      ),
    [funktionen]
  );

  const renderFerkelInfoAsRows = useCallback(
    () =>
      detailsFerkelInfo.length ? (
        <Table aria-label="collapsible table">
          {sortFerkelInfo(detailsFerkelInfo).map(row => (
            <DetailsInfoRow
              key={row[0].recordsGroupId}
              row={row}
              funktionId={row[0].funktionId!}
              funktion={funktionen![row[0].funktionId!]}
              currentProzess={currentProzess}
            />
          ))}
        </Table>
      ) : (
        <div className={classes.center} data-cy="loading-indicator">
          <CircularProgress className={classes.progress} />
        </div>
      ),
    [classes.center, classes.progress, currentProzess, detailsFerkelInfo, funktionen, sortFerkelInfo]
  );

  const onCloseHandler = useCallback(() => {
    removeDetailsFerkelInfo();
    closeModal();
  }, [closeModal, removeDetailsFerkelInfo]);

  useEffect(() => {
    normalizeFunktionen();
  }, [normalizeFunktionen]);

  useEffect(() => {
    extractRecordIds();
  }, [extractRecordIds]);

  useEffect(() => {
    groupFerkelInfoByFunktionId();
  }, [groupFerkelInfoByFunktionId]);

  useEffect(() => {
    if (recordIds) {
      getDetailsFerkelInfo(recordIds);
    }
  }, [getDetailsFerkelInfo, recordIds]);

  return (
    <Dialog fullScreen={true} open={open} onClose={closeModal}>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}>{`TierIdent: ${
          selectedFerkel?.tierIdent ? selectedFerkel.tierIdent : "-"
        }`}</Typography>
        <Typography className={classes.title}>{`Transponder: ${
          selectedFerkel?.transponder ? selectedFerkel?.transponder : "-"
        }`}</Typography>
      </div>
      <TableContainer component={Paper} className={classes.table}>
        {renderFerkelInfoAsRows()}
      </TableContainer>
      <div className={classes.buttonContainer}>
        <AppButton className={classes.saveButton} handler={onCloseHandler} type="button">
          {t("COMMON.CLOSE").toUpperCase()}
        </AppButton>
      </div>
    </Dialog>
  );
};

export default DetailsInfoComponent;
