import GewichtModeComponent, { ConnectedState } from "./gewicht-mode.component";
import { connect } from "react-redux";
import { AppState } from "../../store/app.state";
import {
  getBluetoothConnectionStatus,
  getShouldUseManualGewichtValue,
} from "../../store/bluetooth/bluetooth.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  bluetoothConnectionStatus: getBluetoothConnectionStatus(state),
  shouldBeInactive: getShouldUseManualGewichtValue(state),
});

export default connect(mapStateToProps)(GewichtModeComponent);
