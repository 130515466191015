import { IProzessDto, ProzessType } from "../../api/backend-api-v7";
import { v1 as uuidv1 } from "uuid";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { IValidationInfo } from "../../db/database";

export interface HandledProzessEvents {
  modifiedProzessEvents: IProzessEventsWithAdditionalData[];
  prozessEventsForCapturedRecord: (IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[])[];
}

export const tryToCreateProzessEventsForMultipleProzessEvents = (
  prozessEvents: any // (IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[])[]
): IProzessEventsWithAdditionalData[] | HandledProzessEvents => {
  const multipleProzessEvent = prozessEvents.find((pe: IProzessEventsWithAdditionalData) =>
    Array.isArray(pe)
  );
  const updatedCreatedDate = new Date();

  if (multipleProzessEvent && multipleProzessEvent.length) {
    const singleProzessEvents = prozessEvents.filter(
      (pe: IProzessEventsWithAdditionalData) => !Array.isArray(pe)
    );

    const handledProzessEvents = multipleProzessEvent.reduce(
      (total: HandledProzessEvents, current: IProzessEventsWithAdditionalData, index: number) => {
        const recordId = uuidv1();
        // Use unique transactionId for each prozess event to prevent errors in local DB.
        // Set the same created date for each prozess event.
        total.modifiedProzessEvents.push({
          ...current,
          recordId,
          transactionId: uuidv1(),
          createdAtUtc: updatedCreatedDate,
        });
        singleProzessEvents.forEach((pe: IProzessEventsWithAdditionalData) =>
          total.modifiedProzessEvents.push({
            ...pe,
            recordId,
            transactionId: uuidv1(),
            createdAtUtc: updatedCreatedDate,
          })
        );

        if (index < 1) {
          total.prozessEventsForCapturedRecord.push([
            { ...current, recordId, transactionId: uuidv1(), createdAtUtc: updatedCreatedDate },
          ]);
        } else {
          total.prozessEventsForCapturedRecord.forEach(item => {
            if (Array.isArray(item)) {
              item.push({ ...current, recordId, transactionId: uuidv1(), createdAtUtc: updatedCreatedDate });
            }
          });
        }
        return total;
      },
      { modifiedProzessEvents: [], prozessEventsForCapturedRecord: [...singleProzessEvents] }
    );
    return handledProzessEvents;
  }

  // Set the same created date for each prozess event.
  prozessEvents.forEach((pe: IProzessEventsWithAdditionalData) => (pe.createdAtUtc = updatedCreatedDate));
  return prozessEvents;
};

export const revealProzessEvents = (
  prozessEvents: IProzessEventsWithAdditionalData[] | HandledProzessEvents
) => {
  if (Array.isArray(prozessEvents)) {
    // If recordId was not modified for prozess events.
    return prozessEvents;
  } else {
    return prozessEvents.modifiedProzessEvents;
  }
};

export const revealProzessEventsForCapturedRecord = (
  prozessEvents: IProzessEventsWithAdditionalData[] | HandledProzessEvents
) => {
  if (Array.isArray(prozessEvents)) {
    // If recordId was not modified for prozess events.
    return prozessEvents;
  } else {
    return prozessEvents.prozessEventsForCapturedRecord;
  }
};

export const isProzessEventsSynchronous = (
  prozessEvents: IProzessEventsWithAdditionalData[] | HandledProzessEvents
) => {
  if (Array.isArray(prozessEvents)) {
    if (!prozessEvents.length) {
      return false;
    }
    return prozessEvents.every(pe => pe.synchronousProzessEvent);
  } else {
    return prozessEvents.modifiedProzessEvents.every(pe => pe.synchronousProzessEvent);
  }
};

export const identifyProzessEventByWorkflowId = (
  identificator: number,
  prozessEvents: IProzessEventsWithAdditionalData[]
) => prozessEvents.filter(pe => pe.workflowId === identificator);

export const extractDataFromProzessEvent = (
  prozess: IProzessDto,
  prozessEvent: IProzessEventsWithAdditionalData | undefined
) => {
  if (!prozessEvent) {
    return;
  }

  // TODO: check it.
  if (prozessEvent.additionalData && prozessEvent.additionalData[prozess.prozessType!.toLowerCase()]) {
    return prozessEvent.additionalData[prozess.prozessType!.toLowerCase()];
  } else {
    return prozessEvent;
  }
};

export const extractFilteredByData = (
  prozess: IProzessDto,
  prozessEvent: IProzessEventsWithAdditionalData | undefined
) => {
  if (!prozessEvent) {
    return;
  }

  // Edge case for bucht prozess.
  if (
    prozessEvent.additionalData &&
    prozessEvent.additionalData[prozess.prozessType!.toLowerCase()] &&
    prozess.prozessType !== ProzessType.SAU
  ) {
    return prozessEvent.additionalData[prozess.prozessType!.toLowerCase()];
  } else {
    return prozessEvent;
  }
};

export const groupByRecordId = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): IProzessEventsWithAdditionalData[][] => {
  const result: { [key: string]: IProzessEventsWithAdditionalData[] } = prozessEvents.reduce(
    (acc: { [key: string]: IProzessEventsWithAdditionalData[] }, curr: IProzessEventsWithAdditionalData) => {
      acc[curr.recordId] = [...(acc[curr.recordId] || []), curr];
      return acc;
    },
    {}
  );

  return Object.values(result);
};

export const createValidationLogs = (
  prozessEvents: IProzessEventsWithAdditionalData[],
  info: string
): IValidationInfo[] =>
  prozessEvents.reduce((total: IValidationInfo[], current) => {
    const { userId, recordId, transactionId, createdAtUtc } = current;

    if (!userId || !recordId) {
      return [
        ...total,
        {
          id: uuidv1(),
          createdAtUtc,
          info: `${info}: event transactionId: ${transactionId}, userId=${userId}, recordId=${recordId}`,
        },
      ];
    }
    return total;
  }, []);
