import { connect } from "react-redux";
import { Dispatch } from "redux";
import { reportProblem } from "../../../store/problem-report/problem-report.actions";
import ProblemReportComponent, { ConnectedDispatch } from "./problem-report.component";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  reportProblem: (message: string) => dispatch(reportProblem({ message, shouldRemoveData: false })),
});

export default connect(null, mapDispatchToProps)(ProblemReportComponent);
