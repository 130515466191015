import { EventChannel } from "redux-saga";
import { Action } from "../action";
import createReducer from "../createReducer";
import { BluetoothSimulationActionType } from "./bluetooth-data-simulation.actions";

export interface BluetoothSimulationState {
  activeChannel: EventChannel<{}> | undefined;
}

export const initialState: BluetoothSimulationState = {
  activeChannel: undefined,
};

export default createReducer(initialState, {
  [BluetoothSimulationActionType.SET_ACTIVE_CHANNEL](
    state: BluetoothSimulationState,
    action: Action<EventChannel<{}>>
  ) {
    return { ...state, activeChannel: action.payload };
  },
  [BluetoothSimulationActionType.REMOVE_CHANNEL](state: BluetoothSimulationState) {
    return { ...state, activeChannel: undefined };
  },
});
