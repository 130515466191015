import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableCell: {
      fontSize: "1.2rem",
      whiteSpace: "nowrap",
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
      border: "1px solid #b0b0b0",
      minWidth: "100px",
    },
    tableRow: {
      backgroundColor: "#e1e1e1",
    },
    wurfuebersicht: {
      backgroundColor: "#e1e1e1",
      width: "100%",
      height: "50px",
      textAlign: "center",
      lineHeight: "50px",
      fontWeight: "bold",
    },
    header: {
      position: "relative",
    },
    detailsButton: {
      position: "absolute",
      right: 0,
      bottom: 0,
      color: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);

export default useStyles;
