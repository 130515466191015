import { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./synchronization.styles";
import { Props, SynchronizationType } from "./synchronization.types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { checkInternetConnection } from "../../utils/network-status";

const SynchronizationComponent: FunctionComponent<Props> = props => {
  const {
    shouldSynchronizeApp,
    forceToUpdate,
    synchronize,
    postponeSynchronization,
    showNetworkError,
    isDataObsolete,
    sendReport,
    isAppNeedForceUpdate,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(shouldSynchronizeApp);
  const [synchronizationType, setSynchronizationType] = useState<SynchronizationType>("ask");

  const closeDialogHandler = () => {
    if (!forceToUpdate) {
      setOpen(!open);
      postponeSynchronization();
    }
  };

  const syncHandler = () => {
    const isOnline = checkInternetConnection();

    if (isOnline) {
      if (isDataObsolete && forceToUpdate) {
        sendReport();
      }
      synchronize();
      setOpen(false);
    } else {
      showNetworkError();
    }
  };

  useEffect(() => {
    setOpen(shouldSynchronizeApp);
  }, [shouldSynchronizeApp]);

  useEffect(() => {
    if (forceToUpdate) {
      setSynchronizationType("force");
    } else {
      setSynchronizationType("ask");
    }
  }, [forceToUpdate]);

  return (
    <Dialog
      open={open && !isAppNeedForceUpdate}
      onClose={closeDialogHandler}
      fullWidth={true}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle classes={{ root: classes.title }}>
        {t(`MODAL.SYNCRONIZATION.TITLE.${synchronizationType.toUpperCase()}`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("MODAL.SYNCRONIZATION.TEXT")}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        {!forceToUpdate && (
          <Button data-cy="postpone" variant="contained" color="primary" onClick={closeDialogHandler}>
            {t("COMMON.SPÄTER")}
          </Button>
        )}
        <Button data-cy="synchronize" variant="contained" color="secondary" onClick={syncHandler}>
          {t("COMMON.SYNCHRONISIEREN")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SynchronizationComponent;
