import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getTransponder } from "../../../store/bluetooth/bluetooth.selectors";
import TransponderComponent from "./transponder.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./transponder.types";
import { Dispatch } from "redux";
import {
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import {
  getCurrentRecordId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import {
  getIsDialogOpen,
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    transponder: getTransponder(state),
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldIgnoreBluetoothData: getIsDialogOpen(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
    ferkel: getFilteredFerkel(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransponderComponent);
