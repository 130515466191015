import { IProzessDto, ISauLifeStageDescriptorDto, ProzessDataDto } from "../../api/backend-api-v7";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";

export function mapIdsToLabels(
  prozesse: IProzessDto[],
  prozessEvents: (IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[])[]
) {
  return prozessEvents.map(pe => {
    // single prozess events.
    if (!Array.isArray(pe)) {
      // single prozess events with the exception of pe for SELECT_WITH_DIALOG control type.
      if (!Array.isArray(pe.data)) {
        const prozess = prozesse.find(p => p.workflowId === pe.workflowId);
        if (prozess) {
          let data: ProzessDataDto | undefined;
          // Sau selector new type.
          if (typeof pe.data === "object") {
            const { tierSysId, belegNr, ammenObjectId } = pe.data as ISauLifeStageDescriptorDto;
            data = prozess.data?.find(
              d =>
                d.id === tierSysId &&
                ((belegNr && d.additional!.belegNr === belegNr) ||
                  (ammenObjectId && d.additional!.ammenObjectId === ammenObjectId))
            );
          } else {
            data = prozess.data?.find(d => d.id === pe.data);
          }
          if (data) {
            return { ...pe, data: data.label };
          }
        }
      } else {
        // single prozess events for SELECT_WITH_DIALOG control type with the exception of pe which has isMultipleSelectionAllowed equals true (kommentar).
        const prozess = prozesse.find(p => p.workflowId === pe.workflowId);
        if (prozess) {
          if (!prozess.isMultipleSelectionAllowed) {
            const data = prozess.data?.find(d => d.id === pe.data[0]);
            if (data) {
              return { ...pe, data: data.label };
            }
          } else {
            const dataWithLabel = pe.data.map(item => {
              const prozessData = prozess.data?.find(d => d.id === item);
              return prozessData?.label;
            });
            return { ...pe, data: dataWithLabel };
          }
        }
      }
    } else {
      // multiple prozess events (multiple transponders).
      const existingData: { value: string; recordId: string }[] = [];
      pe.forEach(prozessEvent => {
        existingData.push({ value: prozessEvent.data, recordId: prozessEvent.recordId });
      });
      return { ...pe[0], data: existingData };
    }
    return pe;
  });
}
