import { AppState } from "./store/app.state";
import { BluetoothConnectionStatus } from "./store/bluetooth/bluetooth.reducer";
import { loadInitial, loadSuccess } from "./store/loading-status";
import {
  UserDto,
  UserSettingsDto,
  IDataOverviewDto,
  IBuchtDto,
  BuchtFunktionHistoryDto,
  BuchtFutterDto,
  BuchtKommentarDto,
  ProzessDataDto,
  ProzessDto,
  IProzessDto,
  ProzessType,
  IFunktionConfigurationDto,
  IFunktionDto,
  IProzessDataFilterDto,
  FieldFilterDto,
  FilterDto,
  EventCategory,
  Geschlecht,
  ProcessingState,
  PlanFerkelDto,
  SauLifeStageTypeEnum,
} from "./api/backend-api-v7";
import { BluetoothDataFormat } from "./store/bluetooth/bluetooth-data-format";
import { BluetoothDataTypes } from "./store/bluetooth/bluetooth-data-types";
import { ISyncedCapturedRecord, ILocalFerkelDto, ILocalSauDto } from "./db/database";
import { IProzessEventsWithAdditionalData } from "./pages/funktion/funktion.types";
import { WurfuebersichtFerkelDto, WurfFerkelDto } from "./store/wurfuebersicht/wurfuebersicht.actions";
import { ProzessEventsRecord } from "./store/prozess-events/prozess-events.reducer";
import { TransponderDto } from "./components/prozesse/gruppenerfassung/gruppenerfassung.types";
import { GewichtConfiguration } from "./components/prozesse/gewicht/gewicht.types";

export const allProzesse: ProzessDto[] = [
  new ProzessDto({
    workflowId: 321,
    prozessType: ProzessType.GEWICHT,
    label: "Gewicht",
    controlType: "GEWICHT",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    position: "1/1/1/1",
    data: [],
    isRequired: true,
    isBluetoothRequired: true,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
    validMinValue: 3,
    validMaxValue: 9,
    optimalMinValue: 3,
    optimalMaxValue: 7,
    gewichtMaxDifference: 30,
    gewichtAverageCount: 5,
    skipNumberOfValidGewichtValues: 5,
  }),
  new ProzessDto({
    workflowId: 456,
    prozessType: ProzessType.TRANSPONDER,
    label: "Transponder",
    controlType: "TRANSPONDER",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    position: "1/1/1/2",
    data: [],
    isRequired: true,
    isBluetoothRequired: true,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    shouldCreateMultipleProzessEvents: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 457,
    prozessType: ProzessType.KOMMENTARE,
    label: "Kommentare",
    controlType: "SELECT_WITH_DIALOG",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    position: "1/1/1/3",
    data: [
      new ProzessDataDto({ id: "5", label: "Gelenkentzündungen", value: "5" }),
      new ProzessDataDto({ id: "7", label: "Endoparasiten (Würmer)", value: "7" }),
    ],
    isRequired: false,
    isBluetoothRequired: true,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: true,
    shouldShowCreatedRecords: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 4,
    prozessType: ProzessType.LAST_RECORDS,
    label: "Letzte Datensätze",
    controlType: "LAST_RECORDS",
    data: [],
    position: "5/1/8/5",
    isRequired: false,
    isBluetoothRequired: false,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: false,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 5,
    prozessType: ProzessType.GESCHLECHT,
    label: "Geschlecht",
    controlType: "GESCHLECHT",
    data: [
      new ProzessDataDto({ id: "Eber", label: "mars", value: "Eber", additional: undefined }),
      new ProzessDataDto({ id: "Sau", label: "venus", value: "Sau", additional: undefined }),
      new ProzessDataDto({ id: "Kastrat", label: "genderless", value: "Kastrat", additional: undefined }),
    ],
    position: "4/1/5/3",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    isBluetoothRequired: false,
    isRequired: true,
    shouldKeepValue: true,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    shouldListenForChangesOnWorkflowId: 9,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 6,
    prozessType: ProzessType.BUCHTSAU,
    label: "Buchtsau",
    controlType: "SELECT_WITH_DIALOG",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    data: [
      new ProzessDataDto({
        id: "11111111",
        label: "1111",
        value: "e420c824-db3a-47fb-8980-131e3b1bbcfc",
      }),
      new ProzessDataDto({
        id: "55555555",
        label: "555",
        value: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936",
      }),
      new ProzessDataDto({
        id: "33333333",
        label: "33333",
        value: "41a155c8-a1b2-496e-bc03-df2f7d2b12ed",
      }),
      new ProzessDataDto({
        id: "22222222",
        label: "2222",
        value: "e88e284e-ea98-4593-92a4-e9febb113873",
      }),
    ],
    position: "1/1/2/2",
    isRequired: true,
    isBluetoothRequired: false,
    shouldKeepValue: true,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 7,
    prozessType: ProzessType.GENETISCHESAU,
    label: "Genetische Sau",
    controlType: "SELECT_WITH_DIALOG",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    data: [
      new ProzessDataDto({
        id: "11111111",
        label: "1111",
        value: "e420c824-db3a-47fb-8980-131e3b1bbcfc",
      }),
      new ProzessDataDto({
        id: "66666666",
        label: "66666",
        value: "5210b0c3-b49f-49db-ba36-198841886ae1",
      }),
      new ProzessDataDto({
        id: "55555555",
        label: "555",
        value: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936",
      }),
      new ProzessDataDto({
        id: "44444444",
        label: "4444",
        value: "1f3745a6-b8ab-418e-b795-fbfee52e4767",
      }),
    ],
    position: "1/2/2/3",
    isRequired: true,
    isBluetoothRequired: false,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: false,
    shouldHighlightDifferenceToWorkflowId: 6,
    isAskingForChangesConfirmation: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 499,
    prozessType: ProzessType.TIER_IDENT,
    label: "TierIdent",
    controlType: "TIER_IDENT",
    eventType: "TIER_IDENT_CAPTURED",
    manualEventType: "TIER_IDENT_CHANGED_MANUALLY",
    position: "1/1/1/2",
    data: [],
    isRequired: true,
    isBluetoothRequired: true,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 9,
    prozessType: ProzessType.TIER_IDENT,
    label: "Grid mit Ferkeln",
    controlType: "FERKEL_GRID",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    data: [],
    position: "1/3/5/5",
    isRequired: true,
    isBluetoothRequired: false,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    filteredByWorkflowId: 6,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    controlType: "WURFUBERSICHT",
    data: [
      new ProzessDataDto({
        additional: { genetischesau: "41a155c8-a1b2-496e-bc03-df2f7d2b12ed" },
        id: "41a155c8-a1b2-496e-bc03-df2f7d2b12ed",
        label: "910",
        value: "41a155c8-a1b2-496e-bc03-df2f7d2b12ed",
      }),
      new ProzessDataDto({
        additional: { genetischesau: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936" },
        id: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936",
        label: "911",
        value: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936",
      }),
      new ProzessDataDto({
        additional: { genetischesau: "e88e284e-ea98-4593-92a4-e9febb113873" },
        id: "e88e284e-ea98-4593-92a4-e9febb113873",
        label: "912",
        value: "e88e284e-ea98-4593-92a4-e9febb113873",
      }),
    ],
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    workflowId: 10,
    isAskingForChangesConfirmation: false,
    isBluetoothRequired: false,
    isMultipleSelectionAllowed: false,
    isRequired: false,
    label: "Wurfuebersicht",
    position: "2/3/4/5",
    shouldKeepValue: false,
    shouldListenForChangesOnWorkflowId: 9,
    shouldShowCreatedRecords: false,
    prozessType: ProzessType.WURFUEBERSICHT,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    controlType: "FERKEL_ABGESETZT",
    data: [],
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    workflowId: 14,
    isAskingForChangesConfirmation: false,
    isBluetoothRequired: false,
    isMultipleSelectionAllowed: false,
    isRequired: false,
    label: "Ferkel abgesetzt",
    position: "4/2/5/3",
    shouldKeepValue: false,
    shouldListenForChangesOnWorkflowId: 8,
    shouldShowCreatedRecords: false,
    prozessType: ProzessType.FERKEL_ABGESETZT,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    controlType: "ABSETZDATUM",
    data: [],
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    workflowId: 16,
    isAskingForChangesConfirmation: false,
    isBluetoothRequired: false,
    isMultipleSelectionAllowed: false,
    isRequired: true,
    label: "Absetzdatum",
    position: "3/2/4/3",
    shouldKeepValue: true,
    shouldListenForChangesOnWorkflowId: 8,
    shouldShowCreatedRecords: false,
    prozessType: ProzessType.ABSETZDATUM,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    controlType: "NUMBER_FIELD",
    data: [],
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    workflowId: 23,
    isAskingForChangesConfirmation: false,
    isBluetoothRequired: false,
    isMultipleSelectionAllowed: false,
    isRequired: true,
    label: "RSD Vorne",
    position: "3/2/4/3",
    shouldKeepValue: true,
    shouldListenForChangesOnWorkflowId: 8,
    shouldShowCreatedRecords: false,
    prozessType: ProzessType.RUECKENSPECKDICKE_VORNE,
    recordsDisplayOrder: 0,
    optimalMaxValue: 24.5,
    optimalMinValue: 15.5,
    validMaxValue: 30,
    validMinValue: 8,
    valuePostfix: "mm",
    shouldShowInTierInfoDetails: true,
  }),
  new ProzessDto({
    workflowId: 75,
    prozessType: ProzessType.KOMMENTARE,
    label: "Checkbox",
    controlType: "CHECKBOX",
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    position: "1/1/1/2",
    data: [
      new ProzessDataDto({
        additional: undefined,
        id: 168,
        label: "Checkbox label",
        value: "168",
      }),
    ],
    isRequired: true,
    isBluetoothRequired: true,
    shouldKeepValue: false,
    isMultipleSelectionAllowed: false,
    shouldShowCreatedRecords: true,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: true,
    shouldListenForChangesOnWorkflowId: 499,
  }),
  new ProzessDto({
    controlType: "DATA_OVERVIEW",
    data: [],
    eventType: "CAPTURED",
    manualEventType: "CHANGED_MANUALLY",
    workflowId: 98,
    isBluetoothRequired: false,
    isMultipleSelectionAllowed: false,
    isRequired: false,
    label: "Ferkel abgesetzt",
    position: "4/2/5/3",
    shouldKeepValue: false,
    shouldShowCreatedRecords: false,
    prozessType: ProzessType.DATA_OVERVIEW,
    recordsDisplayOrder: 0,
    shouldShowInTierInfoDetails: false,
    shouldFixHeader: true,
    shouldFixLeftColumn: true,
  }),
];

export const testFunktionen: { [funktionId: number]: IFunktionDto } = {
  123: {
    id: 123,
    name: "Absetzen",
    icon: "E2034",
    prozesse: allProzesse.map(p => `123-${p.workflowId}`) as any,
    displayOrder: 2,
    color: "#ff9900",
    prozessDataFilters: [],
  },
  122: {
    id: 122,
    name: "Ersterfassung",
    icon: "E2035",
    prozesse: allProzesse.map(p => `122-${p.workflowId}`) as any,
    displayOrder: 1,
    color: "#99ff00",
    prozessDataFilters: [],
  },
};

export const normalizeProzesse = (funktionId: number) =>
  allProzesse.reduce((obj: { [workflowId: string]: IProzessDto }, prozess) => {
    obj[`${funktionId}-${prozess.workflowId}`] = prozess;
    return obj;
  }, {});

export const testProzesse: { [workflowId: string]: IProzessDto } = {
  ...normalizeProzesse(123),
  ...normalizeProzesse(122),
};

export const testBluetoothData: BluetoothDataFormat[] = [
  {
    version: "1.0.0",
    type: BluetoothDataTypes.Waage,
    value: "12345",
    deviceId: "7,5",
    deviceSoftwareId: "1.2.c35b45g",
    received: new Date("2019-04-01T03:24:00.000Z"),
  },
  {
    version: "1.0.0",
    type: BluetoothDataTypes.Transponder,
    value: "701445367088401",
    deviceId: "1234567890134",
    deviceSoftwareId: "1.2.c35b45g",
    received: new Date("2019-04-02T03:24:00.000Z"),
  },
  {
    version: "1.0.0",
    type: BluetoothDataTypes.TierIdent,
    value: "54321",
    deviceId: "237482478329743298",
    deviceSoftwareId: "1.2.c35b45g",
    received: new Date("2019-04-03T03:24:00.000Z"),
  },
];

export const testProzessEvents: IProzessEventsWithAdditionalData[] = [
  {
    data: testBluetoothData[0].value,
    metaData: testBluetoothData[0],
    recordId: "0aa815d0-553b-11e9-83a1-214605fa6111",
    recordsGroupId: "11208090-553b-11e9-8f60-cb0134b45111",
    funktionId: 123,
    createdAtUtc: new Date("2019-04-02T03:24:00.000Z"),
    eventType: "CAPTURED",
    eventCategory: EventCategory.Ferkel,
    transactionId: "08208090-553b-11e9-8f60-cb0134b458e8",
    workflowId: 321,
    appVersion: "1A",
    apiVersion: "v6",
    userId: "11208090-6693b-11e9-8f60-cb0134b45111",
    expirationDate: null,
    prozessType: ProzessType.TRANSPONDER,
    processingState: ProcessingState.New,
    label: "label",
  },
  {
    data: testBluetoothData[1].value,
    metaData: testBluetoothData[1],
    recordId: "0aa815d0-553b-11e9-83a1-214605fa6111",
    recordsGroupId: "11208090-553b-11e9-8f60-cb0134b45111",
    funktionId: 123,
    createdAtUtc: new Date("2019-04-02T03:24:01.000Z"),
    eventType: "CAPTURED",
    eventCategory: EventCategory.Ferkel,
    transactionId: "0aa815d0-553b-11e9-83a1-214605fa6767",
    workflowId: 456,
    appVersion: "1A",
    apiVersion: "v6",
    userId: "11208090-6693b-11e9-8f60-cb0134b45111",
    expirationDate: null,
    prozessType: ProzessType.TRANSPONDER,
    processingState: ProcessingState.New,
    label: "label",
  },
  {
    data: testBluetoothData[2].value,
    metaData: testBluetoothData[2],
    recordId: "0aa815d0-553b-11e9-83a1-214605fa7111",
    recordsGroupId: "11208090-553b-11e9-8f60-cb0134b45111",
    funktionId: 123,
    createdAtUtc: new Date("2019-04-02T03:24:01.000Z"),
    eventType: "CAPTURED",
    eventCategory: EventCategory.Ferkel,
    transactionId: "0aa815d0-553b-11e9-83a1-214605fa6769",
    workflowId: 499,
    appVersion: "1A",
    apiVersion: "v6",
    userId: "11208090-6693b-11e9-8f60-cb0134b45111",
    expirationDate: null,
    prozessType: ProzessType.TRANSPONDER,
    processingState: ProcessingState.New,
    label: "label",
  },
  {
    data: "4567",
    metaData: null,
    recordId: "0aa815d0-553b-11e9-83a1-214605fa7118",
    recordsGroupId: "11208090-553b-11e9-8f60-cb0134b45111",
    funktionId: 123,
    createdAtUtc: new Date("2019-04-02T03:24:01.000Z"),
    eventType: "CAPTURED",
    eventCategory: EventCategory.Ferkel,
    transactionId: "0aa815d0-553b-11e9-83a1-214605fa6768",
    workflowId: 9,
    appVersion: "1A",
    apiVersion: "v6",
    userId: "11208090-6693b-11e9-8f60-cb0134b45111",
    expirationDate: null,
    prozessType: ProzessType.TRANSPONDER,
    processingState: ProcessingState.New,
    label: "label",
  },
];

export const testMultipleProzessEvents = {
  data: [
    { value: 177296868483282, recordId: "d88f5680-5c9f-11eb-9184-df549643fc62" },
    { value: 362229207361502, recordId: "f3c45d60-5c9f-11eb-9184-df549643fc62" },
    { value: 430180264556987, recordId: "f3c45d65-5c9f-11eb-9184-df549643fc62" },
  ] as any,
  metaData: null,
  recordId: "0aa815d0-553b-11e9-83a1-214605fa6111",
  recordsGroupId: "11208090-553b-11e9-8f60-cb0134b45111",
  transactionId: "e1150a20-5c9f-11eb-9184-df549643fc62",
  funktionId: 123,
  createdAtUtc: new Date("2019-04-02T03:24:01.000Z"),
  eventType: "CAPTURED",
  eventCategory: EventCategory.Ferkel,
  workflowId: 2,
  appVersion: "1A",
  apiVersion: "v6",
  userId: "11208090-6693b-11e9-8f60-cb0134b45111",
  expirationDate: null,
  prozessType: ProzessType.TRANSPONDER,
  processingState: ProcessingState.New,
};

export const testFunktionConfiguration: IFunktionConfigurationDto = {
  pufferzeit: 14,
  saeugezeit: 21,
  traechtigkeitstage: 115,
};

export const testGewichtConfiguration: GewichtConfiguration = {
  validMinValue: 3,
  validMaxValue: 9,
  optimalMinValue: 3,
  optimalMaxValue: 7,
  gewichtMaxDifference: 30,
  gewichtAverageCount: 5,
  skipNumberOfValidGewichtValues: 5,
};

export const testLastRecords: ISyncedCapturedRecord[] = [
  {
    timestamp: 795562080000,
    synced: 0,
    recordId: "12345",
    recordsGroupId: "6789",
    funktionId: testFunktionen[123].id,
    data: { "1": "2" },
    validationErrors: undefined,
  },
  {
    timestamp: 795562080000,
    synced: 1,
    recordId: "54321",
    recordsGroupId: "67890",
    funktionId: testFunktionen[123].id,
    data: { "3": "4" },
    validationErrors: undefined,
  },
];

export const testRecordsState: ISyncedCapturedRecord[] = [
  {
    timestamp: 795562080000,
    synced: 0,
    recordId: "12345",
    recordsGroupId: "6789",
    funktionId: testFunktionen[123].id,
    data: { "1": "2" },
    validationErrors: undefined,
  },
  {
    timestamp: 795562080000,
    synced: 1,
    recordId: "54321",
    recordsGroupId: "67890",
    funktionId: testFunktionen[123].id,
    data: { "3": "4" },
    validationErrors: undefined,
  },
];

export const testTranslations = {
  translation: {
    de: {
      translation: {
        absetzen: "Absetzen",
        sau: "Sau",
      },
    },
    en: {
      translation: {
        absetzen: "drop off",
        sau: "sow",
      },
    },
    nl: {
      translation: {
        absetzen: "verkopen",
        sau: "zeug",
      },
    },
  },
};

export const testFerkel: ILocalFerkelDto[] = [
  {
    buchtsauDescriptor: { belegNr: 8, tierSysId: "e420c824-db3a-47fb-8980-131e3b1bbcfc" },
    createdAtUtc: 1594970784976,
    funktionenHistory: [],
    genetischeSauDescriptor: { belegNr: 8, tierSysId: "e420c824-db3a-47fb-8980-131e3b1bbcfc" },
    geschlecht: Geschlecht.Sau,
    id: 2,
    modifiedAtUtc: 1594970784976,
    tierIdent: "494154",
    transponder: 111111111111111,
    verlustgrund: undefined,
    geburtsgewicht: 2,
    creationRecordId: "3749e1c0-dee5-11ea-8a18-79a75115a85c",
  },
  {
    buchtsauDescriptor: { belegNr: 8, tierSysId: "8748ccf1-6e13-4a27-bb51-aa1fe8c7aa43" },
    createdAtUtc: 1594884384977,
    funktionenHistory: [],
    genetischeSauDescriptor: { belegNr: 8, tierSysId: "8748ccf1-6e13-4a27-bb51-aa1fe8c7aa43" },
    geschlecht: Geschlecht.Kastrat,
    id: 3,
    modifiedAtUtc: 1594884384977,
    tierIdent: "494178",
    transponder: 222222222222222,
    verlustgrund: 11,
    creationRecordId: "3749e1c0-dee6-11ea-8a18-79a75115a85c",
  },
];

export const testSauen: ILocalSauDto[] = [
  {
    id: 2,
    tierSysId: "e420c824-db3a-47fb-8980-131e3b1bbcfc",
    halterbetrieb: "276456789000099",
    sauNrBetrieb: "912",
    sauNrPlaner: 11824,
    modifiedAtUtc: 1618661401910,
    lifeStages: [
      {
        lifeStageType: SauLifeStageTypeEnum.Belegung,
        belegNr: 2,
        mumien: undefined,
        abferkeldatum: undefined,
        absetzdatum: undefined,
        belegdatum: 1594970784976,
        buchten: [],
        funktionenHistory: [],
      },
    ],
  },
  {
    id: 13,
    tierSysId: "e88e284e-ea98-4593-92a4-e9febb113873",
    halterbetrieb: "276456789000099",
    sauNrBetrieb: "977",
    sauNrPlaner: 11803,
    modifiedAtUtc: 1618661401917,
    lifeStages: [
      {
        lifeStageType: SauLifeStageTypeEnum.Belegung,
        belegNr: 1,
        mumien: undefined,
        abferkeldatum: undefined,
        absetzdatum: undefined,
        belegdatum: 1594970786976,
        buchten: [],
        funktionenHistory: [],
      },
    ],
  },
  {
    id: 8,
    tierSysId: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936",
    halterbetrieb: "276456789000099",
    sauNrBetrieb: "T-753",
    sauNrPlaner: 11909,
    modifiedAtUtc: 1618661401914,
    lifeStages: [
      {
        lifeStageType: SauLifeStageTypeEnum.Belegung,
        belegNr: 5,
        mumien: undefined,
        abferkeldatum: undefined,
        absetzdatum: undefined,
        belegdatum: 1594970794976,
        buchten: [],
        funktionenHistory: [],
      },
    ],
  },
];

export const testBuchten: IBuchtDto[] = [
  {
    id: 1,
    betriebId: 1,
    modifiedAtUtc: 1618661401914,
    funktionenHistory: [
      {
        funktionId: 123,
        recordId: "3749e1c0-dee6-11ea-8a18-79a75115a85c",
        createdAtUtc: 1594884384977,
        futter: {
          gewicht: 2,
          fuetterungsdatum: 1618661401914,
        } as BuchtFutterDto,
        kommentare: [{ kommentarId: 1, workflowId: 1 } as BuchtKommentarDto],
      } as BuchtFunktionHistoryDto,
    ],
  },
  {
    id: 2,
    betriebId: 1,
    modifiedAtUtc: 1618771401914,
    funktionenHistory: [
      {
        funktionId: 123,
        recordId: "4759e1c1-dee6-11ea-8a18-79a75115a85c",
        createdAtUtc: 1594884384977,
        futter: {
          gewicht: 2,
          fuetterungsdatum: 1618661401914,
        } as BuchtFutterDto,
        kommentare: [{ kommentarId: 1, workflowId: 1 } as BuchtKommentarDto],
      } as BuchtFunktionHistoryDto,
    ],
  },
];

export const testPlanFerkel: PlanFerkelDto[] = [
  {
    buchtId: 1,
    transponder: 900222001041268,
    geschlecht: "Sau",
    keyValuesInfo: [
      { key: "Standort", value: "A1/1 Bewegungsbucht" },
      { key: "Tier", value: "A2093" },
      { key: "Geschlecht", value: "weiblich" },
      { key: "Durchgang", value: "FE 08" },
    ],
  } as PlanFerkelDto,
  {
    buchtId: 8,
    transponder: 900222001040813,
    geschlecht: "Sau",
    keyValuesInfo: [
      { key: "Standort", value: "A2/2 Bewegungsbucht" },
      { key: "Tier", value: "A2094" },
      { key: "Geschlecht", value: "weiblich" },
      { key: "Durchgang", value: "FE 06" },
    ],
  } as PlanFerkelDto,
];

export const testProzessDataFilters: IProzessDataFilterDto[] = [
  {
    type: "ferkel",
    filters: [
      {
        fieldName: "createdAtUtc",
        fieldFilters: [
          {
            operator: "between_age_in_days",
            value: [0, 30],
          } as FieldFilterDto,
        ],
      },
    ] as FilterDto[],
  },
  {
    type: "sau",
    filters: [
      {
        fieldName: "belegDatum",
        fieldFilters: [
          {
            operator: "between_age_in_days",
            value: [0, 150],
          },
        ],
      },
    ] as FilterDto[],
  },
];

export const testBuchtsau: ProzessDataDto[] = [
  new ProzessDataDto({
    id: "e420c824-db3a-47fb-8980-131e3b1bbcfc",
    label: "910",
    value: "e420c824-db3a-47fb-8980-131e3b1bbcfc",
  }),
  new ProzessDataDto({
    id: "55555555",
    label: "911",
    value: "ea7bd000-5ed3-4f44-bb7d-da23f08cf936",
  }),
  new ProzessDataDto({
    id: "33333333",
    label: "912",
    value: "41a155c8-a1b2-496e-bc03-df2f7d2b12ed",
  }),
  new ProzessDataDto({
    id: "22222222",
    label: "977",
    value: "e88e284e-ea98-4593-92a4-e9febb113873",
  }),
];

export const testWurfuebersichtFerkelData: WurfuebersichtFerkelDto[] = [
  { geschlecht: "Eber", totgeburt: true, id: "3749e1c0-dee4-11ea-8a18-79a75115a85c" },
  { geschlecht: "Sau", totgeburt: true, id: "3749e1c1-dee4-11ea-8a18-79a75115a85c" },
  { geschlecht: "Kastrat", totgeburt: true, id: "3749e1c2-dee4-11ea-8a18-79a75115a85c" },
  { geschlecht: "Eber", totgeburt: false, id: "3749e1c3-dee4-11ea-8a18-79a75115a85c" },
  { geschlecht: "Sau", totgeburt: false, id: "3749e1c4-dee4-11ea-8a18-79a75115a85c" },
  { geschlecht: "Kastrat", totgeburt: false, id: "3749e1c5-dee4-11ea-8a18-79a75115a85c" },
];

export const testWurfFerkelData: WurfFerkelDto = {
  lebendeFerkel: testWurfuebersichtFerkelData.filter(item => item.totgeburt === false),
  totgeburtFerkel: testWurfuebersichtFerkelData.filter(item => item.totgeburt === true),
};

export const testProzessEventsRecord: ProzessEventsRecord = {
  7: {
    workflowId: 7,
    eventType: 204,
    data: "3",
    metaData: null,
    created: new Date("March 18, 1995 21:28:00.000Z"),
    transactionId: "3749e1c2-dee4-11ea-8a18-79a75115a85c",
    recordId: "3749e1c0-dee4-11ea-8a18-79a75115a85c",
    funktionId: 2,
  },
  11: {
    workflowId: 11,
    eventType: 208,
    data: "13",
    metaData: null,
    created: new Date("March 18, 1995 21:28:00.000Z"),
    transactionId: "3749e1c3-dee4-11ea-8a18-79a75115a85c",
    recordId: "3749e1c0-dee4-11ea-8a18-79a75115a85c",
    funktionId: 2,
  },
};

export const testDataOverview: IDataOverviewDto = {
  headers: [
    "Funktion",
    "MI <br /> 12.01.",
    "DI <br /> 11.30.",
    "MO <br /> 11.29.",
    "SO <br /> 11.28.",
    "SA <br /> 11.27.",
    "FR <br /> 11.26.",
    "DO <br /> 11.25.",
    "MI <br /> 12.01.",
    "DI <br /> 11.30.",
    "MO <br /> 11.29.",
    "SO <br /> 11.28.",
    "SA <br /> 11.27.",
    "FR <br /> 11.26.",
    "DO <br /> 11.25.",
  ],
  rows: [
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Datenaustausch", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Lokalisation FAZ", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Lokalisation MAST", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Nachkennzeichnen FAZ", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Nachkennzeichnen MAST", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],

    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Ersterfassung", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Datenaustausch", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Lokalisation FAZ", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Lokalisation MAST", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Nachkennzeichnen FAZ", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
    ["Nachkennzeichnen MAST", "1", "2", "3", "4", "5", "6", "7", "1", "2", "3", "4", "5", "6", "7"],
  ],
};

export const testEditedCapturedRecords = {
  data: {
    1: "2",
    3: "333",
    7: "mars",
    8: "925",
    9: "925",
    11: "[]",
  },
};

export const testTransponder: TransponderDto = {
  transponder: "701445367088401",
  isProzessCreated: false,
  isProzessCreatedInEditMode: false,
  metaData: testBluetoothData[1],
};

export const testState: AppState = {
  funktionen: {
    currentFunktionId: testFunktionen[123].id,
    funktionen: testFunktionen,
    prozesse: { "1": Object.values(testProzesse) },
    lastFetched: new Date("March 18, 1995 21:28:00.000Z"),
    loadStatus: loadSuccess(),
  },
  common: {
    drawerOpen: false,
    isAppUpdateAvailable: false,
    isEditModeActivated: false,
    shouldHighlightRecord: false,
    isManuallyEmpty: false,
    isDialogOpen: false,
    shouldCreateProzessEventForMultipleProzess: false,
    countOfMultipleProzessEvents: undefined,
    isDifferentialWeightingActive: false,
    forceAppUpdate: false,
    isAppDeprecated: false,
    isDataImportSnackbarOpen: false,
    shouldOpenValidationOverviewDialog: false,
    canEditProzessEvent: true,
    canProcessMultipleProzessEvents: true,
  },
  bluetooth: {
    connectionStatus: BluetoothConnectionStatus.CONNECTED,
    connectedDevice: "TEST-IQ-HUB",
    data: {
      waage: {
        lastReceived: testBluetoothData[0],
        stableCandidates: testBluetoothData,
        stable: testBluetoothData[1],
        captureStable: {
          enabled: false,
          gewichtConfiguration: undefined,
        },
        shouldUseManualValue: false,
        numberOfSkippedValues: 0,
        totalAmountOfGewicht: 0,
      },
      transponder: testBluetoothData[1],
      tierIdent: testBluetoothData[2],
    },
  },
  user: {
    user: undefined,
    settings: {} as UserSettingsDto,
    loadStatus: loadInitial(),
    updateStatus: loadInitial(),
  },
  capturedRecords: {
    [testFunktionen[123].id!]: {
      localRecords: testLastRecords,
      remote: {
        records: [],
        offset: 1,
        totalCount: 0,
        lastFetched: new Date("March 18, 1995 21:28:00.000Z"),
        noMoreCapturedRecordsAvailable: false,
      },
    },
    editedRecord: undefined,
    loadStatus: loadInitial(),
  },
  i18n: {
    chosenLanguage: "de",
    initialized: true,
  },
  authentication: {
    token: "151das585dsa0",
    deviceId: "0ihoasidnfß9a",
    userId: "1dhqasrdsbß9a",
    users: [
      new UserDto({ displayName: "Stanley", displayOrder: 1, id: "f8c5858f-aed4-4dbf-b80b-5f8d76ed3281" }),
    ],
    loggedInUser: "Stanley",
  },
  notification: { notification: { message: "", variant: "default" } },
  prozessEvents: {
    recordId: "123456",
    recordsGroupId: "67890",
    prozessEvents: {},
    allProzessEventsValidInCreationMode: undefined,
    givenData: {},
    filteredBy: {},
    comparisonData: {},
    appVersion: "AppVersion Test",
    synchronousProcessingStatus: loadInitial(),
    recordProcessingStatus: loadInitial(),
  },
  ferkel: {
    filteredFerkel: [],
    lastFetched: new Date("March 18, 1995 21:28:00.000Z"),
    loadStatus: loadSuccess(),
  },
  wurfuebersicht: {
    selectedBuchtsau: testBuchtsau[0],
    ferkel: { [testBuchtsau[0].id]: testWurfuebersichtFerkelData },
    lebendeFerkel: undefined,
    totgeburtFerkel: undefined,
  },
  transitoryProzessData: {
    transpondersForDeletion: [],
    ferkelInfo: [],
    dataExport: undefined,
    dataImportValidationErrors: undefined,
  },
  bluetoothSimulation: {
    activeChannel: undefined,
  },
  sauen: {
    loadStatus: loadSuccess(),
    lastFetched: new Date("March 18, 1995 21:28:00.000Z"),
    sauen: [],
  },
  buchten: {
    loadStatus: loadSuccess(),
    lastFetched: new Date("March 18, 1995 21:28:00.000Z"),
    shouldUpdateBuchtenTable: false,
  },
  planFerkel: {
    loadStatus: loadSuccess(),
    lastFetched: new Date("March 18, 2022 21:28:00.000Z"),
  },
  synchronization: {
    allProzessEventsSynced: false,
    allProzessEventsSaved: false,
    forceToUpdate: false,
    shouldRemoveObsoleteData: false,
    shouldSynchronizeApp: false,
  },
};
