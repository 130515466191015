import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexWrapper: {
      display: "flex",
      flexDirection: "column",
      fontSize: "14pt",
    },
    header: {
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "start",
    },
    title: {
      fontWeight: "bold",
      fontSize: "20pt",
      marginBottom: "20px",
      marginTop: "10px",
    },
    description: {
      fontSize: "1.5rem",
    },
    closeIcon: {
      color: "black!important",
      top: "13%!important",
    },
    formControl: {
      marginTop: "20px",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      "&:hover": {
        backgroundColor: "rgb(23, 108, 147)",
      },
    },
  })
);

export default useStyles;
