import i18n from "i18next";
import { findIconDefinition, IconName, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faGenderless } from "@fortawesome/free-solid-svg-icons";
import { convertDateForTable, validateDateFormat } from "../../../../utils/datetime.utils";
import { isFloat } from "../../../../utils/number.utils";
import { EMPTY_DATA } from "../../tierident/tierident.component";
import TooltipComponent from "../../../tooltip/tooltip.component";
import { Order, RecordColumn } from "./records-table.types";
import { ISyncedCapturedRecord } from "../../../../db/database";
import { BEARBEITET_COLUMN } from "./records-table.component";
import { ITextFileDto } from "../../upload/upload.types";

library.add(faMars, faVenus, faGenderless);

export const formatProzessData = (prozessData: string | string[] | ITextFileDto) => {
  if (!prozessData || prozessData === EMPTY_DATA) {
    return "-";
  }

  if (Array.isArray(prozessData)) {
    if (prozessData.every(item => typeof item === "string")) {
      return prozessData.length === 1 ? prozessData[0] : <TooltipComponent prozessData={prozessData} />;
    } else {
      return prozessData.length;
    }
  }

  if (typeof prozessData === "object") {
    return prozessData.fileName;
  }

  const geschlechtIcon = findIconDefinition({
    prefix: "fas",
    iconName: prozessData as IconName,
  });

  if (geschlechtIcon) {
    return <FontAwesomeIcon icon={geschlechtIcon} />;
  }

  const isDateValid = validateDateFormat(prozessData);

  if (isDateValid) {
    return convertDateForTable(prozessData);
  } else {
    const asNumber = parseFloat(prozessData);
    if (isFloat(asNumber)) {
      return asNumber.toLocaleString(i18n.language);
    }
  }

  return prozessData;
};

const descendingComparator = (a: ISyncedCapturedRecord, b: ISyncedCapturedRecord, orderBy: RecordColumn) => {
  // Sorting for Bearbeitet column.
  if (orderBy === BEARBEITET_COLUMN) {
    if (b.timestamp! < a.timestamp!) {
      return -1;
    }
    if (b.timestamp! > a.timestamp!) {
      return 1;
    }
    return 0;
  }

  // Sorting for Kommentare (single and miltiple) and multiple transponders.
  if (
    (Array.isArray(a.data![orderBy.workflowId]) || a.data![orderBy.workflowId] === EMPTY_DATA) &&
    (Array.isArray(b.data![orderBy.workflowId]) || b.data![orderBy.workflowId] === EMPTY_DATA)
  ) {
    // Check types of data in array.
    if (
      typeof a.data![orderBy.workflowId][0] === "object" &&
      typeof b.data![orderBy.workflowId][0] === "object"
    ) {
      // Multiple transponders.
      return a
        .data![orderBy.workflowId].length.toString()
        .localeCompare(b.data![orderBy.workflowId].length.toString());
    } else {
      // Kommentare (single and miltiple).
      return (a.data![orderBy.workflowId].length ? a.data![orderBy.workflowId][0] : "-").localeCompare(
        b.data![orderBy.workflowId].length ? b.data![orderBy.workflowId][0] : "-"
      );
    }
  }

  // Sorting for rest data.
  return (a.data![orderBy.workflowId] ? a.data![orderBy.workflowId].toString() : "-").localeCompare(
    b.data![orderBy.workflowId] ? b.data![orderBy.workflowId].toString() : "-",
    undefined,
    { numeric: true, sensitivity: "base" }
  );
};

export const getComparator = (
  order: Order,
  orderBy: RecordColumn
): ((a: ISyncedCapturedRecord, b: ISyncedCapturedRecord) => number) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
