import dayjs from "dayjs";
import { IFunktionDto } from "../api/backend-api-v7";

const ONE_DAY_MILLISECONDS = 86400000;

export enum LastSyncPeriod {
  MORE_TWO_WEEK = ">2 week",
  MORE_ONE_WEEK = ">1 week",
  LESS_ONE_WEEK = "<1 week",
}

export const convertDaysToSeconds = (days: number) => days * ONE_DAY_MILLISECONDS;

export const convertDateForCalendar = (date: string) => dayjs(date).format("YYYY-MM-DD");

export const convertDateForTable = (date: string | number) => dayjs(date).format("DD.MM.YYYY");

export const calculateFilteringInterval = (startDate: number, endDate: number) => {
  const startDateTimestamp = convertDaysToSeconds(startDate);
  const endDateTimestamp = convertDaysToSeconds(endDate);
  const startInterval = new Date().getTime() - endDateTimestamp;
  const endInterval = new Date().getTime() - startDateTimestamp;
  return { startInterval, endInterval };
};

export const canDeleteAndEditRecord = (timestamp: number, maxMinutesDeletable: number) =>
  dayjs().isBefore(dayjs(timestamp).add(maxMinutesDeletable, "minute"));

export const isEditTimeExpired = (timestamp: number, maxMinutesDeletable: number) =>
  dayjs().isAfter(dayjs(timestamp).add(maxMinutesDeletable, "minute"));

const isWeekGone = (timestamp: number, count: number) => dayjs().isAfter(dayjs(timestamp).add(count, "week"));

export const checkLastSyncDate = (timestamp: number) => {
  if (isWeekGone(timestamp, 2)) {
    return LastSyncPeriod.MORE_TWO_WEEK;
  } else {
    if (isWeekGone(timestamp, 1)) {
      return LastSyncPeriod.MORE_ONE_WEEK;
    } else {
      return LastSyncPeriod.LESS_ONE_WEEK;
    }
  }
};

export const isOneDayGone = (timestamp: number) => dayjs().isAfter(dayjs(timestamp).add(1, "day"));

export const calculateExpirationDate = (funktion: IFunktionDto) => {
  if (funktion.funktionConfiguration?.maxMinutesDeletable) {
    return dayjs().add(funktion.funktionConfiguration.maxMinutesDeletable, "minute").valueOf();
  }
  return null;
};

export const isExpirationDateGone = (timestamp: number | null) => dayjs().isAfter(dayjs(timestamp));

export const subtractCountOfDays = (timestamp: number, daysCount: number) =>
  dayjs(timestamp).subtract(daysCount, "day").valueOf();

export const validateDateFormat = (date: string) =>
  /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(date);
