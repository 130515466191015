import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: 0,
      marginBottom: 0,
      display: "flex",
      height: "100%",
      "& div": {
        height: "100%",
      },
      "& svg": {
        fontSize: "2rem",
      },
      "&:focus": {
        backgroundColor: "#ff0000",
      },
      "& input": {
        textOverflow: "ellipsis",
      },
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    highlightDifferenceInput: {
      borderColor: `${theme.additionalColors.ampelGelb}!important`,
    },
    highlightDifferenceLabel: {
      "& label": {
        color: `${theme.additionalColors.ampelGelb}!important`,
      },
    },
    disabled: {
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
  })
);

export default useStyles;
