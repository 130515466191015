import { FunctionComponent, ReactNode, MouseEventHandler } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "#229bd3",
    width: "190px",
    height: "60px",
    color: "white",
    fontWeight: "bold",
    fontSize: "13pt",
    borderRadius: "2px",
    border: "none",
    "&:focus": {
      outline: "none",
      border: "none",
    },
  },
}));

export interface Props {
  children: ReactNode;
  handler?: MouseEventHandler;
  type?: "button" | "reset" | "submit" | undefined;
  className?: string;
  disabled?: boolean;
  dataCy?: string;
}

const AppButton: FunctionComponent<Props> = ({ handler, children, className, disabled, dataCy, type }) => {
  const classes = useStyles();
  return (
    <button
      onClick={handler}
      className={`${className} ${classes.button}`}
      disabled={disabled}
      data-cy={dataCy}
      type={type}
    >
      {children}
    </button>
  );
};

export default AppButton;
