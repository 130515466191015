import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "14pt",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.additionalColors.lightGray,
    width: "100%",
    position: "relative",
  },
  title: {
    fontWeight: "bold",
    fontSize: "26pt",
    marginBottom: "20px",
    marginTop: "10px",
    color: "white",
  },
  topic: {
    fontWeight: "bold",
    fontSize: "20pt",
    marginBottom: "5px",
  },
  textWrapper: {
    margin: theme.spacing(0, 0, 0, 1),
    padding: theme.spacing(2, 2, 2, 0),
    height: "90vh",
    overflowY: "scroll",
  },
  description: {
    marginBottom: "10px",
  },
  paragraph: {
    fontWeight: "bold",
  },
}));

export default useStyles;
