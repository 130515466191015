import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalBuchtDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const futterGewichtProzessEventHandler = (
  bucht: ILocalBuchtDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (bucht: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const futterGewicht = prozessEvents.find(pe => pe.prozessType === ProzessType.FUTTER_GEWICHT);

  if (futterGewicht) {
    const { recordId, funktionId } = prozessEvents[0];

    const updatedBucht = {
      ...bucht,
      funktionenHistory: bucht.funktionenHistory?.map(history => {
        if (history.funktionId === funktionId && history.recordId === recordId) {
          return { ...history, futter: { ...history.futter, gewicht: +futterGewicht.data } };
        }
        return history;
      }),
    };

    return next(updatedBucht, prozessEvents);
  }

  return next(bucht, prozessEvents);
};
