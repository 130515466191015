import { FunctionComponent, useCallback, useEffect, useState } from "react";
import useStyles from "./wurfuebersicht-details.styles";
import { useTranslation } from "react-i18next";
import { Props } from "./wurfuebersicht-details.types";
import { Dialog, Typography } from "@mui/material";
import AppButton from "../../../common/app-button/app-button";
import RecordsTableComponent from "../../records/records-table/records-table.component";
import { ISyncedCapturedRecord } from "../../../../db/database";
import { IProzessDto, ProzessType } from "../../../../api/backend-api-v7";
import { mapFerkelDtosToRecords } from "./wurfuebersicht-details.utils";

const WurfuebersichtDetailsComponent: FunctionComponent<Props> = props => {
  const { open, closeModal, buchtsau, currentProzess, funktionId, funktion, prozesse, ferkel } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [filteredRecords, setFilteredRecords] = useState<ISyncedCapturedRecord[]>([]);
  const [filteredProzesse, setFilteredProzesse] = useState<IProzessDto[]>([]);

  const filterProzesseForTable = useCallback(() => {
    if (prozesse) {
      const filteredProzesse = prozesse.filter(
        prozess =>
          prozess.workflowId !== currentProzess.filteredByWorkflowId &&
          prozess.prozessType !== ProzessType.MUMIEN &&
          prozess.prozessType !== ProzessType.ABFERKELDATUM &&
          prozess.prozessType !== ProzessType.KOMMENTARE
      );
      setFilteredProzesse(filteredProzesse);
    }
  }, [currentProzess.filteredByWorkflowId, prozesse]);

  const handleCloseDialog = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const sortRecordsByTimestamp = useCallback(
    (records: ISyncedCapturedRecord[]) => records.sort((a, b) => a.timestamp! - b.timestamp!),
    []
  );

  const renderRecords = useCallback(
    () =>
      !filteredRecords.length ? (
        <div data-cy="empty-table" className={classes.emptyRecords}>
          {t("COMMON.NO_DATA")}
        </div>
      ) : (
        <div className={classes.recordsTable} data-cy="details-wurfuebersicht">
          <RecordsTableComponent
            currentProzess={currentProzess}
            prozesse={filteredProzesse}
            records={[filteredRecords]}
            funktionId={funktionId}
            shouldUseConsecutiveNr={true}
          />
        </div>
      ),
    [
      classes.emptyRecords,
      classes.recordsTable,
      currentProzess,
      filteredProzesse,
      filteredRecords,
      funktionId,
      t,
    ]
  );

  const mapRecordsForTable = useCallback(() => {
    if (ferkel.length && filteredProzesse.length) {
      const filteredFerkel = ferkel.filter(item => item.buchtsauDescriptor.tierSysId === buchtsau?.id);
      const records = mapFerkelDtosToRecords(filteredFerkel, filteredProzesse, funktion);
      const sortedRecords = sortRecordsByTimestamp(records);
      setFilteredRecords(sortedRecords);
    }
  }, [buchtsau?.id, ferkel, filteredProzesse, funktion, sortRecordsByTimestamp]);

  useEffect(() => {
    filterProzesseForTable();
  }, [filterProzesseForTable]);

  useEffect(() => {
    mapRecordsForTable();
  }, [mapRecordsForTable]);

  return (
    <Dialog fullScreen={true} open={open} onClose={closeModal}>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}>{`${t(currentProzess.label!)}: ${
          buchtsau ? buchtsau.label : "-"
        }`}</Typography>
      </div>
      {renderRecords()}
      <div className={classes.buttonContainer}>
        <AppButton className={classes.closeButton} handler={handleCloseDialog} type="button">
          {t("COMMON.CLOSE").toUpperCase()}
        </AppButton>
      </div>
    </Dialog>
  );
};

export default WurfuebersichtDetailsComponent;
