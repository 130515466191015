import WurfuebersichtDetailsComponent from "./wurfuebersicht-details.component";
import { OwnProps, ConnectedState } from "./wurfuebersicht-details.types";
import { connect } from "react-redux";
import { getSelectedBuchtsau } from "../../../../store/wurfuebersicht/wurfuebersicht.selectors";
import { AppState } from "../../../../store/app.state";
import { getFunktionById, getProzesseByFunktionId } from "../../../../store/funktionen/funktionen.selectors";
import { getFilteredFerkel } from "../../../../store/ferkel/ferkel.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const funktionId = ownProps.funktionId;

  return {
    funktion: getFunktionById(funktionId)(state),
    prozesse: getProzesseByFunktionId(funktionId)(state),
    buchtsau: getSelectedBuchtsau(state),
    ferkel: getFilteredFerkel(state),
  };
};

export default connect(mapStateToProps)(WurfuebersichtDetailsComponent);
