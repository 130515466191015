import logger from "../../../logger";
import {
  SelectWithDateState,
  SelectWithDateAction,
  SelectWithDateActionType,
} from "./select-with-date.types";
import dayjs from "dayjs";

const logInfo = logger.info("select-with-date.reducer");
const DATE_FORMAT = "YYYY-MM-DD";

export const initialState: SelectWithDateState = {
  date: undefined,
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const selectWithDateReducer = (
  state: SelectWithDateState,
  action: SelectWithDateAction
): SelectWithDateState => {
  const {
    type,
    payload: { value, isRequired, isValid, isProzessEdited, shouldUsePreselectedValue },
  } = action;

  switch (type) {
    case SelectWithDateActionType.INIT: {
      logInfo("SelectWithDate INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case SelectWithDateActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        date: value,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case SelectWithDateActionType.RESET: {
      logInfo("SelectWithDate RESET");
      const preselectedDate = shouldUsePreselectedValue ? dayjs(Date.now()).format(DATE_FORMAT) : undefined;
      return {
        ...initialState,
        date: preselectedDate,
        isError: preselectedDate ? false : isError(!!isValid, isRequired),
      };
    }
    case SelectWithDateActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
