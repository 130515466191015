import { FunctionComponent, useState, useCallback, useEffect } from "react";
import useStyles from "./info-futter.styles";
import { Props } from "./info-futter.types";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import { findBuchtById } from "../../../utils/find-data-in-db.utils";
import { IBuchtKommentarDto } from "../../../api/backend-api-v7";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ILocalBuchtDto } from "../../../db/database";

dayjs.extend(utc);

const InfoFutterComponent: FunctionComponent<Props> = props => {
  const { prozess, filteredBy, prozesse, shouldUpdateTable, updateBuchtenTable, funktionId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [bucht, setBucht] = useState<ILocalBuchtDto | undefined>(undefined);

  const filterBuchtFunktionHistory = useCallback(
    (bucht: ILocalBuchtDto) => {
      if (bucht.funktionenHistory?.length) {
        const historyForCurrentFunktion = bucht.funktionenHistory
          .filter(history => history.funktionId === funktionId)
          .sort((a, b) => b.createdAtUtc! - a.createdAtUtc!);

        if (historyForCurrentFunktion.length >= 3) {
          const filteredHistory = historyForCurrentFunktion.slice(0, 3);
          return { ...bucht, funktionenHistory: filteredHistory };
        } else {
          while (historyForCurrentFunktion.length < 3) {
            historyForCurrentFunktion.unshift({});
          }
        }
        return { ...bucht, funktionenHistory: historyForCurrentFunktion };
      }
    },
    [funktionId]
  );

  const setBuchtById = useCallback(async () => {
    if (filteredBy && filteredBy.data) {
      const bucht = await findBuchtById(filteredBy.data);
      if (bucht) {
        setBucht(filterBuchtFunktionHistory(bucht));
      } else {
        setBucht(undefined);
      }
      if (shouldUpdateTable) {
        updateBuchtenTable();
      }
      return;
    }
    setBucht(undefined);
  }, [filterBuchtFunktionHistory, filteredBy, shouldUpdateTable, updateBuchtenTable]);

  const renderKommentarCells = useCallback(
    (label: string, kommentare: IBuchtKommentarDto[], key: string) => {
      const currentProzess = prozesse.find(item => item.label === label);
      const kommentar = kommentare.find(item => item.workflowId === currentProzess?.workflowId);
      const kommentarData = currentProzess?.data?.find(item => item.id === kommentar?.kommentarId);

      return (
        <TableCell key={key} align="center" className={classes.tableCell}>
          {kommentarData?.label ?? "-"}
        </TableCell>
      );
    },
    [classes.tableCell, prozesse]
  );

  const renderEmptyCells = useCallback(
    () => (
      <>
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
      </>
    ),
    [classes.tableCell]
  );

  useEffect(() => {
    setBuchtById();
  }, [setBuchtById]);

  return (
    <div style={{ gridArea: prozess.position }} data-cy="InfoFutterComponent">
      <div className={classes.label}>{prozess.label!}</div>
      <Table>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>{t("PROZESS.LABEL.FÜTTERUNGSDATUM")}</TableCell>
            {bucht
              ? bucht.funktionenHistory?.map((item, index) => (
                  <TableCell align="center" className={classes.tableCell} key={index}>
                    {item?.futter?.fuetterungsdatum
                      ? dayjs(item.futter.fuetterungsdatum).format("DD.MM.YYYY")
                      : "-"}
                  </TableCell>
                ))
              : renderEmptyCells()}
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>{t("PROZESS.LABEL.FUTTERART")}</TableCell>
            {bucht
              ? bucht.funktionenHistory?.map((item, index) =>
                  item.kommentare ? (
                    renderKommentarCells("Futterart", item.kommentare, item.recordId!)
                  ) : (
                    <TableCell key={index} align="center" className={classes.tableCell}>
                      -
                    </TableCell>
                  )
                )
              : renderEmptyCells()}
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>{t("PROZESS.LABEL.GEWICHT_KG")}</TableCell>
            {bucht
              ? bucht.funktionenHistory?.map((item, index) => (
                  <TableCell align="center" className={classes.tableCell} key={index}>
                    {item?.futter?.gewicht ? item.futter.gewicht : "-"}
                  </TableCell>
                ))
              : renderEmptyCells()}
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>{t("PROZESS.LABEL.RESTMENGE")}</TableCell>
            {bucht
              ? bucht.funktionenHistory?.map((item, index) =>
                  item.kommentare ? (
                    renderKommentarCells("Restmenge", item.kommentare, item.recordId!)
                  ) : (
                    <TableCell key={index} align="center" className={classes.tableCell}>
                      -
                    </TableCell>
                  )
                )
              : renderEmptyCells()}
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>{t("PROZESS.LABEL.KOMMENTAR")}</TableCell>
            {bucht
              ? bucht.funktionenHistory?.map((item, index) =>
                  item.kommentare ? (
                    renderKommentarCells("Kommentar", item.kommentare, item.recordId!)
                  ) : (
                    <TableCell key={index} align="center" className={classes.tableCell}>
                      -
                    </TableCell>
                  )
                )
              : renderEmptyCells()}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default InfoFutterComponent;
