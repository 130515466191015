import { FunctionComponent, useCallback, useEffect } from "react";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";

import { Props } from "./key-value-table.types";
import useStyles from "./key-value-table.styles";
import { defineFerkelProperty } from "../../../utils/ferkel.utils";
import { useKeyValueInfoTableState } from "./key-value-table.hooks";
import { IKeyValueDto, IKommentarDto, KeyValueDto, ProzessType } from "../../../api/backend-api-v7";
import { ILocalFerkelDto } from "../../../db/database";
import { convertDateForTable } from "../../../utils/datetime.utils";

const KeyValueTableComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    givenData,
    givenDataApplied,
    ferkel,
    onChanged,
    isEditing,
    isManuallyEmpty,
    recordId,
    prevRecordId,
    editedValue,
  } = props;
  const classes = useStyles();
  const { state, onProzessDataChanged, reset, save, shouldEditProzessData } = useKeyValueInfoTableState(
    prozess,
    onChanged,
    isEditing
  );

  /**
   * Creates {@link IKeyValueDto}[] based on {@link IKommentarDto}[] from Verkauf Funktion.
   * The {@link ProzessMetadataDto} contains kommentar prozess.label to show in the table.
   */
  const mapMetadataToKeyValueInfo = useCallback(
    (ferkel: ILocalFerkelDto) => {
      const verkaufHistory = ferkel.funktionenHistory?.find(history => history.verkaufsdatum);
      if (verkaufHistory) {
        const prozessMetadataInfo = prozess.metadata?.funktionen?.flatMap(funktion => funktion.prozesse);
        const keyValueInfo = verkaufHistory.kommentare!.reduce(
          (total: IKeyValueDto[], current: IKommentarDto) => {
            const label = prozessMetadataInfo?.find(
              prozess => prozess?.workflowId === current.workflowId
            )?.label;
            return [...total, { key: label, value: current.name }];
          },
          []
        );

        if (verkaufHistory.verkaufsdatum) {
          keyValueInfo.push({
            key: "Verkaufsdatum",
            value: convertDateForTable(verkaufHistory.verkaufsdatum),
          });
        }
        onProzessDataChanged(keyValueInfo as KeyValueDto[]);
      }
    },
    [onProzessDataChanged, prozess.metadata?.funktionen]
  );

  /**
   * Defines the way to handle GivenData by prozess type.
   */
  const processGivenData = useCallback(
    (ferkel: ILocalFerkelDto | undefined) => {
      if (ferkel) {
        switch (prozess.prozessType) {
          case ProzessType.INFO_PLAN:
            if (isEditing) {
              shouldEditProzessData(true);
            }
            onProzessDataChanged(ferkel.plan?.keyValuesInfo);
            break;
          case ProzessType.INFO_VERKAUF:
            mapMetadataToKeyValueInfo(ferkel);
            break;
          default:
            onProzessDataChanged(undefined);
            break;
        }
      } else {
        onProzessDataChanged(undefined);
      }
    },
    [isEditing, mapMetadataToKeyValueInfo, onProzessDataChanged, prozess.prozessType, shouldEditProzessData]
  );

  const checkOnGivenData = useCallback(() => {
    if (givenData) {
      if (givenData.data) {
        const { prozessType, data } = givenData.data;
        const key = defineFerkelProperty(prozessType);
        const currentFerkel = ferkel.find(ferkel => ferkel[key] === data);

        processGivenData(currentFerkel);
      } else {
        reset();
      }
      givenDataApplied(prozess.workflowId!);
    }
  }, [ferkel, givenData, givenDataApplied, processGivenData, prozess.workflowId, reset]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      shouldEditProzessData(false);
      onProzessDataChanged(editedValue);
    }
  }, [editedValue, isEditing, onProzessDataChanged, shouldEditProzessData]);

  const createProzessEvent = useCallback(() => {
    const isRecordActive = prevRecordId === recordId;

    if (isEditing) {
      if (isRecordActive && state.isProzessEdited) {
        save(state.value);
      }
    } else {
      if (isRecordActive) {
        save(state.value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    reset();
  }, [recordId, reset, isManuallyEmpty]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    createProzessEvent();
  }, [createProzessEvent]);

  return (
    <div style={{ gridArea: prozess.position }} data-cy="KeyValueTableComponent">
      <div className={classes.label}>{prozess.label!}</div>
      <Table>
        <TableBody>
          {!state.value ? (
            <TableRow>
              <TableCell align="center" className={classes.tableCell}>
                {state.isError && <span className={classes.error}>Keine Information verfügbar</span>}
              </TableCell>
            </TableRow>
          ) : (
            state.value.map(({ key, value }, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>{key}</TableCell>
                <TableCell className={classes.tableCell}>{value}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default KeyValueTableComponent;
