import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const totgeburtProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const totgeburt = prozessEvents.find(pe => pe.prozessType === ProzessType.TOTGEBURT);

  if (totgeburt) {
    const updatedFerkel = { ...ferkel, totgeburt: +totgeburt.data[0] };
    return next(updatedFerkel, prozessEvents);
  }
  return next(ferkel, prozessEvents);
};
