import { AppState } from "../../../store/app.state";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import InfoFutterComponent from "./info-futter.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./info-futter.types";
import { getFilteredByWorkflowId } from "../../../store/prozess-events/prozess-events.selectors";
import { getProzesseByFunktionId } from "../../../store/funktionen/funktionen.selectors";
import { getShouldUpdateBuchtenTable } from "../../../store/buchten/buchten.selectors";
import { updateBuchtenTable } from "../../../store/buchten/buchten.actions";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;
  const funktionId = ownProps.funktionId;

  return {
    filteredBy: getFilteredByWorkflowId(workflowId)(state),
    prozesse: getProzesseByFunktionId(funktionId)(state),
    shouldUpdateTable: getShouldUpdateBuchtenTable(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  updateBuchtenTable: () => dispatch(updateBuchtenTable(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoFutterComponent);
