import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { useCallback, useReducer } from "react";
import { initialState, selectWithDialogReducer } from "./select-with-dialog.reducer";
import { SelectWithDialogActionType } from "./select-with-dialog.types";
import { EMPTY_DATA } from "../tierident/tierident.component";

const isValid = (value: string | string[] | undefined) => !!value && value.length !== 0;

export const useSelectWithDialogState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  shouldIgnoreEventType: boolean
) => {
  const { isRequired, shouldKeepValue } = prozess;
  const [state, dispatch] = useReducer(selectWithDialogReducer, initialState);

  const init = useCallback(
    () =>
      dispatch({
        type: SelectWithDialogActionType.INIT,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: string | string[] | undefined) => {
      dispatch({
        type: SelectWithDialogActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid: isValid(value),
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: SelectWithDialogActionType.RESET,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const save = useCallback(
    (selectedValues: string | string[] | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing && !shouldIgnoreEventType ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: selectedValues,
        label: prozess.data?.find(d => d.id === selectedValues![0])?.label,
        metaData: null,
        additionalData: prozess.data?.find(d => d.id === selectedValues![0])?.additional,
        operation: isEditing
          ? ProzessEventOperationTypes.CREATE
          : !!selectedValues && selectedValues !== EMPTY_DATA
          ? ProzessEventOperationTypes.CREATE
          : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!selectedValues : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.changeEventType,
      prozess.eventType,
      prozess.eventCategory,
      prozess.data,
      prozess.isRequired,
      prozess.prozessType,
      isEditing,
      shouldIgnoreEventType,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: SelectWithDialogActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, reset, onProzessDataChanged, save, shouldEditProzessData };
};
