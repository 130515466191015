import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      padding: "5px",
      fontSize: "20px",
      whiteSpace: "pre-wrap",
      border: "1px solid #b0b0b0",
      maxWidth: "25%",
      width: "100%",
    },
    tableRow: {
      backgroundColor: "#e1e1e1",
    },
    label: {
      backgroundColor: theme.additionalColors.lightGray,
      width: "100%",
      textAlign: "center",
      lineHeight: "40px",
      fontWeight: "bold",
      height: "40px",
    },
  })
);

export default useStyles;
