import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getTierIdent } from "../../../store/bluetooth/bluetooth.selectors";
import TierIdentComponent from "./tierident.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./tierident.types";
import { Dispatch } from "redux";
import {
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import {
  getCurrentRecordId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import { resetTierIdentData } from "../../../store/bluetooth/bluetooth.actions";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    tierIdent: getTierIdent(state),
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
  resetTierIdentData: () => dispatch(resetTierIdentData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TierIdentComponent);
