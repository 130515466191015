import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalBuchtDto, ILocalBuchtFunktionHistoryDto } from "../../../db/database";

export const funktionHistoryHandler = (
  bucht: ILocalBuchtDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (bucht: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const { recordId, funktionId } = prozessEvents[0];

  const newHistory: ILocalBuchtFunktionHistoryDto = {
    funktionId,
    recordId,
    createdAtUtc: new Date().getTime(),
    kommentare: [],
    futter: { gewicht: undefined, fuetterungsdatum: undefined },
    previousData: bucht,
  };

  const updatedBucht = {
    ...bucht,
    funktionenHistory: [...(bucht.funktionenHistory || []), newHistory],
  };

  return next(updatedBucht, prozessEvents);
};
