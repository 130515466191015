import BluetoothSimulatorComponent, {
  ConnectedState,
  ConnectedDispatch,
} from "./bluetooth-simulator.component";
import { connect } from "react-redux";
import { AppState } from "../../store/app.state";
import {
  getBluetoothConnectionStatus,
  getConnectedBluetoothDevice,
} from "../../store/bluetooth/bluetooth.selectors";
import { Dispatch } from "redux";
import { startSimulation } from "../../store/bluetooth/bluetooth-data-simulation.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  bluetoothConnectionStatus: getBluetoothConnectionStatus(state),
  connectedBluetoothDevice: getConnectedBluetoothDevice(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  startSimulation: () => dispatch(startSimulation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BluetoothSimulatorComponent);
