export enum ControlTypes {
  ALL_RECORDS = "ALL_RECORDS",
  ALL_EXPORTS = "ALL_EXPORTS",
  COMBO_BOX = "COMBO_BOX",
  CHECKBOX = "CHECKBOX",
  DATA_OVERVIEW = "DATA_OVERVIEW",
  INFO_FERKEL = "INFO_FERKEL",
  INFO_FUTTER = "INFO_FUTTER",
  INFO_NEUE_BUCHTSAU = "INFO_NEUE_BUCHTSAU",
  FERKEL_ABGESETZT = "FERKEL_ABGESETZT",
  FERKEL_GRID = "FERKEL_GRID",
  GESCHLECHT = "GESCHLECHT",
  GEWICHT = "GEWICHT",
  GEWICHT_DIFFERENTIAL = "GEWICHT_DIFFERENTIAL",
  HIDDEN = "HIDDEN",
  KEY_VALUES_INFO_TABLE = "KEY_VALUES_INFO_TABLE",
  LAST_EXPORTS = "LAST_EXPORTS",
  LAST_RECORDS = "LAST_RECORDS",
  MULTIPLE_TRANSPONDERS = "MULTIPLE_TRANSPONDERS",
  NUMBER_FIELD = "NUMBER_FIELD",
  SAU_SELECTOR = "SAU_SELECTOR",
  SAU_SELECTOR_FULLSCREEN = "SAU_SELECTOR_FULLSCREEN",
  SELECT_WITH_DATE = "SELECT_WITH_DATE",
  SELECT_WITH_DIALOG = "SELECT_WITH_DIALOG",
  SELECT_WITH_DIALOG_FULLSCREEN = "SELECT_WITH_DIALOG_FULLSCREEN",
  TEXT_FIELD = "TEXT_FIELD",
  TEXT_FILE_CONTENT_UPLOAD = "TEXT_FILE_CONTENT_UPLOAD",
  TIER_IDENT = "TIER_IDENT",
  TIER_INFO = "TIER_INFO",
  TRANSPONDER = "TRANSPONDER",
  PROZESS_EVENTS_COUNTER = "PROZESS_EVENTS_COUNTER",
  WAAGE = "WAAGE",
  WURFUEBERSICHT = "WURFUEBERSICHT",
}
