import SelectWithDateComponent from "./select-with-date.component";
import { Dispatch } from "redux";
import {
  ProzessInputData,
  prozessDataChanged,
  givenDataApplied,
} from "../../../store/prozess-events/prozess-events.actions";
import { connect } from "react-redux";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./select-with-date.types";
import { AppState } from "../../../store/app.state";
import {
  getCurrentRecordId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import {
  getIsManuallyEmpty,
  getIsProzessEventEditable,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";
import { showWarningRequest } from "../../../store/notifications/notifications.actions";
import { setCanProcessMultipleProzessEvents } from "../../../store/common/common.actions";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    prevRecordId: getCurrentRecordId(state),
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
    isProzessEventEditable: getIsProzessEventEditable(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
  showWarning: (message: string) => dispatch(showWarningRequest(message)),
  setCanProcessMultipleProzessEvents: (canProcess: boolean) =>
    dispatch(setCanProcessMultipleProzessEvents(canProcess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectWithDateComponent);
