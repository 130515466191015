import logger from "../../../logger";
import { UploadAction, UploadActionType, UploadState } from "./upload.types";

const logInfo = logger.info("tierident.reducer");

export const initialState: UploadState = {
  file: null,
  isError: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const uploadReducer = (state: UploadState, action: UploadAction): UploadState => {
  const {
    type,
    payload: { file, isRequired, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case UploadActionType.RESET: {
      logInfo("UPLOAD RESET");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case UploadActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        file,
        isError: isError(!!isValid, isRequired),
      };
    }
    case UploadActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
