import { FunctionComponent, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ITextFileDto, Props } from "./upload.types";
import { useUploadState } from "./upload.hooks";
import useStyles from "./upload.styles";
import classnames from "classnames";

import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (file: ITextFileDto | null) => void,
  file: ITextFileDto | null,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;
  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(file);
    }
  } else {
    if (isRecordActive) {
      save(file);
    }
  }
};

const UploadComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    onChanged,
    recordId,
    prevRecordId,
    isManuallyEmpty,
    isEditing,
    editedValue,
    shouldCreateProzessEventForMultipleProzess,
    setIsValidationError,
    shouldValidate,
  } = props;
  const classes = useStyles();
  const { onProzessDataChanged, reset, state, save, shouldEditProzessData } = useUploadState(
    prozess,
    onChanged,
    isEditing
  );

  const mapFileToProzessData = useCallback(
    async (file: File): Promise<ITextFileDto> => ({
      fileName: file.name,
      content: await file.text(),
    }),
    []
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        if (isEditing) {
          shouldEditProzessData(true);
        }

        const file = await mapFileToProzessData(acceptedFiles[0]);
        onProzessDataChanged(file);
      }
    },
    [isEditing, mapFileToProzessData, onProzessDataChanged, shouldEditProzessData]
  );

  const { getRootProps, getInputProps, open, isDragReject } = useDropzone({
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    // accept only csv files.
    accept: "text/csv",
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      onProzessDataChanged(editedValue as unknown as ITextFileDto);
    } else {
      shouldEditProzessData(false);
    }
  }, [editedValue, isEditing, onProzessDataChanged, shouldEditProzessData]);

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  useEffect(() => {
    reset();
  }, [recordId, reset, isManuallyEmpty]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.file, state.isProzessEdited, isEditing);
    // Should listen only state changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div
      style={{ gridArea: prozess.position }}
      className={classnames(classes.container, {
        [classes.error]: isDragReject || (shouldValidate && state.isError),
      })}
    >
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        {state.file ? (
          <>
            <UploadFileIcon className={classes.icon} />
            <span className={classes.fileName}>{state.file.fileName}</span>
          </>
        ) : (
          <>
            <span>Datei hierher ziehen</span>
            <div className={classes.text}>oder</div>
            <Button variant="contained" color="secondary" onClick={open}>
              Datei zum Importieren wählen
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadComponent;
