import { Divider, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CancelIcon from "@mui/icons-material/Cancel";

import { FunctionComponent, useCallback, useEffect, useState, Fragment, MouseEvent } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import classNames from "classnames";
import useStyles from "./records-table.styles";
import ConfirmationModalWindow from "../../../modal-windows/confirmation-dialog/confirmation-dialog.component";
import InfoComponent from "../../../info/info.component";
import AppButton from "../../../common/app-button/app-button";
import { Order, OwnProps, RecordColumn } from "./records-table.types";
import { useTranslation } from "react-i18next";
import { ISyncedCapturedRecord } from "../../../../db/database";
import { canDeleteAndEditRecord } from "../../../../utils/datetime.utils";
import { formatProzessData, getComparator } from "./records-table.utils";
import { IProzessEventsValidationResultDto, ProcessingState } from "../../../../api/backend-api-v7";

dayjs.extend(utc);
export const BEARBEITET_COLUMN = { workflowId: 0, label: "Bearbeitet" };

// Da dieser Prozess nicht die Werte eines bestimmten Domänenobjektes darstellt, sondern eher eine Übersicht über mehrere Werte bietet,
// wurde entschieden eine englische Bezeichnung zu wählen
const RecordsTableComponent: FunctionComponent<OwnProps> = props => {
  const {
    currentProzess,
    prozesse,
    records,
    funktionId,
    isDialogOpen,
    isEditing,
    maxMinutesDeletable,
    shouldHighlightRecord,
    deleteRecord,
    activateEditMode,
    shouldUseActionBar,
    resendData,
    recordsGroupId = "",
    shouldHideSyncedColumn,
    shouldUseConsecutiveNr,
    editedCapturedRecord,
    shouldDisableSorting,
    validationErrors,
    shouldOpenValidationOverviewDialog,
    setShouldOpenValidationOverviewDialog,
    setValidationErrors,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [editedRecord, setEditedRecord] = useState<ISyncedCapturedRecord>();
  const [recordForDelete, setRecordForDelete] = useState<ISyncedCapturedRecord>();
  const [isEditingDialogOpen, setIsEditingDialogOpen] = useState(false);
  const [isDeleteRecordDialogOpen, setIsDeleteRecordDialogOpen] = useState(false);
  const [isValidationOverviewDialogOpen, setIsValidationOverviewDialogOpen] = useState(false);
  const [selectedRecordsGroupId, setSelectedRecordsGroupId] = useState("");
  const [prozessEventsValidationResult, setProzessEventsValidationResult] = useState<
    IProzessEventsValidationResultDto | undefined
  >(undefined);

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<RecordColumn>(BEARBEITET_COLUMN);

  const handleRequestSort = (event: MouseEvent<unknown>, property: RecordColumn) => {
    const isAsc = orderBy.workflowId === property.workflowId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: RecordColumn) => (event: MouseEvent<unknown>) => {
    if (!shouldDisableSorting) {
      handleRequestSort(event, property);
    }
  };

  const revealColumns = useCallback(
    () =>
      currentProzess
        ? [
            BEARBEITET_COLUMN,
            ...Object.values(prozesse)
              .filter(prozess => prozess.workflowId !== currentProzess.workflowId!)
              .filter(prozess => prozess.shouldShowCreatedRecords)
              .sort((a, b) => a.recordsDisplayOrder! - b.recordsDisplayOrder!)
              .map(p => ({ workflowId: p.workflowId!, label: p.label! })),
          ]
        : [],
    [currentProzess, prozesse]
  );

  const [columns, setColumns] = useState<RecordColumn[]>(revealColumns());

  const renderValidationResultInfo = useCallback(() => {
    if (prozessEventsValidationResult?.generalMessages) {
      return (
        <div className={classes.flexWrapper}>
          {Object.entries(prozessEventsValidationResult.generalMessages).map((error, index) => (
            <p key={index}>{t(error)}</p>
          ))}
        </div>
      );
    }
    if (prozessEventsValidationResult?.rowValidationMessages) {
      return (
        <Table className={classes.validationOverviewTable}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Zeile")}</TableCell>
              <TableCell sx={{ width: "45%" }}>{t("Daten")}</TableCell>
              <TableCell sx={{ width: "45%" }}>{t("Fehler")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prozessEventsValidationResult.rowValidationMessages.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.rowNumber}</TableCell>
                <TableCell sx={{ width: "45%" }}>{t(`${row.rowData}`)}</TableCell>
                <TableCell sx={{ width: "45%" }}>{t(`${row.errorMessage}`)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
    return null;
  }, [classes.flexWrapper, classes.validationOverviewTable, prozessEventsValidationResult, t]);

  useEffect(() => {
    setColumns(revealColumns());
  }, [funktionId, revealColumns]);

  useEffect(() => {
    if (shouldOpenValidationOverviewDialog && validationErrors) {
      setProzessEventsValidationResult(validationErrors);
      setIsValidationOverviewDialogOpen(true);
    }
  }, [shouldOpenValidationOverviewDialog, validationErrors]);

  useEffect(() => {
    setEditedRecord(editedCapturedRecord);
  }, [editedCapturedRecord]);

  useEffect(() => {
    // Highlight successfully edited or created record for 2 sec
    if (isEditing) {
      if (shouldHighlightRecord && !!editedRecord) {
        setSelectedRecordsGroupId(editedRecord.recordsGroupId!);
      } else {
        const timer = setTimeout(() => {
          setSelectedRecordsGroupId("");
        }, 2000);
        return () => clearTimeout(timer);
      }
    } else {
      if (!selectedRecordsGroupId) {
        setSelectedRecordsGroupId(recordsGroupId);
      }
      if (!shouldHighlightRecord) {
        const timer = setTimeout(() => {
          setSelectedRecordsGroupId("");
        }, 2000);
        return () => clearTimeout(timer);
      }
    }
  }, [editedRecord, isEditing, recordsGroupId, selectedRecordsGroupId, shouldHighlightRecord]);

  if (!currentProzess) {
    return null;
  }

  const dateFormat = "DD.MM HH:mm";
  const columnCount = columns.length + 2;
  const columnCountWithEdit = columnCount + 3;

  const renderDivider = (recordSlice: ISyncedCapturedRecord[]) => {
    if (records.indexOf(recordSlice) > 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell
              className={classes.dividerContainer}
              colSpan={isDialogOpen ? columnCountWithEdit : columnCount}
            >
              <Divider className={classes.divider} />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return null;
  };

  const handleCancelIconClick = (validationErrors: IProzessEventsValidationResultDto | undefined) => {
    if (validationErrors?.generalMessages || validationErrors?.rowValidationMessages) {
      setProzessEventsValidationResult(validationErrors);
      setIsValidationOverviewDialogOpen(true);
    }
  };

  const renderIconForSyncedColumn = (record: ISyncedCapturedRecord) => {
    if (record.processingState && record.processingState === ProcessingState.Failed) {
      return (
        <CancelIcon
          color="error"
          className={classNames(classes.icon, classes.cancelIcon)}
          onClick={() => handleCancelIconClick(record.validationErrors)}
        />
      );
    }
    return (
      <CheckCircleIcon
        className={classNames(classes.icon, record.synced ? classes.syncedIcon : classes.unsyncedIcon)}
        data-cy={record.synced ? "record-synced" : "record-unsynced"}
      />
    );
  };

  const openEditingDialog = (record: any) => {
    setIsEditingDialogOpen(true);
    setEditedRecord(record);
  };

  const closeEditingDialog = () => {
    setIsEditingDialogOpen(false);
  };

  const openDeleteRecordDialog = (record: ISyncedCapturedRecord) => {
    setIsDeleteRecordDialogOpen(true);
    setRecordForDelete(record);
  };

  const closeDeleteRecordDialog = () => {
    setIsDeleteRecordDialogOpen(false);
  };

  const deleteRecordHandler = () => {
    deleteRecord!(recordForDelete!);
    closeDeleteRecordDialog();
  };

  const sendEmailHandler = (record: ISyncedCapturedRecord) => {
    resendData!({ recordsGroupId: record.recordsGroupId! });
  };

  const validationOverviewDialogHandler = () => {
    setProzessEventsValidationResult(undefined);
    setIsValidationOverviewDialogOpen(false);
    setShouldOpenValidationOverviewDialog!(false);
    setValidationErrors!(undefined);
  };

  const isRecordCanBeChanged = (record: ISyncedCapturedRecord) =>
    maxMinutesDeletable && canDeleteAndEditRecord(record.timestamp!, maxMinutesDeletable);

  const isEdited = (record: ISyncedCapturedRecord) =>
    isEditing && !!editedCapturedRecord && editedCapturedRecord.recordsGroupId === record.recordsGroupId;

  const isHighlighted = (record: ISyncedCapturedRecord) =>
    !!selectedRecordsGroupId && selectedRecordsGroupId === record.recordsGroupId;

  const revealCellColor = (record: ISyncedCapturedRecord) => {
    if (isHighlighted(record)) {
      return record.validationErrors ? classes.redCell : classes.greenCell;
    }
    return null;
  };

  return (
    <>
      <Table stickyHeader size="medium">
        <TableHead>
          <TableRow>
            {!shouldHideSyncedColumn && (
              <TableCell className={classes.iconCell}>
                <CloudUploadIcon className={classes.icon} />
              </TableCell>
            )}
            {shouldUseConsecutiveNr && <TableCell className={classes.tableCell}>Nr.</TableCell>}
            {columns.map(column => (
              <TableCell
                key={`last-record-th-${column.workflowId}`}
                align="center"
                className={classes.tableCell}
              >
                <TableSortLabel
                  active={!shouldDisableSorting && orderBy.workflowId === column.workflowId}
                  direction={orderBy.workflowId === column.workflowId ? order : "asc"}
                  onClick={createSortHandler(column)}
                  hideSortIcon={shouldDisableSorting}
                  classes={{ root: classes.sortLabel }}
                >
                  {column.label!}
                </TableSortLabel>
              </TableCell>
            ))}
            {shouldUseActionBar && (
              <TableCell align="center" className={classes.tableCell} data-cy="aktion-cell">
                {t("Aktion")}
              </TableCell>
            )}
            {isDialogOpen && maxMinutesDeletable ? (
              <Fragment>
                <TableCell align="center" className={classes.additionalCell} />
                <TableCell align="center" className={classes.additionalCell} />
              </Fragment>
            ) : null}
          </TableRow>
        </TableHead>
        {records.map((recordSlice: ISyncedCapturedRecord[]) => (
          <Fragment key={`recordSlice-${records.indexOf(recordSlice)}`}>
            {renderDivider(recordSlice)}
            <TableBody>
              {recordSlice.sort(getComparator(order, orderBy)).map((record, index) => (
                <TableRow
                  key={record.recordsGroupId}
                  hover={false}
                  data-cy="record-row"
                  className={isEdited(record) ? classes.edited : ""}
                >
                  {!shouldHideSyncedColumn && (
                    <TableCell className={classNames(classes.tableCell, revealCellColor(record))}>
                      {!isHighlighted(record) ? (
                        renderIconForSyncedColumn(record)
                      ) : (
                        <div style={{ minHeight: "43px" }} />
                      )}
                    </TableCell>
                  )}
                  {shouldUseConsecutiveNr && <TableCell className={classes.tableCell}>{index + 1}</TableCell>}
                  {columns.map(column =>
                    column.workflowId ? (
                      <TableCell
                        key={`last-record-tb-${column.workflowId}`}
                        align="center"
                        className={classNames(classes.tableCell, revealCellColor(record))}
                      >
                        {formatProzessData(record.data![column.workflowId])}
                      </TableCell>
                    ) : (
                      <TableCell
                        key={`last-record-tb-${column.workflowId}`}
                        scope="row"
                        align="center"
                        className={classNames(classes.tableCell, revealCellColor(record))}
                      >
                        {dayjs(record.timestamp).format(dateFormat)}
                      </TableCell>
                    )
                  )}
                  {shouldUseActionBar && (
                    <TableCell
                      align="center"
                      className={classNames(classes.tableCell, revealCellColor(record))}
                    >
                      <AppButton
                        dataCy="email-button"
                        className={classes.mailButton}
                        handler={() => sendEmailHandler(record)}
                        type={"button"}
                      >
                        <MailOutlineIcon className={classes.mailIcon} />
                      </AppButton>
                    </TableCell>
                  )}
                  {isDialogOpen && isRecordCanBeChanged(record) ? (
                    <Fragment>
                      <TableCell align="center" className={classes.additionalCell}>
                        <AppButton
                          dataCy="edit-button"
                          className={classes.editButton}
                          handler={() => openEditingDialog(record)}
                        >
                          <EditIcon />
                        </AppButton>
                      </TableCell>
                      <TableCell align="center" className={classes.additionalCell}>
                        <AppButton
                          dataCy="delete-button"
                          className={classes.deleteButton}
                          handler={() => openDeleteRecordDialog(record)}
                        >
                          <DeleteForeverIcon />
                        </AppButton>
                      </TableCell>
                    </Fragment>
                  ) : maxMinutesDeletable ? (
                    <Fragment>
                      <TableCell align="center" className={classes.additionalCell} />
                      <TableCell align="center" className={classes.additionalCell} />
                    </Fragment>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Fragment>
        ))}
      </Table>
      {isEditingDialogOpen && (
        <ConfirmationModalWindow
          closeModal={closeEditingDialog}
          open={isEditingDialogOpen}
          acceptHandler={() => activateEditMode!(editedRecord!)}
          acceptButtonTitle="COMMON.BEARBEITEN"
        >
          Möchten Sie diesen Datensatz bearbeiten?
        </ConfirmationModalWindow>
      )}
      {isDeleteRecordDialogOpen && (
        <ConfirmationModalWindow
          closeModal={closeDeleteRecordDialog}
          open={isDeleteRecordDialogOpen}
          acceptHandler={deleteRecordHandler}
          acceptButtonTitle="LÖSCHEN"
        >
          Möchten Sie diesen Datensatz unwiderruflich löschen?
        </ConfirmationModalWindow>
      )}
      {isValidationOverviewDialogOpen && (
        <InfoComponent
          open={isValidationOverviewDialogOpen}
          onClose={validationOverviewDialogHandler}
          title={t("COMMON.IMPORT_ERRORS")}
        >
          {renderValidationResultInfo()}
        </InfoComponent>
      )}
    </>
  );
};

export default RecordsTableComponent;
