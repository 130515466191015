import I18n from "i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getIsProzessEventsExist } from "../../store/prozess-events/prozess-events.selectors";

import { AppState } from "../../store/app.state";
import MenuItemComponent, { ConnectedDispatch, ConnectedState } from "./menu-item.component";
import { resetBluetoothData } from "../../store/bluetooth/bluetooth.actions";
import { showFailedRequest } from "../../store/notifications/notifications.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  isDataExist: getIsProzessEventsExist(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  resetBluetoothData: () => dispatch(resetBluetoothData()),
  showNetworkError: () => dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.NETWORK_ERROR"))),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemComponent);
