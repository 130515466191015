import { call, put, select, takeEvery } from "redux-saga/effects";
import BackendClient from "../../api/backend-client";
import { Dispatch } from "redux";
import logger from "../../logger";
import { Action } from "../action";
import {
  AuthenticationActionType,
  authUserFailure,
  authUserSuccess,
  setDeviceId,
  setLoggedInUser,
  setToken,
  setUsers,
} from "./authentication.actions";
import { showSuccessfulRequest, showFailedRequest } from "../notifications/notifications.actions";
import { getActiveUser, getDeviceId } from "./authentication.selectors";
import I18n from "i18next";
import { ITokenResponseDto, IUserDto } from "../../api/backend-api-v7";
import { history } from "../../browser-history";
import { setCurrentFunktionId } from "../funktionen/funktionen.actions";
import { disconnectBluetoothDevice } from "../bluetooth/bluetooth.actions";
import { LocalStorageItems } from "../../utils/local-storage.enum";

const logInfo = logger.info("authentication.saga");
const logError = logger.error("authentication.saga");
const networkErrorMessage = "Network Error";
const { TOKEN, REFRESH_TOKEN, LOGGED_IN_USER, USER_ID, DEVICE_ID } = LocalStorageItems;

let backendClient: BackendClient;

export function* authenticateUser(username: string, password: string, deviceId: string) {
  try {
    const response: ITokenResponseDto = yield call(
      [backendClient, "authenticate"],
      username,
      password,
      deviceId
    );
    const { token, refreshToken } = response;
    const user: IUserDto | undefined = yield select(getActiveUser(username));

    yield put(authUserSuccess(username, token!, user!.id!));

    yield call([localStorage, "setItem"], TOKEN, token!);
    yield call([localStorage, "setItem"], REFRESH_TOKEN, refreshToken!);
    yield call([localStorage, "setItem"], LOGGED_IN_USER, username);
    yield call([localStorage, "setItem"], USER_ID, user!.id!);

    yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_LOGIN")));

    logInfo("User successfully authenticated");
  } catch (e) {
    yield put(authUserFailure());
    yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_LOGIN")));
    logError("Could not authenticate user", e);
  }
}

export function* registerDevice(username: string, password: string) {
  try {
    const deviceId: string = yield call([backendClient, "registerDevice"], username, password);

    yield call([localStorage, "setItem"], DEVICE_ID, deviceId);

    yield put(setDeviceId(deviceId));
    yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_DEVICE_REGISTRATION")));
  } catch (e: any) {
    if (e.message === networkErrorMessage) {
      yield put(showFailedRequest(I18n.t("NOTIFICATIONS.OFFLINE_LOGIN")));
    } else {
      yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_DEVICE_REGISTRATION")));
    }
    logError("Could not register device", e);
  }
}

export function* createAuthentication(action: Action<{ username: string; password: string }>) {
  const { username, password } = action.payload;
  const deviceId: string | undefined = yield select(getDeviceId);

  if (deviceId) {
    yield call(authenticateUser, username, password, deviceId);
  } else {
    yield call(registerDevice, username, password);
  }
}

export function* logout() {
  yield call([localStorage, "removeItem"], TOKEN);
  yield call([localStorage, "removeItem"], USER_ID);
  yield put(setCurrentFunktionId(undefined));
  yield put(disconnectBluetoothDevice());
  history.push("/");
}

export function* unregisterDevice() {
  try {
    yield call([localStorage, "removeItem"], DEVICE_ID);
    history.push("/");
  } catch (e) {
    logError("Could not remove device id", e);
  }
}

export function* fetchUsers() {
  try {
    const deviceId: string | undefined = yield select(getDeviceId);

    if (deviceId) {
      const users: IUserDto[] = yield call([backendClient, "getUserList"], deviceId);
      yield put(setUsers(users));
    }
  } catch (e) {
    yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_TO_GET_USERS")));
    logError("Could not fetch users", e);
  }
}

export function* applyDeviceId() {
  const deviceId: string | undefined = yield call([localStorage, "getItem"], DEVICE_ID);
  if (deviceId) {
    yield put(setDeviceId(deviceId));
  }
}

export function* applyToken() {
  const token: string | undefined = yield call([localStorage, "getItem"], TOKEN);
  if (token) {
    yield put(setToken(token));
  }
}

export function* applyLoggedInUser() {
  const loggedInUser: string | undefined = yield call([localStorage, "getItem"], LOGGED_IN_USER);
  const userId: string | undefined = yield call([localStorage, "getItem"], USER_ID);

  if (loggedInUser && userId) {
    yield put(setLoggedInUser({ loggedInUser, userId }));
  }
}

export default function* authenticationSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield call(applyDeviceId);
  yield call(applyToken);
  yield call(applyLoggedInUser);

  yield takeEvery(AuthenticationActionType.AUTH_USER, createAuthentication);
  yield takeEvery(AuthenticationActionType.FETCH_USERS, fetchUsers);
  yield takeEvery(AuthenticationActionType.LOGOUT, logout);
  yield takeEvery(AuthenticationActionType.UNREGISTER_DEVICE, unregisterDevice);
}
