import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import useStyles from "./tile.styles";
import { checkInternetConnection } from "../../utils/network-status";

interface OwnProps {
  imgSrc?: string;
  title: string;
  path: string;
  color: string;
  isNetworkRequired: boolean;
  showNetworkError: () => void;
}

const TileComponent: FunctionComponent<OwnProps> = props => {
  const { imgSrc: imageSrc, title, path, color, isNetworkRequired, showNetworkError } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    if (isNetworkRequired) {
      const isOnline = checkInternetConnection();
      if (!isOnline) {
        showNetworkError();
        return;
      }
    }
    navigate(path);
  };

  return (
    <Card className={classes.card} elevation={0} onClick={handleClick}>
      <CardActionArea className={classes.cardActionArea}>
        {imageSrc && <CardMedia className={classes.media} image={imageSrc} title={title} />}
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom={true} variant="h6" align="center">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <div style={{ backgroundColor: `${color}`, width: "100%", height: "10px" }} />
    </Card>
  );
};

export default TileComponent;
