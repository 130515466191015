import { Action } from "../action";
import createReducer from "../createReducer";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { PlanFerkelActionType } from "./plan-ferkel.actions";

export interface PlanFerkelState {
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
}

export const initialState: PlanFerkelState = {
  loadStatus: loadInitial(),
  lastFetched: undefined,
};

export default createReducer(initialState, {
  [PlanFerkelActionType.LOAD_PLAN_FERKEL](state: PlanFerkelState): PlanFerkelState {
    return { ...state, loadStatus: loadPending() };
  },
  [PlanFerkelActionType.LOAD_PLAN_FERKEL_SUCCESS](state: PlanFerkelState): PlanFerkelState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [PlanFerkelActionType.SET_LAST_FETCHED_DATE](state: PlanFerkelState): PlanFerkelState {
    return {
      ...state,
      lastFetched: new Date(Date.now()),
    };
  },
  [PlanFerkelActionType.LOAD_PLAN_FERKEL_FAILURE](
    state: PlanFerkelState,
    action: Action<string>
  ): PlanFerkelState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
});
