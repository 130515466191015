import { Action } from "../action";
import createReducer from "../createReducer";
import { FunktionenActionType } from "./funktionen.actions";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { IFunktionDto, IProzessDto } from "../../api/backend-api-v7";

export interface FunktionenState {
  currentFunktionId: number | undefined;
  funktionen: { [funktionId: string]: IFunktionDto };
  prozesse: { [funktionId: string]: IProzessDto[] };
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
}

export const initialState: FunktionenState = {
  currentFunktionId: undefined,
  funktionen: {},
  prozesse: {},
  loadStatus: loadInitial(),
  lastFetched: undefined,
};

export default createReducer(initialState, {
  [FunktionenActionType.SET_CURRENT_FUNKTION_ID](
    state: FunktionenState,
    action: Action<number | undefined>
  ): FunktionenState {
    return { ...state, currentFunktionId: action.payload };
  },
  [FunktionenActionType.LOAD_FUNKTIONEN](state: FunktionenState, action: Action<null>): FunktionenState {
    return { ...state, loadStatus: loadPending() };
  },
  [FunktionenActionType.LOAD_FUNKTIONEN_SUCCESS](
    state: FunktionenState,
    action: Action<{
      funktionen: { [funktionId: number]: IFunktionDto };
    }>
  ): FunktionenState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      funktionen: action.payload.funktionen,
      lastFetched: new Date(Date.now()),
    };
  },
  [FunktionenActionType.LOAD_FUNKTIONEN_FAILURE](
    state: FunktionenState,
    action: Action<string>
  ): FunktionenState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      funktionen: {},
      lastFetched: new Date(Date.now()),
    };
  },
  [FunktionenActionType.SET_PROZESSE](
    state: FunktionenState,
    action: Action<{ [funktionId: string]: IProzessDto[] }>
  ): FunktionenState {
    return {
      ...state,
      prozesse: action.payload,
    };
  },
  [FunktionenActionType.UPDATE_FUNKTION](
    state: FunktionenState,
    action: Action<IFunktionDto>
  ): FunktionenState {
    const funktion = action.payload;
    return {
      ...state,
      funktionen: { ...state.funktionen, [funktion.id!]: funktion },
    };
  },
});
