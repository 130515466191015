import { FunctionComponent } from "react";
import { AppBar, Dialog, Toolbar, Typography } from "@mui/material";

import useStyles from "./info.styles";
import CloseButton from "../../components/common/close-button/close-button.component";
import { useTranslation } from "react-i18next";

export interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  children: JSX.Element | null;
}

const InfoComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { title, open, onClose, children } = props;
  const { t } = useTranslation();

  return (
    <Dialog fullScreen={true} open={open} onClose={onClose}>
      <div className={classes.flexWrapper}>
        <AppBar className={classes.appBar} position={"static"}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {t(title)}
            </Typography>
            <CloseButton onCloseHandler={onClose} />
          </Toolbar>
        </AppBar>
        <div className={classes.description}>{children}</div>
      </div>
    </Dialog>
  );
};

export default InfoComponent;
