import {
  ChangeEvent,
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useStyles from "./tierident-settings.styles";
import classnames from "classnames";
import { ILocalFerkelDto } from "../../../db/database";

export interface ConnectedState {
  ferkel: ILocalFerkelDto[];
}

interface Props extends ConnectedState {
  tierIdent: string;
  setTierIdent: Dispatch<SetStateAction<string>>;
}

const TierIdentSettingsComponent: FunctionComponent<Props> = props => {
  const { ferkel, tierIdent, setTierIdent } = props;
  const classes = useStyles();
  const [tierIdentData, setTierIdentData] = useState<string[]>([]);

  const extractTieridentData = useCallback(() => {
    if (ferkel) {
      const data = ferkel.reduce((total: string[], value: ILocalFerkelDto) => {
        if (value.tierIdent) {
          total.push(value.tierIdent);
        }
        return total;
      }, []);
      const sortedData = data.sort((a, b) => a.localeCompare(b));
      setTierIdentData(sortedData);
    }
  }, [ferkel]);

  useEffect(() => {
    extractTieridentData();
  }, [extractTieridentData]);

  const onInputChangeHandler = (event: ChangeEvent<{}>, manualValue: string) => {
    setTierIdent(manualValue);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id="tierident-settings"
        options={tierIdentData}
        fullWidth={true}
        freeSolo={true}
        inputValue={tierIdent}
        onInputChange={(event: ChangeEvent<{}>, newValue: string) => onInputChangeHandler(event, newValue)}
        classes={{
          endAdornment: classes.endAdornment,
          clearIndicator: classes.clearIndicator,
        }}
        renderInput={params => (
          <TextField
            {...params}
            className={classnames(classes.textField, tierIdent.length ? classes.validField : "")}
            variant="outlined"
            label="TierIdent"
            type="string"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
};

export default TierIdentSettingsComponent;
