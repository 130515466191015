import { connect } from "react-redux";

import LastRecordsComponent, {
  ConnectedDispatch,
  ConnectedState,
  LAST_RECORDS_AMOUNT,
  OwnProps,
} from "./last-records.component";
import { AppState } from "../../../../store/app.state";
import { Dispatch } from "redux";
import {
  getEditedCapturedRecord,
  getLastLocalCapturedRecordsByFunktionId,
} from "../../../../store/captured-records/captured-records.selectors";
import { fetchLocalCapturedRecords } from "../../../../store/captured-records/captured-records.actions";
import {
  isEditModeActivated,
  getShouldHighlightRecord,
  getShouldOpenValidationOverviewDialog,
} from "../../../../store/common/common.selectors";
import { getCurrentGroupId } from "../../../../store/prozess-events/prozess-events.selectors";
import { getDataImportValidationErrors } from "../../../../store/transitory-prozess-data/transitory-prozess-data.selectors";
import { setShouldOpenValidationOverviewDialog } from "../../../../store/common/common.actions";
import { setValidationErrors } from "../../../../store/transitory-prozess-data/transitory-prozess-data.actions";
import { IProzessEventsValidationResultDto } from "../../../../api/backend-api-v7";

const mapStateToProps = (state: AppState, props: OwnProps): ConnectedState => {
  const { funktionId } = props;
  return {
    recordsGroupId: getCurrentGroupId(state),
    lastRecords: getLastLocalCapturedRecordsByFunktionId(funktionId, LAST_RECORDS_AMOUNT)(state),
    isEditing: isEditModeActivated(state),
    shouldHighlightRecord: getShouldHighlightRecord(state),
    editedCapturedRecord: getEditedCapturedRecord(state),
    validationErrors: getDataImportValidationErrors(state),
    shouldOpenValidationOverviewDialog: getShouldOpenValidationOverviewDialog(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  fetchLastRecords: (funktionId: number, amount: number) => dispatch(fetchLocalCapturedRecords(funktionId)),
  setShouldOpenValidationOverviewDialog: (shouldOpen: boolean) =>
    dispatch(setShouldOpenValidationOverviewDialog(shouldOpen)),
  setValidationErrors: (validationErrors: IProzessEventsValidationResultDto | undefined) =>
    setValidationErrors(validationErrors),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastRecordsComponent);
