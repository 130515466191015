import { Card, CardContent } from "@mui/material";

import { FunctionComponent, useEffect } from "react";
import TileComponent from "../../components/tile/tile.component";
import RoutePaths from "../../routes/route-paths";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import useStyles from "./dashboard.styles";

import { useTranslation } from "react-i18next";
import iqImages from "../../images/iq-images";
import { IFunktionDto } from "../../api/backend-api-v7";

export interface ConnectedState {
  funktionen: { [funktionId: number]: IFunktionDto };
}

export interface ConnectedDispatch {
  setCurrentFunktionId: () => void;
  showNetworkError: () => void;
}

interface OwnProps extends ConnectedState, ConnectedDispatch {}

const DashboardComponent: FunctionComponent<OwnProps> = props => {
  const { funktionen, setCurrentFunktionId, showNetworkError } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentFunktionId();
  }, [setCurrentFunktionId]);

  return (
    <>
      <AppBarContainer />
      <div className={classes.root} data-cy="dashboard">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {funktionen &&
              Object.values(funktionen)
                .sort((a: IFunktionDto, b: IFunktionDto) => a.displayOrder! - b.displayOrder!)
                .map(funktion => (
                  <TileComponent
                    key={funktion.id}
                    title={funktion.name!}
                    path={`${RoutePaths.Funktionen}/${funktion.id}`}
                    color={funktion.color!}
                    isNetworkRequired={!!funktion.funktionConfiguration?.isOnlineOnly}
                    showNetworkError={showNetworkError}
                    // @ts-ignore
                    imgSrc={iqImages[`${funktion.icon}`]}
                  />
                ))}
            {!funktionen && (
              <p className={classes.center}>{t("COMPONENTS.DASHDOARD.NO_FUNCTION_AVAILABLE")}</p>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default DashboardComponent;
