import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      height: "24px",
      width: "24px",
    },
    iconButton: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    filterBlue: {
      filter: "invert(23%) sepia(88%) saturate(3489%) hue-rotate(570deg) brightness(97%) contrast(102%)",
    },
    filterGray: {
      filter: "invert(52%) sepia(7%) saturate(7%) hue-rotate(351deg) brightness(94%) contrast(89%)",
    },
  })
);

export default useStyles;
