import logger from "../../../logger";
import { SauSelectorState, SauSelectorAction, SauSelectorActionType } from "./sau-selector.types";

const logInfo = logger.info("sauSelector.reducer");

export const initialState: SauSelectorState = {
  value: undefined,
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const sauSelectorReducer = (state: SauSelectorState, action: SauSelectorAction): SauSelectorState => {
  const {
    type,
    payload: { value, isRequired, isValid, shouldKeepValue, isProzessEdited },
  } = action;

  switch (type) {
    case SauSelectorActionType.INIT: {
      logInfo("SauSelector INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case SauSelectorActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        value,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case SauSelectorActionType.RESET: {
      logInfo("SauSelector RESET");

      const data = shouldKeepValue ? state.value : undefined;
      return { ...initialState, value: data, isError: isError(!!data, isRequired) };
    }
    case SauSelectorActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
