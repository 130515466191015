import { AppState } from "../app.state";

export const isAllEventsSynced = (state: AppState) => state.synchronization.allProzessEventsSynced;

export const getIsAllEventsSaved = (state: AppState) => state.synchronization.allProzessEventsSaved;

export const getShouldSynchronizeApp = (state: AppState) => state.synchronization.shouldSynchronizeApp;

export const getForceToUpdate = (state: AppState) => state.synchronization.forceToUpdate;

export const getShouldRemoveObsoleteData = (state: AppState) =>
  state.synchronization.shouldRemoveObsoleteData;
