import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "../../store/app.state";
import { disconnectBluetoothDevice, requestBluetoothDevice } from "../../store/bluetooth/bluetooth.actions";
import {
  getBluetoothConnectionStatus,
  getConnectedBluetoothDevice,
} from "../../store/bluetooth/bluetooth.selectors";
import BluetoothConnectionComponent, {
  ConnectedDispatch,
  ConnectedState,
} from "./bluetooth-connection.component";

const mapStateToProps = (state: AppState): ConnectedState => ({
  bluetoothConnectionStatus: getBluetoothConnectionStatus(state),
  connectedBluetoothDevice: getConnectedBluetoothDevice(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  requestBluetoothDevice: () => dispatch(requestBluetoothDevice()),
  disconnectBluetoothDevice: () => dispatch(disconnectBluetoothDevice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BluetoothConnectionComponent);
