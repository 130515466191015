import TextFieldComponent from "./text-field.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./text-field.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { prozessDataChanged, ProzessInputData } from "../../../store/prozess-events/prozess-events.actions";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { AppState } from "../../../store/app.state";
import { getCurrentRecordId } from "../../../store/prozess-events/prozess-events.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextFieldComponent);
