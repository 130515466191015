import db from "../../db/database";
import { put, takeLeading, call } from "redux-saga/effects";

import { InitActionType } from "./init.actions";
import { loadUser } from "../user/user.actions";
import { loadFunktionen } from "../funktionen/funktionen.actions";
import { AuthenticationActionType, fetchUsers } from "../authentication/authentication.actions";
import { prozessEventsAreSynced, syncProzessEvents } from "../synchronization/synchronization.actions";
import { loadFerkel } from "../ferkel/ferkel.actions";
import { loadSauen } from "../sauen/sauen.actions";
import { loadBuchten } from "../buchten/buchten.actions";
import { resetWurfuebersichtTable } from "../wurfuebersicht/wurfuebersicht.actions";
import { createNewRecord } from "../prozess-events/prozess-events.actions";
import { loadPlanFerkel } from "../plan-ferkel/plan-ferkel.actions";

export function* loadData() {
  yield put(loadFerkel());
  yield put(loadSauen());
  yield put(loadBuchten());
  yield put(loadPlanFerkel());
  yield put(loadUser());
  yield put(fetchUsers());
  yield put(loadFunktionen());
  const prozessEventsCount: number = yield call(db.getCountOfUnsyncedProzessEvents);
  yield put(prozessEventsAreSynced(prozessEventsCount === 0));
}

export function* refreshData() {
  yield put(syncProzessEvents());
  yield put(createNewRecord());
  yield put(resetWurfuebersichtTable());

  yield put(loadFerkel());
  yield put(loadSauen());
  yield put(loadBuchten());
  yield put(loadPlanFerkel());
  yield put(loadFunktionen());

  yield put(loadUser());
  yield put(fetchUsers());
}

export default function* initSaga() {
  yield takeLeading(InitActionType.REFRESH, refreshData);
  yield takeLeading(AuthenticationActionType.AUTH_USER_SUCCESS, loadData);
  yield takeLeading(AuthenticationActionType.SET_TOKEN, loadData);
}
