import { ISyncedCapturedRecord } from "../../../../db/database";
import { IFunktionDto, IProzessDto, IProzessDataDto, ProzessType } from "../../../../api/backend-api-v7";
import { ILocalFerkelDto } from "../../../../db/database";
import { v1 as uuidv1 } from "uuid";
import { defineFerkelProperty } from "../../../../utils/ferkel.utils";

const GENETISCHESAU = "genetischeSauDescriptor";

export const mapFerkelDtosToRecords = (
  ferkel: ILocalFerkelDto[],
  prozesse: IProzessDto[],
  funktion: IFunktionDto
): ISyncedCapturedRecord[] => {
  const { shouldGenerateTierIdentOnTotgeburt } = funktion.funktionConfiguration!;

  const records = ferkel.map(item => {
    const { createdAtUtc, creationRecordId } = item;
    const data = prozesse.reduce((total: any, current: IProzessDto) => {
      const ferkelIdentificator = defineFerkelProperty(current.prozessType!);
      if (Object.keys(item).includes(ferkelIdentificator)) {
        let additionalData: IProzessDataDto | undefined;
        if (
          current.prozessType === ProzessType.TIER_IDENT &&
          item.totgeburt &&
          shouldGenerateTierIdentOnTotgeburt
        ) {
          // Hide backend auto generated value for ferkel with totgeburt reason.
          return { ...total, [current.workflowId!]: "-" };
        }

        if (ferkelIdentificator === GENETISCHESAU) {
          additionalData = current.data?.find(d => d.id === item[ferkelIdentificator].tierSysId);
        } else {
          additionalData = current.data?.find(d => d.id === item[ferkelIdentificator]);
        }

        return {
          ...total,
          [current.workflowId!]: additionalData ? additionalData.label : item[ferkelIdentificator],
        };
      }
      if (current.prozessType === ProzessType.GEWICHT) {
        // There is no geburtsgewicht prozess type.
        return { ...total, [current.workflowId!]: item.geburtsgewicht?.toString() };
      }
      return total;
    }, {});

    return {
      data,
      funktionId: funktion.id,
      recordId: creationRecordId,
      recordsGroupId: uuidv1(),
      synced: 1,
      timestamp: createdAtUtc,
      validationErrors: undefined,
    };
  });

  return records;
};
