import { connect } from "react-redux";
import { Dispatch } from "redux";

import SettingsComponent from "./settings.component";
import { ConnectedDispatch } from "./settings.types";
import { updateProzessConfiguration } from "../../../store/funktionen/funktionen.actions";
import { IWorkflowConfigurationDto } from "../../../api/backend-api-v7";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  updateProzessConfiguration: (settings: IWorkflowConfigurationDto[]) =>
    dispatch(updateProzessConfiguration(settings)),
});

export default connect(null, mapDispatchToProps)(SettingsComponent);
