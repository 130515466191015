import { CheckboxAction, CheckboxActionType, CheckboxState } from "./checkbox.types";

import logger from "../../../logger";

const logInfo = logger.info("checkbox.reducer");

export const initialState: CheckboxState = {
  value: "",
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const checkboxReducer = (state: CheckboxState, action: CheckboxAction): CheckboxState => {
  const {
    type,
    payload: { value, isRequired, shouldKeepValue, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case CheckboxActionType.INIT: {
      logInfo("CHECKBOX INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case CheckboxActionType.PROZESS_DATA_CHANGED: {
      if (Array.isArray(value) && !value.length) {
        return {
          ...state,
          value: "[]",
          isError: isError(!!isValid, isRequired),
          isValid: !!isValid,
        };
      }

      return {
        ...state,
        value,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case CheckboxActionType.RESET: {
      logInfo("CHECKBOX RESET");
      const value = shouldKeepValue ? state.value : "";
      return { ...initialState, value, isError: isError(!!isValid, isRequired) };
    }
    case CheckboxActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
