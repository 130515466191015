import BluetoothSimulatorSettingsComponent from "./bluetooth-simulator-settings.component";
import { ConnectedDispatch, ConnectedState } from "./bluetooth-simulator-settings.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  setSimulationData,
  SimulationData,
  finishSimulation,
} from "../../store/bluetooth/bluetooth-data-simulation.actions";
import { AppState } from "../../store/app.state";
import { getChannel } from "../../store/bluetooth/bluetooth-data-simulation.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  gewichtChannel: getChannel(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setSimulationData: (data: SimulationData) => dispatch(setSimulationData(data)),
  finishSimulation: () => dispatch(finishSimulation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BluetoothSimulatorSettingsComponent);
