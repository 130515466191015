import { FunctionComponent } from "react";
import CloseButton from "../../components/common/close-button/close-button.component";
import useStyles from "./privacy-policy.styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {}

const PrivacyPolicyComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}> {t("COMPONENTS.PRIVACY_POLICY.TITLE")}</Typography>
        <CloseButton onCloseHandler={() => history.back()} />
      </div>
      <div className={classes.textWrapper}>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_1")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_2")}</Typography>
        <div className={classes.description}>
          <ul>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_3")} www.iq-agrar.de</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_4")} portal.iq-agrar.de</Typography>
            </li>
            <li>
              <Typography>
                {t("COMPONENTS.PRIVACY_POLICY.TEXT_5")} IQ-Agrar MOBIL: Play Store (Android), Apple App Store
                (iOS)
              </Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_6")} PIG-MONITOR: app.pigmonitor.de</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_7")} PIG-CHECK: app.pig-check.de</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_8")}</Typography>
            </li>
          </ul>
        </div>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_9")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_10")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_11")}</Typography>
        <Typography>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_12")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_13")}
        </Typography>
        <div>
          <ul>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_14")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_15")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_16")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_17")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_18")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_19")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_20")}</Typography>
            </li>
          </ul>
        </div>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_21")}</Typography>
        <div>
          <ul>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_22")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_23")}</Typography>
            </li>
          </ul>
          <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_24")}</Typography>
        </div>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_25")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_26")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_27")}</Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_28")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_29")}
        </Typography>
        <div>
          <ul>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_30")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_31")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_32")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.PRIVACY_POLICY.TEXT_33")}</Typography>
            </li>
          </ul>
        </div>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_34")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_35")}</Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_36")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_37")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_38")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_39")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_40")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_41")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_42")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_43")}
        </Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_44")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_45")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_65")}.</Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_64")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_46")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_47")}
        </Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_48")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_49")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_50")}</Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_51")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_52")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_53")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_54")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_55")}
        </Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_56")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_57")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.PRIVACY_POLICY.TEXT_58")}</Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_59")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_60")}
        </Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_61")}
          <br />
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_62")}
        </Typography>
        <Typography className={classes.description}>
          IQ-Agrar Service GmbH, Iburger Straße 225, 49082 Osnabrück,
          <br />
          Tel.: +49 (0) 541 / 600288 80,
          <br />
          Fax: +49 (0) 541 / 600 288 90,
          <br />
          E-Mail: info@iq-agrar.de
        </Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.PRIVACY_POLICY.TEXT_63")} IQ-Agrar Service GmbH, Stephanie Bering, Iburger Straße
          225, 49082 Osnabrück,
          <br />
          Tel.: +49 (0) 541 / 600 288 80,
          <br />
          Fax: +49 (0) 541 / 600 288 90,
          <br />
          E-Mail: info@iq-agrar.de
        </Typography>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
