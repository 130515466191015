import { connect } from "react-redux";
import PrivateRouteComponent, { ConnectedState } from "./private-route.component";
import { AppState } from "../store/app.state";
import { getIsLoggedIn } from "../store/authentication/authentication.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps, null)(PrivateRouteComponent);
