import logger from "../../../logger";
import { GewichtAction, GewichtActionType, GewichtState } from "./gewicht.types";

const logInfo = logger.info("gewicht.reducer");

export const initialState: GewichtState = {
  gewicht: "",
  metaData: null,
  isError: false,
  isValid: false,
  isOptimal: false,
  isStable: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const gewichtReducer = (state: GewichtState, action: GewichtAction): GewichtState => {
  const {
    type,
    payload: { value, metaData, isRequired, isValid, isOptimal, isStable, isProzessEdited },
  } = action;

  switch (type) {
    case GewichtActionType.INIT: {
      logInfo("GEWICHT INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case GewichtActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        gewicht: value ? value : "",
        metaData: metaData ?? null,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
        isOptimal: !!isOptimal,
        isStable: !!isStable,
      };
    }
    case GewichtActionType.RESET: {
      logInfo("GEWICHT RESET");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case GewichtActionType.PROZESS_DATA_EDITED: {
      return { ...initialState, isProzessEdited };
    }
  }

  return state;
};
