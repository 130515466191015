import TierIdentSettingsComponent, { ConnectedState } from "./tierident-settings.component";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  ferkel: getFilteredFerkel(state),
});

export default connect(mapStateToProps)(TierIdentSettingsComponent);
