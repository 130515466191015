import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { useCallback, useReducer } from "react";
import { gewichtReducer, initialState } from "./gewicht.reducer";
import { GewichtActionType, GewichtConfiguration } from "./gewicht.types";
import { IProzessDto } from "../../../api/backend-api-v7";
import { verifyIfGewichtIsOptimal, verifyIfGewichtIsValid } from "./gewicht.utils";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";

export const useGewichtState = (
  gewichtConfiguration: GewichtConfiguration,
  restartStableGewicht: (gewichtConfiguration: GewichtConfiguration) => void,
  manualInput: boolean,
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  shouldCalculateDifference: boolean | undefined,
  shouldIgnoreEventType: boolean
) => {
  const [state, dispatch] = useReducer(gewichtReducer, initialState);
  const { isRequired } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: GewichtActionType.INIT,
        payload: {
          value: undefined,
          isRequired,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired]
  );

  const revealIsValueValid = useCallback(
    (value: string | undefined) =>
      // Skip validation for GEWICHT_DIFFERENTIAL control type.
      shouldCalculateDifference ? true : value ? verifyIfGewichtIsValid(+value, gewichtConfiguration) : false,
    [gewichtConfiguration, shouldCalculateDifference]
  );

  const onProzessDataChanged = useCallback(
    (value: string | undefined, isStable: boolean, metaData?: BluetoothDataFormat | null) => {
      const isValid = revealIsValueValid(value);
      const isOptimal = value ? verifyIfGewichtIsOptimal(+value, gewichtConfiguration) : false;

      dispatch({
        type: GewichtActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          metaData,
          isRequired,
          isValid,
          isOptimal,
          status: ProzessEventOperationTypes.CREATE,
          isStable,
        },
      });
    },
    [gewichtConfiguration, isRequired, revealIsValueValid]
  );

  const reset = useCallback(() => {
    dispatch({
      type: GewichtActionType.RESET,
      payload: { value: undefined, isRequired, status: ProzessEventOperationTypes.DELETE },
    });

    if (!manualInput) {
      restartStableGewicht(gewichtConfiguration);
    }
  }, [gewichtConfiguration, isRequired, manualInput, restartStableGewicht]);

  const revealEventType = useCallback(
    (metaData?: BluetoothDataFormat | null) =>
      isEditing && metaData && !shouldIgnoreEventType
        ? prozess.changeEventType
        : isEditing && !metaData && !shouldIgnoreEventType
        ? prozess.changeManualEventType
        : !isEditing && metaData
        ? prozess.eventType
        : prozess.manualEventType,
    [
      isEditing,
      prozess.changeEventType,
      prozess.changeManualEventType,
      prozess.eventType,
      prozess.manualEventType,
      shouldIgnoreEventType,
    ]
  );

  const save = useCallback(
    (gewicht: string | undefined, metaData?: BluetoothDataFormat | null) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: revealEventType(metaData),
        eventCategory: prozess.eventCategory,
        data: gewicht,
        metaData,
        additionalData: undefined,
        operation: state.isValid ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!gewicht : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.eventCategory,
      prozess.isRequired,
      prozess.prozessType,
      revealEventType,
      state.isValid,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: GewichtActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          isRequired,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired]
  );

  return { state, init, reset, onProzessDataChanged, save, shouldEditProzessData };
};
