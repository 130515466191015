import { UserActionType } from "./user.actions";
import createReducer from "../createReducer";
import { Action } from "../action";
import { loadSuccess, loadFailed, loadPending, LoadingStatus, loadInitial } from "../loading-status";
import { UserDto, UserSettingsDto } from "../../api/backend-api-v7";

export interface UserState {
  user: UserDto | undefined;
  settings: UserSettingsDto;
  loadStatus: LoadingStatus;
  updateStatus: LoadingStatus;
}

export const initialState: UserState = {
  user: undefined,
  settings: new UserSettingsDto(),
  loadStatus: loadInitial(),
  updateStatus: loadInitial(),
};

export default createReducer(initialState, {
  [UserActionType.LOAD_USER](state: UserState, action: Action<null>) {
    return { ...state, loadStatus: loadPending() };
  },
  [UserActionType.LOAD_USER_SUCCESS](state: UserState, action: Action<UserDto>) {
    return { ...state, loadStatus: loadSuccess(), user: action.payload, settings: action.payload.settings };
  },
  [UserActionType.LOAD_USER_FAILURE](state: UserState, action: Action<string>) {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      settings: undefined,
    };
  },
  [UserActionType.UPDATE_USER_SETTINGS](state: UserState, action: Action<Partial<UserSettingsDto>>) {
    return {
      ...state,
      settings: { ...state.settings, ...action.payload },
      updateStatus: loadPending(),
    };
  },
  [UserActionType.UPDATE_USER_SETTINGS_SUCCESS](state: UserState) {
    return { ...state, updateStatus: loadSuccess() };
  },
  [UserActionType.UPDATE_USER_SETTINGS_FAILURE](state: UserState, action: Action<string>) {
    return { ...state, updateStatus: loadFailed(action.payload) };
  },
});
