import logger from "../../logger";
import BackendClient from "../../api/backend-client";
import db from "../../db/database";
import I18n from "i18next";
import { Dispatch } from "redux";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { call, select, takeLeading, put } from "redux-saga/effects";
import { ReportProblem, ReportProblemActionType } from "./problem-report.actions";
import { Action } from "../action";
import { getDeviceId, getLoggedInUser } from "../authentication/authentication.selectors";
import { getAppVersion } from "../prozess-events/prozess-events.selectors";
import { v1 as uuidv1 } from "uuid";

import * as Sentry from "@sentry/browser";
import { UserProblemReport } from "../../utils/problem-report-exception";
import { IProzessEventDto } from "../../api/backend-api-v7";
import { prozessEventsAreSynced, removeObsoleteData } from "../synchronization/synchronization.actions";
import { getCurrentFunktionId } from "../funktionen/funktionen.selectors";
import { fetchLocalCapturedRecords } from "../captured-records/captured-records.actions";

const logError = logger.error("report-problem.saga");
let backendClient: BackendClient;

export function* reportProblem(action: Action<ReportProblem>) {
  try {
    const { message, shouldRemoveData } = action.payload;
    const deviceId: string = yield select(getDeviceId);
    const appVersion: string = yield select(getAppVersion);
    const userName: string = yield select(getLoggedInUser);
    const id = uuidv1();

    const database: Blob = yield call([db, "exportDB"]);
    const exportedData: string = yield call([database, "text"]);

    yield call([backendClient, "reportProblem"], {
      reportMessage: message,
      deviceId,
      appVersion,
      userName,
      id,
      exportedData,
    });

    // https://docs.sentry.io/platforms/javascript/enriching-events/scopes/
    Sentry.withScope(function (scope) {
      scope.clear();
      scope.setLevel("warning");
      scope.setTransactionName(id);
      scope.clearBreadcrumbs();
      scope.setExtra("Additional information", {
        user: userName,
        reportMessage: message,
        deviceId,
        appVersion,
        reportId: id,
      });
      scope.setTags({ report: "problem-report" });

      Sentry.captureException(new UserProblemReport(message));
    });

    if (!shouldRemoveData) {
      yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_REPORT_PROBLEM")));
    } else {
      yield call(removeForbiddenData);
    }
  } catch (e: any) {
    yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_TO_REPORT_PROBLEM")));
    logError("Could not get db data to report problem", e.message);
  }
}

export function* removeForbiddenData() {
  try {
    const funktionId: number | undefined = yield select(getCurrentFunktionId);
    const prozessEvents: IProzessEventDto[] = yield call([db, "getUnsyncedProzessEvents"]);

    const recordsGroupIdsToDelete: string[] = prozessEvents.map(pe => pe.recordsGroupId!);
    const uniqIds = [...new Set(recordsGroupIdsToDelete)];

    yield call([db, "clearData"], uniqIds);

    const prozessEventsCount: number = yield call(db.getCountOfUnsyncedProzessEvents);
    yield put(prozessEventsAreSynced(prozessEventsCount === 0));
    yield put(removeObsoleteData(false));

    // Update last records if funktion is active.
    if (funktionId) {
      yield put(fetchLocalCapturedRecords(funktionId));
    }
  } catch (e) {
    logError("Could not remove forbidden data:", e);
  }
}

export default function* reportProblemSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ReportProblemActionType.REPORT_PROBLEM, reportProblem);
}
