import { IProzessDto } from "../../../api/backend-api-v7";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
  funktionId: number;
  manualInput: boolean;
}

export interface ConnectedState {
  prevRecordId: string;
  givenData: ProzessInputData | undefined;
  isManuallyEmpty: boolean | undefined;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | string[] | undefined;
  isEditing: boolean;
  listeningProzess: IProzessDto | undefined;
  stableGewicht: BluetoothDataFormat | undefined;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  givenDataApplied: (workflowId: number) => void;
}

export interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface CheckboxState {
  value: string | number[];
  isError: boolean;
  isValid: boolean;
  isProzessEdited?: boolean;
}

export interface CheckboxAction {
  type: CheckboxActionType;
  payload: {
    value: string | number[];
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
    isProzessEdited?: boolean;
  };
}

export enum CheckboxActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}
