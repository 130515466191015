import createReducer from "../createReducer";
import { Action } from "../action";
import { I18NActionType } from "./i18n.actions";
import { DEFAULT_LANGUAGE } from "../../constants";

export interface I18NState {
  chosenLanguage: string;
  initialized: boolean;
}

export const initialState: I18NState = {
  chosenLanguage: DEFAULT_LANGUAGE,
  initialized: false,
};

export default createReducer(initialState, {
  [I18NActionType.INIT_TRANSLATIONS_SUCCESS](state: I18NState): I18NState {
    return { ...state, initialized: true };
  },
  [I18NActionType.CHANGE_LANGUAGE](state: I18NState, action: Action<string>): I18NState {
    return { ...state, chosenLanguage: action.payload };
  },
});
