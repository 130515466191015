import { ILocalFerkelDto, ISyncedCapturedRecord } from "../../db/database";
import { WurfuebersichtFerkelDto } from "./wurfuebersicht.actions";
import { IProzessDto, ProzessType } from "../../api/backend-api-v7";
import { ProzessEventsRecord } from "../prozess-events/prozess-events.reducer";
import { v1 as uuid } from "uuid";
import { EMPTY_DATA } from "../../components/prozesse/tierident/tierident.component";

const geschlechtDto: any = {
  mars: "Eber",
  venus: "Sau",
  genderless: "Kastrat",
};

export interface WurfuebersichtProzesseId {
  geschlecht: number | undefined;
  totgeburt: number | undefined;
}

export interface WurfuebersichtFerkelData {
  geschlecht: string | undefined;
  totgeburt: boolean;
}

export const extractGeschlechtAndVerlustData = (ferkel: ILocalFerkelDto[]): WurfuebersichtFerkelDto[] =>
  ferkel.map(item => ({ geschlecht: item.geschlecht!, totgeburt: !!item.totgeburt, id: uuid() }));

export const separateLebendeAndTotgeburtFerkel = (ferkel: ILocalFerkelDto[]) => {
  const lebende: ILocalFerkelDto[] = [];
  const totgeburt: ILocalFerkelDto[] = [];
  if (ferkel) {
    ferkel.forEach(item => (item.totgeburt ? totgeburt.push(item) : lebende.push(item)));
    const lebendeFerkel = extractGeschlechtAndVerlustData(lebende);
    const totgeburtFerkel = extractGeschlechtAndVerlustData(totgeburt);
    return { lebendeFerkel, totgeburtFerkel };
  }
};

export const recalculateWurfFerkel = (ferkel: WurfuebersichtFerkelDto[]) => {
  const lebendeFerkel: WurfuebersichtFerkelDto[] = [];
  const totgeburtFerkel: WurfuebersichtFerkelDto[] = [];

  if (ferkel) {
    ferkel.forEach(item => (item.totgeburt ? totgeburtFerkel.push(item) : lebendeFerkel.push(item)));
    return { lebendeFerkel, totgeburtFerkel };
  }
};

export const createNewFerkel = (data: WurfuebersichtFerkelData) => ({
  id: uuid(),
  geschlecht: data.geschlecht!,
  totgeburt: data.totgeburt,
});

export const findGeschlechtAndTotgeburtWorkflowId = (prozesse: IProzessDto[]) => {
  const geschlechtProzess = prozesse.find(item => item.prozessType === ProzessType.GESCHLECHT);
  const totgeburtProzess = prozesse.find(item => item.prozessType === ProzessType.TOTGEBURT);

  return {
    geschlecht: geschlechtProzess ? geschlechtProzess.workflowId : undefined,
    totgeburt: totgeburtProzess ? totgeburtProzess.workflowId : undefined,
  };
};

export const extractBuchtsauDataFromRecord = (prozesse: IProzessDto[], record: ISyncedCapturedRecord) => {
  const buchtsauProzess = prozesse.find(item => item.prozessType === ProzessType.BUCHTSAU);

  const buchtsauRecordData = record.data![buchtsauProzess!.workflowId!];
  if (buchtsauRecordData) {
    return buchtsauProzess?.data?.find(item => item.label === buchtsauRecordData);
  }
};

export const extractGeschlechtAndTotgeburtDataFromProzessEvents = (
  prozessEvents: ProzessEventsRecord,
  ids: WurfuebersichtProzesseId
) => ({
  geschlecht: prozessEvents[ids.geschlecht!] && prozessEvents[ids.geschlecht!].data,
  totgeburt:
    prozessEvents[ids.totgeburt!] && prozessEvents[ids.totgeburt!].data !== EMPTY_DATA
      ? !!prozessEvents[ids.totgeburt!].data
      : false,
});

export const findGeschlechtAndTotgeburtValue = (
  record: ISyncedCapturedRecord | undefined,
  ids: WurfuebersichtProzesseId
) => {
  if (record && record.data) {
    const { data } = record;
    return {
      geschlecht: data[ids.geschlecht!] ? geschlechtDto[data[ids.geschlecht!]] : undefined,
      totgeburt: data[ids.totgeburt!] && data[ids.totgeburt!] !== EMPTY_DATA ? true : false,
    };
  }
};

export const findEditedFerkel = (
  ferkel: WurfuebersichtFerkelDto[] | undefined,
  editedWurfuebersichtFerkelData: { geschlecht: any; totgeburt: boolean }
) => {
  const { geschlecht, totgeburt } = editedWurfuebersichtFerkelData;
  return ferkel?.find(item => item.geschlecht === geschlecht && item.totgeburt === totgeburt);
};

export const createUpdatedFerkel = (
  editedFerkel: WurfuebersichtFerkelDto | undefined,
  wurfuebersichtFerkelData: WurfuebersichtFerkelData
) => ({
  id: editedFerkel!.id,
  totgeburt: wurfuebersichtFerkelData.totgeburt
    ? wurfuebersichtFerkelData.totgeburt
    : editedFerkel!.totgeburt,
  geschlecht: wurfuebersichtFerkelData.geschlecht
    ? wurfuebersichtFerkelData.geschlecht
    : editedFerkel!.geschlecht,
});
