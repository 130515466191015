import { useCallback, useReducer } from "react";
import { hiddenReducer, initialState } from "./hidden.reducer";
import { HiddenActionType } from "./hidden.types";

import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { EMPTY_DATA } from "../tierident/tierident.component";

export const useHiddenState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean
) => {
  const [state, dispatch] = useReducer(hiddenReducer, initialState);
  const { isRequired, shouldKeepValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: HiddenActionType.INIT,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: any | undefined) => {
      dispatch({
        type: HiddenActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid: true,
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const save = useCallback(
    (value: any | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: value,
        label: undefined,
        metaData: null,
        additionalData: undefined,
        operation:
          !!value && value !== EMPTY_DATA
            ? ProzessEventOperationTypes.CREATE
            : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!value : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      isEditing,
      onChanged,
      prozess.changeEventType,
      prozess.eventCategory,
      prozess.eventType,
      prozess.isRequired,
      prozess.prozessType,
      prozess.workflowId,
    ]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: HiddenActionType.RESET,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, onProzessDataChanged, reset, save };
};
