import { GewichtConfiguration } from "../prozesse/gewicht/gewicht.types";
import { TRANSPONDER_VALID_LENGTH } from "../prozesse/transponder/transponder.component";

export const isTransponderValid = (transponder: string) =>
  transponder.toString().trim().length === TRANSPONDER_VALID_LENGTH && Number(transponder) > 0;

export const isGewichtValid = (gewicht: string) => {
  if (gewicht.trim().includes("-")) {
    const rangeValues = gewicht.trim().split("-");
    return (
      rangeValues.length === 2 &&
      Number(rangeValues[0]) <= Number(rangeValues[1]) &&
      rangeValues.every(value => Number(value) >= 0)
    );
  }
  return false;
};

const generateValueFromRange = (min: number, max: number) =>
  +(Math.random() * (Math.floor(max - 0.5) - Math.ceil(min) + 1) + Math.ceil(min)).toFixed(2);

export const createRandomGewichtRange = (gewichtConfiguration: GewichtConfiguration) => {
  const { validMinValue, validMaxValue } = gewichtConfiguration;
  let min = validMaxValue;
  let max = validMinValue;

  if (min && max) {
    do {
      min = generateValueFromRange(validMinValue!, validMaxValue!);
      max = generateValueFromRange(validMinValue!, validMaxValue!);
    } while (min > max);

    return `${min} - ${max}`;
  }

  return undefined;
};

export const createStableGewichtRange = (gewichtConfiguration: GewichtConfiguration) => {
  const { validMinValue, validMaxValue } = gewichtConfiguration;

  const stableValue = generateValueFromRange(validMinValue!, validMaxValue!);

  return `${stableValue} - ${stableValue}`;
};
