import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "space-around",
  },
  saveButton: {
    display: "flex",
    fontSize: "18pt",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(23, 108, 147)",
    },
  },
  cancelButton: {
    display: "flex",
    fontSize: "18pt",
    borderRadius: "4px",
    backgroundColor: "rgb(213,213,213)",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(166,166,166)",
    },
  },
  title: {
    display: "flex",
    fontSize: "18pt",
    color: "rgb(134,134,134)",
    justifyContent: "center",
    fontWeight: "bold",
  },
}));

export default useStyles;
