import { ProzessType } from "../../api/backend-api-v7";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { ILocalBuchtDto } from "../../db/database";

// Bucht handlers.
import { fuetterungsdatumProzessEventHandler } from "./handlers/fuetterungsdatum.handler";
import { futterGewichtProzessEventHandler } from "./handlers/futter-gewicht.handler";
import { kommentareProzessEventHandler } from "./handlers/kommentare.handler";
import { funktionHistoryHandler } from "./handlers/funktion-history.handler";

type Handler = (
  bucht: ILocalBuchtDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (bucht: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => void | ILocalBuchtDto;

interface BuchtHandlers {
  funktionHistoryHandler: Handler;
  fuetterungsdatumProzessEventHandler: Handler;
  futterGewichtProzessEventHandler: Handler;
  kommentareProzessEventHandler: Handler;
}

const buchtHandlers: BuchtHandlers = {
  funktionHistoryHandler,
  fuetterungsdatumProzessEventHandler,
  futterGewichtProzessEventHandler,
  kommentareProzessEventHandler,
};

class BuchtService {
  private handlers: ((
    sau: ILocalBuchtDto,
    prozessEvents: IProzessEventsWithAdditionalData[]
  ) => ILocalBuchtDto)[];

  constructor(handlers: BuchtHandlers) {
    this.handlers = Object.values(handlers).map(
      (handler, index) => (sau: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) =>
        handler(sau, prozessEvents, this.handlers[index + 1])
    );
  }

  modifyBucht(sau: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) {
    return this.handlers[0](sau, prozessEvents);
  }

  modifyBuchtEditMode(sau: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) {
    return this.handlers[1](sau, prozessEvents);
  }

  findBuchtIdentificator(prozessEvents: IProzessEventsWithAdditionalData[]) {
    const prozessEventToIdentify = prozessEvents.find(pe => pe.prozessType === ProzessType.BUCHT);
    if (prozessEventToIdentify) {
      return +prozessEventToIdentify.data[0];
    }
    return undefined;
  }
}

export default new BuchtService(buchtHandlers);
