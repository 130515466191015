import { BluetoothDataFormat } from "./bluetooth-data-format";
import { BluetoothDataTypes } from "./bluetooth-data-types";
import { BluetoothDataError } from "./bluetooth-data.error";

const parseBluetoothData = (data: string): BluetoothDataFormat => {
  const splitedData = data.split("|");

  if (splitedData.length !== 5) {
    throw new BluetoothDataError("Das Format der empfangenen Bluetoothdaten ist ungültig", data);
  }

  const type = +splitedData[1];
  if (!(type in BluetoothDataTypes)) {
    throw new BluetoothDataError("Unbekannter Gerätetyp", data);
  }

  return {
    version: splitedData[0],
    type,
    value: splitedData[2].trim(),
    deviceId: splitedData[3],
    deviceSoftwareId: splitedData[4],
    received: new Date(Date.now()),
  };
};
export default parseBluetoothData;
