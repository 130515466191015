class ExtendableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "User's problem report";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class UserProblemReport extends ExtendableError {}
