import {
  FunctionComponent,
  ChangeEvent,
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
  useEffect,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useStyles from "./transponder-settings.styles";
import classnames from "classnames";
import { TRANSPONDER_VALID_LENGTH } from "../../prozesse/transponder/transponder.component";
import { ILocalFerkelDto } from "../../../db/database";

export interface ConnectedState {
  ferkel: ILocalFerkelDto[];
}

interface Props extends ConnectedState {
  transponder: string;
  setTransponder: Dispatch<SetStateAction<string>>;
  shouldValidate: boolean;
}

const TransponderSettingsComponent: FunctionComponent<Props> = props => {
  const { transponder, setTransponder, shouldValidate, ferkel } = props;
  const classes = useStyles();
  const [isError, setIsError] = useState<boolean>(false);
  const [transponderData, setTransponderData] = useState<string[]>([]);

  const extractTransponderData = useCallback(() => {
    if (ferkel) {
      const data = ferkel.reduce((total: string[], value: ILocalFerkelDto) => {
        if (value.transponder) {
          total.push(value.transponder.toString());
        }
        return total;
      }, []);
      const sortedData = data.sort((a, b) => a.localeCompare(b));
      setTransponderData(sortedData);
    }
  }, [ferkel]);

  useEffect(() => {
    extractTransponderData();
  }, [extractTransponderData]);

  const onInputChangeHandler = (event: ChangeEvent<{}>, manualValue: string) => {
    setTransponder(manualValue);

    if (manualValue.length === TRANSPONDER_VALID_LENGTH) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id="transponder-settings"
        options={transponderData}
        fullWidth={true}
        freeSolo={true}
        inputValue={transponder}
        onInputChange={(event: ChangeEvent<{}>, newValue: string) => onInputChangeHandler(event, newValue)}
        classes={{
          endAdornment: classes.endAdornment,
          clearIndicator: classes.clearIndicator,
        }}
        renderInput={params => (
          <TextField
            {...params}
            className={classnames(
              classes.textField,
              transponder.length === TRANSPONDER_VALID_LENGTH ? classes.validField : ""
            )}
            variant="outlined"
            label="Transponder"
            type="string"
            InputLabelProps={{ shrink: true }}
            error={shouldValidate && isError}
          />
        )}
      />
    </div>
  );
};

export default TransponderSettingsComponent;
