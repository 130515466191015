import { FunctionComponent, useEffect, useState, useCallback } from "react";
import ChangeGewichtModeDialogContainer from "./change-gewicht-mode.dialog.container";
import useStyles from "./gewicht-mode.styles";
import iqImages from "../../images/iq-images";
import classNames from "classnames";
import { BluetoothConnectionStatus } from "../../store/bluetooth/bluetooth.reducer";
import { IconButton } from "@mui/material";

export interface ConnectedState {
  bluetoothConnectionStatus: BluetoothConnectionStatus;
  shouldBeInactive: boolean;
}

interface Props extends ConnectedState {}

const GewichtModeComponent: FunctionComponent<Props> = props => {
  const { bluetoothConnectionStatus, shouldBeInactive } = props;
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (bluetoothConnectionStatus === BluetoothConnectionStatus.CONNECTED) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [bluetoothConnectionStatus]);

  const evaluateClassName = useCallback(
    (filterBlue: string, filterGray: string) => {
      if (bluetoothConnectionStatus !== BluetoothConnectionStatus.CONNECTED) {
        return filterGray;
      } else {
        return shouldBeInactive ? filterGray : filterBlue;
      }
    },
    [bluetoothConnectionStatus, shouldBeInactive]
  );

  const onClickHandler = () => {
    if (!isDisabled) {
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <IconButton
        disabled={isDisabled}
        data-cy="scales-button"
        className={classes.iconButton}
        onClick={onClickHandler}
      >
        <img
          src={iqImages.balanceScales}
          alt="balance-scales"
          className={classNames(classes.icon, evaluateClassName(classes.filterBlue, classes.filterGray))}
        />
      </IconButton>
      {isDialogOpen && (
        <ChangeGewichtModeDialogContainer
          open={isDialogOpen}
          closeDialogHandler={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};

export default GewichtModeComponent;
