import { FunctionComponent, ChangeEvent } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export interface Props {
  searchTerm: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCloseClick?: () => void;
  dataCy?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
}

export const SearchField: FunctionComponent<Props> = ({
  searchTerm,
  handleChange,
  handleCloseClick,
  dataCy,
  variant,
}) => {
  const { t } = useTranslation();
  const customAttr = {
    "data-cy": dataCy,
  };

  return (
    <TextField
      id="outlined-basic"
      placeholder={t("COMMON.SEARCH_PLACEHOLDER")}
      value={searchTerm}
      onChange={handleChange}
      variant={variant ? variant : "outlined"}
      InputProps={{
        endAdornment: handleCloseClick ? (
          <InputAdornment position="end" onClick={handleCloseClick}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...customAttr}
    />
  );
};
