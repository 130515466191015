import { Action } from "../action";
import db, { ISyncedCapturedRecord } from "../../db/database";

import {
  CapturedRecordsActionType,
  clearCapturedRecords,
  setLocalCapturedRecords,
  setNoMoreRemoteCapturedRecordsAvailable,
  setRemoteCapturedRecords,
  startEditCapturedRecord,
  fetchLocalCapturedRecords as fetchLocalCapturedRecordsAction,
  failedToFetchRemoteCapturedRecords,
} from "./captured-records.actions";
import { call, put, select } from "redux-saga-test-plan/matchers";
import { takeLatest, takeEvery, delay } from "redux-saga/effects";
import logger from "../../logger";
import {
  ISendDataExportRequestDto,
  IDeleteProzessEventsByRecordsGroupRequestDto,
  CapturedRecordsGroupsResponseDto,
  IProzessEventDto,
  IFunktionDto,
  IProzessDto,
  ProzessType,
} from "../../api/backend-api-v7";
import { ProzessEventsActionType, ProzessEventsInfo } from "../prozess-events/prozess-events.actions";
import {
  getLocalCapturedRecordsByFunktionId,
  getRemoteCapturedRecordsOffset,
  getRemoteCapturedRecordsByFunktionId,
  getEditedCapturedRecord,
} from "./captured-records.selectors";
import {
  getCurrentFunktionId,
  getFunktionById,
  getMaxCapturedRecordsAmountByFunktionId,
  getProzesseByFunktionId,
} from "../funktionen/funktionen.selectors";
import { showSuccessfulRequest, showFailedRequest } from "../notifications/notifications.actions";
import { IRemoteCapturedRecordsState } from "./captured-records.reducer";
import { FunktionenActionType } from "../funktionen/funktionen.actions";
import BackendClient from "../../api/backend-client";
import { Dispatch } from "redux";
import { checkDeletedRecord } from "../wurfuebersicht/wurfuebersicht.actions";
import { filterFerkel } from "../ferkel/ferkel.actions";
import I18n from "i18next";
import { mapIdsToLabels } from "./captured-records.utils";
import {
  revealDataForDeletion,
  TransitoryDataForDeletion,
} from "../transitory-prozess-data/transitory-prozess-data.saga";
import { deleteProzessEventsByRecordId } from "../prozess-events/prozess-events.saga";
import { ICapturedRecordRequest } from "../../api/api-interfaces";
import { SynchronizationActionType } from "../synchronization/synchronization.actions";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { deleteBuchtenFunktionenHistory } from "../buchten/buchten.saga";
import { checkInternetConnection } from "../../utils/network-status";

const logError = logger.error("captured-localRecords.saga");
const logInfo = logger.info("captured-localRecords.saga");

let backendClient: BackendClient;
const networkErrorMessage = "Network Error";

export function* fetchLocalCapturedRecords(action: Action<number>) {
  yield call(getLocalCapturedRecords, action.payload);
}

function* getLocalCapturedRecords(funktionId: number) {
  try {
    const records: ISyncedCapturedRecord[] = yield call([db, "getCapturedRecords"], funktionId);
    yield put(setLocalCapturedRecords(funktionId, records));
  } catch (e) {
    logError("Could not fetch captured localRecords");
  }
}

function* removeOldSyncedRecords(funktionId: number) {
  const amountCapturedRecords: number | undefined = yield select(
    getMaxCapturedRecordsAmountByFunktionId(funktionId)
  );
  yield call([db, "removeOldSyncedRecords"], funktionId, amountCapturedRecords);
}

export function* addCapturedRecords(action: Action<ProzessEventsInfo>) {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);
    const prozesse: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));
    const { prozessEvents, validationErrors } = action.payload;

    yield call([db, "addCapturedRecords"], mapIdsToLabels(prozesse, prozessEvents), validationErrors);
    yield call(removeOldSyncedRecords, funktionId);
    yield call(getLocalCapturedRecords, funktionId);
  } catch (e) {
    logError("Could not add localRecords to local db", e);
  }
}

export function* updateCapturedRecords(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    const funktionId: number = yield select(getCurrentFunktionId);
    const editedCapturedRecord: ISyncedCapturedRecord = yield select(getEditedCapturedRecord);
    const prozesse: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));

    const dataForDeletion: TransitoryDataForDeletion = yield call(revealDataForDeletion);
    const groupId = editedCapturedRecord.recordsGroupId;

    if (dataForDeletion.valueForDeletion.length) {
      yield call(deleteProzessEventsByRecordId, dataForDeletion.valueForDeletion);
    }

    yield call(
      [db, "updateCapturedRecords"],
      mapIdsToLabels(prozesse, prozessEvents),
      groupId,
      dataForDeletion
    );

    yield call(removeOldSyncedRecords, funktionId);
    yield call(getLocalCapturedRecords, funktionId);
  } catch (e) {
    logError("Could not update localRecords to local db", e);
  }
}

export function* markCapturedRecordsAsSynced(action: Action<IProzessEventDto[]>) {
  try {
    yield delay(1000);
    yield call([db, "markCapturedRecordsAsSynced"], action.payload);

    const funktionIds = action.payload
      .flat()
      .reduce(
        (obj: number[], prozessEvent: IProzessEventDto) =>
          obj.includes(prozessEvent.funktionId) ? obj : [...obj, prozessEvent.funktionId],
        []
      );

    for (const funktionId of funktionIds) {
      yield call(getLocalCapturedRecords, funktionId);
      yield call(removeOldSyncedRecords, funktionId);
    }
  } catch (e) {
    logError("Could not mark localRecords as synced", e);
  }
}

export function* fetchRemoteCapturedRecords(action: Action<ICapturedRecordRequest>) {
  try {
    const { funktionId } = action.payload;
    const { type, ...rest } = action.payload;
    const offset: number = yield select(getRemoteCapturedRecordsOffset(funktionId));

    const skipRecordIds: ISyncedCapturedRecord[] = yield select(
      getLocalCapturedRecordsByFunktionId(funktionId)
    );

    const request = {
      ...rest,
      offset,
      skipRecordsGroupIds: skipRecordIds.map(r => r.recordsGroupId),
    };

    let newRemoteRecords: CapturedRecordsGroupsResponseDto;

    if (type === ProzessType.ALL_RECORDS) {
      newRemoteRecords = yield call([backendClient, "getCapturedRecords"], request);
    } else {
      newRemoteRecords = yield call([backendClient, "getCapturedRecordsForDataExports"], request);
    }

    if (newRemoteRecords.recordsGroups!.length > 0) {
      const syncedRemoteRecords: ISyncedCapturedRecord[] = newRemoteRecords.recordsGroups!.map(record => ({
        ...record,
        synced: 1,
        validationErrors: undefined,
      }));

      const existingRemoteRecords: IRemoteCapturedRecordsState = yield select(
        getRemoteCapturedRecordsByFunktionId(funktionId)
      );

      const combinedRecords = [
        ...(existingRemoteRecords ? existingRemoteRecords.records : []),
        ...syncedRemoteRecords,
      ];

      yield put(
        setRemoteCapturedRecords(funktionId, {
          records: combinedRecords,
          offset: newRemoteRecords.offset! + newRemoteRecords.recordsGroups!.length,
          totalCount: newRemoteRecords.totalCount!,
          lastFetched: new Date(Date.now()),
          noMoreCapturedRecordsAvailable: false,
        })
      );
    } else {
      yield put(setNoMoreRemoteCapturedRecordsAvailable(funktionId, new Date(Date.now())));
    }
  } catch (e: any) {
    if (e.message === networkErrorMessage) {
      yield put(showFailedRequest(I18n.t("NOTIFICATIONS.NETWORK_ERROR")));
    }
    yield put(failedToFetchRemoteCapturedRecords(e.message));
    logError("Could not fetch remote captured localRecords", e);
  }
}

export function* clearRecords() {
  yield put(clearCapturedRecords());
}

export function* getCapturedRecordForEdit(action: Action<string>) {
  try {
    const recordId = action.payload;
    const capturedRecordForEdit: ISyncedCapturedRecord | undefined = yield call(
      [db, "getCapturedRecordByRecordId"],
      recordId
    );

    if (capturedRecordForEdit) {
      logInfo(`Found captured-record for edit by recordId ${recordId}: ${capturedRecordForEdit}`);
      yield put(startEditCapturedRecord(capturedRecordForEdit));
    } else {
      logError(`No captured-record for edit by recordId ${recordId} found`);
    }
  } catch (e) {
    logError("Could not get captured-record for edit", e);
  }
}

export function* deleteCapturedRecord(action: Action<ISyncedCapturedRecord>) {
  try {
    const { recordsGroupId, recordId, synced } = action.payload;
    const funktionId: number = yield select(getCurrentFunktionId);
    const funktion: IFunktionDto = yield select(getFunktionById(funktionId));
    const isOnline = checkInternetConnection();

    const requestData: IDeleteProzessEventsByRecordsGroupRequestDto = {
      recordsGroupId: recordsGroupId!,
      funktionId,
    };

    if (isOnline) {
      yield call([backendClient, "deleteRecordByGroupId"], requestData);
      yield call([db, "deleteProzessEventsByRecordsGroupId"], recordsGroupId);
    } else {
      if (synced) {
        // Synced record cannot be deleted in offline mode.
        yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_TO_DELETE_RECORD")));
        return;
      }
      yield call([db, "markUnsyncedProzessEventsAsDeleted"], recordsGroupId);
    }
    yield call([db, "deleteCapturedRecord"], recordsGroupId);
    yield call([db, "restoreCapturedRecord"], recordsGroupId);
    yield call([db, "deleteFerkelHistory"], { funktionId, recordId });
    yield call([db, "deleteSauenHistory"], { funktionId, recordId });
    yield call(deleteBuchtenFunktionenHistory, funktionId, recordId);

    yield put(checkDeletedRecord(action.payload));
    yield put(fetchLocalCapturedRecordsAction(funktionId));

    // After changes with funkitonen history, we need to update information about filtered ferkel in ferkel reducer.
    yield put(filterFerkel(funktion));
    yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_DELETE_RECORD")));
  } catch (e) {
    logError("Could not delete captured-record", e);
    yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_TO_DELETE_RECORD")));
  }
}

export function* resendDataExportForCapturedRecord(action: Action<ISendDataExportRequestDto>) {
  try {
    yield call([backendClient, "sendDataExport"], action.payload);
    yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_RESEND_DATA_EXPORT")));
  } catch (e: any) {
    if (e.message === networkErrorMessage) {
      yield put(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_TO_RESEND_DATA_EXPORT")));
    }
    logError("Could not resend data export for captured-record", e);
  }
}

export default function* capturedRecordsSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLatest(CapturedRecordsActionType.FETCH_LOCAL_CAPTURED_RECORDS, fetchLocalCapturedRecords);
  yield takeLatest(CapturedRecordsActionType.FETCH_REMOTE_CAPTURED_RECORDS, fetchRemoteCapturedRecords);
  yield takeLatest(CapturedRecordsActionType.GET_CAPTURED_RECORD_FOR_EDIT, getCapturedRecordForEdit);
  yield takeLatest(CapturedRecordsActionType.DELETE_CAPTURED_RECORD, deleteCapturedRecord);
  yield takeLatest(
    CapturedRecordsActionType.RESEND_DATA_EXPORT_FOR_CAPTURED_RECORD,
    resendDataExportForCapturedRecord
  );

  yield takeEvery(ProzessEventsActionType.SAVE_PROZESS_EVENTS_SUCCESS, addCapturedRecords);
  yield takeEvery(ProzessEventsActionType.UPDATE_PROZESS_EVENTS, updateCapturedRecords);
  yield takeEvery(ProzessEventsActionType.SAVE_PROZESS_EVENTS_FAILURE, addCapturedRecords);

  yield takeEvery(SynchronizationActionType.SYNC_PROZESS_EVENTS_SUCCESS, markCapturedRecordsAsSynced);

  yield takeLatest(FunktionenActionType.LOAD_FUNKTIONEN, clearRecords);
}
