import { TextField } from "@mui/material";
import { FunctionComponent, useEffect, useState, useCallback } from "react";
import WarningModalWindow from "../../modal-windows/warning-dialog/warning-dialog.component";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Props } from "./tierident.types";
import useStyles from "./tierident.styles";
import { useTierIdentState } from "./tierident.hooks";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { defineFerkelProperty, findFerkelByQuery } from "../../../utils/ferkel.utils";

export const EMPTY_DATA = "[]";
const DISABLED_TIERIDENT = "-";
const TIER_IDENT = "tierIdent";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (tierIdent: string | undefined, metaData?: BluetoothDataFormat) => void,
  tierIdent: string | undefined,
  metaData: BluetoothDataFormat | undefined,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;
  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(tierIdent, metaData);
    }
  } else {
    if (isRecordActive) {
      save(tierIdent, metaData);
    }
  }
};

const TierIdentComponent: FunctionComponent<Props> = props => {
  const {
    tierIdent,
    prozess,
    recordId,
    manualInput,
    shouldValidate,
    onChanged,
    givenData,
    givenDataApplied,
    prevRecordId,
    editedValue,
    setIsValidationError,
    isManuallyEmpty,
    funktionId,
    resetTierIdentData,
    shouldCreateProzessEventForMultipleProzess,
    isEditing,
    funktionConfiguration,
  } = props;
  const [valueWillBeGenerated, setValueWillBeGenerated] = useState(!!givenData);
  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const [shouldIgnoreEventType, setShouldIgnoreEventType] = useState(false);

  const { init, save, onProzessDataChanged, reset, state, shouldEditProzessData } = useTierIdentState(
    prozess,
    onChanged,
    valueWillBeGenerated,
    manualInput,
    isEditing,
    shouldIgnoreEventType
  );
  const { t } = useTranslation();
  const classes = useStyles();

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      setShouldIgnoreEventType(true);
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      shouldEditProzessData(false);
      onProzessDataChanged(editedValue);
      if (editedValue === DISABLED_TIERIDENT) {
        setValueWillBeGenerated(true);
      }
    } else {
      setShouldIgnoreEventType(false);
      shouldEditProzessData(false);
      setValueWillBeGenerated(false);
      onProzessDataChanged("");
    }
  }, [isEditing, editedValue, shouldEditProzessData, onProzessDataChanged]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const isTierIdentAlreadyExist = useCallback(
    async (value: string) => !!(await findFerkelByQuery(TIER_IDENT, value)),
    []
  );

  const checkIsTierIdentAlreadyExist = useCallback(
    async (value?: string) => {
      if (value && value !== editedValue) {
        // For Bluetooth mode.
        const isExist = await isTierIdentAlreadyExist(value);
        if (isExist) {
          setShouldShowWarning(true);
        }
        return;
      }

      if (state.tierIdent && state.tierIdent !== editedValue && state.tierIdent !== DISABLED_TIERIDENT) {
        // For manual change.
        const isExist = await isTierIdentAlreadyExist(state.tierIdent);
        if (isExist) {
          setShouldShowWarning(true);
        }
      }
    },
    [editedValue, isTierIdentAlreadyExist, state.tierIdent]
  );

  const checkOnGivenData = useCallback(async () => {
    if (givenData) {
      // Only case to listen Totgeburt field and remove Tieridend.
      if (funktionConfiguration.shouldGenerateTierIdentOnTotgeburt) {
        // "[]" says that you removed dependent prozess in edit mode and Tierident prozess is required.
        givenData?.data.data !== EMPTY_DATA
          ? setValueWillBeGenerated(!!givenData?.data)
          : setValueWillBeGenerated(false);
        if (!!givenData?.data && givenData?.data.data !== EMPTY_DATA) {
          onProzessDataChanged(DISABLED_TIERIDENT);
          shouldEditProzessData(true);
        } else {
          reset();
        }
      } else {
        if (givenData.data) {
          const ferkelKey = defineFerkelProperty(givenData.data.prozessType);
          const ferkel = await findFerkelByQuery(ferkelKey, givenData.data.data);
          if (ferkel) {
            onProzessDataChanged(ferkel.tierIdent);
          }
        } else {
          reset();
        }
      }
    }
    givenDataApplied(prozess.workflowId!);
  }, [
    funktionConfiguration.shouldGenerateTierIdentOnTotgeburt,
    givenData,
    givenDataApplied,
    onProzessDataChanged,
    prozess.workflowId,
    reset,
    shouldEditProzessData,
  ]);

  useEffect(() => {
    init();
  }, [init, prozess]);

  useEffect(() => {
    reset();
    setValueWillBeGenerated(false);
  }, [recordId, reset, manualInput, isManuallyEmpty, funktionId]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    saveWhenRecordIsActive(
      prevRecordId,
      recordId,
      save,
      state.tierIdent,
      tierIdent,
      state.isProzessEdited,
      isEditing
    );
    // Should listen only state.tierIdent changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tierIdent]);

  useEffect(() => {
    if (!manualInput && !!tierIdent && !valueWillBeGenerated) {
      shouldEditProzessData(true);
      onProzessDataChanged(tierIdent ? tierIdent.value : "");
      checkIsTierIdentAlreadyExist(tierIdent.value);
      resetTierIdentData();
    }
  }, [
    onProzessDataChanged,
    shouldEditProzessData,
    manualInput,
    tierIdent?.value,
    valueWillBeGenerated,
    checkIsTierIdentAlreadyExist,
    resetTierIdentData,
    tierIdent,
  ]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  const manualChange = (manualValue: string) => {
    shouldEditProzessData(true);
    onProzessDataChanged(manualValue);
  };

  const acceptModalHandler = () => {
    setShouldShowWarning(false);
    reset();
  };

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root}>
      <TextField
        id={prozess.prozessType}
        required={prozess.isRequired && !valueWillBeGenerated}
        disabled={valueWillBeGenerated || prozess.isReadOnly}
        label={prozess.label!}
        variant="outlined"
        placeholder={valueWillBeGenerated ? t("wird generiert") : ""}
        value={state.tierIdent !== DISABLED_TIERIDENT ? state.tierIdent : ""}
        className={classNames(classes.textField, {
          [classes.validLabel]: state.isValid,
          [classes.disabled]: valueWillBeGenerated || prozess.isReadOnly,
        })}
        // This callback is only called on manual input
        // onChange={e => onChange(prozess.workflowId, prozess.manualEventType, e.currentTarget.value)}
        onChange={e => manualChange(e.currentTarget.value)}
        InputProps={{
          readOnly: !manualInput,
          classes: {
            notchedOutline:
              state.isValid && !valueWillBeGenerated && !prozess.isReadOnly ? classes.validBorder : "",
          },
        }}
        InputLabelProps={{ shrink: true }}
        error={shouldValidate && state.isError}
        onBlur={() => checkIsTierIdentAlreadyExist()}
      />
      {shouldShowWarning && (
        <WarningModalWindow
          open={shouldShowWarning}
          acceptHandler={acceptModalHandler}
          title={`Bekannte ${prozess.label}`}
        >
          Zu dieser Tier Ident existiert bereits ein Datensatz. Eine Tier Ident kann nicht doppelt verwendet
          werden. Verwenden Sie eine andere Tier Ident.
        </WarningModalWindow>
      )}
    </div>
  );
};

export default TierIdentComponent;
