import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      textAlign: "center",
      lineHeight: "40px",
      fontWeight: "bold",
      height: "40px",
    },
    tableCell: {
      fontSize: "20px",
      whiteSpace: "pre-wrap",
      padding: theme.spacing(),
      border: `1px solid ${theme.palette.primary.main}`,
      height: "50px",
    },
    error: {
      color: theme.additionalColors.ampelRot,
    },
  })
);

export default useStyles;
