import { IProzessDto, ISauLifeStageDescriptorDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  fullScreen?: boolean;
  setIsValidationError: (isError: boolean) => void;
}

export interface ConnectedState {
  prevRecordId: string;
  isManuallyEmpty: boolean | undefined;
  comparisonData: ProzessInputData | undefined;
  shouldCreateProzessEventForMultipleProzess: boolean;
  filteredBy: ProzessInputData | undefined;
  editedValue: string | undefined;
  isEditing: boolean;
  givenData: ProzessInputData | undefined;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  givenDataApplied: (workflowId: number) => void;
  createFilteredBy: (prozessInputData: ProzessInputData) => void;
  updateComparisonData: () => void;
}

export interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface SauSelectorState {
  value: ISauLifeStageDescriptorDto | undefined;
  isError: boolean;
  isValid: boolean;
  isProzessEdited?: boolean;
}

export interface SauSelectorAction {
  type: SauSelectorActionType;
  payload: {
    value: ISauLifeStageDescriptorDto | undefined;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
    isProzessEdited?: boolean;
  };
}

export enum SauSelectorActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}
