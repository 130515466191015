import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "14pt",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.additionalColors.lightGray,
    width: "100%",
    position: "relative",
  },
  title: {
    fontWeight: "bold",
    fontSize: "26pt",
    marginBottom: "20px",
    marginTop: "10px",
    color: "white",
  },
  contactDetails: {
    marginTop: "20px",
  },
}));

export default useStyles;
