import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      margin: 0,
      marginTop: "30px",
      width: "100%",
      "& div": {
        height: "80px",
        width: "250px",
      },
    },
    textField: {
      minWidth: "150px",
      width: "100%",
      "& input": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
      },
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    endAdornment: {
      display: "flex",
      width: "30px! important",
      justifyContent: "flex-end",
    },
    clearIndicator: {
      position: "absolute",
      right: "30px",
    },
    validField: {
      "& > .MuiInputLabel-root": {
        color: theme.additionalColors.ampelGruen,
      },
      "& fieldset": {
        borderColor: `${theme.additionalColors.ampelGruen}!important`,
      },
    },
  })
);

export default useStyles;
