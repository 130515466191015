import { Colors } from "./colors";

declare module "@mui/material/styles" {
  interface Theme {
    additionalColors: {
      ampelRot: React.CSSProperties["color"];
      ampelGelb: React.CSSProperties["color"];
      ampelGruen: React.CSSProperties["color"];
      bluetoothBlue: React.CSSProperties["color"];
      gray: React.CSSProperties["color"];
      dimGray: React.CSSProperties["color"];
      lightGray: React.CSSProperties["color"];
    };
  }

  interface ThemeOptions {
    additionalColors: {
      ampelRot: React.CSSProperties["color"];
      ampelGelb: React.CSSProperties["color"];
      ampelGruen: React.CSSProperties["color"];
      bluetoothBlue: React.CSSProperties["color"];
      gray: React.CSSProperties["color"];
      dimGray: React.CSSProperties["color"];
      lightGray: React.CSSProperties["color"];
    };
  }
}

export default {
  typography: {
    useNextVariants: true,
    fontSize: 21,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
  },
  palette: {
    primary: {
      light: Colors.PrimaryLight,
      main: Colors.PrimaryMain,
      dark: Colors.PrimaryDark,
      contrastText: Colors.Black,
    },
    secondary: {
      main: Colors.SchmuckfarbeCyan,
      contrastText: Colors.White,
    },
  },
  additionalColors: {
    ampelRot: Colors.AmpelRot,
    ampelGelb: Colors.AmpelGelb,
    ampelGruen: Colors.AmpelGruen,
    bluetoothBlue: Colors.BluetoothBlue,
    gray: Colors.Gray,
    dimGray: Colors.DimGray,
    lightGray: Colors.LightGray,
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple!
      },
    },
  },
  transitions: {
    // `transition: none;` everywhere
    create: () => "none",
  },
};
