import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { useCallback, useReducer } from "react";
import { TextFieldActionType } from "./text-field.types";
import { initialState, textFieldReducer } from "./text-field.reducer";
import { verifyIfValueIsOptimal, verifyIfValueIsValid, validateStringValue } from "./text-field.utils";
import { FieldTypes } from "./text-field.component";
import { IProzessDto } from "../../../api/backend-api-v7";

const isValueValid = (value: number | string | undefined, type: string, prozess: IProzessDto) => {
  if (!!value && type === FieldTypes.NUMBER) {
    return verifyIfValueIsValid(+value, prozess);
  }
  if (!!value && type === FieldTypes.STRING) {
    return validateStringValue(value.toString(), prozess);
  }
  return false;
};

export const useTextFieldState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  type: string,
  shouldIgnoreEventType: boolean
) => {
  const [state, dispatch] = useReducer(textFieldReducer, initialState);

  const { isRequired, shouldKeepValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: TextFieldActionType.INIT,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: number | string | undefined) => {
      const isValid = isValueValid(value, type, prozess);
      const isOptimal = value && type === FieldTypes.NUMBER ? verifyIfValueIsOptimal(+value, prozess) : false;

      dispatch({
        type: TextFieldActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid,
          isOptimal,
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, prozess, shouldKeepValue, type]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: TextFieldActionType.RESET,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const save = useCallback(
    (value: number | string | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing && !shouldIgnoreEventType ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: value,
        additionalData: undefined,
        operation: value ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: isValueValid(value, type, prozess),
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [isEditing, onChanged, prozess, shouldIgnoreEventType, type]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: TextFieldActionType.PROZESS_DATA_EDITED,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, reset, onProzessDataChanged, save, shouldEditProzessData };
};
