import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inactive: {
      color: theme.additionalColors.ampelRot,
    },
    active: {
      color: theme.additionalColors.bluetoothBlue,
    },
  })
);

export default useStyles;
