import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ISauLifeStageDescriptorDto, ProzessType } from "../../../api/backend-api-v7";

export const genetischesauProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const genetischesau = prozessEvents.find(pe => pe.prozessType === ProzessType.GENETISCHESAU);

  if (genetischesau) {
    const { ammenObjectId, belegNr, tierSysId } = genetischesau.data as ISauLifeStageDescriptorDto;

    const updateDescriptor = (): ISauLifeStageDescriptorDto =>
      belegNr
        ? { tierSysId, belegNr, ammenObjectId: undefined }
        : { tierSysId, ammenObjectId, belegNr: undefined };

    const updatedFerkel: ILocalFerkelDto = {
      ...ferkel,
      genetischeSauDescriptor: updateDescriptor(),
    };

    return next(updatedFerkel, prozessEvents);
  }
  return next(ferkel, prozessEvents);
};
