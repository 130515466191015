import { FunctionComponent, useEffect } from "react";
import { Props } from "./last-exports.types";
import RecordsTableComponent from "../records-table/records-table.component";

export const LAST_EXPORTS_AMOUNT = 5;

const LastExportsComponent: FunctionComponent<Props> = props => {
  const { currentProzess, prozesse, funktionId, lastExports, fetchLastExports, resendData, recordsGroupId } =
    props;

  useEffect(() => {
    fetchLastExports(funktionId, LAST_EXPORTS_AMOUNT);
  }, [fetchLastExports, funktionId]);

  if (!currentProzess) {
    return null;
  }

  return (
    <div style={{ gridArea: currentProzess.position }} data-cy="LastExportsComponent">
      <RecordsTableComponent
        currentProzess={currentProzess}
        prozesse={prozesse}
        funktionId={funktionId}
        records={[lastExports]}
        shouldUseActionBar={true}
        shouldDisableSorting={true}
        resendData={resendData}
        recordsGroupId={recordsGroupId}
      />
    </div>
  );
};

export default LastExportsComponent;
