import { ISauBuchtDto, SauLifeStageTypeEnum } from "../api/backend-api-v7";
import { ILocalSauDto, ILocalSauLifeStageDto, ILocalSauFunktionHistoryDto } from "../db/database";

export interface IFilteredLocalSau {
  /** ↓General Sau info↓ */
  id?: number;
  tierSysId?: string;
  halterbetrieb?: string | undefined;
  sauNrBetrieb?: string | undefined;
  sauNrPlaner?: number;
  modifiedAtUtc?: number;
  sauTransponder?: number;
  /** ↓LifeStage Sau info↓ */
  lifeStageType?: SauLifeStageTypeEnum;
  belegdatum?: number;
  belegNr?: number;
  ammenObjectId?: string | undefined;
  mumien?: string | undefined;
  abferkeldatum?: number | undefined;
  absetzdatum?: number | undefined;
  funktionenHistory?: ILocalSauFunktionHistoryDto[] | undefined;
  buchten?: ISauBuchtDto[] | undefined;
}

/**
 * Create filtered by ProzessDataFilters Sau entity based on active lifeStage and general Sau info.
 * @param sauLifeStage the {@link ILocalSauLifeStageDto}.
 * @param sau the {@link ILocalSauDto}.
 * @return the {@link IFilteredLocalSau}.
 */
export const createFilteredLocalSau = (
  sauLifeStage: ILocalSauLifeStageDto,
  sau: ILocalSauDto
): IFilteredLocalSau => {
  const { lifeStages, ...restSau } = sau;

  if (sauLifeStage.lifeStageType === SauLifeStageTypeEnum.Ammen) {
    return { ...restSau, ...sauLifeStage, sauNrBetrieb: `${restSau.sauNrBetrieb} (Amme)` };
  } else {
    return { ...restSau, ...sauLifeStage };
  }
};

/**
 * Map each suitable Sau LifeStage to filtered local sau.
 * @param sauLifeStages the {@link ILocalSauLifeStageDto[]}.
 * @param sau the {@link ILocalSauDto}.
 * @return the {@link IFilteredLocalSau[]}.
 */
export const mapFilteredLocalSau = (
  sauLifeStages: ILocalSauLifeStageDto[],
  sau: ILocalSauDto
): IFilteredLocalSau[] => sauLifeStages.map(stage => createFilteredLocalSau(stage, sau));
