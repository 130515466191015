import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const verlustgrundProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const verlustgrund = prozessEvents.find(pe => pe.prozessType === ProzessType.VERLUSTGRUND);

  if (verlustgrund) {
    const updatedFerkel = { ...ferkel, verlustgrund: +verlustgrund.data[0] };
    return updatedFerkel;
  }
  return ferkel;
};
