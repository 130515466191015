import { TextFieldAction, TextFieldActionType, TextFieldState } from "./text-field.types";
import logger from "../../../logger";

const logInfo = logger.info("text-field.reducer");

export const initialState: TextFieldState = {
  value: undefined,
  isError: false,
  isValid: false,
  isOptimal: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const textFieldReducer = (state: TextFieldState, action: TextFieldAction): TextFieldState => {
  const {
    type,
    payload: { value, isRequired, shouldKeepValue, isValid, isOptimal, isProzessEdited },
  } = action;

  switch (type) {
    case TextFieldActionType.INIT: {
      logInfo("TEXT_FIELD INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case TextFieldActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        value,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
        isOptimal: !!isOptimal,
      };
    }
    case TextFieldActionType.RESET: {
      logInfo("TEXT_FIELD RESET");
      const newValue = shouldKeepValue ? state.value : undefined;
      return { ...initialState, value: newValue, isError: isError(!!isValid, isRequired) };
    }
    case TextFieldActionType.PROZESS_DATA_EDITED: {
      return { ...initialState, isProzessEdited };
    }
  }

  return state;
};
