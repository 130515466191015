import logger from "../../logger";
import db from "../../db/database";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { Dispatch } from "redux";
import { Action } from "../action";

import BackendClient from "../../api/backend-client";
import { IPlanFerkelResponseDto } from "../../api/backend-api-v7";

import {
  PlanFerkelActionType,
  loadPlanFerkelSuccess,
  loadPlanFerkelFailure,
  setLastFetchedDate,
} from "./plan-ferkel.actions";
import { getLastFetchedDate } from "./plan-ferkel.selectors";

const logError = logger.error("plan-ferkel.saga");
let backendClient: BackendClient;

/** Load ferkel plan on init */
export function* loadPlanFerkel() {
  try {
    // Send undefined instead of the lastFetched date to get all existing ferkel plan data.
    const response: IPlanFerkelResponseDto = yield call([backendClient, "getPlanFerkel"], undefined);
    yield put(loadPlanFerkelSuccess());

    if (response && response.planFerkel?.length) {
      yield put(setLastFetchedDate());

      yield call([db, "deletePlanFerkel"]);
      yield call([db, "addPlanFerkel"], response.planFerkel);
    }
  } catch (e: any) {
    logError("Could not fetch ferkel plan", e.message);
    yield put(loadPlanFerkelFailure(e.message));
  }
}

/**
 * Check updates for ferkel plan.
 * Should overwrite previous plan with new one.
 */
export function* updatePlanFerkel(action: Action<null>) {
  try {
    const lastFetched: Date | undefined = yield select(getLastFetchedDate);
    const response: IPlanFerkelResponseDto = yield call([backendClient, "getPlanFerkel"], lastFetched);
    yield put(loadPlanFerkelSuccess());

    if (response && response.planFerkel?.length) {
      yield put(setLastFetchedDate());

      yield call([db, "deletePlanFerkel"]);
      yield call([db, "addPlanFerkel"], response.planFerkel);
    }
  } catch (e: any) {
    logError("Could not update ferkel plan", e.message);
    yield put(loadPlanFerkelFailure(e.message));
  }
}

export default function* planFerkelSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(PlanFerkelActionType.LOAD_PLAN_FERKEL, loadPlanFerkel);
  yield takeEvery(PlanFerkelActionType.UPDATE_LOCAL_PLAN_FERKEL, updatePlanFerkel);
}
