import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: 800,
  },
  dialogActions: {
    justifyContent: "space-around",
    padding: "20px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
