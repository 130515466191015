import { FunctionComponent } from "react";
import CloseButton from "../../components/common/close-button/close-button.component";
import useStyles from "./impressum.styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {}

const ImpressumComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}> {t("COMPONENTS.IMPRESSUM.TITLE")}</Typography>
        <CloseButton onCloseHandler={() => history.back()} />
      </div>
      <div className={classes.textWrapper}>
        <Typography className={classes.description}>IQ-Agrar Service GmbH</Typography>
        <Typography className={classes.description}>
          Peter Schwaer, Stephanie Bering
          <br />
          Iburger Straße 225
          <br />
          49082 Osnabrück
        </Typography>
        <Typography className={classes.description}>
          Tel.: +49 (0) 541 / 600 288 80
          <br />
          Fax: +49 (0) 541 / 600 288 90
          <br />
          E-Mail: info@iq-agrar.de
        </Typography>
        <Typography className={classes.description}>
          {t("COMPONENTS.IMPRESSUM.TEXT_1")}: Osnabrück
          <br />
          HRB: 20215
          <br />
          {t("COMPONENTS.IMPRESSUM.TEXT_2")}: Osnabrück
          <br />
          Ust.-ID.: DE 22 1940600
        </Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.IMPRESSUM.TEXT_3")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.IMPRESSUM.TEXT_4")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.IMPRESSUM.TEXT_5")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.IMPRESSUM.TEXT_6")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.IMPRESSUM.TEXT_7")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.IMPRESSUM.TEXT_8")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.IMPRESSUM.TEXT_9")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.IMPRESSUM.TEXT_10")}</Typography>
      </div>
    </div>
  );
};

export default ImpressumComponent;
