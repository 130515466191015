import { ICapturedRecordDto } from "../../api/backend-api-v7";
import { ISyncedCapturedRecord } from "../../db/database";

export const mapFerkelInfoToCapturedRecords = (ferkelInfo: ICapturedRecordDto[]): ISyncedCapturedRecord[] =>
  ferkelInfo.map(item => {
    const { recordId, funktionId, timestamp, data } = item;
    return {
      funktionId,
      timestamp,
      data,
      recordsGroupId: recordId,
      synced: 1,
      validationErrors: undefined,
    };
  });
