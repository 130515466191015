import { ILocalSauDto } from "../../db/database";
import { Action } from "../action";
import createReducer from "../createReducer";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { SauenActionType } from "./sauen.actions";

export interface SauenState {
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
  sauen: ILocalSauDto[];
}

export const initialState: SauenState = {
  loadStatus: loadInitial(),
  lastFetched: undefined,
  sauen: [],
};

export default createReducer(initialState, {
  [SauenActionType.LOAD_SAUEN](state: SauenState): SauenState {
    return { ...state, loadStatus: loadPending() };
  },
  [SauenActionType.LOAD_SAUEN_SUCCESS](state: SauenState): SauenState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [SauenActionType.LOAD_SAUEN_FAILURE](state: SauenState, action: Action<string>): SauenState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [SauenActionType.SET_LAST_FETCHED_DATE](state: SauenState): SauenState {
    return {
      ...state,
      lastFetched: new Date(Date.now()),
    };
  },
  [SauenActionType.SET_SAUEN](state: SauenState, action: Action<ILocalSauDto[]>): SauenState {
    return {
      ...state,
      sauen: action.payload,
    };
  },
});
