import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    margin: "0",
    marginTop: "20px",
    textAlign: "center",
    fontSize: "20pt",
    fontWeight: "bold",
  },
  description: {
    display: "flex",
    fontSize: "16pt",
    color: "rgb(134,134,134)",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  acceptButton: {
    display: "flex",
    fontSize: "18pt",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(23, 108, 147)",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
