import logger from "../../../logger";

import {
  KeyValueInfoTableAction,
  KeyValueInfoTableActionType,
  KeyValueInfoTableState,
} from "./key-value-table.types";

const logInfo = logger.info("keyValueInfoTable.reducer");

export const initialState: KeyValueInfoTableState = {
  value: undefined,
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const keyValueInfoTableReducer = (
  state: KeyValueInfoTableState,
  action: KeyValueInfoTableAction
): KeyValueInfoTableState => {
  const {
    type,
    payload: { value, isRequired, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case KeyValueInfoTableActionType.RESET: {
      logInfo("KeyValueInfoTable component RESET");
      return { ...initialState };
    }
    case KeyValueInfoTableActionType.PROZESS_DATA_CHANGED: {
      return {
        ...state,
        value,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case KeyValueInfoTableActionType.PROZESS_DATA_EDITED: {
      return { ...initialState, isProzessEdited };
    }
  }

  return state;
};
