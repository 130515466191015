import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
}

export interface ConnectedState {
  prevRecordId: string;
  givenData: ProzessInputData | undefined;
  isManuallyEmpty: boolean | undefined;
  isEditing: boolean;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  givenDataApplied: (workflowId: number) => void;
}

export interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface HiddenState {
  value: any | undefined;
  isError: boolean;
  isValid: boolean;
}

export interface HiddenAction {
  type: HiddenActionType;
  payload: {
    value: any | undefined;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
  };
}

export enum HiddenActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
}
