import { IProzessDto } from "../../../api/backend-api-v7";

const isValueInRange = (value: number | undefined, min: number, max: number) =>
  value ? !!(value >= min && value <= max) : false;

export const verifyIfValueIsValid = (value: number | undefined, prozess: IProzessDto) =>
  prozess ? isValueInRange(value, prozess.validMinValue!, prozess.validMaxValue!) : false;

export const verifyIfValueIsOptimal = (value: number | undefined, prozess: IProzessDto) =>
  prozess ? isValueInRange(value, prozess.optimalMinValue!, prozess.optimalMaxValue!) : false;

export const validateStringValue = (value: string | undefined, prozess: IProzessDto): boolean => {
  if (prozess.validationPattern) {
    // Decode from base64 string.
    const validationPattern = atob(prozess.validationPattern!);
    return !!validationPattern && !!value && new RegExp(validationPattern).test(value);
  }
  // Validate only if validationPattern is required.
  return true;
};
