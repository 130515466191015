import { ISauLifeStageDescriptorDto, ProzessType, SauLifeStageTypeEnum } from "../../api/backend-api-v7";
import { AMMEN_LIFE_STAGE_DAYS } from "../../constants";
import { ILocalSauDto, ILocalSauLifeStageDto } from "../../db/database";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { subtractCountOfDays } from "../../utils/datetime.utils";

// Sau handlers.
import { abferkeldatumProzessEventHandler } from "./handlers/abferkeldatum.handler";
import { absetzdatumProzessEventHandler } from "./handlers/absetzdatum.handler";
import { buchtenProzessEventHandler } from "./handlers/buchten.handler";
import { funktionHistoryHandler } from "./handlers/funktion-history.handler";
import { mumienProzessEventHandler } from "./handlers/mumien.handler";

type Handler = (
  lifeStage: ILocalSauLifeStageDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => void | ILocalSauLifeStageDto;

interface SauLifeStageHandlers {
  funktionHistoryHandler: Handler;
  abferkeldatumProzessEventHandler: Handler;
  absetzdatumProzessEventHandler: Handler;
  mumienProzessEventHandler: Handler;
  buchtenProzessEventHandler: Handler;
}

const sauLifeStageHandlers: SauLifeStageHandlers = {
  funktionHistoryHandler,
  abferkeldatumProzessEventHandler,
  absetzdatumProzessEventHandler,
  mumienProzessEventHandler,
  buchtenProzessEventHandler,
};

class SauService {
  private handlers: ((
    lifeStage: ILocalSauLifeStageDto,
    prozessEvents: IProzessEventsWithAdditionalData[]
  ) => ILocalSauLifeStageDto)[];

  constructor(handlers: SauLifeStageHandlers) {
    this.handlers = Object.values(handlers).map(
      (handler, index) =>
        (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) =>
          handler(lifeStage, prozessEvents, this.handlers[index + 1])
    );
  }

  /* AMMENDATUM prozess type defines the way to create Ammen lifestage for sau. */
  isAmmen(prozessEvents: IProzessEventsWithAdditionalData[]) {
    return prozessEvents.some(pe => pe.prozessType === ProzessType.AMMENDATUM);
  }

  findLifeStageToUpdate(sau: ILocalSauDto, belegNr: number | undefined, ammenObjectId: string | undefined) {
    return sau.lifeStages.find(
      stage =>
        (belegNr && stage.belegNr === belegNr) || (ammenObjectId && stage.ammenObjectId === ammenObjectId)
    );
  }

  applyUpdatedLifeStage(
    sau: ILocalSauDto,
    belegNr: number | undefined,
    ammenObjectId: string | undefined,
    lifeStage: ILocalSauLifeStageDto
  ): ILocalSauDto {
    const updatedStages = sau.lifeStages.map(stage => {
      if (
        (belegNr && stage.belegNr === belegNr) ||
        (ammenObjectId && stage.ammenObjectId === ammenObjectId)
      ) {
        return lifeStage;
      }
      return stage;
    });
    return { ...sau, lifeStages: updatedStages };
  }

  modifySau(
    sau: ILocalSauDto,
    prozessEvents: IProzessEventsWithAdditionalData[],
    belegNr: number | undefined,
    ammenObjectId: string | undefined
  ) {
    const lifeStage = this.findLifeStageToUpdate(sau, belegNr, ammenObjectId!);

    if (lifeStage) {
      const stage = this.handlers[0](lifeStage, prozessEvents);
      return this.applyUpdatedLifeStage(sau, belegNr, ammenObjectId, stage);
    } else {
      return sau;
    }
  }

  modifySauEditMode(
    sau: ILocalSauDto,
    lifeStage: ILocalSauLifeStageDto,
    prozessEvents: IProzessEventsWithAdditionalData[]
  ) {
    const stage = this.handlers[1](lifeStage, prozessEvents);
    return this.applyUpdatedLifeStage(sau, lifeStage.belegNr, lifeStage.ammenObjectId, stage);
  }

  findSauIdentificator(
    prozessEvents: IProzessEventsWithAdditionalData[]
  ): ISauLifeStageDescriptorDto | undefined {
    const prozessEventToIdentify = prozessEvents.find(pe => pe.prozessType === ProzessType.SAU);
    if (prozessEventToIdentify) {
      return prozessEventToIdentify.data as ISauLifeStageDescriptorDto;
    }
    return undefined;
  }
  createAmmen(sau: ILocalSauDto, prozessEvents: IProzessEventsWithAdditionalData[]) {
    const ammenLifeStage = this.createAmmenLifeStage(prozessEvents);
    return { ...sau, lifeStages: [...sau.lifeStages, ammenLifeStage] };
  }

  createAmmenLifeStage(prozessEvents: IProzessEventsWithAdditionalData[]): ILocalSauLifeStageDto {
    const { recordId, funktionId } = prozessEvents[0];
    const ammenDate = prozessEvents.find(pe => pe.prozessType === ProzessType.AMMENDATUM);

    return {
      abferkeldatum: undefined,
      absetzdatum: undefined,
      mumien: undefined,
      belegNr: undefined,
      buchten: [],
      funktionenHistory: [{ recordId, funktionId }],
      ammenObjectId: recordId,
      /* belegdatum formula: ammenDate - 115 days */
      belegdatum: subtractCountOfDays(new Date(ammenDate!.data).getTime(), AMMEN_LIFE_STAGE_DAYS),
      lifeStageType: SauLifeStageTypeEnum.Ammen,
    };
  }
}

export default new SauService(sauLifeStageHandlers);
