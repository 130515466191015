import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { AppBar, Button, Dialog, DialogActions, DialogContent, Tab, Tabs, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./ferkel-grid.styles";
import classnames from "classnames";
import { useFerkelGridState, useFerkelGrid } from "./ferkel-grid.hooks";
import { FerkelGridProps } from "./ferkel-grid.types";
import { SearchField } from "../../search-field/search-field.component";
import { ILocalFerkelDto } from "../../../db/database";
import { findFerkelByQuery } from "../../../utils/ferkel.utils";

export const DISPLAYED_BUTTONS_AMOUNT = 15;

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (selectedFerkel: ILocalFerkelDto | undefined) => void,
  ferkel: ILocalFerkelDto | undefined,
  isEditing: boolean,
  shouldSaveProzess: boolean
) => {
  if (isEditing) {
    // In edit mode we can't change data.
    return;
  } else {
    const isRecordActive = prevRecordId === recordId;
    if (isRecordActive && shouldSaveProzess) {
      save(ferkel);
    }
  }
};

const FerkelGridComponent: FunctionComponent<FerkelGridProps> = props => {
  const {
    prozess,
    recordId,
    onChanged,
    shouldValidate,
    filteredBy,
    prevRecordId,
    funktionId,
    editedValue,
    isManuallyEmpty,
    isEditing,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { init, onProzessDataChanged, save, reset, toggle, state } = useFerkelGridState(prozess, onChanged);
  const { allFerkel, displayedFerkel, filteredFerkel } = useFerkelGrid(props, state, reset);
  const [selectedFerkel, setSelectedFerkel] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<ILocalFerkelDto[]>([]);

  const onCloseDialog = () => {
    setSearchResults([]);
    setSearchTerm("");
    setIsDialogOpen(false);
  };

  const onOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const onSelectionChanged = useCallback(
    (newSelection: ILocalFerkelDto) => {
      if (isEditing) {
        // In edit mode we can't change data.
        return;
      } else {
        toggle(newSelection);
        onCloseDialog();
      }
    },
    [isEditing, toggle]
  );

  const findSelectedFerkel = useCallback(
    async (tierIdent: string) => {
      const ferkelForSelection = displayedFerkel.find(item => item.tierIdent === tierIdent);
      return ferkelForSelection ? ferkelForSelection : await findFerkelByQuery("tierIdent", tierIdent);
    },
    [displayedFerkel]
  );

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.ferkel, isEditing, state.shouldSaveProzess);
  }, [isEditing, prevRecordId, recordId, save, state.ferkel, state.shouldSaveProzess]);

  useEffect(() => {
    init();
  }, [init, prozess, isManuallyEmpty]);

  useEffect(() => {
    if (!isEditing) {
      reset();
    }
  }, [isEditing, recordId, reset, filteredBy]);

  const checkIsFerkelAlreadyUsed = useCallback(
    (value: ILocalFerkelDto) => {
      const ferkelAlreadyUsed =
        !!value &&
        !!value.funktionenHistory &&
        value.funktionenHistory.find(item => item.funktionId === funktionId);
      return !!ferkelAlreadyUsed;
    },
    [funktionId]
  );

  const checkOnDisable = useCallback(
    (value: ILocalFerkelDto) => checkIsFerkelAlreadyUsed(value),
    [checkIsFerkelAlreadyUsed]
  );

  const grid = useCallback(
    (ferkel: ILocalFerkelDto[]) =>
      ferkel.map(
        obj =>
          obj &&
          obj.tierIdent && (
            <Button
              variant="contained"
              size="large"
              key={obj.creationRecordId}
              data-cy="ferkel-button"
              onClick={() => onSelectionChanged(obj)}
              disabled={checkOnDisable(obj)}
              // In edit mode we should display selectedFerkel and add styles for disabled data.
              className={classnames(classes.ferkelBtn, {
                [classes.inactiveFerkel]: isEditing,
                [classes.selectedFerkel]: obj.tierIdent === selectedFerkel,
                [classes.activeButton]: state.ferkel === obj,
              })}
            >
              {obj.tierIdent}
            </Button>
          )
      ),
    [
      checkOnDisable,
      classes.ferkelBtn,
      classes.inactiveFerkel,
      classes.selectedFerkel,
      classes.activeButton,
      isEditing,
      selectedFerkel,
      state.ferkel,
      onSelectionChanged,
    ]
  );

  const filteredGrid = useCallback(
    (ferkel: ILocalFerkelDto[], position: string) =>
      filteredBy ? (
        displayedFerkel.length === 0 ? (
          <div className={classes.message} style={{ gridArea: position }}>
            {t("COMPONENTS.FERKEL_GRID.NO_PIGLETS_AVAILABLE")}
          </div>
        ) : (
          grid(ferkel)
        )
      ) : (
        <div className={classes.message} style={{ gridArea: position }}>
          {t("COMPONENTS.FERKEL_GRID.SELECT_SOW")}
        </div>
      ),
    [classes.message, displayedFerkel.length, filteredBy, grid, t]
  );

  const ferkelGridMemo = useMemo(
    () => filteredGrid(displayedFerkel, "1 / 1 / 2 / 5"),
    [displayedFerkel, filteredGrid]
  );

  const searchedResults = useCallback(
    (result: string) => {
      const searchedFerkel: ILocalFerkelDto[] = [];
      const ferkel = tabValue === 0 ? filteredFerkel : allFerkel;
      ferkel.map(
        item => !!item && !!item.tierIdent && item.tierIdent.includes(result) && searchedFerkel.push(item)
      );
      return searchedFerkel;
    },
    [allFerkel, filteredFerkel, tabValue]
  );

  const checkOnEditMode = useCallback(async () => {
    if (isEditing && editedValue) {
      setSelectedFerkel(editedValue);
      const ferkel = await findSelectedFerkel(editedValue);
      if (ferkel) {
        onProzessDataChanged(ferkel!);
      }
    } else {
      setSelectedFerkel("");
    }
    setSearchTerm(searchTerm);
    setSearchResults(searchedResults(searchTerm));
  }, [isEditing, editedValue, searchTerm, searchedResults, findSelectedFerkel, onProzessDataChanged]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  if (!prozess) {
    return null;
  }

  const handleCloseClick = () => {
    setSearchTerm("");
  };

  return (
    <>
      <div
        style={{ gridArea: prozess.position }}
        className={classnames(classes.root, shouldValidate && state.isError ? classes.error : classes.valid)}
        data-cy="FerkelGridComponent"
      >
        {ferkelGridMemo}

        <Tooltip
          key="more"
          title={t("COMPONENTS.FERKEL_GRID.MORE_DATA") || ""}
          className={classes.showMoreButton}
        >
          <Button data-cy="..." variant="contained" size="large" onClick={onOpenDialog} disabled={isEditing}>
            ...
          </Button>
        </Tooltip>
      </div>
      {isDialogOpen && (
        <Dialog
          open={true}
          fullScreen={true}
          scroll="paper"
          onClose={onCloseDialog}
          data-cy="FerkelGridComponent-Dialog"
        >
          <DialogContent className={classes.dialogContent}>
            <div>
              <AppBar position="static" className={classes.tabAppBar}>
                <Tabs
                  variant="fullWidth"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                  classes={{
                    indicator: classes.tabIndicator,
                  }}
                >
                  <Tab
                    className={classes.tab}
                    label={
                      t("COMPONENTS.FERKEL_GRID.PIGLETS_BY_SOW") +
                      (filteredBy?.label ? ` ${filteredBy.label}` : "")
                    }
                    data-cy="ferkel-buchtsau"
                  />
                  <Tab
                    className={classes.tab}
                    label={t("COMPONENTS.FERKEL_GRID.ALL_PIGLETS")}
                    data-cy="all-ferkel"
                  />
                </Tabs>
              </AppBar>

              {tabValue === 0 && (
                <div className={classes.dialogRoot}>
                  {!!searchResults && !!searchTerm
                    ? grid(searchResults)
                    : filteredGrid(filteredFerkel, "1 / 1 / 2 / 9")}
                </div>
              )}
              {tabValue === 1 && (
                <div className={classes.dialogRoot}>
                  {!!searchResults && !!searchTerm ? grid(searchResults) : grid(allFerkel)}
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <SearchField
              searchTerm={searchTerm}
              handleChange={handleChange}
              handleCloseClick={handleCloseClick}
              dataCy="search-field"
            />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className={classes.largeButton}
              onClick={onCloseDialog}
            >
              {t("COMMON.CLOSE")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FerkelGridComponent;
