import { connect } from "react-redux";
import RecordsPageComponent from "./records-page.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./records-page.types";
import { AppState } from "../../../../store/app.state";
import {
  getLocalCapturedRecordsByFunktionId,
  getRecordsLoadStatus,
  getRemoteCapturedRecordsByFunktionId,
} from "../../../../store/captured-records/captured-records.selectors";
import { Dispatch } from "redux";
import {
  deleteCapturedRecord,
  fetchLocalCapturedRecords,
  startEditCapturedRecord,
  removeNoMoreRemoteCapturedRecordsAvailable,
  fetchRemoteCapturedRecords,
  resendDataExportForCapturedRecord,
} from "../../../../store/captured-records/captured-records.actions";
import {
  getShouldOpenValidationOverviewDialog,
  isEditModeActivated,
} from "../../../../store/common/common.selectors";
import { ISyncedCapturedRecord } from "../../../../db/database";
import {
  setShouldOpenValidationOverviewDialog,
  toggleEditMode,
} from "../../../../store/common/common.actions";
import { getFunktionById } from "../../../../store/funktionen/funktionen.selectors";
import { getCurrentGroupId } from "../../../../store/prozess-events/prozess-events.selectors";
import { IProzessEventsValidationResultDto, ISendDataExportRequestDto } from "../../../../api/backend-api-v7";
import { getDataImportValidationErrors } from "../../../../store/transitory-prozess-data/transitory-prozess-data.selectors";
import { setValidationErrors } from "../../../../store/transitory-prozess-data/transitory-prozess-data.actions";

const mapStateToProps = (state: AppState, props: OwnProps): ConnectedState => {
  const { funktionId } = props;
  return {
    recordsGroupId: getCurrentGroupId(state),
    funktion: getFunktionById(funktionId)(state),
    localRecords: getLocalCapturedRecordsByFunktionId(funktionId)(state),
    remote: getRemoteCapturedRecordsByFunktionId(funktionId)(state),
    loadStatus: getRecordsLoadStatus(state),
    isEditing: isEditModeActivated(state),
    validationErrors: getDataImportValidationErrors(state),
    shouldOpenValidationOverviewDialog: getShouldOpenValidationOverviewDialog(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  fetchAllRecords: (funktionId: number) => dispatch(fetchLocalCapturedRecords(funktionId)),
  fetchRemoteRecords: (funktionId: number, limit: number, type: string) =>
    dispatch(fetchRemoteCapturedRecords(funktionId, limit, type)),
  deleteRecord: (record: ISyncedCapturedRecord | undefined) => dispatch(deleteCapturedRecord(record!)),
  activateEditMode: (record: ISyncedCapturedRecord) => {
    dispatch(toggleEditMode(true));
    dispatch(startEditCapturedRecord(record));
  },
  resetRemoteRecordsAvailableStatus: (funktionId: number) =>
    dispatch(removeNoMoreRemoteCapturedRecordsAvailable(funktionId)),
  resendData: (recordData: ISendDataExportRequestDto) =>
    dispatch(resendDataExportForCapturedRecord(recordData)),
  setShouldOpenValidationOverviewDialog: (shouldOpen: boolean) =>
    dispatch(setShouldOpenValidationOverviewDialog(shouldOpen)),
  setValidationErrors: (validationErrors: IProzessEventsValidationResultDto | undefined) =>
    setValidationErrors(validationErrors),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordsPageComponent);
