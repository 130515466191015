import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalBuchtDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const fuetterungsdatumProzessEventHandler = (
  bucht: ILocalBuchtDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (bucht: ILocalBuchtDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const fuetterungsdatum = prozessEvents.find(pe => pe.prozessType === ProzessType.FUETTERUNGSDATUM);

  if (fuetterungsdatum) {
    const { recordId, funktionId } = prozessEvents[0];

    const updatedBucht = {
      ...bucht,
      funktionenHistory: bucht.funktionenHistory!.map(history => {
        if (history.funktionId === funktionId && history.recordId === recordId) {
          return {
            ...history,
            futter: { ...history.futter, fuetterungsdatum: new Date(fuetterungsdatum.data).getTime() },
          };
        }
        return history;
      }),
    };

    return next(updatedBucht, prozessEvents);
  }

  return next(bucht, prozessEvents);
};
