import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { Action } from "../action";

export enum BuchtenActionType {
  LOAD_BUCHTEN = "LOAD_BUCHTEN",
  LOAD_BUCHTEN_SUCCESS = "LOAD_BUCHTEN_SUCCESS",
  LOAD_BUCHTEN_FAILURE = "LOAD_BUCHTEN_FAILURE",
  UPDATE_LOCAL_BUCHTEN = "UPDATE_LOCAL_BUCHTEN",
  UPDATE_BUCHT_DATA = "UPDATE_BUCHT_DATA",
  UPDATE_BUCHT_DATA_EDIT_MODE = "UPDATE_BUCHT_DATA_EDIT_MODE",
  SET_LAST_FETCHED_DATE = "SET_LAST_FETCHED_DATE",
  SHOULD_UPDATE_BUCHTEN_TABLE = "SHOULD_UPDATE_BUCHTEN_TABLE",
}

export const loadBuchten = (): Action<null> => ({
  type: BuchtenActionType.LOAD_BUCHTEN,
  payload: null,
});

export const loadBuchtenSuccess = (): Action<null> => ({
  type: BuchtenActionType.LOAD_BUCHTEN_SUCCESS,
  payload: null,
});

export const loadBuchtenFailure = (errorMessage: string): Action<string> => ({
  type: BuchtenActionType.LOAD_BUCHTEN_FAILURE,
  payload: errorMessage,
});

export const updateBuchten = (): Action<null> => ({
  type: BuchtenActionType.UPDATE_LOCAL_BUCHTEN,
  payload: null,
});

export const updateBuchtData = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): Action<IProzessEventsWithAdditionalData[]> => ({
  type: BuchtenActionType.UPDATE_BUCHT_DATA,
  payload: prozessEvents,
});

export const updateBuchtDataEditMode = (
  prozessEvents: IProzessEventsWithAdditionalData[]
): Action<IProzessEventsWithAdditionalData[]> => ({
  type: BuchtenActionType.UPDATE_BUCHT_DATA_EDIT_MODE,
  payload: prozessEvents,
});

export const setLastFetchedDate = (): Action<null> => ({
  type: BuchtenActionType.SET_LAST_FETCHED_DATE,
  payload: null,
});

export const updateBuchtenTable = (shouldUpdate: boolean): Action<boolean> => ({
  type: BuchtenActionType.SHOULD_UPDATE_BUCHTEN_TABLE,
  payload: shouldUpdate,
});
