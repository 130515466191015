import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: "scroll",
      width: "100%",
      height: "100%",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
    },
    table: {
      borderCollapse: "separate",
    },
    scrollableHeader: {
      left: "unset",
    },
    cell: {
      background: "white",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
    },
    fixedLeftCell: {
      position: "sticky",
      left: 0,
      zIndex: 10,
    },
    fixedHeaderCell: {
      position: "sticky",
      left: 0,
      zIndex: 11,
    },
    coloredCell: {
      background: "#e1e1e1",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "fixed",
      top: 0,
    },
    progress: {
      margin: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
  })
);

export default useStyles;
