import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

export const tierIdentProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const tierIdent = prozessEvents.find(pe => pe.prozessType === ProzessType.TIER_IDENT);

  if (tierIdent) {
    const updatedFerkel = { ...ferkel, tierIdent: tierIdent.data };
    return next(updatedFerkel, prozessEvents);
  }
  return next(ferkel, prozessEvents);
};
