import {
  GruppenerfassungAction,
  GruppenerfassungActionType,
  GruppenerfassungState,
} from "./gruppenerfassung.types";

import logger from "../../../logger";

const logInfo = logger.info("gruppenerfassung.reducer");

export const initialState: GruppenerfassungState = {
  transponders: [],
  isError: false,
  isValid: false,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const gruppenerfassungReducer = (
  state: GruppenerfassungState,
  action: GruppenerfassungAction
): GruppenerfassungState => {
  const {
    type,
    payload: { value, isRequired, shouldKeepValue, isValid },
  } = action;

  switch (type) {
    case GruppenerfassungActionType.INIT: {
      logInfo("GRUPPENERFASSUNG INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }

    case GruppenerfassungActionType.ADD_PROZESS_DATA: {
      if (!!value && !Array.isArray(value)) {
        return {
          ...state,
          transponders: [...state.transponders, value],
          isError: isError(!!isValid, isRequired),
          isValid: !!isValid,
        };
      }
      if (!!value && Array.isArray(value)) {
        return {
          ...state,
          transponders: value,
          isError: isError(!!isValid, isRequired),
          isValid: !!isValid,
        };
      }
      return state;
    }

    case GruppenerfassungActionType.DELETE_PROZESS_DATA: {
      return {
        ...state,
        transponders: state.transponders.filter(item => item.transponder !== value!.transponder),
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }

    case GruppenerfassungActionType.CONFIRM_CREATE_PROZESS: {
      return {
        ...state,
        transponders: state.transponders.map(item => {
          if (item.transponder === value!.transponder) {
            return { ...item, isProzessCreated: true };
          }
          return item;
        }),
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }

    case GruppenerfassungActionType.RESET: {
      logInfo("GRUPPENERFASSUNG RESET");
      const transponders = shouldKeepValue ? state.transponders : [];
      return { ...initialState, transponders, isError: isError(!!isValid, isRequired) };
    }
  }

  return state;
};
