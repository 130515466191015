import { Action } from "../action";

export enum I18NActionType {
  INIT_TRANSLATIONS = "INIT_TRANSLATIONS",
  INIT_TRANSLATIONS_SUCCESS = "INIT_TRANSLATIONS_SUCCESS",
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
}

export const initTranslations = (): Action<null> => ({
  type: I18NActionType.INIT_TRANSLATIONS,
  payload: null,
});

export const initTranslationsSuccess = (): Action<null> => ({
  type: I18NActionType.INIT_TRANSLATIONS_SUCCESS,
  payload: null,
});

export const changeLanguage = (language: string): Action<string> => ({
  type: I18NActionType.CHANGE_LANGUAGE,
  payload: language,
});
