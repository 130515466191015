import { FunctionComponent } from "react";

import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      maxWidth: 800,
    },
    dialogActions: {},
    formControl: {
      marginLeft: 0,
    },
    button: {
      width: 150,
      margin: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

interface Props {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  title?: string;
  message: string;
}

const ConfirmationDialogComponent: FunctionComponent<Props> = props => {
  const { open, onClose, title, message } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = (confirmed: boolean) => {
    onClose(confirmed);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      fullWidth={true}
      PaperProps={{ className: classes.dialog }}
      data-cy="confirmation-dialog"
    >
      <DialogTitle>{title ? title : ""}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <span>
          <Button
            onClick={() => handleClose(true)}
            className={classes.button}
            variant="contained"
            color="primary"
            data-cy="ja"
          >
            {t("COMMON.JA")}
          </Button>
          <Button
            onClick={() => handleClose(false)}
            className={classes.button}
            variant="contained"
            color="primary"
            data-cy="nein"
          >
            {t("COMMON.NEIN")}
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogComponent;
