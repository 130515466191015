import { AppState } from "../app.state";
import { TransponderForDeletion } from "./transitory-prozess-data.actions";

export const getTranspondersForDeletion = (state: AppState): TransponderForDeletion[] =>
  state.transitoryProzessData.transpondersForDeletion;

export const getFerkelInfo = (state: AppState) => state.transitoryProzessData.ferkelInfo;

export const getExportedData = (state: AppState) => state.transitoryProzessData.dataExport;

export const getDataImportValidationErrors = (state: AppState) =>
  state.transitoryProzessData.dataImportValidationErrors;
