import { FunctionComponent, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModalWindow from "../modal-windows/confirmation-dialog/confirmation-dialog.component";

import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import { checkInternetConnection } from "../../utils/network-status";
import useStyles from "./menu-item.styles";

export interface ConnectedState {
  isDataExist: boolean;
}

export interface ConnectedDispatch {
  resetBluetoothData: () => void;
  showNetworkError: () => void;
}

interface OwnProps extends ConnectedState, ConnectedDispatch {
  path: string;
  label: string;
  imgSrc?: string;
  icon?: ReactElement;
  color: string;
  locationPathname: string;
  isNetworkRequired?: boolean;
}

const MenuItemComponent: FunctionComponent<OwnProps> = props => {
  const {
    path,
    label,
    imgSrc,
    icon,
    locationPathname,
    color,
    isDataExist,
    resetBluetoothData,
    isNetworkRequired,
    showNetworkError,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onItemClickHandler = () => {
    if (isNetworkRequired) {
      const isOnline = checkInternetConnection();
      if (!isOnline) {
        showNetworkError();
        return;
      }
    }

    if (isDataExist) {
      setIsDialogOpen(true);
    } else {
      navigate(path);
    }
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const onConfirmationHandler = () => {
    navigate(path);
    setIsDialogOpen(false);
    resetBluetoothData();
  };

  return (
    <>
      <MenuItem
        onClick={onItemClickHandler}
        selected={locationPathname === path}
        className={classes.menuItem}
        style={{ borderRightColor: `${color}` }}
        data-cy="menu-item"
      >
        <Tooltip title={label || ""}>
          <ListItemIcon>
            {icon ? icon : <img className={classes.image} src={imgSrc} alt="Menü-Eintrag" />}
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={label} />
      </MenuItem>
      {isDialogOpen && (
        <ConfirmationModalWindow
          open={isDialogOpen}
          closeModal={onCloseDialog}
          acceptHandler={onConfirmationHandler}
          acceptButtonTitle="FUNKTION VERLASSEN"
          acceptButtonStyles={classes.confirmButton}
          modalSize="800px"
        >
          Es befinden sich ungespeicherte Daten in der Eingabemaske. Wenn Sie die Funktion verlassen, gehen
          diese Daten verloren.
        </ConfirmationModalWindow>
      )}
    </>
  );
};

export default MenuItemComponent;
