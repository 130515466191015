import { Action } from "../action";

export enum NotificationsActionsType {
  SHOW_SUCCESSFUL_REQUEST = "SHOW_SUCCESSFUL_REQUEST",
  SHOW_FAILED_REQUEST = "SHOW_FAILED_REQUEST",
  SHOW_WARNING_REQUEST = "SHOW_WARNING_REQUEST",
}

export interface NotificationMessage {
  message: string;
  variant: "default" | "error" | "success" | "warning" | "info";
}

export const showSuccessfulRequest = (message: string): Action<NotificationMessage> => ({
  type: NotificationsActionsType.SHOW_SUCCESSFUL_REQUEST,
  payload: {
    message,
    variant: "success",
  },
});

export const showFailedRequest = (message?: any): Action<NotificationMessage> => ({
  type: NotificationsActionsType.SHOW_FAILED_REQUEST,
  payload: {
    message: message ? message : "Es kam zu einem Fehler!",
    variant: "error",
  },
});

export const showWarningRequest = (message: string): Action<NotificationMessage> => ({
  type: NotificationsActionsType.SHOW_WARNING_REQUEST,
  payload: {
    message,
    variant: "warning",
  },
});
