import logger from "../../../logger";
import { GeschlechtAction, GeschlechtActionType, GeschlechtState } from "./geschlecht.types";

const logInfo = logger.info("geschlecht.reducer");

export const initialState: GeschlechtState = {
  geschlecht: "",
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (geschlecht: string, isRequired?: boolean) => !!(geschlecht ? false : isRequired);

export const geschlechtReducer = (state: GeschlechtState, action: GeschlechtAction): GeschlechtState => {
  const {
    type,
    payload: { value, isRequired, shouldKeepValue, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case GeschlechtActionType.INIT: {
      logInfo("GESCHLECHT INIT");
      return { ...initialState, isError: isError(state.geschlecht, isRequired) };
    }
    case GeschlechtActionType.PROZESS_DATA_CHANGED: {
      const geschlecht = value ? (value as string) : "";
      if (state.geschlecht === geschlecht && state.isError === isError(geschlecht, isRequired)) {
        return state;
      }

      return {
        ...state,
        geschlecht,
        isError: isError(geschlecht, isRequired),
        isValid: !!isValid,
      };
    }
    case GeschlechtActionType.TOGGLE: {
      const geschlecht = state.geschlecht === value ? "" : value ? (value as string) : "";

      return {
        ...state,
        geschlecht,
        isError: isError(geschlecht, isRequired),
        isValid: !!isValid,
      };
    }
    case GeschlechtActionType.RESET: {
      logInfo("GESCHLECHT RESET");
      const geschlecht = shouldKeepValue ? state.geschlecht : "";
      return { ...initialState, geschlecht, isError: isError(state.geschlecht, isRequired) };
    }
    case GeschlechtActionType.PROZESS_DATA_EDITED: {
      return { ...initialState, isProzessEdited };
    }
  }

  return state;
};
