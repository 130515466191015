import { IUserDto } from "../../api/backend-api-v7";
import { AuthenticationActionType } from "./authentication.actions";
import createReducer from "../createReducer";
import { Action } from "../action";

export interface AuthenticationState {
  loggedInUser: string | undefined;
  token: string | undefined;
  deviceId: string | undefined;
  userId: string | undefined;
  users: IUserDto[] | undefined;
}

export const initialState: AuthenticationState = {
  loggedInUser: undefined,
  token: undefined,
  deviceId: undefined,
  userId: undefined,
  users: [],
};

export default createReducer(initialState, {
  [AuthenticationActionType.AUTH_USER_SUCCESS](
    state: AuthenticationState,
    action: Action<{ loggedInUser: string; token: string; userId: string }>
  ): AuthenticationState {
    const { loggedInUser, token, userId } = action.payload;
    return {
      ...state,
      loggedInUser,
      token,
      userId,
    };
  },
  [AuthenticationActionType.AUTH_USER_FAILURE](
    state: AuthenticationState,
    action: Action<null>
  ): AuthenticationState {
    return {
      ...state,
      token: undefined,
    };
  },
  [AuthenticationActionType.SET_DEVICE_ID](
    state: AuthenticationState,
    action: Action<string>
  ): AuthenticationState {
    return {
      ...state,
      deviceId: action.payload,
    };
  },
  [AuthenticationActionType.UNREGISTER_DEVICE](
    state: AuthenticationState,
    action: Action<null>
  ): AuthenticationState {
    return initialState;
  },
  [AuthenticationActionType.SET_TOKEN](
    state: AuthenticationState,
    action: Action<string>
  ): AuthenticationState {
    return {
      ...state,
      token: action.payload,
    };
  },
  [AuthenticationActionType.SET_LOGGED_IN_USER](
    state: AuthenticationState,
    action: Action<{ loggedInUser: string; userId: string }>
  ): AuthenticationState {
    const { loggedInUser, userId } = action.payload;
    return {
      ...state,
      loggedInUser,
      userId,
    };
  },
  [AuthenticationActionType.SET_USERS](
    state: AuthenticationState,
    action: Action<IUserDto[]>
  ): AuthenticationState {
    return {
      ...state,
      users: action.payload,
    };
  },
  [AuthenticationActionType.LOGOUT](state: AuthenticationState, action: Action<null>): AuthenticationState {
    return {
      ...state,
      token: undefined,
      loggedInUser: undefined,
    };
  },
});
