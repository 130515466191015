import FerkelAbgesetztComponent from "./ferkel-abgesetzt.component";
import { OwnProps, ConnectedState } from "./ferkel-abgesetzt.types";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getFilteredByWorkflowId } from "../../../store/prozess-events/prozess-events.selectors";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    filteredBy: getFilteredByWorkflowId(workflowId)(state),
    ferkel: getFilteredFerkel(state),
  };
};

export default connect(mapStateToProps)(FerkelAbgesetztComponent);
