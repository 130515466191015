import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Button } from "@mui/material";

interface Props {
  typeOfValue: string;
  setGeneratedValue: Dispatch<SetStateAction<string>>;
}

export enum TypeOfValues {
  TRANSPONDER = "transponder",
  TIERIDENT = "tierIdent",
}

const RandomValueGeneratorComponent: FunctionComponent<Props> = ({ typeOfValue, setGeneratedValue }) => {
  const onClickHandler = () => {
    let randomValue: string;
    if (typeOfValue === TypeOfValues.TRANSPONDER) {
      randomValue = `${Math.floor(Math.random() * (999 - 100) + 100).toString()}${Math.floor(
        Math.random() * (999999999999 - 100000000000) + 100000000000
      ).toString()}`;
      setGeneratedValue(randomValue);
    } else {
      randomValue = `${Math.floor(Math.random() * (99 - 10) + 10).toString()}${Math.floor(
        Math.random() * (9999 - 1000) + 1000
      ).toString()}`;
    }

    setGeneratedValue(randomValue);
  };

  return (
    <Button variant="contained" color="secondary" onClick={onClickHandler}>
      RANDOM
    </Button>
  );
};

export default RandomValueGeneratorComponent;
