import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getIsForceAppUpdate } from "../../../store/common/common.selectors";

import ForceUpdateComponent from "./force-update.component";
import { ConnectedState } from "./force-update.types";

const mapStateToProps = (state: AppState): ConnectedState => ({
  open: getIsForceAppUpdate(state),
});

export default connect(mapStateToProps)(ForceUpdateComponent);
