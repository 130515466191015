import ProzessEventsCounterComponent from "./prozess-events-counter.component";
import { ConnectedState } from "./prozess-events-counter.types";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { getCountOfMultipleProzessEvents } from "../../../store/common/common.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  countOfProzessEvents: getCountOfMultipleProzessEvents(state),
});

export default connect(mapStateToProps)(ProzessEventsCounterComponent);
