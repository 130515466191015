import { CssBaseline, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FunctionComponent } from "react";

import { SnackbarProvider } from "notistack";
import { Colors } from "./theme/colors";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      fontSize: "14px!important",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: "520px",
    },
    snackbarSuccess: {
      backgroundColor: `${Colors.AmpelGruen}!important`,
    },
    snackbarError: {
      backgroundColor: Colors.AmpelRot,
    },
    snackbarWarning: {
      backgroundColor: `${Colors.AmpelGelb}!important`,
    },
    snackbarInfo: {
      backgroundColor: Colors.SchmuckfarbeCyan,
    },
  })
);

function withRoot(Component: FunctionComponent<any>) {
  function WithRoot(props: object) {
    const classes = useStyles();
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          variantSuccess: classNames(classes.snackbar, classes.snackbarSuccess),
          variantError: classNames(classes.snackbar, classes.snackbarError),
          variantWarning: classNames(classes.snackbar, classes.snackbarWarning),
          variantInfo: classNames(classes.snackbar, classes.snackbarInfo),
        }}
        hideIconVariant={true}
      >
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </SnackbarProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
