/**
 * Bluetooth Terminal to connect with different IQ-hubs.
 */
export class BluetoothTerminal {
  /** Buffer containing not separated data. */
  private _receiveBuffer = "";
  /** Receive separator with length equal to one character. */
  private _receiveSeparator = "\n";
  /** Send separator with length equal to one character. */
  private _sendSeparator = "\n";
  /** Max characteristic value length. */
  private _maxCharacteristicValueLength = 20;
  /** Device object cache. */
  private _device: BluetoothDevice | null = null;
  /** Characteristic object cache. */
  private _characteristic: BluetoothRemoteGATTCharacteristic | null = null;
  private _onConnected: Function | undefined;
  private _onDisconnected: Function | undefined;

  private _boundHandleDisconnection;
  private _boundHandleCharacteristicValueChanged;

  // eslint-disable-next-line prettier/prettier
  private _oldServiceUuid = 0xFFE0;
  private _newServiceUuid = "49535343-fe7d-4ae5-8fa9-9fafd205e455";

  // eslint-disable-next-line prettier/prettier
  private _oldCharacteristicUuid = 0xFFE1;
  private _newCharacteristicUuid = "49535343-1e4d-4bd9-ba61-23c647249616";

  /**
   * Create preconfigured Bluetooth Terminal instance.
   */
  constructor(receiveSeparator = "\n", sendSeparator = "\n") {
    // Bound functions used to add and remove appropriate event handlers.
    this._boundHandleDisconnection = this._handleDisconnection.bind(this);
    this._boundHandleCharacteristicValueChanged = this._handleCharacteristicValueChanged.bind(this);

    // Configure with specified parameters.
    this.setReceiveSeparator(receiveSeparator);
    this.setSendSeparator(sendSeparator);
  }

  /**
   * Set character representing separator for data coming from the connected device, end of line for example.
   */
  setReceiveSeparator(separator: string) {
    if (!(typeof separator === "string")) {
      throw new Error("Separator type is not a string");
    }

    if (separator.length !== 1) {
      throw new Error("Separator length must be equal to one character");
    }

    this._receiveSeparator = separator;
  }

  /**
   * Set string representing separator for data coming to the connected device, end of line for example.
   */
  setSendSeparator(separator: string) {
    if (!(typeof separator === "string")) {
      throw new Error("Separator type is not a string");
    }

    if (separator.length !== 1) {
      throw new Error("Separator length must be equal to one character");
    }

    this._sendSeparator = separator;
  }

  /**
   * Set a listener to be called after a device is connected.
   */
  public setOnConnected(listener: Function) {
    this._onConnected = listener;
  }

  /**
   * Set a listener to be called after a device is disconnected.
   */
  public setOnDisconnected(listener: Function) {
    this._onDisconnected = listener;
  }

  /**
   * Launch Bluetooth device chooser and connect to the selected device.
   * Return promise which will be fulfilled when notifications will be started or rejected if something went wrong
   */
  public connect() {
    return this._connectToDevice(this._device).then(() => {
      if (this._onConnected) {
        this._onConnected();
      }
    });
  }

  /**
   * Disconnect from the connected device.
   */
  disconnect() {
    this._disconnectFromDevice(this._device);

    if (this._characteristic) {
      this._characteristic.removeEventListener(
        "characteristicvaluechanged",
        this._boundHandleCharacteristicValueChanged
      );
      this._characteristic = null;
    }

    this._device = null;

    if (this._onDisconnected) {
      this._onDisconnected();
    }
  }

  /**
   * Data receiving handler which called whenever the new data comes from
   * the connected device, override it to handle incoming data.
   */
  receive(data: string) {
    // Handle incoming data.
  }

  /**
   * Send data to the connected device.
   * Return promise which will be fulfilled when data will be sent or rejected if something went wrong
   */
  send(data: string) {
    // Convert data to the string using global object.
    data = String(data || "");

    // Return rejected promise immediately if data is empty.
    if (!data) {
      return Promise.reject(new Error("Data must be not empty"));
    }

    data += this._sendSeparator;

    // Split data to chunks by max characteristic value length.
    const chunks = BluetoothTerminal._splitByLength(data, this._maxCharacteristicValueLength);

    // Return rejected promise immediately if there is no connected device.
    if (!this._characteristic) {
      return Promise.reject(new Error("There is no connected device"));
    }

    // Write first chunk to the characteristic immediately.
    let promise = this._writeToCharacteristic(this._characteristic, chunks[0]);

    // Iterate over chunks if there are more than one of it.
    for (let i = 1; i < chunks.length; i++) {
      // Chain new promise.
      promise = promise.then(
        () =>
          new Promise((resolve, reject) => {
            // Reject promise if the device has been disconnected.
            if (!this._characteristic) {
              reject(new Error("Device has been disconnected"));
            }

            // Write chunk to the characteristic and resolve the promise.
            this._writeToCharacteristic(this._characteristic!, chunks[i]).then(resolve).catch(reject);
          })
      );
    }

    return promise;
  }

  /**
   * Get the connected device name.
   * Return device name or empty string if not connected
   */
  getDeviceName() {
    if (!this._device) {
      return "";
    }

    return this._device.name;
  }

  /**
   * Connect to device.
   */
  private _connectToDevice(device: BluetoothDevice | null) {
    return (device ? Promise.resolve(device) : this._requestBluetoothDevice())
      .then(device => this._connectDeviceAndCacheCharacteristic(device))
      .then(characteristic => this._startNotifications(characteristic))
      .catch(error => {
        this._log(error);
        return Promise.reject(error);
      });
  }

  /**
   * Disconnect from device.
   */
  private _disconnectFromDevice(device: BluetoothDevice | null) {
    if (!device) {
      return;
    }

    this._log('Disconnecting from "' + device.name + '" bluetooth device...');

    device.removeEventListener("gattserverdisconnected", this._boundHandleDisconnection);

    if (!device.gatt?.connected) {
      this._log('"' + device.name + '" bluetooth device is already disconnected');
      return;
    }

    device.gatt.disconnect();

    this._log('"' + device.name + '" bluetooth device disconnected');
  }

  /**
   * Request bluetooth device.
   */
  private _requestBluetoothDevice() {
    this._log("Requesting bluetooth device...");

    return navigator.bluetooth
      .requestDevice({
        acceptAllDevices: true,
        optionalServices: [this._oldServiceUuid, this._newServiceUuid],
      })
      .then((device: BluetoothDevice) => {
        this._log('"' + device.name + '" bluetooth device selected');

        this._device = device; // Remember device.
        this._device.addEventListener("gattserverdisconnected", this._boundHandleDisconnection);

        return this._device;
      });
  }

  /**
   * Connect device and cache characteristic.
   */
  private async _connectDeviceAndCacheCharacteristic(device: BluetoothDevice) {
    // Check remembered characteristic.
    if (device.gatt?.connected && this._characteristic) {
      return Promise.resolve(this._characteristic);
    }

    this._log("Connecting to GATT server...");

    try {
      // Connect to old device
      const remoteGattServer = await device.gatt!.connect();
      const service = await remoteGattServer.getPrimaryService(this._oldServiceUuid);
      const characteristics = await service.getCharacteristic(this._oldCharacteristicUuid);
      this._log("Characteristic found");
      this._characteristic = characteristics; // Remember characteristic.
    } catch {
      // Connect to new device
      const remoteGattServer = await device.gatt!.connect();
      const service = await remoteGattServer.getPrimaryService(this._newServiceUuid);
      const characteristics = await service.getCharacteristic(this._newCharacteristicUuid);
      this._log("Characteristic found");
      this._characteristic = characteristics; // Remember characteristic.
    }

    return this._characteristic!;
  }

  /**
   * Start notifications.
   */
  private _startNotifications(characteristic: BluetoothRemoteGATTCharacteristic) {
    this._log("Starting notifications...");

    return characteristic.startNotifications().then(() => {
      this._log("Notifications started");

      characteristic.addEventListener(
        "characteristicvaluechanged",
        this._boundHandleCharacteristicValueChanged
      );
    });
  }

  /**
   * Stop notifications.
   */
  // private _stopNotifications(characteristic: BluetoothRemoteGATTCharacteristic) {
  //   this._log("Stopping notifications...");

  //   return characteristic.stopNotifications().then(() => {
  //     this._log("Notifications stopped");

  //     characteristic.removeEventListener(
  //       "characteristicvaluechanged",
  //       this._boundHandleCharacteristicValueChanged
  //     );
  //   });
  // }

  /**
   * Handle disconnection.
   */
  private _handleDisconnection(event: Event) {
    const device = event.target as BluetoothDevice;

    this._log('"' + device.name + '" bluetooth device disconnected, trying to reconnect...');

    if (this._onDisconnected) {
      this._onDisconnected();
    }

    this._connectDeviceAndCacheCharacteristic(device)
      .then(characteristic => this._startNotifications(characteristic))
      .then(() => {
        if (this._onConnected) {
          this._onConnected();
        }
      })
      .catch(error => this._log(error));
  }

  /**
   * Handle characteristic value changed.
   */
  private _handleCharacteristicValueChanged(event: Event) {
    const value = new TextDecoder().decode((event.target as BluetoothRemoteGATTCharacteristic).value);

    for (const c of value) {
      if (c === this._receiveSeparator) {
        const data = this._receiveBuffer.trim();
        this._receiveBuffer = "";

        if (data) {
          this.receive(data);
        }
      } else {
        this._receiveBuffer += c;
      }
    }
  }

  /**
   * Write to characteristic.
   */
  private _writeToCharacteristic(characteristic: BluetoothRemoteGATTCharacteristic, data: string) {
    return characteristic.writeValue(new TextEncoder().encode(data));
  }

  /**
   * Log.
   */
  private _log(...messages: string[]) {
    console.log(...messages); // eslint-disable-line no-console
  }

  /**
   * Split by length.
   */
  private static _splitByLength(string: string, length: number) {
    return string.match(new RegExp("(.|[\r\n]){1," + length + "}", "g"))!;
  }
}
