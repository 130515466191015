import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: "0.7em",
    },
    card: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "max-content",
      marginBottom: "1em",
      paddingTop: "1em",
    },
    cardContent: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      maxHeight: "85vh",
      overflowY: "scroll",
    },
    center: {
      width: "100%",
      textAlign: "center",
    },
  })
);

export default useStyles;
