import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import FerkelGridComponent from "./ferkel-grid.component";
import { Dispatch } from "redux";
import {
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./ferkel-grid.types";
import {
  getCurrentRecordId,
  getFilteredByWorkflowId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";
import { getIsManuallyEmpty, isEditModeActivated } from "../../../store/common/common.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    filteredBy: getFilteredByWorkflowId(workflowId)(state),
    prevRecordId: getCurrentRecordId(state),
    ferkel: getFilteredFerkel(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FerkelGridComponent);
