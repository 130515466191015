import { FunctionComponent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { NotificationMessage } from "../../store/notifications/notifications.actions";
import { useSnackbar } from "notistack";
import { Button, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      maxWidth: "350px",
      fontSize: "14px",
    },
    btn: {
      fontSize: "16px",
      color: "white",
      backgroundColor: theme.palette.secondary.main,
      border: "1px solid white",
      padding: "4px 16px",
      "&:first-child": {
        marginRight: "1em",
      },
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    grayBtn: {
      backgroundColor: "rgb(123 124 126)",
      "&:hover": {
        backgroundColor: "rgb(123 124 126)",
      },
    },
    link: {
      color: "white",
      textDecoration: "underline",
    },
  })
);

const THREE_HOURS = 10800000;

enum SnackbarKey {
  APP_UPDATE = "appUpdateSnackbar",
  APP_DEPRECATED = "appDeprecatedSnackbar",
  DATA_IMPORT = "dataImportSnackbar",
}

export interface ConnectedState {
  notification: NotificationMessage | undefined;
  isAppUpdateAvailable: boolean | undefined;
  isAppDeprecated: boolean;
  isAppNeedToSync: boolean;
  isAppNeedForceUpdate: boolean;
  shouldOpenDataImportSnackbar: boolean;
}

export interface ConnectedDispatch {
  openValidationOverviewDialog: () => void;
  toggleDataImportSnackbar: (isOpen: boolean) => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const NotificationComponent: FunctionComponent<Props> = props => {
  const {
    notification,
    isAppUpdateAvailable,
    isAppDeprecated,
    isAppNeedToSync,
    isAppNeedForceUpdate,
    shouldOpenDataImportSnackbar,
    openValidationOverviewDialog,
    toggleDataImportSnackbar,
  } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleLinkClick = useCallback(() => {
    openValidationOverviewDialog();
    toggleDataImportSnackbar(false);
    closeSnackbar(SnackbarKey.DATA_IMPORT);
  }, [closeSnackbar, openValidationOverviewDialog, toggleDataImportSnackbar]);

  const createSnackbar = useCallback(
    (key: string, message: string) => {
      const action = () => (
        <>
          <Button
            className={classNames(classes.btn, { [classes.grayBtn]: key === SnackbarKey.APP_DEPRECATED })}
            onClick={() => closeSnackbar(key)}
          >
            {t("COMMON.LATER")}
          </Button>
          <Button className={classes.btn} onClick={() => window.location.reload()}>
            {key === SnackbarKey.APP_UPDATE ? t("COMMON.RELOAD") : "Update"}
          </Button>
        </>
      );

      enqueueSnackbar(<div className={classes.text}>{t(message)}</div>, {
        variant: "info",
        preventDuplicate: true,
        persist: true,
        key,
        action,
        TransitionProps:
          key === SnackbarKey.APP_DEPRECATED ? { style: { backgroundColor: "rgb(200, 5, 49)" } } : undefined,
      });
    },
    [classes.btn, classes.grayBtn, classes.text, closeSnackbar, enqueueSnackbar, t]
  );

  const createDataImportSnackbar = useCallback(() => {
    const action = () => (
      <p className={classes.link} onClick={handleLinkClick}>
        {t("COMMON.VIEW_DETAILS")}
      </p>
    );

    enqueueSnackbar(<div className={classes.text}>{t("NOTIFICATIONS.FAILED_TO_IMPORT_DATA")}</div>, {
      variant: "error",
      autoHideDuration: 5000,
      preventDuplicate: true,
      key: SnackbarKey.DATA_IMPORT,
      action,
      onClose: () => toggleDataImportSnackbar(false),
      TransitionProps: { style: { zIndex: 2 } },
    });
  }, [classes.link, classes.text, enqueueSnackbar, handleLinkClick, t, toggleDataImportSnackbar]);

  useEffect(() => {
    if (notification && notification.message !== "") {
      enqueueSnackbar(notification.message, {
        variant: notification.variant,
        autoHideDuration: 2000,
        preventDuplicate: true,
        TransitionProps: { style: { zIndex: 2 } },
      });
    }
  }, [enqueueSnackbar, notification]);

  useEffect(() => {
    if (isAppUpdateAvailable && !isAppNeedToSync && !isAppNeedForceUpdate) {
      createSnackbar(SnackbarKey.APP_UPDATE, "NOTIFICATIONS.UPDATE_APP");

      const appUpdateIntervall = setInterval(() => {
        createSnackbar(SnackbarKey.APP_UPDATE, "NOTIFICATIONS.UPDATE_APP");
      }, THREE_HOURS);
      return () => clearInterval(appUpdateIntervall);
    }
  }, [createSnackbar, isAppNeedForceUpdate, isAppNeedToSync, isAppUpdateAvailable]);

  useEffect(() => {
    if (isAppDeprecated && !isAppNeedToSync && !isAppNeedForceUpdate) {
      createSnackbar(SnackbarKey.APP_DEPRECATED, "NOTIFICATIONS.APP_DEPRECATED");

      const appDeprecatedIntervall = setInterval(() => {
        createSnackbar(SnackbarKey.APP_DEPRECATED, "NOTIFICATIONS.APP_DEPRECATED");
      }, THREE_HOURS);
      return () => clearInterval(appDeprecatedIntervall);
    }
  }, [createSnackbar, isAppDeprecated, isAppNeedForceUpdate, isAppNeedToSync]);

  useEffect(() => {
    if (shouldOpenDataImportSnackbar) {
      createDataImportSnackbar();
    }
  }, [createDataImportSnackbar, shouldOpenDataImportSnackbar]);

  return null;
};

export default NotificationComponent;
