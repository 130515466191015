import CheckboxComponent from "./checkbox.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./checkbox.types";
import { connect } from "react-redux";
import { AppState } from "../../../store/app.state";
import { Dispatch } from "redux";
import {
  getCurrentRecordId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";
import {
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { getProzessById } from "../../../store/funktionen/funktionen.selectors";
import { getStableGewicht } from "../../../store/bluetooth/bluetooth.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;
  const listeningWorkflowId = ownProps.prozess.shouldListenForChangesOnWorkflowId;
  const funktionId = ownProps.funktionId;

  return {
    prevRecordId: getCurrentRecordId(state),
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
    listeningProzess: listeningWorkflowId
      ? getProzessById(funktionId, listeningWorkflowId)(state)
      : undefined,
    stableGewicht: getStableGewicht(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxComponent);
