import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: 20,
      marginRight: "15px",
    },
    menuItem: {
      height: "auto",
      borderRight: `${theme.spacing(1.25)} solid ${theme.palette.primary.light}`,
    },
    confirmButton: {
      width: "340px",
    },
    link: {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  })
);

export default useStyles;
