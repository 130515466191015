import { combineReducers } from "redux";

import bluetoothReducer from "./bluetooth/bluetooth.reducer";
import commonReducer from "./common/common.reducer";
import funktionenReducer from "./funktionen/funktionen.reducer";
import userReducer from "./user/user.reducer";
import capturedRecordsReducer from "./captured-records/captured-records.reducer";
import authenticationReducer from "./authentication/authentication.reducer";
import i18nReducer from "./i18n/i18n.reducer";
import notificationReducer from "./notifications/notifications.reducer";
import prozessEventsReducer from "./prozess-events/prozess-events.reducer";
import ferkelReducer from "./ferkel/ferkel.reducer";
import wurfuebersichtReducer from "./wurfuebersicht/wurfuebersicht.reducer";
import bluetoothDataSimulationReducer from "./bluetooth/bluetooth-data-simulation.reducer";
import sauenReducer from "./sauen/sauen.reducer";
import transitoryProzessDataReducer from "./transitory-prozess-data/transitory-prozess-data.reducer";
import synchronizationReducer from "./synchronization/synchronization.reducer";
import buchtenReducer from "./buchten/buchten.reducer";
import planFerkelReducer from "./plan-ferkel/plan-ferkel.reducer";

import prozessEventsSaga from "./prozess-events/prozess-events.saga";
import bluetoothConnectionSaga from "./bluetooth/bluetooth-connection.saga";
import userSaga from "./user/user.saga";
import funktionenSaga from "./funktionen/funktionen.saga";
import bluetoothDataSimulationSaga from "./bluetooth/bluetooth-data-simulation.saga";
import capturedRecordsSaga from "./captured-records/captured-records.saga";
import i18nSaga from "./i18n/i18n.saga";
import initSaga from "./init/init.saga";
import authenticationSaga from "./authentication/authentication.saga";
import ferkelSaga from "./ferkel/ferkel.saga";
import wurfuebersichtSaga from "./wurfuebersicht/wurfuebersicht.saga";
import transitoryProzessDataSaga from "./transitory-prozess-data/transitory-prozess-data.saga";
import sauenSaga from "./sauen/sauen.saga";
import reportProblemSaga from "./problem-report/problem-report.saga";
import synchronizationSaga from "./synchronization/synchronization.saga";
import buchtenSaga from "./buchten/buchten.saga";
import restoreSaga from "./restore/restore.saga";
import planFerkelSaga from "./plan-ferkel/plan-ferkel.saga";

const rootReducer = () =>
  combineReducers({
    common: commonReducer,
    funktionen: funktionenReducer,
    bluetooth: bluetoothReducer,
    user: userReducer,
    prozessEvents: prozessEventsReducer,
    capturedRecords: capturedRecordsReducer,
    i18n: i18nReducer,
    authentication: authenticationReducer,
    notification: notificationReducer,
    ferkel: ferkelReducer,
    wurfuebersicht: wurfuebersichtReducer,
    bluetoothSimulation: bluetoothDataSimulationReducer,
    transitoryProzessData: transitoryProzessDataReducer,
    sauen: sauenReducer,
    synchronization: synchronizationReducer,
    buchten: buchtenReducer,
    planFerkel: planFerkelReducer,
  });

const sagas = [
  planFerkelSaga,
  restoreSaga,
  buchtenSaga,
  synchronizationSaga,
  reportProblemSaga,
  sauenSaga,
  bluetoothDataSimulationSaga,
  transitoryProzessDataSaga,
  wurfuebersichtSaga,
  ferkelSaga,
  funktionenSaga,
  prozessEventsSaga,
  bluetoothConnectionSaga,
  userSaga,
  capturedRecordsSaga,
  i18nSaga,
  initSaga,
  authenticationSaga,
];

export { rootReducer, sagas };
