import { AxiosError } from "axios";
import { showFailedRequest, showWarningRequest } from "../store/notifications/notifications.actions";
import { Dispatch } from "redux";
import { logout, unregisterDevice } from "../store/authentication/authentication.actions";
import I18n from "i18next";
import { reportProblem } from "../store/problem-report/problem-report.actions";
import { forceUserToUpdate } from "../store/common/common.actions";

export default (error: AxiosError, dispatch: Dispatch) => {
  const { response } = error;

  if (!response) {
    return;
  }

  if (response.status === 401) {
    if (!!response.headers && !!response.headers["www-authenticate"]) {
      const wwwAuthenticateHeader = response.headers["www-authenticate"];

      if (wwwAuthenticateHeader.includes('error="device_not_found"')) {
        dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.INVALID_DEVICE")));
        dispatch(unregisterDevice());
        return;
      }
    }

    dispatch(showWarningRequest(I18n.t("NOTIFICATIONS.AUTHORISATION_REQUEST_WARNING")));
    dispatch(logout());
  }

  if (response.status >= 400 && response.status !== 401) {
    if (response.data === "Invalid device id") {
      dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.INVALID_DEVICE")));
      dispatch(unregisterDevice());
      return;
    }
    if (response.status === 403) {
      dispatch(
        reportProblem({
          message: `${response.config.url} ${error.message} (access denied)`,
          shouldRemoveData: true,
        })
      );
      return;
    }

    // Exception for data-export resend response.
    if (response.status === 409 && response.config.url?.includes("data-export")) {
      dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_TO_PROCESS_DATEN_EXPORT")));
      return;
    }

    if (response.status === 410) {
      dispatch(forceUserToUpdate(true));
      return;
    }

    if (response.status === 502) {
      dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.FAILED_CONNECT_TO_SERVER")));
      return;
    }

    if (response.status >= 500) {
      dispatch(showFailedRequest());
    }
  }
};
