import { FunctionComponent, useEffect, useState, FormEvent } from "react";
import { FormControl, InputLabel, MenuItem, Select, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AppBarContainer from "../../components/app-bar/app-bar.container";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTranslation } from "react-i18next";
import { IUserDto } from "../../api/backend-api-v7";
import RoutePaths from "../../routes/route-paths";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: "0.7em",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logout: {
      margin: theme.spacing(3, 0, 2),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

export interface ConnectedState {
  token: string | undefined;
  isDeviceRegistered: boolean;
  users: IUserDto[] | undefined;
  isLoggedIn: boolean;
}

export interface ConnectedDispatch {
  createAuthentication: (username: string, password: string) => void;
  unregisterDevice: () => void;
  fetchUsers: () => void;
  showLoginFormInvalid: () => void;
}

interface OwnProps extends ConnectedState, ConnectedDispatch {}

const LoginComponent: FunctionComponent<OwnProps> = props => {
  const {
    createAuthentication,
    isDeviceRegistered,
    users,
    unregisterDevice,
    fetchUsers,
    isLoggedIn,
    showLoginFormInvalid,
  } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState({ username: false, password: false });

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDeviceRegistered) {
      fetchUsers();
    }

    setPassword("");
  }, [fetchUsers, isDeviceRegistered]);

  const handleUsernameChange = (value: string) => {
    setUsername(value);
    setIsError({ ...isError, username: false });
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setIsError({ ...isError, password: false });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const submitLogin = () => {
    if (!isFormValid()) {
      showLoginFormInvalid();
      return;
    }
    createAuthentication(username, password);
  };

  const submitLogout = () => {
    unregisterDevice();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    submitLogin();
    event.preventDefault();
  };

  const isFormValid = () => {
    const tmpIsError = isError;
    let isValid = true;

    if (username === "") {
      setIsError({ ...tmpIsError, username: true });
      tmpIsError.username = true;
      isValid = false;
    }

    if (password === "") {
      setIsError({ ...tmpIsError, password: true });
      tmpIsError.password = true;
      isValid = false;
    }

    return isValid;
  };

  const renderUserSelect = () => (
    <FormControl fullWidth margin="normal" data-cy="user-select">
      <InputLabel id="select-label" className={classes.label}>
        {t("COMMON.BENUTZERNAME")}
      </InputLabel>
      <Select
        labelId="select-label"
        label="Benutzername"
        onChange={event => handleUsernameChange(event.target.value as string)}
        value={username}
        error={isError.username}
      >
        <MenuItem value="" />
        {!!users &&
          users
            .sort((a, b) => a.displayOrder! - b.displayOrder!)
            .map((user, key) => (
              <MenuItem data-cy="user-to-select" key={key} value={user.displayName}>
                {user.displayName}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );

  const renderUserInput = () => (
    <TextField
      variant="outlined"
      margin="normal"
      required={true}
      fullWidth={true}
      id="username"
      label={t("COMMON.BENUTZERNAME")}
      name="username"
      onChange={event => handleUsernameChange(event.currentTarget.value)}
      value={username}
      error={isError.username}
      className={classes.label}
    />
  );

  const renderLogoutButton = () => (
    <Button
      fullWidth={true}
      variant="contained"
      color="primary"
      data-cy="logout-button"
      className={classes.logout}
      onClick={() => submitLogout()}
    >
      {t("COMMON.COMPANY_LOGOUT")}
    </Button>
  );

  useEffect(() => {
    if (isLoggedIn) {
      navigate(RoutePaths.Dashboard);
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <AppBarContainer />
      <Container maxWidth="xs" className={classes.root}>
        <form onSubmit={handleSubmit}>
          <div className={classes.paper}>
            {isDeviceRegistered && renderUserSelect()}
            {!isDeviceRegistered && renderUserInput()}
            <TextField
              variant="outlined"
              required={true}
              fullWidth={true}
              id="password"
              label={t("COMMON.PASSWORT")}
              name="password"
              type={showPassword ? "text" : "password"}
              onChange={event => handlePasswordChange(event.currentTarget.value)}
              value={password}
              className={classes.label}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={isError.password}
            />
            <Button
              fullWidth={true}
              variant="contained"
              color="secondary"
              data-cy="login-button"
              className={classes.submit}
              onClick={() => submitLogin()}
            >
              {t("COMMON.ANMELDEN")}
            </Button>
            {isDeviceRegistered && renderLogoutButton()}
          </div>
        </form>
      </Container>
    </>
  );
};

export default LoginComponent;
