import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "#a7a8aa",
    },
    title: {
      marginLeft: theme.spacing(2),
      display: "flex",
      flex: 1,
      justifyContent: "center",
      color: "white",
    },
    stretchButton: {
      height: "100%",
      padding: theme.spacing(1.5),
      width: "max-content",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    fetchButton: {
      display: "flex",
      fontSize: "18pt",
      borderRadius: "4px",
      backgroundColor: "rgb(213,213,213)",
      justifyContent: "center",
      alignItems: "center",
      width: "max-content",
      height: "70px",
      fontWeight: "bold",
      padding: "10px",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(166,166,166)",
      },
    },
    closeButton: {
      height: "70px",
      padding: theme.spacing(0, 4),
    },
    dialog: {
      padding: "1em",
      marginTop: "3em",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
      position: "absolute",
      bottom: 0,
      padding: theme.spacing(0, 2),
    },
    loadRemoteButton: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      marginLeft: 0,
      marginRight: 0,
      height: theme.spacing(0.5),
    },
    emptyRecords: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(4),
    },
    recordsTable: {
      height: "80vh",
      overflowY: "scroll",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "fixed",
      top: 0,
    },
    progress: {
      margin: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
  })
);

export default useStyles;
