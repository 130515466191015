import { useCallback, useReducer } from "react";
import { sauSelectorReducer, initialState } from "./sau-selector.reducer";
import { SauSelectorActionType } from "./sau-selector.types";

import { IProzessDto, ISauLifeStageDescriptorDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export const useSauSelectorState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  createFilteredBy: (prozessInputData: ProzessInputData) => void,
  shouldIgnoreEventType: boolean
) => {
  const { isRequired, shouldKeepValue } = prozess;
  const [state, dispatch] = useReducer(sauSelectorReducer, initialState);

  const init = useCallback(
    () =>
      dispatch({
        type: SauSelectorActionType.INIT,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: ISauLifeStageDescriptorDto | undefined) => {
      dispatch({
        type: SauSelectorActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid: !!value,
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: SauSelectorActionType.RESET,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const defineAdditionalData = useCallback(
    (selectedValue: ISauLifeStageDescriptorDto | undefined) => {
      const result = prozess.data?.filter(item => item.value === selectedValue?.tierSysId);

      if (selectedValue?.ammenObjectId) {
        return result?.find(item => item.additional?.ammenObjectId === selectedValue.ammenObjectId)
          ?.additional;
      } else {
        return result?.find(item => item.id === selectedValue?.tierSysId)?.additional;
      }
    },
    [prozess.data]
  );

  const save = useCallback(
    (selectedValue: ISauLifeStageDescriptorDto | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing && !shouldIgnoreEventType ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: selectedValue,
        label: prozess.data?.find(d => d.value === selectedValue?.tierSysId)?.label,
        metaData: null,
        additionalData: defineAdditionalData(selectedValue),
        operation: selectedValue ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!selectedValue : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.changeEventType,
      prozess.eventType,
      prozess.eventCategory,
      prozess.data,
      prozess.isRequired,
      prozess.prozessType,
      isEditing,
      shouldIgnoreEventType,
      defineAdditionalData,
    ]
  );

  /**
   * Creates FilteredBy value to display ferkel grid in edit mode.
   * @param selectedValue {@link ISauLifeStageDescriptorDto}.
   */
  const filterProzess = useCallback(
    (selectedValue: ISauLifeStageDescriptorDto | undefined) => {
      createFilteredBy({
        workflowId: prozess.workflowId,
        eventType: prozess.eventType,
        data: selectedValue,
        label: prozess.data?.find(d => d.value === selectedValue)?.label,
        metaData: null,
        additionalData: prozess.data?.find(d => d.value === selectedValue)?.additional,
        operation: selectedValue ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!selectedValue : true,
      } as ProzessInputData);
    },
    [createFilteredBy, prozess]
  );

  /**
   * Determines whether the component should create a Prozess Event in edit mode.
   * @param isProzessEdited {@link Boolean}.
   */
  const shouldEditProzessData = useCallback(
    (isProzessEdited: boolean) =>
      dispatch({
        type: SauSelectorActionType.PROZESS_DATA_EDITED,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, onProzessDataChanged, reset, save, shouldEditProzessData, filterProzess };
};
