import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { useCallback, useReducer } from "react";
import { TransponderActionType } from "./transponder.types";
import { IProzessDto } from "../../../api/backend-api-v7";
import { initialState, transponderReducer } from "./transponder.reducer";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { TRANSPONDER_VALID_LENGTH } from "./transponder.component";
import { ILocalFerkelDto } from "../../../db/database";

const isTransponderValid = (transponder: string | undefined) => {
  if (!transponder) {
    return false;
  }

  return transponder.toString().trim().length === TRANSPONDER_VALID_LENGTH && Number(transponder) > 0;
};

export const useTransponderState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  manualInput: boolean,
  isEditing: boolean,
  ferkel: ILocalFerkelDto[],
  shouldIgnoreEventType: boolean
) => {
  const [state, dispatch] = useReducer(transponderReducer, initialState);

  const { isRequired, shouldKeepValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: TransponderActionType.INIT,
        payload: {
          value: "",
          metaData: null,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: string | undefined, metaData?: BluetoothDataFormat | null) => {
      dispatch({
        type: TransponderActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          metaData,
          isRequired,
          isValid: isTransponderValid(value),
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: TransponderActionType.RESET,
        payload: {
          value: "",
          metaData: null,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const revealEventType = useCallback(
    () =>
      isEditing && manualInput && !shouldIgnoreEventType
        ? prozess.changeManualEventType
        : isEditing && !manualInput && !shouldIgnoreEventType
        ? prozess.changeEventType
        : !isEditing && manualInput
        ? prozess.manualEventType
        : prozess.eventType,
    [
      isEditing,
      manualInput,
      prozess.changeEventType,
      prozess.changeManualEventType,
      prozess.eventType,
      prozess.manualEventType,
      shouldIgnoreEventType,
    ]
  );

  const createAdditionalData = useCallback(
    (metaData?: BluetoothDataFormat | null) => {
      if (metaData) {
        const existingFerkel = ferkel.find(ferkel => ferkel.transponder === +metaData.value);
        if (existingFerkel?.plan) {
          return { ...existingFerkel.plan, bucht: existingFerkel.plan.buchtId };
        }
        return undefined;
      }
      return undefined;
    },
    [ferkel]
  );

  const save = useCallback(
    (transponder: string | undefined, metaData?: BluetoothDataFormat | null) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: revealEventType(),
        eventCategory: prozess.eventCategory,
        data: Number(transponder),
        metaData,
        additionalData: createAdditionalData(metaData),
        operation: isTransponderValid(transponder)
          ? ProzessEventOperationTypes.CREATE
          : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? isTransponderValid(transponder) : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.eventCategory,
      prozess.isRequired,
      prozess.prozessType,
      revealEventType,
      createAdditionalData,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: TransponderActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          metaData: null,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, reset, onProzessDataChanged, save, shouldEditProzessData };
};
