import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextField, Typography } from "@mui/material";

import AppButton from "../../common/app-button/app-button";
import CloseButton from "../../common/close-button/close-button.component";
import TransitionsModal from "../../common/modal/modal-window";

import useStyles from "./settings.styles";
import { Props } from "./settings.types";
import { IWorkflowConfigurationDto } from "../../../api/backend-api-v7";

const MAX_VALUE = 999;

const SettingsComponent: FunctionComponent<Props> = props => {
  const { open, onClose, gewichtProzess, updateProzessConfiguration } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [minValue, setMinValue] = useState<string | undefined>(gewichtProzess?.optimalMinValue?.toString());
  const [maxValue, setMaxValue] = useState<string | undefined>(gewichtProzess?.optimalMaxValue?.toString());
  const [isError, setIsError] = useState<boolean>(false);

  const save = () => {
    if (isFormValid()) {
      // Hardcode only for gewicht workflow.
      const settings: IWorkflowConfigurationDto[] = [
        {
          workflowId: gewichtProzess?.workflowId,
          configurationKey: "optimalMinValue",
          configurationValue: minValue,
        },
        {
          workflowId: gewichtProzess?.workflowId,
          configurationKey: "optimalMaxValue",
          configurationValue: maxValue,
        },
      ];
      updateProzessConfiguration(settings);
      onClose();
    } else {
      setIsError(true);
    }
  };

  const isFormValid = () => {
    const isOptimalRangeCorrect = !!minValue && +minValue >= 0 && maxValue && +maxValue > +minValue;

    if (isOptimalRangeCorrect) {
      const { validMinValue, validMaxValue } = gewichtProzess!;

      if (validMinValue?.toString() && +validMinValue >= 0 && validMaxValue) {
        const isOptimalRangeInValidRange = +minValue >= +validMinValue && +maxValue <= +validMaxValue;
        return isOptimalRangeInValidRange;
      }

      return true;
    }

    return false;
  };

  const handleMinValueChange = (value: string) => {
    setMinValue(value);
    setIsError(false);
  };

  const handleMaxValueChange = (value: string) => {
    if (+value <= MAX_VALUE) {
      setMaxValue(value);
      setIsError(false);
    }
  };

  return (
    <TransitionsModal closeModal={onClose} open={open} size="800px">
      <div className={classes.flexWrapper}>
        <div className={classes.header}>
          <Typography className={classes.title}>{t("MODAL.SETTINGS.TITLE")}</Typography>
          <CloseButton onCloseHandler={onClose} className={classes.closeIcon} />
        </div>
        <Typography className={classes.description}>{t("MODAL.SETTINGS.DESCRIPTION")}</Typography>
        <Typography className={classes.description}>{`${t("MODAL.SETTINGS.REQUIREMENTS")} ${
          gewichtProzess?.validMinValue ?? ""
        } - ${gewichtProzess?.validMaxValue ?? ""}`}</Typography>
        <div className={classes.form}>
          <div className={classes.fieldWrapper}>
            <Typography>Min</Typography>
            <TextField
              id="optimalMinValue"
              variant="outlined"
              margin="normal"
              name="optimalMinValue"
              onChange={event => handleMinValueChange(event.currentTarget.value)}
              value={minValue}
              className={classes.textField}
              error={isError}
              inputProps={{
                min: 0,
                max: 999,
                type: "number",
              }}
            />
          </div>
          <div className={classes.fieldWrapper}>
            <Typography>Max</Typography>
            <TextField
              id="optimalMaxValue"
              variant="outlined"
              margin="normal"
              name="optimalMaxValue"
              onChange={event => handleMaxValueChange(event.currentTarget.value)}
              value={maxValue}
              className={classes.textField}
              error={isError}
              inputProps={{
                min: 0,
                max: 999,
                type: "number",
              }}
            />
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <AppButton handler={() => save()} className={classes.button}>
            {t("COMMON.ÜBERNEHMEN").toUpperCase()}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default SettingsComponent;
