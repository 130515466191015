import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginComponent, { ConnectedDispatch, ConnectedState } from "./login.component";
import { authUser, fetchUsers, unregisterDevice } from "../../store/authentication/authentication.actions";
import { AppState } from "../../store/app.state";
import {
  getIsDeviceRegistered,
  getIsLoggedIn,
  getToken,
  getUsers,
} from "../../store/authentication/authentication.selectors";
import I18n from "i18next";
import { showFailedRequest } from "../../store/notifications/notifications.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  token: getToken(state),
  isDeviceRegistered: getIsDeviceRegistered(state),
  users: getUsers(state),
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  createAuthentication: (username: string, password: string) => dispatch(authUser(username, password)),
  unregisterDevice: () => dispatch(unregisterDevice()),
  fetchUsers: () => dispatch(fetchUsers()),
  showLoginFormInvalid: () => dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.INVALID_LOGIN_FORM"))),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
