import { Action } from "../action";
import { ProzessDataDto } from "../../api/backend-api-v7";
import { ProzessEventsRecord } from "../prozess-events/prozess-events.reducer";
import { ISyncedCapturedRecord } from "../../db/database";

export interface WurfFerkelDto {
  lebendeFerkel: WurfuebersichtFerkelDto[];
  totgeburtFerkel: WurfuebersichtFerkelDto[];
}

export interface WurfuebersichtFerkelDto {
  id: string;
  geschlecht: string;
  totgeburt: boolean;
}

export enum WurfuebersichtActionType {
  SET_BUCHTSAU = "SET_BUCHTSAU",
  SET_FERKEL = "SET_FERKEL",
  SET_NEW_FERKEL = "SET_NEW_FERKEL",
  SET_WURF_FERKEL = "SET_WURF_FERKEL",
  DELETE_WURF_FERKEL = "DELETE_WURF_FERKEL",
  GET_WURFUEBERSICHT_DATA = "GET_WURFUEBERSICHT_DATA",
  UPDATE_FERKEL = "UPDATE_FERKEL",
  UPDATE_FERKEL_WITH_DIFFERENT_SAU = "UPDATE_FERKEL_WITH_DIFFERENT_SAU",
  RESET_WURFUEBERSICHT_TABLE = "RESET_WURFUEBERSICHT_TABLE",
  CHECK_DELETED_RECORD = "CHECK_DELETED_RECORD",
  REMOVE_DELETED_FERKEL = "REMOVE_DELETED_FERKEL",
}

export const setBuchtsau = (buchtsau: ProzessDataDto | undefined): Action<ProzessDataDto | undefined> => ({
  type: WurfuebersichtActionType.SET_BUCHTSAU,
  payload: buchtsau,
});

export const setFerkel = (ferkel: WurfuebersichtFerkelDto[]): Action<WurfuebersichtFerkelDto[]> => ({
  type: WurfuebersichtActionType.SET_FERKEL,
  payload: ferkel,
});

export const setNewFerkel = (ferkel: WurfuebersichtFerkelDto): Action<WurfuebersichtFerkelDto> => ({
  type: WurfuebersichtActionType.SET_NEW_FERKEL,
  payload: ferkel,
});

export const setWurfFerkel = (wurfFerkel: WurfFerkelDto): Action<WurfFerkelDto> => ({
  type: WurfuebersichtActionType.SET_WURF_FERKEL,
  payload: wurfFerkel,
});

export const deleteWurfFerkel = (): Action<null> => ({
  type: WurfuebersichtActionType.DELETE_WURF_FERKEL,
  payload: null,
});

export const getWurfuebersichtData = (prozessEvents: ProzessEventsRecord): Action<ProzessEventsRecord> => ({
  type: WurfuebersichtActionType.GET_WURFUEBERSICHT_DATA,
  payload: prozessEvents,
});

export const updateFerkel = (ferkel: WurfuebersichtFerkelDto): Action<WurfuebersichtFerkelDto> => ({
  type: WurfuebersichtActionType.UPDATE_FERKEL,
  payload: ferkel,
});

export const updateFerkelWithDifferentSau = (data: {
  prevSau: string;
  editedFerkel: WurfuebersichtFerkelDto;
  updatedFerkel: WurfuebersichtFerkelDto;
}): Action<{
  prevSau: string;
  editedFerkel: WurfuebersichtFerkelDto;
  updatedFerkel: WurfuebersichtFerkelDto;
}> => ({
  type: WurfuebersichtActionType.UPDATE_FERKEL_WITH_DIFFERENT_SAU,
  payload: data,
});

export const resetWurfuebersichtTable = (): Action<null> => ({
  type: WurfuebersichtActionType.RESET_WURFUEBERSICHT_TABLE,
  payload: null,
});

export const checkDeletedRecord = (record: ISyncedCapturedRecord): Action<ISyncedCapturedRecord> => ({
  type: WurfuebersichtActionType.CHECK_DELETED_RECORD,
  payload: record,
});

export const removeDeletedFerkel = (ids: {
  id: string;
  buchtsauId: string;
}): Action<{ id: string; buchtsauId: string }> => ({
  type: WurfuebersichtActionType.REMOVE_DELETED_FERKEL,
  payload: ids,
});
