import { FunctionComponent, useMemo } from "react";
import { ControlTypes } from "../../pages/funktion/controltypes.enum";
import GewichtContainer from "./gewicht/gewicht.container";
import TransponderContainer from "./transponder/transponder.container";
import TierIdentContainer from "./tierident/tierident.container";
import SelectWithDialogContainer from "./select-with-dialog/select-with-dialog.container";
import LastRecordsContainer from "./records/last-records/last-records.container";
import GeschlechtContainer from "./geschlecht/geschlecht.container";
import WurfuebersichtContainer from "./wurfuebersicht/wurfuebersicht.container";
import InfoFerkelContainer from "./info-ferkel/info-ferkel.container";
import FerkelAbgesetztContainer from "./ferkel-abgesetzt/ferkel-abgesetzt.container";
import ComboBoxContainer from "./combo-box/combo-box.container";
import GruppenerfassungContainer from "./gruppenerfassung/gruppenerfassung.container";
import ProzessEventsCounterContainer from "./prozess-events-counter/prozess-events-counter.container";
import TextFieldContainer from "./text-field/text-field.container";
import LastExportsContainer from "./records/last-exports/last-exports.container";
import { IFunktionDto, IProzessDto } from "../../api/backend-api-v7";
import TierInfoContainer from "./tier-info/tier-info.container";
import FerkelGridContainer from "./ferkel-grid/ferkel-grid.container";
import RecordsPageContainer from "./records/records-page/records-page.container";
import SelectWithDateContainer from "./select-with-date/select-with-date.container";
import InfoNeueBuchtsauContainer from "./info-neue-buchtsau/info-neue-buchtsau.container";
import CheckboxContainer from "./checkbox/checkbox.container";
import HiddenContainer from "./hidden/hidden.container";
import InfoFutterContainer from "./info-futter/info-futter.container";
import DataOverviewContainer from "./data-overview/data-overview.container";
import UploadContainer from "./upload/upload.container";
import KeyValueTableContainer from "./key-value-table/key-value-table.container";
import SauSelectorContainer from "./sau-selector/sau-selector.container";

interface OwnProps {
  funktion: IFunktionDto;
  prozesse: { [workflowId: number]: IProzessDto };
  prozess: IProzessDto;
  manualInput: boolean;
  recordId: string;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
  saveRecord: () => void;
}

const ProzessComponent: FunctionComponent<OwnProps> = props => {
  const {
    funktion,
    prozesse,
    prozess,
    manualInput,
    recordId,
    shouldValidate,
    setIsValidationError,
    saveRecord,
  } = props;

  const RevealMemoizedProzessComponent = useMemo(() => {
    switch (prozess.controlType) {
      case ControlTypes.GEWICHT: {
        return (
          <GewichtContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            manualInput={manualInput}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
          />
        );
      }
      case ControlTypes.GEWICHT_DIFFERENTIAL: {
        return (
          <GewichtContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            manualInput={manualInput}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            shouldCalculateDifference={true}
          />
        );
      }
      case ControlTypes.TRANSPONDER: {
        return (
          <TransponderContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            manualInput={manualInput}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            saveRecord={saveRecord}
          />
        );
      }
      case ControlTypes.TIER_IDENT: {
        return (
          <TierIdentContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            manualInput={manualInput}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            funktionId={funktion.id!}
            funktionConfiguration={funktion.funktionConfiguration!}
          />
        );
      }
      case ControlTypes.SAU_SELECTOR: {
        return (
          <SauSelectorContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
          />
        );
      }
      case ControlTypes.SAU_SELECTOR_FULLSCREEN: {
        return (
          <SauSelectorContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            fullScreen={true}
          />
        );
      }
      case ControlTypes.SELECT_WITH_DIALOG: {
        return (
          <SelectWithDialogContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            funktionId={funktion.id!}
          />
        );
      }
      case ControlTypes.SELECT_WITH_DIALOG_FULLSCREEN: {
        return (
          <SelectWithDialogContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            funktionId={funktion.id!}
            fullScreen={true}
          />
        );
      }
      case ControlTypes.LAST_RECORDS: {
        return (
          <LastRecordsContainer
            prozesse={prozesse}
            currentProzess={prozess}
            key={prozess.workflowId}
            funktionId={funktion.id!}
          />
        );
      }
      case ControlTypes.GESCHLECHT: {
        return (
          <GeschlechtContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            data={prozess.data!}
            shouldValidate={shouldValidate}
          />
        );
      }
      case ControlTypes.FERKEL_GRID: {
        return (
          <FerkelGridContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            funktionId={funktion.id!}
          />
        );
      }
      case ControlTypes.ALL_RECORDS: {
        return (
          <RecordsPageContainer
            currentProzess={prozess}
            key={prozess.workflowId}
            prozesse={prozesse}
            funktionId={funktion.id!}
          />
        );
      }
      case ControlTypes.ALL_EXPORTS: {
        return (
          <RecordsPageContainer
            currentProzess={prozess}
            key={prozess.workflowId}
            prozesse={prozesse}
            funktionId={funktion.id!}
            shouldUseActionBar={true}
          />
        );
      }
      case ControlTypes.WURFUEBERSICHT: {
        return (
          <WurfuebersichtContainer prozess={prozess} key={prozess.workflowId} funktionId={funktion.id!} />
        );
      }
      case ControlTypes.FERKEL_ABGESETZT: {
        return (
          <FerkelAbgesetztContainer prozess={prozess} key={prozess.workflowId} funktionId={funktion.id!} />
        );
      }
      case ControlTypes.COMBO_BOX: {
        return (
          <ComboBoxContainer
            prozess={prozess}
            key={prozess.workflowId}
            funktionId={funktion.id!}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            manualInput={manualInput}
          />
        );
      }
      case ControlTypes.SELECT_WITH_DATE: {
        return (
          <SelectWithDateContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
          />
        );
      }
      case ControlTypes.MULTIPLE_TRANSPONDERS: {
        return (
          <GruppenerfassungContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
          />
        );
      }
      case ControlTypes.PROZESS_EVENTS_COUNTER: {
        return <ProzessEventsCounterContainer prozess={prozess} key={prozess.workflowId} />;
      }
      case ControlTypes.INFO_NEUE_BUCHTSAU: {
        return (
          <InfoNeueBuchtsauContainer prozess={prozess} key={prozess.workflowId} funktionId={funktion.id!} />
        );
      }
      case ControlTypes.INFO_FERKEL: {
        return <InfoFerkelContainer prozess={prozess} key={prozess.workflowId} funktionId={funktion.id!} />;
      }
      case ControlTypes.NUMBER_FIELD: {
        return (
          <TextFieldContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            type="number"
          />
        );
      }
      case ControlTypes.TEXT_FIELD: {
        return (
          <TextFieldContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            type="string"
          />
        );
      }
      case ControlTypes.LAST_EXPORTS: {
        return (
          <LastExportsContainer
            prozesse={prozesse}
            currentProzess={prozess}
            key={prozess.workflowId}
            funktionId={funktion.id!}
          />
        );
      }
      case ControlTypes.WAAGE: {
        return (
          <GewichtContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            manualInput={manualInput}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            isWaageMode={true}
          />
        );
      }
      case ControlTypes.TIER_INFO: {
        return <TierInfoContainer prozess={prozess} key={prozess.workflowId} />;
      }
      case ControlTypes.CHECKBOX: {
        return (
          <CheckboxContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
            funktionId={funktion.id!}
            manualInput={manualInput}
          />
        );
      }
      case ControlTypes.HIDDEN: {
        return <HiddenContainer prozess={prozess} key={prozess.workflowId} recordId={recordId} />;
      }
      case ControlTypes.INFO_FUTTER: {
        return <InfoFutterContainer key={prozess.workflowId} prozess={prozess} funktionId={funktion.id!} />;
      }
      case ControlTypes.DATA_OVERVIEW: {
        return <DataOverviewContainer prozess={prozess} key={prozess.workflowId} funktionId={funktion.id!} />;
      }
      case ControlTypes.TEXT_FILE_CONTENT_UPLOAD: {
        return (
          <UploadContainer
            prozess={prozess}
            key={prozess.workflowId}
            recordId={recordId}
            shouldValidate={shouldValidate}
            setIsValidationError={setIsValidationError}
          />
        );
      }
      case ControlTypes.KEY_VALUES_INFO_TABLE: {
        return <KeyValueTableContainer prozess={prozess} key={prozess.workflowId} recordId={recordId} />;
      }
    }
  }, [
    prozess,
    recordId,
    manualInput,
    shouldValidate,
    setIsValidationError,
    saveRecord,
    funktion.id,
    funktion.funktionConfiguration,
    prozesse,
  ]);

  if (!funktion || !prozess) {
    return null;
  }

  return <>{RevealMemoizedProzessComponent}</>;
};

export default ProzessComponent;
