import { FunctionComponent, useEffect, useState, MouseEvent, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Props } from "./app-bar.types";
import { AppBar, Divider, IconButton, ListItemIcon, MenuItem, Toolbar, Typography } from "@mui/material";
import { AccountCircle, Menu as MenuIcon, Sync, SyncLock } from "@mui/icons-material";
import I18n from "i18next";
import useStyles from "./app-bar.styles";
import Menu from "@mui/material/Menu";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReportIcon from "@mui/icons-material/Report";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import BluetoothSimulatorContainer from "../bluetooth-simulator/bluetooth-simulator.container";
import BluetoothConnectionContainer from "../bluetooth/bluetooth-connection.container";
import GewichtModeContainer from "../gewicht-mode/gewicht-mode.container";
import ProblemReportContainer from "../modal-windows/problem-report/problem-report.container";
import LogoutDialogComponent from "../modal-windows/logout-dialog/logout-dialog.component";
import FunktionInfoComponent from "../funktion-info/funktion-info.component";
import SettingsContainer from "../modal-windows/settings/settings.container";
import iqImages from "../../images/iq-images";
import { Colors } from "../../theme/colors";
import RoutePaths from "../../routes/route-paths";
import classNames from "classnames";
import { LocalStorageItems } from "../../utils/local-storage.enum";
import { ProzessType } from "../../api/backend-api-v7";
import { checkInternetConnection } from "../../utils/network-status";

// @ts-ignore
import licencesFile from "./licences.txt";

const AppBarComponent: FunctionComponent<Props> = props => {
  const {
    drawerOpen,
    currentFunktion,
    toggleDrawer,
    isLoggedIn,
    loggedInUser,
    logout,
    isAllEventsSynced,
    refreshAppData,
    showNetworkError,
  } = props;
  const classes = useStyles();
  const [icon, setIcon] = useState(currentFunktion ? currentFunktion.icon : "logo");
  const [name, setName] = useState(currentFunktion ? currentFunktion.name : "");
  const [color, setColor] = useState(currentFunktion ? currentFunktion.color : "transparent");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState<boolean>(false);
  const [isUnsyncedDialogOpen, setIsUnsyncedDialogOpen] = useState<boolean>(false);
  const [isFunktionInfoOpen, setIsFunktionInfoOpen] = useState<boolean>(false);
  const [isFunktionSettingsOpen, setIsFunktionSettingsOpen] = useState<boolean>(false);

  const gewichtProzess = useMemo(
    () =>
      currentFunktion?.prozesse?.find(
        prozess =>
          prozess.prozessType === ProzessType.GEWICHT || prozess.prozessType === ProzessType.FUTTER_GEWICHT
      ),
    [currentFunktion]
  );

  const isUserMenuOpen = Boolean(anchorEl);
  const useBluetoothSimulation = localStorage.getItem(LocalStorageItems.USE_BLUETOOTH_SIMULATION);
  const disableSynchronisation = localStorage.getItem(LocalStorageItems.DISABLE_SYNCHRONISATION);
  const shouldUseBluetoothSimulation = useBluetoothSimulation && JSON.parse(useBluetoothSimulation);
  const isSynchronisationDisabled = disableSynchronisation && JSON.parse(disableSynchronisation);

  useEffect(() => {
    setIcon(currentFunktion ? currentFunktion.icon : "logo");
    setName(currentFunktion ? currentFunktion.name : "");
    setColor(currentFunktion ? currentFunktion.color : "transparent");
  }, [currentFunktion]);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handeFunktionInfoDialog = () => {
    setIsFunktionInfoOpen(!isFunktionInfoOpen);
  };

  const reportDialogHandler = () => {
    setIsReportDialogOpen(!isReportDialogOpen);
  };

  const handeFunktionSettingsDialog = () => {
    const isOnline = checkInternetConnection();
    if (!isOnline) {
      showNetworkError();
      return;
    }
    setIsFunktionSettingsOpen(!isFunktionSettingsOpen);
  };

  const logoutHandler = () => {
    if (isAllEventsSynced) {
      handleClose();
      logout();
    } else {
      setIsUnsyncedDialogOpen(true);
    }
  };

  const renderInfoMenuItems = useCallback(
    () => (
      <div>
        <MenuItem className={classes.menuItem}>
          <Link className={classes.link} to={RoutePaths.Kontakt}>
            {I18n.t<string>("COMPONENTS.KONTAKT.TITLE")}
          </Link>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Link className={classes.link} to={RoutePaths.Impressum}>
            {I18n.t<string>("COMPONENTS.IMPRESSUM.TITLE")}
          </Link>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Link className={classes.link} to={RoutePaths.PrivacyPolicy}>
            {I18n.t<string>("COMMON.DATENSCHUTZ")}
          </Link>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Link className={classes.link} to={RoutePaths.TermsAndConditions}>
            {I18n.t<string>("COMPONENTS.CONDITIONS.TITLE")}
          </Link>
        </MenuItem>
        <a
          id="lizenzen"
          data-cy="lizenzen"
          className={classes.licencesLink}
          href={licencesFile}
          download={"Lizenzen"}
          target={"blank"}
          tabIndex={-1}
        >
          <MenuItem className={classes.menuItem}>{I18n.t<string>("COMMON.LIZENZEN")}</MenuItem>
        </a>
      </div>
    ),
    [classes.licencesLink, classes.link, classes.menuItem]
  );

  return (
    <AppBar className={classes.appBar} style={{ borderBottomColor: `${color}` }}>
      <Toolbar>
        {isLoggedIn && (
          <IconButton
            color="default"
            aria-label="open drawer"
            data-cy="open-drawer-button"
            onClick={() => toggleDrawer(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
        )}

        <span className={classes.centered}>
          {/*
          // @ts-ignore */}
          <img src={iqImages[`${icon}`]} className={classes.icon} alt={"Icon"} />
          <Typography variant="h6" color="textPrimary">
            {name ?? ""}
          </Typography>
          {currentFunktion?.description && (
            <HelpIcon className={classes.helpIcon} color="info" onClick={handeFunktionInfoDialog} />
          )}
        </span>

        {isLoggedIn ? (
          <>
            {isSynchronisationDisabled && <SyncLock style={{ color: Colors.AmpelRot }} />}
            {shouldUseBluetoothSimulation && <BluetoothSimulatorContainer />}
            {gewichtProzess && (
              <SettingsIcon style={{ color: Colors.Gray }} onClick={handeFunktionSettingsDialog} />
            )}
            <ReportIcon
              className={classes.actionIcon}
              onClick={reportDialogHandler}
              style={{ color: Colors.Gray }}
            />
            <GewichtModeContainer />
            <CloudUploadIcon style={{ color: isAllEventsSynced ? Colors.AmpelGruen : Colors.Gray }} />
            <Sync className={classes.actionIcon} onClick={refreshAppData} />
            <BluetoothConnectionContainer />
            <div>
              <IconButton onClick={event => handleMenu(event as unknown as MouseEvent<HTMLElement>)}>
                <AccountCircle />
              </IconButton>
              <Menu
                data-cy="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted={true}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={isUserMenuOpen}
                onClose={handleClose}
              >
                <MenuItem className={classes.usernameMenuItem}>
                  <ListItemIcon className={classes.usernameListItemIcon}>
                    <AccountCircle />
                  </ListItemIcon>

                  {loggedInUser}
                </MenuItem>
                <Divider />
                {renderInfoMenuItems()}
                <Divider />
                <MenuItem
                  onClick={logoutHandler}
                  data-cy="logout-button"
                  className={classNames(classes.menuItem, classes.logoutButton)}
                >
                  {I18n.t<string>("COMMON.ABMELDEN")}
                </MenuItem>
              </Menu>
            </div>
          </>
        ) : (
          <>
            <IconButton
              onClick={event => handleMenu(event as unknown as MouseEvent<HTMLElement>)}
              color="default"
              data-cy="info-icon"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </IconButton>
            <Menu
              data-cy="info-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted={true}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={isUserMenuOpen}
              onClose={handleClose}
            >
              {renderInfoMenuItems()}
            </Menu>
          </>
        )}
      </Toolbar>
      {isReportDialogOpen && (
        <ProblemReportContainer open={isReportDialogOpen} closeModal={reportDialogHandler} />
      )}
      {isUnsyncedDialogOpen && (
        <LogoutDialogComponent
          open={isUnsyncedDialogOpen}
          onClose={() => setIsUnsyncedDialogOpen(!isUnsyncedDialogOpen)}
          logout={logout}
        />
      )}
      {isFunktionInfoOpen && (
        <FunktionInfoComponent
          open={isFunktionInfoOpen}
          onClose={handeFunktionInfoDialog}
          funktion={currentFunktion}
        />
      )}
      {isFunktionSettingsOpen && (
        <SettingsContainer
          open={isFunktionSettingsOpen}
          onClose={handeFunktionSettingsDialog}
          gewichtProzess={gewichtProzess}
        />
      )}
    </AppBar>
  );
};

export default AppBarComponent;
