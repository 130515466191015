import { LoadingStatus } from "../loading-status";
import { AppState } from "../app.state";
import { ProzessInputData } from "./prozess-events.actions";
import { createSelector } from "reselect";
import { ProzessEventsRecord } from "./prozess-events.reducer";

export const getProzessEvents = (state: AppState): ProzessEventsRecord => state.prozessEvents.prozessEvents;

export const getIsProzessEventsExist = (state: AppState): boolean =>
  !!Object.values(state.prozessEvents.prozessEvents).length;

export const getallProzessEventsValidInCreationMode = (state: AppState): boolean | undefined =>
  state.prozessEvents.allProzessEventsValidInCreationMode;

export const getCurrentRecordId = (state: AppState): string => state.prozessEvents.recordId;

export const getCurrentGroupId = (state: AppState): string => state.prozessEvents.recordsGroupId;

export const getGivenData = (state: AppState): { [workflowId: number]: ProzessInputData } =>
  state.prozessEvents.givenData;

export const getGivenDataByWorkflowId = (workflowId: number) =>
  createSelector([getGivenData], (givenData: { [workflowId: number]: ProzessInputData }) =>
    givenData ? givenData[workflowId] : undefined
  );

export const getFilteredBy = (state: AppState): { [workflowId: number]: ProzessInputData } =>
  state.prozessEvents.filteredBy;

export const getComparison = (state: AppState): { [workflowId: number]: ProzessInputData } =>
  state.prozessEvents.comparisonData;

export const getFilteredByWorkflowId = (workflowId: number) =>
  createSelector([getFilteredBy], (filteredBy: { [workflowId: number]: ProzessInputData }) =>
    filteredBy ? filteredBy[workflowId] : undefined
  );

export const getComparisonByWorkflowId = (workflowId: number) =>
  createSelector([getComparison], (comparison: { [workflowId: number]: ProzessInputData }) =>
    comparison ? comparison[workflowId] : undefined
  );

export const getAppVersion = (state: AppState): string => state.prozessEvents.appVersion;

export const getSynchronousProcessingStatus = (state: AppState): LoadingStatus =>
  state.prozessEvents.synchronousProcessingStatus;

export const getRecordProcessingStatus = (state: AppState): LoadingStatus =>
  state.prozessEvents.recordProcessingStatus;
