import logger from "../../../logger";
import { TierIdentAction, TierIdentActionType, TierIdentState } from "./tierident.types";

const logInfo = logger.info("tierident.reducer");

export const initialState: TierIdentState = {
  tierIdent: "",
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const tierIdentReducer = (state: TierIdentState, action: TierIdentAction): TierIdentState => {
  const {
    type,
    payload: { value, isRequired, shouldKeepValue, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case TierIdentActionType.INIT: {
      logInfo("TIERIDENT INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case TierIdentActionType.PROZESS_DATA_CHANGED: {
      const tierIdent = value ? (value as string) : "";

      if (state.tierIdent === tierIdent && state.isError === isError(!!isValid, isRequired)) {
        return state;
      }

      return {
        ...state,
        tierIdent,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case TierIdentActionType.RESET: {
      logInfo("TIERIDENT RESET");
      const tierIdent = shouldKeepValue ? state.tierIdent : "";
      return { ...initialState, tierIdent, isError: isError(!!isValid, isRequired) };
    }
    case TierIdentActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
