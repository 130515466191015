import { FunctionComponent } from "react";
import CloseButton from "../../components/common/close-button/close-button.component";
import useStyles from "./terms-and-conditions.styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {}

const TermsAndConditionsComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}> {t("COMPONENTS.CONDITIONS.TITLE")}</Typography>
        <CloseButton onCloseHandler={() => history.back()} />
      </div>
      <div className={classes.textWrapper}>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_1")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_2")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_3")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_4")}</Typography>
        <div className={classes.description}>
          <ul>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_5")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_6")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_7")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_8")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_9")}</Typography>
            </li>
          </ul>
        </div>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_10")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_11")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_12")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_13")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_14")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_15")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_16")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_17")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_18")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_19")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_59")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_20")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_21")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_22")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_23")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_24")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_25")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_26")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_27")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_28")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_29")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_30")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_31")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_32")}</Typography>
        <Typography>{t("COMPONENTS.CONDITIONS.TEXT_33")}</Typography>
        <div>
          <ul>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_34")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_35")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_36")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_37")}</Typography>
            </li>
            <li>
              <Typography>{t("COMPONENTS.CONDITIONS.TEXT_38")}</Typography>
            </li>
          </ul>
        </div>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_39")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_40")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_41")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_42")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_43")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_44")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_45")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_46")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_47")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_48")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_49")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_50")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_51")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_52")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_53")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_54")}</Typography>
        <Typography className={classes.topic}>{t("COMPONENTS.CONDITIONS.TEXT_55")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_56")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_57")}</Typography>
        <Typography className={classes.description}>{t("COMPONENTS.CONDITIONS.TEXT_58")}</Typography>
      </div>
    </div>
  );
};

export default TermsAndConditionsComponent;
