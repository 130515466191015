import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: 0,
      "& div": {
        height: "100%",
      },
    },
    textField: {
      minWidth: "150px",
      width: "100%",
      "& input": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: theme.additionalColors.gray,
        fontWeight: "bold",
        textAlign: "left",
        "&:focus": {
          direction: "ltr",
          outline: "none",
          border: "none",
        },
      },
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  })
);

export default useStyles;
