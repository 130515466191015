import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      margin: 0,
      borderRadius: "4px",
      border: `1px solid ${theme.additionalColors.lightGray}`,
    },
    checkbox: {
      color: theme.additionalColors.ampelGruen,
    },
    checked: {
      color: `${theme.additionalColors.ampelGruen}!important`,
    },
    formControlLabel: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginRight: "0px",
    },
    errorBorder: {
      border: "1px solid #f44336",
    },
    validBorder: {
      border: `1px solid ${theme.additionalColors.ampelGruen}`,
    },
    validLabel: {
      color: theme.additionalColors.ampelGruen,
    },
  })
);

export default useStyles;
