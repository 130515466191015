// @ts-nocheck
import { FunctionComponent, useState, useEffect, useCallback, ChangeEvent } from "react";
import { Column, Table, AutoSizer, TableCellProps } from "react-virtualized";
import "react-virtualized/styles.css";

import DetailsInfoContainer from "./details-info/details-info.container";
import useStyles from "./tier-info.styles";
import { Button } from "@mui/material";
import { SearchField } from "../../search-field/search-field.component";
import { Props } from "./tier-info.types";
import { ILocalFerkelDto } from "../../../db/database";
import { checkInternetConnection } from "../../../utils/network-status";
import { defineFerkelProperty } from "../../../utils/ferkel.utils";
import { IProzessDataDto, ProzessType } from "../../../api/backend-api-v7";

interface TableSearchTerms {
  [key: string]: string | undefined;
}

const defaultColumn: IProzessDataDto = {
  id: "details",
  label: "Details",
  value: "Details",
};

const TierInfoComponent: FunctionComponent<Props> = props => {
  const { prozess, filteredFerkel, sauen, showNetworkError, transponder } = props;
  const classes = useStyles();

  const [tableSearchTerms, setTableSearchTerms] = useState<TableSearchTerms>({});
  const [ferkel, setFerkel] = useState<ILocalFerkelDto[]>([]);
  const [displayedFerkel, setDisplayedFerkel] = useState<ILocalFerkelDto[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<ILocalFerkelDto | undefined>(undefined);
  const [columns, setColumns] = useState<IProzessDataDto[]>([]);

  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState<boolean>(false);

  const revealColumns = useCallback(() => {
    if (prozess.data) {
      const columnsData = prozess.data.map(item => ({ ...item, id: defineFerkelProperty(item.id) }));
      setColumns(columnsData);
    }
  }, [prozess.data]);

  const createInitialTableSearchTerms = useCallback(() => {
    if (prozess.data) {
      const initialSearchTerms = prozess.data.reduce(
        (acc: TableSearchTerms, current: IProzessDataDto) => ({
          ...acc,
          [defineFerkelProperty(current.id)]: "",
        }),
        {}
      );
      setTableSearchTerms(initialSearchTerms);
    }
  }, [prozess.data]);

  const filterFerkelByTerms = useCallback(() => {
    if (Object.values(tableSearchTerms).some(term => !!term)) {
      const filteredValues = ferkel.filter((item: any) => {
        let shouldKeepValue = true;
        for (const key in tableSearchTerms) {
          if (tableSearchTerms[key]) {
            if (!item[key] || !item[key].toString().includes(tableSearchTerms[key])) {
              shouldKeepValue = false;
              break;
            }
          }
        }
        return shouldKeepValue;
      });
      filteredValues.unshift({} as any);
      setDisplayedFerkel(filteredValues);
    } else {
      setDisplayedFerkel(ferkel);
    }
  }, [ferkel, tableSearchTerms]);

  const handleSearchChange = useCallback((event: ChangeEvent<{ value: string }>, searchKey: string) => {
    setTableSearchTerms(prev => ({ ...prev, [searchKey]: event.target.value }));
  }, []);

  const onDetailsDialogOpenHandler = useCallback(
    (ferkel: ILocalFerkelDto) => {
      const isOnline = checkInternetConnection();
      if (isOnline) {
        setSelectedRowData(ferkel);
        setIsDetailsDialogOpen(!isDetailsDialogOpen);
      } else {
        showNetworkError();
      }
    },
    [isDetailsDialogOpen, showNetworkError]
  );

  const onDetailsDialogCloseHandler = useCallback(() => {
    setSelectedRowData(undefined);
    setIsDetailsDialogOpen(!isDetailsDialogOpen);
  }, [isDetailsDialogOpen]);

  const customSort = useCallback(
    (ferkel: ILocalFerkelDto[]) =>
      ferkel.sort((a, b) => {
        const isTierIdentColumnExist = prozess.data?.find(column => column.id === ProzessType.TIER_IDENT);
        if (isTierIdentColumnExist) {
          return a.tierIdent!.localeCompare(b.tierIdent!, undefined, { numeric: true, sensitivity: "base" });
        } else {
          return (b.transponder ? b.transponder.toString() : "").localeCompare(
            a.transponder ? a.transponder.toString() : "",
            undefined,
            { numeric: true, sensitivity: "base" }
          );
        }
      }),
    [prozess.data]
  );

  const mapIdsToLabel = useCallback(() => {
    if (filteredFerkel && filteredFerkel.length && sauen.length) {
      // Change Sau ids to label.
      const modifiedFerkel: ILocalFerkelDto[] = filteredFerkel.reduce(
        (total: any[], current: ILocalFerkelDto): ILocalFerkelDto[] => {
          const genetischesau = sauen.find(
            sau => sau.tierSysId === current.genetischeSauDescriptor.tierSysId
          );
          const buchtsau = sauen.find(sau => sau.tierSysId === current.buchtsauDescriptor.tierSysId);
          return [
            ...total,
            {
              ...current,
              genetischeSauDescriptor: genetischesau?.sauNrBetrieb,
              buchtsauDescriptor: buchtsau?.sauNrBetrieb,
            },
          ];
        },
        []
      );
      const sortedFerkel = customSort(modifiedFerkel);
      // Add row for search fields.
      sortedFerkel.unshift({} as any);
      setFerkel(sortedFerkel);
      setDisplayedFerkel(sortedFerkel);
    }
  }, [filteredFerkel, sauen, customSort]);

  const setTransponderViaBluetooth = useCallback(() => {
    if (transponder) {
      setTableSearchTerms(prev => ({ ...prev, transponder: transponder.value }));
    }
  }, [transponder]);

  const renderDetailsButton = useCallback(
    (data: TableCellProps) => {
      if (Object.values(data.rowData).length) {
        return (
          <Button
            className={classes.detailsButton}
            variant="contained"
            color="secondary"
            onClick={() => onDetailsDialogOpenHandler(data.rowData)}
          >
            DETAILS
          </Button>
        );
      }
    },
    [classes.detailsButton, onDetailsDialogOpenHandler]
  );

  const renderSearchField = useCallback(
    (data: TableCellProps) => {
      if (!Object.values(data.rowData).length) {
        return (
          <SearchField
            handleChange={e => handleSearchChange(e, data.dataKey)}
            searchTerm={tableSearchTerms[data.dataKey]!}
            variant="standard"
          />
        );
      }
      return data.cellData;
    },
    [handleSearchChange, tableSearchTerms]
  );

  useEffect(() => {
    revealColumns();
  }, [revealColumns]);

  useEffect(() => {
    createInitialTableSearchTerms();
  }, [createInitialTableSearchTerms]);

  useEffect(() => {
    mapIdsToLabel();
  }, [mapIdsToLabel]);

  useEffect(() => {
    filterFerkelByTerms();
  }, [filterFerkelByTerms]);

  useEffect(() => {
    setTransponderViaBluetooth();
  }, [setTransponderViaBluetooth]);

  if (!prozess.data?.length) {
    return null;
  }

  return (
    <>
      <div style={{ gridArea: prozess.position }} data-cy="TierInfoComponent">
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={60}
              rowHeight={80}
              rowCount={displayedFerkel.length}
              rowGetter={({ index }) => displayedFerkel[index]}
            >
              {columns.map((column, index) => (
                <Column
                  key={index}
                  label={column.label!}
                  dataKey={column.id}
                  width={(width - 200) / columns.length}
                  className={classes.tableCell}
                  headerClassName={classes.headerCell}
                  cellRenderer={(data: TableCellProps) => renderSearchField(data)}
                />
              ))}
              <Column
                label={defaultColumn.label}
                dataKey={defaultColumn.id}
                width={200}
                className={classes.tableCell}
                headerClassName={classes.headerCell}
                cellRenderer={(data: TableCellProps) => renderDetailsButton(data)}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
      {isDetailsDialogOpen && (
        <DetailsInfoContainer
          open={isDetailsDialogOpen}
          closeModal={onDetailsDialogCloseHandler}
          selectedFerkel={selectedRowData}
          currentProzess={prozess}
        />
      )}
      {displayedFerkel.length === 1 && (
        <div className={classes.notFound}>Keine Übereinstimmungen gefunden</div>
      )}
    </>
  );
};

export default TierInfoComponent;
