import { applyMiddleware, createStore, Dispatch } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import createSagaMonitor from "@clarketm/saga-monitor";

import createSagaMiddleware, { SagaMiddleware, SagaMiddlewareOptions } from "@redux-saga/core";

import { rootReducer, sagas } from "./index";

const logger = (createLogger as any)({ collapsed: true });

const dev = process.env.NODE_ENV === "development";

let sagaMiddleware: SagaMiddleware;

const sagaOptions: SagaMiddlewareOptions = {};
// create the saga middleware
if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_ENABLE_SAGA_MONITOR === "true") {
    const sagaMonitorConfig = {
      level: "log",
      effectTrigger: true,
      effectResolve: true,
      effectCancel: true,
    };

    sagaOptions.sagaMonitor = createSagaMonitor(sagaMonitorConfig);
  }
}

sagaMiddleware = createSagaMiddleware(sagaOptions);

let middleware = dev ? applyMiddleware(logger, sagaMiddleware) : applyMiddleware(sagaMiddleware);

if (dev) {
  middleware = composeWithDevTools(middleware);
}

export default () => {
  const store = createStore(rootReducer(), {}, middleware);

  // then run the sagas
  sagas.forEach(saga => {
    sagaMiddleware.run(saga, store.dispatch as Dispatch);
  });

  return store;
};
