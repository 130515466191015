import { connect } from "react-redux";

import GewichtComponent from "./gewicht.component";
import {
  getLastReceivedGewicht,
  getShouldUseManualGewichtValue,
  getStableGewicht,
} from "../../../store/bluetooth/bluetooth.selectors";
import { AppState } from "../../../store/app.state";
import {
  resetStableGewicht,
  startCaptureStableGewicht,
  sendData,
  stableGewichtCaptured,
  resetTotalOfGewicht,
} from "../../../store/bluetooth/bluetooth.actions";
import { Dispatch } from "redux";
import { prozessDataChanged, ProzessInputData } from "../../../store/prozess-events/prozess-events.actions";
import { ConnectedDispatch, ConnectedState, GewichtConfiguration, OwnProps } from "./gewicht.types";
import { getCurrentRecordId } from "../../../store/prozess-events/prozess-events.selectors";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";
import { activateDifferentialWeighting } from "../../../store/common/common.actions";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    stableGewicht: getStableGewicht(state),
    lastReceivedGewicht: getLastReceivedGewicht(state),
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldUseManualGewichtValue: getShouldUseManualGewichtValue(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  restartStableGewicht: (gewichtConfiguration: GewichtConfiguration) => {
    dispatch(resetStableGewicht());
    dispatch(startCaptureStableGewicht(gewichtConfiguration));
  },
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  sendTareData: (message: string) => {
    dispatch(sendData(message));
    dispatch(resetTotalOfGewicht());
  },
  stableGewichtCaptured: (stableGewicht: BluetoothDataFormat) =>
    dispatch(stableGewichtCaptured(stableGewicht)),
  activateDifferentialWeighting: (shouldUseDifferentialWeighting: boolean) =>
    dispatch(activateDifferentialWeighting(shouldUseDifferentialWeighting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GewichtComponent);
