import { AppState } from "../app.state";
import { createSelector } from "reselect";
import { getCurrentFunktionId, getFunktionen } from "../funktionen/funktionen.selectors";
import { IFunktionDto } from "../../api/backend-api-v7";

export const getIsProzessEventEditable = (state: AppState): boolean => state.common.canEditProzessEvent;

export const getCanProcessMultipleProzessEvents = (state: AppState): boolean =>
  state.common.canProcessMultipleProzessEvents;

export const isDrawerOpen = (state: AppState): boolean => state.common.drawerOpen;

export const isAppUpdateAvailable = (state: AppState): boolean => state.common.isAppUpdateAvailable;

export const isEditModeActivated = (state: AppState): boolean => state.common.isEditModeActivated;

export const getShouldHighlightRecord = (state: AppState) => state.common.shouldHighlightRecord;

export const getIsManuallyEmpty = (state: AppState): boolean => state.common.isManuallyEmpty;

export const getIsDialogOpen = (state: AppState): boolean => state.common.isDialogOpen;

export const getIsForceAppUpdate = (state: AppState): boolean => state.common.forceAppUpdate;

export const getIsAppDeprecated = (state: AppState): boolean => state.common.isAppDeprecated;

export const getIsDataImportSnackbarOpen = (state: AppState): boolean =>
  state.common.isDataImportSnackbarOpen;

export const getShouldOpenValidationOverviewDialog = (state: AppState): boolean =>
  state.common.shouldOpenValidationOverviewDialog;

export const getShouldCreateProzessEventForMultipleProzess = (state: AppState): boolean =>
  state.common.shouldCreateProzessEventForMultipleProzess;

export const getCountOfMultipleProzessEvents = (state: AppState): number | undefined =>
  state.common.countOfMultipleProzessEvents;

export const getIsDifferentialWeightingActive = (state: AppState): boolean =>
  state.common.isDifferentialWeightingActive;

export const getCurrentFunktion = createSelector(
  [getCurrentFunktionId, getFunktionen],
  (
    funktionId: number | undefined,
    funktionen: { [funktionId: number]: IFunktionDto }
  ): IFunktionDto | undefined => {
    if (funktionId) {
      const funktion = funktionen[+funktionId];

      if (funktion) {
        return funktion;
      }
    }

    return undefined;
  }
);
