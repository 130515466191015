import { FunctionComponent, useCallback, useEffect, useState, useMemo } from "react";
import { Button, CircularProgress } from "@mui/material";
import logger from "../../logger";
import { useTranslation } from "react-i18next";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import ProzessComponent from "../../components/prozesse/prozess.component";
import ConnectToBluetoothDialogContainer from "../../components/bluetooth/connect-to-bluetooth.dialog.container";
import { OwnProps } from "./funktion.types";
import useStyles from "./funktion.styles";
import { IProzessEventDto } from "../../api/backend-api-v7";
import { OptionsObject, useSnackbar } from "notistack";
import ConfirmationModalWindow from "../../components/modal-windows/confirmation-dialog/confirmation-dialog.component";
import iqImages from "../../images/iq-images";
import { isEditTimeExpired } from "../../utils/datetime.utils";
import classNames from "classnames";

const logInfo = logger.info("funktion.component");

export interface IProzessEventsWithAdditionalData extends IProzessEventDto {
  additionalData?: any;
}

const FunktionComponent: FunctionComponent<OwnProps> = props => {
  const {
    saveProzessEvents,
    resetBluetoothData,
    bluetoothConnected,
    bluetoothConnecting,
    bluetoothRequired,
    setCurrentFunktionId,
    createNewRecord,
    recordId,
    allProzessEventsValidInCreationMode,
    editedCapturedRecord,
    finishEditCapturedRecord,
    filterFerkel,
    filterSauen,
    setIsManuallyEmpty,
    funktion,
    prozesse,
    clearProzessEvents,
    isEditing,
    synchronousProcessingStatus,
    recordProcessingStatus,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [connectToBluetoothDialogOpen, setConnectToBluetoothDialogOpen] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [editSnackbarKey, setEditSnackbarKey] = useState<any>(undefined);
  const [shouldDisableSaveButton, setShouldDisableSaveButton] = useState<boolean>(false);

  const disableValidationOnNewRecordId = useCallback(() => {
    setShouldValidate(false);
  }, [setShouldValidate]);

  const initOnFunktionChange = useCallback(() => {
    setCurrentFunktionId(funktion ? funktion.id : undefined);
    setShouldValidate(false);
    createNewRecord();
    resetBluetoothData();
  }, [createNewRecord, funktion, resetBluetoothData, setCurrentFunktionId]);

  const askForBluetoothOnFunktionChange = useCallback(() => {
    if (bluetoothRequired && (!bluetoothConnected || bluetoothConnecting)) {
      logInfo("No bluetooth connection");
      if (!bluetoothConnecting) {
        setConnectToBluetoothDialogOpen(true);
      }
    }
  }, [bluetoothConnected, bluetoothConnecting, bluetoothRequired]);

  const editModeSnackbar: OptionsObject = {
    variant: "warning",
    persist: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    className: classes.editSnackbar,
    action: (
      <Button
        className={classes.cancelButton}
        onClick={() => finishEditCapturedRecord()}
        variant="outlined"
        data-cy="cancel-edit-button"
      >
        {t("COMMON.ABBRECHEN").toUpperCase()}
      </Button>
    ),
  };

  const checkOnEditMode = useCallback(() => {
    if (editedCapturedRecord) {
      const key = enqueueSnackbar(t("Sie bearbeiten einen bestehenden Datensatz"), editModeSnackbar);
      setEditSnackbarKey(key);
    } else {
      closeSnackbar(editSnackbarKey);
      setEditSnackbarKey(undefined);
      setShouldDisableSaveButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedCapturedRecord]);

  const validateProzesseErrorsInEditMode = useCallback(() => {
    const isEmpty =
      !!editedCapturedRecord &&
      Object.values(editedCapturedRecord!.data!).every(item => !!item || item !== "");
    setShouldValidate(isEmpty);
  }, [editedCapturedRecord]);

  const startFilteringForFerkel = useCallback(() => {
    if (funktion) {
      filterFerkel(funktion);
    }
  }, [filterFerkel, funktion]);

  const startFilteringForSauen = useCallback(() => {
    if (funktion) {
      filterSauen(funktion.prozessDataFilters!);
    }
  }, [filterSauen, funktion]);

  const updateSaveButtonStatus = useCallback(() => {
    if (synchronousProcessingStatus.pending || recordProcessingStatus.pending) {
      setShouldDisableSaveButton(true);
      return;
    }

    setShouldDisableSaveButton(false);
  }, [recordProcessingStatus.pending, synchronousProcessingStatus.pending]);

  useEffect(() => {
    if (isEditing && funktion && editedCapturedRecord) {
      const { funktionConfiguration } = funktion;

      const interval: NodeJS.Timer = setInterval(() => {
        const shouldDisable = isEditTimeExpired(
          editedCapturedRecord.timestamp!,
          funktionConfiguration!.maxMinutesDeletable!
        );

        setShouldDisableSaveButton(shouldDisable);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [editedCapturedRecord, funktion, isEditing]);

  useEffect(() => {
    initOnFunktionChange();
  }, [initOnFunktionChange]);

  useEffect(() => {
    setShouldValidate(false);
  }, [funktion, recordId]);

  useEffect(() => {
    startFilteringForFerkel();
  }, [startFilteringForFerkel]);

  useEffect(() => {
    startFilteringForSauen();
  }, [startFilteringForSauen]);

  useEffect(() => {
    askForBluetoothOnFunktionChange();
  }, [funktion, askForBluetoothOnFunktionChange]);

  useEffect(() => {
    disableValidationOnNewRecordId();
  }, [recordId, disableValidationOnNewRecordId]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    validateProzesseErrorsInEditMode();
  }, [validateProzesseErrorsInEditMode]);

  useEffect(() => {
    if (isEditing) {
      clearProzessEvents();
    }
  }, [clearProzessEvents, isEditing]);

  useEffect(
    () => () => {
      finishEditCapturedRecord();
      closeSnackbar(editSnackbarKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeSnackbar, finishEditCapturedRecord]
  );

  useEffect(() => {
    updateSaveButtonStatus();
  }, [updateSaveButtonStatus]);

  const save = useCallback(() => {
    if (isEditing && !isValidationError) {
      // Open dialog for saving edited record only if prozesse have no validation errors.
      setOpen(true);
    } else if (!isEditing) {
      saveProzessEvents();

      if (allProzessEventsValidInCreationMode) {
        resetBluetoothData();

        if (shouldValidate) {
          setShouldValidate(false);
        }
      } else {
        setShouldValidate(true);
      }
    }
  }, [
    allProzessEventsValidInCreationMode,
    isEditing,
    isValidationError,
    resetBluetoothData,
    saveProzessEvents,
    shouldValidate,
  ]);

  const emptyData = useCallback(() => {
    if (!isEditing) {
      setIsManuallyEmpty(true);
      setShouldValidate(false);

      setTimeout(() => {
        setIsManuallyEmpty(false);
      }, 100);
    }
  }, [isEditing, setIsManuallyEmpty]);

  const confirmEditRecordsHandler = () => {
    if (shouldValidate) {
      saveProzessEvents();
    } else {
      setShouldValidate(true);
    }
    setOpen(false);
  };

  const closeWindow = () => {
    setOpen(false);
  };

  const manualInput = !bluetoothConnected || bluetoothConnecting;

  const revealSaveButton = useCallback(() => {
    if (funktion) {
      const { funktionConfiguration } = funktion;

      return (
        <Button
          variant="contained"
          color="secondary"
          className={classNames(classes.stretchButton, classes.saveButton)}
          disabled={shouldDisableSaveButton}
          onClick={save}
        >
          {funktionConfiguration?.saveButtonIcon && (
            // @ts-ignore
            <img src={iqImages[`${funktionConfiguration.saveButtonIcon}`]} alt={"mail-icon"} />
          )}
          {funktionConfiguration?.saveButtonName
            ? t(`${funktionConfiguration.saveButtonName}`)
            : t("COMMON.SPEICHERN")}
        </Button>
      );
    }
  }, [classes.saveButton, classes.stretchButton, funktion, save, shouldDisableSaveButton, t]);

  const memoizedProzessComponent = useMemo(() => {
    const { funktionConfiguration } = funktion!;

    return (
      <form>
        <div className={classes.grid}>
          {prozesse &&
            Object.values(prozesse).map(prozess => (
              <ProzessComponent
                key={prozess.workflowId}
                funktion={funktion!}
                prozesse={prozesse!}
                prozess={prozess}
                manualInput={manualInput}
                recordId={recordId}
                shouldValidate={shouldValidate}
                setIsValidationError={setIsValidationError}
                saveRecord={save}
              />
            ))}
          {(synchronousProcessingStatus.pending || recordProcessingStatus.pending) && (
            <div className={classes.synchronousProcessingContainer}>
              <CircularProgress className={classes.synchronousProcessingIndicator} />
            </div>
          )}
          {!funktionConfiguration?.isReadOnly && (
            <div
              style={{
                gridArea: "8 / 2 / 9 / 5",
              }}
            >
              <Button variant="contained" className={classes.stretchButton} onClick={emptyData}>
                {t("COMMON.LEEREN")}
              </Button>
            </div>
          )}
          {!funktionConfiguration?.isReadOnly && (
            <div
              style={{
                gridArea: "8 / 4 / 9 / 5",
                justifySelf: "self-end",
              }}
            >
              {revealSaveButton()}
            </div>
          )}
        </div>
      </form>
    );
  }, [
    classes.grid,
    classes.stretchButton,
    classes.synchronousProcessingContainer,
    classes.synchronousProcessingIndicator,
    emptyData,
    funktion,
    manualInput,
    prozesse,
    recordId,
    revealSaveButton,
    save,
    shouldValidate,
    synchronousProcessingStatus.pending,
    recordProcessingStatus.pending,
    t,
  ]);

  if (!funktion || !prozesse) {
    return (
      <>
        <AppBarContainer />
        <div className={classes.center}>
          <CircularProgress className={classes.progress} />
        </div>
      </>
    );
  }

  return (
    <>
      <AppBarContainer />
      {memoizedProzessComponent}
      <ConnectToBluetoothDialogContainer
        open={connectToBluetoothDialogOpen}
        setOpen={setConnectToBluetoothDialogOpen}
      />
      {open && (
        <ConfirmationModalWindow
          closeModal={closeWindow}
          open={open}
          acceptHandler={confirmEditRecordsHandler}
          acceptButtonTitle="SPEICHERN"
        >
          Möchten Sie die Änderungen speichern?
        </ConfirmationModalWindow>
      )}
    </>
  );
};

export default FunktionComponent;
