import createReducer from "../createReducer";
import { TransitoryProzessDataActionType, TransponderForDeletion } from "./transitory-prozess-data.actions";
import { Action } from "../action";
import { TransponderDto } from "../../components/prozesse/gruppenerfassung/gruppenerfassung.types";
import { ISyncedCapturedRecord } from "../../db/database";
import { IDataOverviewDto, IProzessEventsValidationResultDto } from "../../api/backend-api-v7";

export interface TransitoryProzessDataState {
  transpondersForDeletion: TransponderForDeletion[];
  ferkelInfo: ISyncedCapturedRecord[];
  dataExport: IDataOverviewDto | undefined;
  dataImportValidationErrors: IProzessEventsValidationResultDto | undefined;
}

export const initialState: TransitoryProzessDataState = {
  transpondersForDeletion: [],
  ferkelInfo: [],
  dataExport: undefined,
  dataImportValidationErrors: undefined,
};

export default createReducer(initialState, {
  [TransitoryProzessDataActionType.SET_TRANSPONDER](
    state: TransitoryProzessDataState,
    action: Action<TransponderDto>
  ): TransitoryProzessDataState {
    const { recordId, transponder } = action.payload;
    return {
      ...state,
      transpondersForDeletion: [...state.transpondersForDeletion, { value: transponder, recordId }],
    };
  },
  [TransitoryProzessDataActionType.CLEAR_TRANSPONDERS](
    state: TransitoryProzessDataState
  ): TransitoryProzessDataState {
    return {
      ...state,
      transpondersForDeletion: [],
    };
  },
  [TransitoryProzessDataActionType.SET_DETAILS_FERKEL_INFO](
    state: TransitoryProzessDataState,
    action: Action<ISyncedCapturedRecord[]>
  ): TransitoryProzessDataState {
    return {
      ...state,
      ferkelInfo: action.payload,
    };
  },
  [TransitoryProzessDataActionType.REMOVE_DETAILS_FERKEL_INFO](
    state: TransitoryProzessDataState
  ): TransitoryProzessDataState {
    return {
      ...state,
      ferkelInfo: [],
    };
  },
  [TransitoryProzessDataActionType.SET_DATA_EXPORT](
    state: TransitoryProzessDataState,
    action: Action<IDataOverviewDto>
  ): TransitoryProzessDataState {
    return {
      ...state,
      dataExport: action.payload,
    };
  },
  [TransitoryProzessDataActionType.SET_DATA_IMPORT_VALIDATION_ERRORS](
    state: TransitoryProzessDataState,
    action: Action<IProzessEventsValidationResultDto | undefined>
  ): TransitoryProzessDataState {
    return {
      ...state,
      dataImportValidationErrors: action.payload,
    };
  },
});
