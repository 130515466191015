import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { setIsAppDeprecated } from "../store/common/common.actions";

export default (response: AxiosResponse<any>, dispatch: Dispatch) => {
  if (response.headers && response.headers["deprecated"]) {
    dispatch(setIsAppDeprecated(true));
  }

  return response;
};
