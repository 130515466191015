import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalFerkelDto } from "../../../db/database";
import { ProzessType } from "../../../api/backend-api-v7";

const GEBURTSGEWICHT = "geburtsgewicht";

export const geburtsgewichtProzessEventHandler = (
  ferkel: ILocalFerkelDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (ferkel: ILocalFerkelDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const gewicht = prozessEvents.find(pe => pe.prozessType === ProzessType.GEWICHT);

  if (gewicht) {
    const isGeburtsgewichtExist = Object.keys(ferkel).includes(GEBURTSGEWICHT);

    if (!isGeburtsgewichtExist || ferkel.funktionenHistory?.length === 1) {
      const updatedFerkel: ILocalFerkelDto = { ...ferkel, geburtsgewicht: +gewicht.data };
      return next(updatedFerkel, prozessEvents);
    }

    return next(ferkel, prozessEvents);
  }

  return next(ferkel, prozessEvents);
};
