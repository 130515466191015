import pigmonitorTheme from "./theme/theme";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import ReduxRoot from "./redux-root";

// A theme with custom primary and secondary color.
// It's optional.
const customTheme = createTheme(pigmonitorTheme);

function ThemeComponent() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <ReduxRoot />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeComponent;
