import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessInputData,
  ProzessEventOperationTypes,
} from "../../../store/prozess-events/prozess-events.actions";
import { selectWithDateReducer, initialState } from "./select-with-date.reducer";
import { useReducer, useCallback } from "react";
import { SelectWithDateActionType } from "./select-with-date.types";

const isValid = (value: string | undefined) => !!value && value !== "";

export const useSelectWithDateState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  shouldIgnoreEventType: boolean
) => {
  const [state, dispatch] = useReducer(selectWithDateReducer, initialState);
  const { isRequired, shouldUsePreselectedValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: SelectWithDateActionType.INIT,
        payload: {
          value: undefined,
          isRequired,
          shouldUsePreselectedValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldUsePreselectedValue]
  );

  const onProzessDataChanged = useCallback(
    (value: string | undefined) => {
      dispatch({
        type: SelectWithDateActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid: isValid(value),
          shouldUsePreselectedValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldUsePreselectedValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: SelectWithDateActionType.RESET,
        payload: {
          value: undefined,
          isRequired,
          shouldUsePreselectedValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldUsePreselectedValue]
  );

  const save = useCallback(
    (selectedValue: string | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing && !shouldIgnoreEventType ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: selectedValue,
        label: prozess.data?.find(d => d.value === selectedValue)?.label,
        metaData: null,
        additionalData: prozess.data?.find(d => d.value === selectedValue)?.additional,
        operation: selectedValue ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!selectedValue : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.changeEventType,
      prozess.eventType,
      prozess.eventCategory,
      prozess.data,
      prozess.isRequired,
      prozess.prozessType,
      isEditing,
      shouldIgnoreEventType,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: SelectWithDateActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          isRequired,
          shouldUsePreselectedValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldUsePreselectedValue]
  );

  return { state, init, onProzessDataChanged, reset, save, shouldEditProzessData };
};
