import { FunctionComponent, ComponentType } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import RoutePaths from "./route-paths";

export interface ConnectedState {
  isLoggedIn: boolean;
}

interface Props extends ConnectedState {
  component: ComponentType<any>;
}

const PrivateRouteComponent: FunctionComponent<Props> = props => {
  const { component: Component, isLoggedIn } = props;
  let location = useLocation();
  let params = useParams();

  if (!isLoggedIn) {
    return <Navigate to={RoutePaths.Login} state={{ from: location }} replace />;
  }

  return <Component params={params} />;
};

export default PrivateRouteComponent;
