import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
    },
    drawerHeader: theme.mixins.toolbar,
    activeLanguage: {
      backgroundColor: theme.palette.primary.light,
    },
    centered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    icon: {
      height: 50,
      padding: "0 10px",
    },
    fullWidth: {
      width: "100%",
    },
    version: {
      display: "block",
      paddingTop: "1em",
      paddingRight: "0.5em",
      fontSize: "0.5em",
      textAlign: "right",
    },
  })
);

export default useStyles;
