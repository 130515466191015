import logger from "../../logger";
import db, { ILocalBuchtDto } from "../../db/database";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { Dispatch } from "redux";
import { Action } from "../action";

import BackendClient from "../../api/backend-client";
import { IBuchtenResponseDto } from "../../api/backend-api-v7";

import {
  BuchtenActionType,
  loadBuchtenSuccess,
  loadBuchtenFailure,
  setLastFetchedDate,
  updateBuchtenTable,
} from "./buchten.actions";
import { getLastFetchedDate } from "./buchten.selectors";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import BuchtService from "./buchten.utils";

const logError = logger.error("buchten.saga");
let backendClient: BackendClient;

// Load all buchten on init.
export function* loadBuchten() {
  try {
    // Send undefined instead of the lastFetched date to get all existing buchten.
    const response: IBuchtenResponseDto = yield call([backendClient, "getBuchten"], undefined);
    yield put(loadBuchtenSuccess());

    if (response.buchten && response.buchten.length) {
      yield put(setLastFetchedDate());
      // Clear buchten db to prevent dexie errors.
      yield call([db, "deleteAllBuchten"]);
      yield call([db, "addBuchten"], response.buchten);
    }
  } catch (e: any) {
    logError("Could not fetch buchten", e.message);
    yield put(loadBuchtenFailure(e.message));
  }
}

// try to load new info about buchten every minute.
export function* updateBuchten() {
  try {
    const lastFetched: Date | undefined = yield select(getLastFetchedDate);
    const response: IBuchtenResponseDto = yield call([backendClient, "getBuchten"], lastFetched);
    yield put(loadBuchtenSuccess());

    if (response.buchten && response.buchten.length) {
      yield put(setLastFetchedDate());
      yield call([db, "updateBuchten"], response.buchten);
    }
  } catch (e: any) {
    logError("Could not update buchten", e.message);
    yield put(loadBuchtenFailure(e.message));
  }
}

export function* updateBuchtData(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    // Works if PE with Sau event category exists.
    if (prozessEvents.length) {
      const identificator = BuchtService.findBuchtIdentificator(prozessEvents);

      if (identificator) {
        const buchtForUpdate: ILocalBuchtDto[] = yield call([db, "getBuchtById"], identificator);

        if (buchtForUpdate.length) {
          const updatedBucht = BuchtService.modifyBucht(buchtForUpdate[0], prozessEvents);
          yield call([db, "updateBuchten"], [updatedBucht]);
        }
      }
    }
  } catch (e: any) {
    logError("Could not update bucht entity", e.message);
  }
}

export function* updateBuchtDataEditMode(action: Action<IProzessEventsWithAdditionalData[]>) {
  try {
    const prozessEvents = action.payload;

    // Works if PE with Sau event category exists.
    if (prozessEvents.length) {
      const identificator = BuchtService.findBuchtIdentificator(prozessEvents);

      yield call([db, "updateBuchtData"], identificator, prozessEvents);
    }
  } catch (e: any) {
    logError("Could not update bucht entity in edit mode", e.message);
  }
}

export function* deleteBuchtenFunktionenHistory(funktionId: number, recordId: string) {
  try {
    yield call([db, "deleteBuchtenHistory"], { funktionId, recordId });
    yield put(updateBuchtenTable(true));
  } catch (e: any) {
    logError("Could not delete buchten funktionen history", e.message);
  }
}

export default function* buchtenSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(BuchtenActionType.LOAD_BUCHTEN, loadBuchten);
  yield takeEvery(BuchtenActionType.UPDATE_LOCAL_BUCHTEN, updateBuchten);

  yield takeEvery(BuchtenActionType.UPDATE_BUCHT_DATA, updateBuchtData);
  yield takeEvery(BuchtenActionType.UPDATE_BUCHT_DATA_EDIT_MODE, updateBuchtDataEditMode);
}
