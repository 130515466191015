import { AppState } from "../app.state";
import { createSelector } from "reselect";
import { IUserDto } from "../../api/backend-api-v7";

export const getToken = (state: AppState) => state.authentication.token;
export const getDeviceId = (state: AppState) => state.authentication.deviceId;
export const getUsers = (state: AppState) => state.authentication.users;
export const getUserId = (state: AppState) => state.authentication.userId;

export const getIsLoggedIn = createSelector(
  [getToken, getDeviceId],
  (token: string | undefined, deviceId: string | undefined) => !!token && !!deviceId
);

export const getIsDeviceRegistered = createSelector(
  [getDeviceId],
  (deviceId: string | undefined) => !!deviceId
);

export const getLoggedInUser = (state: AppState): string | undefined => state.authentication.loggedInUser;

export const getActiveUser = (username: string) =>
  createSelector([getUsers], (users: IUserDto[] | undefined) => {
    if (users) {
      return users!.find(user => user.displayName === username);
    }
  });
