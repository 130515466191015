import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: 0,
      "& div": {
        height: "100%",
      },
    },
    waageInput: {
      fontSize: "18rem",
    },
    waageAdornment: {
      height: "15vh!important",
      width: "15vh!important",
    },
    textField: {
      width: "100%",
      '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
        WebkitAppearance: "none",
        margin: 0,
      },
      "& > .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    adornedEnd: {
      paddingRight: theme.spacing(),
    },
    optimalBorder: {
      borderColor: `${theme.additionalColors.ampelGruen}!important`,
    },
    optimalLabel: {
      "& > .MuiInputLabel-root": {
        color: theme.additionalColors.ampelGruen,
      },
    },
    validBorder: {
      borderColor: `${theme.additionalColors.ampelGelb}!important`,
    },
    validLabel: {
      "& > .MuiInputLabel-root": {
        color: theme.additionalColors.ampelGelb,
      },
    },
    unit: {
      marginRight: theme.spacing(0.5),
      fontSize: theme.typography.body2.fontSize,
    },
    checkCircleAdornment: {
      marginLeft: 0,
    },
    unitAdornment: {
      marginLeft: theme.spacing(0.5),
    },
    circularProgressAdornment: {
      fontSize: theme.typography.body2.fontSize,
    },
    checkCircle: {
      color: theme.additionalColors.ampelGruen,
      padding: 0,
    },
    disabledCheckCircle: {
      color: theme.additionalColors.gray,
      padding: 0,
    },
    inputMinWidth: {
      minWidth: "155px",
    },
    touchIconButton: {
      marginLeft: theme.spacing(),
      "& svg": {
        fontSize: theme.typography.h6.fontSize,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

export default useStyles;
