import { grey } from "@mui/material/colors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    center: {},
    cardContent: {
      padding: 0,
    },
    cardActionArea: {
      display: "flex",
      flexDirection: "column",
    },
    media: {
      height: "50px",
      width: "100%",
      backgroundSize: "contain",
    },
    card: {
      color: "inherit",
      textDecoration: "none",
      minWidth: "15em",
      maxWidth: "380px",
      display: "flex",
      flex: 1,
      marginLeft: "0.5em",
      marginRight: "0.5em",
      backgroundColor: grey[50],
      flexDirection: "column",
      height: "max-content",
      marginBottom: "1em",
      paddingTop: "1em",
      "&:hover": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  })
);

export default useStyles;
