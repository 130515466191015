import TierInfoComponent from "./tier-info.component";
import I18n from "i18next";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ConnectedState, ConnectedDispatch } from "./tier-info.types";
import { AppState } from "../../../store/app.state";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";
import { getSauen } from "../../../store/sauen/sauen.selectors";
import { showFailedRequest } from "../../../store/notifications/notifications.actions";
import { getTransponder } from "../../../store/bluetooth/bluetooth.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  filteredFerkel: getFilteredFerkel(state),
  sauen: getSauen(state),
  transponder: getTransponder(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  showNetworkError: () => dispatch(showFailedRequest(I18n.t("NOTIFICATIONS.NETWORK_ERROR"))),
});

export default connect(mapStateToProps, mapDispatchToProps)(TierInfoComponent);
