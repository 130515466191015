import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: "#a7a8aa",
  },
  title: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flex: 1,
    justifyContent: "center",
    color: "white",
  },
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.additionalColors.lightGray,
    width: "100%",
    position: "relative",
  },
  description: {
    margin: "20px",
    fontWeight: 500,
  },
}));

export default useStyles;
