import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { default as i18n } from "i18next";
import { initReactI18next } from "react-i18next";

import logger from "../../logger";
import {
  I18NActionType,
  initTranslationsSuccess,
  changeLanguage as changeLanguageAction,
} from "./i18n.actions";
import { Action } from "../action";
import { DEFAULT_LANGUAGE } from "../../constants";
import { LocalStorageItems } from "../../utils/local-storage.enum";
import { getChosenLanguage } from "./i18n.selectors";

import de from "../../assets/i18n/default/de.json";
import en from "../../assets/i18n/default/en.json";
// import nl from "../../assets/i18n/default/nl.json";

const logError = logger.error("translation.saga");
const { LANGUAGE } = LocalStorageItems;

export function initI18n(language: string, resources: any) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: DEFAULT_LANGUAGE,
      debug: process.env.NODE_ENV === "development",
      lng: language,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}

export function* initTranslation() {
  try {
    const savedLanguage: string | null = yield call([localStorage, "getItem"], LANGUAGE);

    let chosenLanguage: string = yield select(getChosenLanguage);
    const translations = { de, en };

    if (savedLanguage && savedLanguage !== chosenLanguage) {
      // set lang from Local Storage if it differs from default (from redux store)
      yield put(changeLanguageAction(savedLanguage));
      chosenLanguage = savedLanguage;
    }

    // finally change lanuage in i18next
    yield call(initI18n, chosenLanguage, translations);
    yield put(initTranslationsSuccess());
  } catch (e) {
    logError("Failed to initialize translation module", e);
  }
}

export function* changeLanguage(action: Action<string>) {
  try {
    const language = action.payload;
    yield call([localStorage, "setItem"], LANGUAGE, language);
    yield call([i18n, "changeLanguage"], language);
  } catch (e) {
    logError(`Failed to change language to ${action.payload}`, e);
  }
}

export default function* i18nSaga() {
  yield takeLeading(I18NActionType.INIT_TRANSLATIONS, initTranslation);
  yield takeLatest(I18NActionType.CHANGE_LANGUAGE, changeLanguage);
}
