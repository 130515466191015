import LastExportsComponent, { LAST_EXPORTS_AMOUNT } from "./last-exports.component";
import { OwnProps, ConnectedState, ConnectedDispatch } from "./last-exports.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../../store/app.state";
import { getLastLocalCapturedRecordsByFunktionId } from "../../../../store/captured-records/captured-records.selectors";
import {
  fetchLocalCapturedRecords,
  resendDataExportForCapturedRecord,
} from "../../../../store/captured-records/captured-records.actions";
import { ISendDataExportRequestDto } from "../../../../api/backend-api-v7";
import { getCurrentGroupId } from "../../../../store/prozess-events/prozess-events.selectors";

const mapStateToProps = (state: AppState, props: OwnProps): ConnectedState => {
  const { funktionId } = props;
  return {
    recordsGroupId: getCurrentGroupId(state),
    lastExports: getLastLocalCapturedRecordsByFunktionId(funktionId, LAST_EXPORTS_AMOUNT)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  fetchLastExports: (funktionId: number, amount: number) => dispatch(fetchLocalCapturedRecords(funktionId)),
  resendData: (recordData: ISendDataExportRequestDto) =>
    dispatch(resendDataExportForCapturedRecord(recordData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastExportsComponent);
