import { FunctionComponent, MouseEventHandler } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./confirmation-dialog.styles";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: MouseEventHandler;
  acceptHandler: () => void;
  acceptButtonTitle: string;
  acceptButtonStyles?: string;
  modalSize?: string;
  children: string;
}

const ConfirmationModalWindow: FunctionComponent<Props> = props => {
  const { closeModal, open, acceptHandler, children, acceptButtonTitle, acceptButtonStyles, modalSize } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open} size={modalSize}>
      <p className={classes.title}>{t(`${children}`)}</p>
      <div className={classes.buttonGroup}>
        <AppButton className={classes.cancelButton} handler={closeModal} dataCy="cancel-changes">
          {t("COMMON.ABBRECHEN").toUpperCase()}
        </AppButton>
        <AppButton
          className={`${classes.saveButton} ${acceptButtonStyles}`}
          handler={acceptHandler}
          dataCy="confirm-changes"
        >
          {t(`${acceptButtonTitle}`).toUpperCase()}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ConfirmationModalWindow;
