import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { IProzessDto } from "../../../api/backend-api-v7";
import { ILocalFerkelDto } from "../../../db/database";

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  givenDataApplied: (workflowId: number) => void;
}

export interface ConnectedState {
  givenData: ProzessInputData | undefined;
  filteredBy: ProzessInputData | undefined;
  prevRecordId: string;
  ferkel: ILocalFerkelDto[];
  isManuallyEmpty: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
}

export interface FerkelGridProps extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
  funktionId: number;
}

export interface FerkelGridState {
  ferkel: ILocalFerkelDto | undefined;
  isError: boolean;
  isValid: boolean;
  shouldSaveProzess: boolean;
}

export interface FerkelGridAction {
  type: FerkelGridActionType;
  payload: {
    value: ILocalFerkelDto | undefined;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
  };
}

export enum FerkelGridActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  TOGGLE,
  RESET,
}
