import { FunctionComponent, useState } from "react";
import { Props } from "./bluetooth-simulator-settings.types";
import TransitionsModal from "../common/modal/modal-window";
import TransponderSettingsContainer from "./transponder-settings/transponder-settings.container";
import TierIdentSettingsContainer from "./tierident-settings/tierident-settings.container";
import GewichtSettingsContainer from "./gewicht-settings/gewicht-settings.container";
import RandomValueGeneratorComponent, {
  TypeOfValues,
} from "./random-value-generator/random-value-generator.component";
import useStyles from "./bluetooth-simulator-settings.styles";
import { Button } from "@mui/material";
import { isGewichtValid, isTransponderValid } from "./bluetooth-simulator-settings.utils";

const BluetoothSimulatorSettingsComponent: FunctionComponent<Props> = props => {
  const { open, closeDialogHandler, setSimulationData, finishSimulation, gewichtChannel } = props;
  const classes = useStyles();

  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const [transponder, setTransponder] = useState<string>("");
  const [tierIdent, setTierIdent] = useState<string>("");
  const [gewichtRange, setGewichtRange] = useState<string>("");

  const applyChangesHandler = () => {
    if (
      (transponder && !isTransponderValid(transponder)) ||
      (gewichtRange && !isGewichtValid(gewichtRange))
    ) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
      setSimulationData({ transponder, tierIdent, gewichtRange });
      closeDialogHandler();
    }
  };

  const handleDisconnect = () => {
    finishSimulation();
    closeDialogHandler();

    if (gewichtChannel) {
      gewichtChannel.close();
    }
  };

  return (
    <TransitionsModal closeModal={closeDialogHandler} open={open} size="600px">
      <div className={classes.flexWrapper} data-cy="settings-content">
        <div className={classes.settingsWrapper}>
          <TransponderSettingsContainer
            transponder={transponder}
            setTransponder={setTransponder}
            shouldValidate={shouldValidate}
          />
          <RandomValueGeneratorComponent
            typeOfValue={TypeOfValues.TRANSPONDER}
            setGeneratedValue={setTransponder}
          />
        </div>
        <div className={classes.settingsWrapper}>
          <TierIdentSettingsContainer tierIdent={tierIdent} setTierIdent={setTierIdent} />
          <RandomValueGeneratorComponent
            typeOfValue={TypeOfValues.TIERIDENT}
            setGeneratedValue={setTierIdent}
          />
        </div>
        <div className={classes.gewichtWrapper}>
          <GewichtSettingsContainer
            gewichtRange={gewichtRange}
            setGewichtRange={setGewichtRange}
            shouldValidate={shouldValidate}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDisconnect}
            data-cy="disconnect-button"
          >
            DISCONNECT
          </Button>
          <Button variant="contained" color="secondary" onClick={applyChangesHandler} data-cy="apply-button">
            APPLY
          </Button>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default BluetoothSimulatorSettingsComponent;
