import { AppState } from "../../../store/app.state";
import { ConnectedState, OwnProps } from "./info-ferkel.types";
import { getFilteredByWorkflowId } from "../../../store/prozess-events/prozess-events.selectors";
import { connect } from "react-redux";
import InfoFerkelComponent from "./info-ferkel.component";
import { getFilteredFerkel } from "../../../store/ferkel/ferkel.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    filteredBy: getFilteredByWorkflowId(workflowId)(state),
    filteredFerkel: getFilteredFerkel(state),
  };
};

export default connect(mapStateToProps)(InfoFerkelComponent);
