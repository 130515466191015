import { FunctionComponent, useCallback } from "react";
import { Divider, MenuList } from "@mui/material";
import DashBoardIcon from "@mui/icons-material/Dashboard";

import iqImages from "../../images/iq-images";
import RoutePaths from "../../routes/route-paths";
import MenuItemContainer from "./menu-item.container";
import classNames from "classnames";
import { IFunktionDto } from "../../api/backend-api-v7";
import { useLocation } from "react-router-dom";
import useStyles from "./menu.styles";

export interface ConnectedState {
  funktionen: { [funktionId: number]: IFunktionDto };
  appVersion: string;
  currentLanguage: string;
}

export interface ConnectedDispatch {
  changeLanguage: (language: string) => void;
}

interface OwnProps extends ConnectedState, ConnectedDispatch {}

const MenuComponent: FunctionComponent<OwnProps> = props => {
  const { funktionen, appVersion } = props;
  const classes = useStyles();
  const location = useLocation();

  // TODO: uncoment code below after when functionality with translating will be fixed
  /*   const languageButton = (language: string, flagCode: string) => (
    <IconButton
      className={currentLanguage === language ? classes.activeLanguage : ""}
      onClick={() => changeLanguage(language)}
    >
      <FlagIcon code={flagCode} size={"36"} />
    </IconButton>
  ); */

  const renderMenuItems = useCallback(
    (funktionen: { [funktionId: number]: IFunktionDto }) =>
      Object.values(funktionen)
        .sort((a: IFunktionDto, b: IFunktionDto) => a.displayOrder! - b.displayOrder!)
        .map(funktion => (
          <MenuItemContainer
            key={funktion.id}
            label={funktion.name!}
            color={funktion.color!}
            path={`${RoutePaths.Funktionen}/${funktion.id}`}
            locationPathname={location.pathname}
            isNetworkRequired={funktion.funktionConfiguration?.isOnlineOnly}
            // @ts-ignore
            imgSrc={iqImages[`${funktion.icon}`]}
          />
        )),
    [location.pathname]
  );

  return (
    <div className={classes.root} data-cy="menu">
      <div>
        <div className={classNames(classes.drawerHeader, classes.centered)}>
          <div>
            <span className={classes.centered}>
              <img src={iqImages.logo} className={classes.icon} alt={"Logo"} />
            </span>
          </div>
        </div>
        <Divider />
        <MenuList>
          <MenuItemContainer
            label="Dashboard"
            path={RoutePaths.Dashboard}
            icon={<DashBoardIcon />}
            color={"#ffffff"}
            locationPathname={location.pathname}
          />
        </MenuList>
        <Divider />
        <MenuList>{funktionen && renderMenuItems(funktionen)}</MenuList>
      </div>
      <div className={classes.fullWidth}>
        {/*
        <div className={classes.centered}>
          {languageButton("de", "DE")}
          {languageButton("en", "GB")}
          {languageButton("nl", "NL")}
        </div> */}
        <span className={classes.version}>Version: {appVersion}</span>
      </div>
    </div>
  );
};

export default MenuComponent;
