import { FunctionComponent, useState, useCallback, useEffect, useMemo } from "react";
import { useSauSelectorState } from "./sau-selector.hooks";
import { Props } from "./sau-selector.types";
import useStyles from "./sau-selector.styles";

import { InputAdornment, TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import SelectDialogContainer from "../../select-dialog/select-dialog.container";
import classnames from "classnames";
import { IProzessDataDto, ISauLifeStageDescriptorDto, ProzessType } from "../../../api/backend-api-v7";

const SauSelectorComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    fullScreen,
    prevRecordId,
    recordId,
    comparisonData,
    updateComparisonData,
    shouldValidate,
    onChanged,
    isEditing,
    editedValue,
    shouldCreateProzessEventForMultipleProzess,
    setIsValidationError,
    isManuallyEmpty,
    filteredBy,
    givenData,
    givenDataApplied,
    createFilteredBy,
  } = props;
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isHighlightedDifference, setIsHighlightedDifference] = useState(false);
  const [displayedValues, setDisplayedValues] = useState<IProzessDataDto[]>([]);
  const [nextValue, setNextValue] = useState<IProzessDataDto | undefined>(undefined);
  const [selectedValueFromGivenData, setSelectedValueFromGivenData] = useState<IProzessDataDto | undefined>(
    undefined
  );
  const [editedValueToDisplayInDialog, setEditedValueToDisplayInDialog] = useState<
    IProzessDataDto[] | undefined
  >(undefined);

  const [shouldIgnoreEventType, setShouldIgnoreEventType] = useState(false);

  const { state, init, onProzessDataChanged, reset, save, shouldEditProzessData, filterProzess } =
    useSauSelectorState(prozess, onChanged, isEditing, createFilteredBy, shouldIgnoreEventType);

  /**
   * Determines whether the input field should be colored to show difference.
   * @return the {@link Boolean}.
   */
  const isHighlighted = useMemo(
    () => !state.isError && isHighlightedDifference && prevRecordId === recordId,
    [isHighlightedDifference, prevRecordId, recordId, state.isError]
  );

  /**
   * TextField onClick handler to open SelectDialogComponent.
   */
  const onOpen = () => {
    if (!prozess.isReadOnly) {
      setIsDialogOpen(true);
    }
  };

  /**
   * Creates sau life stage data for PE. Only belegNr(for Sau) or ammenObjectId(Amme) can be defined.
   */
  const createSauLifeStageDescriptor = (prozessData: IProzessDataDto): ISauLifeStageDescriptorDto => {
    const { belegNr, ammenObjectId } = prozessData.additional!;

    if (belegNr) {
      return { tierSysId: prozessData.id, belegNr };
    } else {
      return { tierSysId: prozessData.id, ammenObjectId };
    }
  };

  /**
   * Determines whether the TextField value should be shown.
   * @return the {@link String}.
   */
  const revealDisplayValue = useCallback(() => {
    if (displayedValues.length) {
      return displayedValues[0] ? displayedValues[0].label : "";
    }
    return "";
  }, [displayedValues]);

  /**
   * Fires if PE with "ShouldCreateMultipleProzessEvents" prozess configuratin was created in edit mode.
   */
  const createProzessEventForMultipleProzess = useCallback(() => {
    if (shouldCreateProzessEventForMultipleProzess) {
      setShouldIgnoreEventType(true);
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  /**
   * Notifies the FunktionComponent to show validation error in edit mode.
   */
  const validateStateError = useCallback(() => {
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const checkOnComparisonData = useCallback(() => {
    if (comparisonData) {
      const comparisonValue = comparisonData?.data;

      if (comparisonValue) {
        setIsHighlightedDifference(!!displayedValues.length && displayedValues[0].value !== comparisonValue);
      } else {
        if (isHighlightedDifference) {
          setIsHighlightedDifference(false);
        }
      }
    }
  }, [comparisonData, displayedValues, isHighlightedDifference]);

  const handleEditedValueToDisplay = useCallback(() => {
    const prozessData = prozess.data?.find(data => data.label === editedValue);
    if (prozessData) {
      const sauInfo = createSauLifeStageDescriptor(prozessData);

      setDisplayedValues([prozessData]);
      setEditedValueToDisplayInDialog([prozessData]);
      onProzessDataChanged(sauInfo);
      filterProzess(sauInfo);
    }
  }, [editedValue, filterProzess, onProzessDataChanged, prozess.data]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      init();
      shouldEditProzessData(false);
      updateComparisonData();
      handleEditedValueToDisplay();
    } else {
      setShouldIgnoreEventType(false);
      shouldEditProzessData(false);
      setEditedValueToDisplayInDialog(undefined);
    }
  }, [editedValue, handleEditedValueToDisplay, init, isEditing, shouldEditProzessData, updateComparisonData]);

  const checkOnGivenData = useCallback(() => {
    if (givenData) {
      if (givenData.data) {
        // Check extractDataFromProzessEvent function in prozess-events-saga.ts to know what data can be here.
        if (typeof givenData.data === "object") {
          if (givenData.data.prozessType === ProzessType.SAU_TRANSPONDER) {
            const prozessData = prozess.data?.find(d => d.additional?.sauTransponder === givenData.data.data);

            if (prozessData) {
              const sauInfo = createSauLifeStageDescriptor(prozessData);
              if (isEditing) {
                shouldEditProzessData(true);
              }
              setDisplayedValues(prozessData ? [prozessData] : []);
              setSelectedValueFromGivenData(prozessData);
              onProzessDataChanged(sauInfo);
            }
          } else {
            // No additional information from the backend.
            reset();
            setDisplayedValues([]);
            setSelectedValueFromGivenData(undefined);
          }
        } else {
          // Use additional information from backend.
          const prozessData = prozess.data?.find(d => d.id === givenData.data);
          if (prozessData) {
            const sauInfo = createSauLifeStageDescriptor(prozessData);
            if (isEditing) {
              shouldEditProzessData(true);
            }
            setDisplayedValues(prozessData ? [prozessData] : []);
            setSelectedValueFromGivenData(prozessData);
            onProzessDataChanged(sauInfo);
          }
        }
      } else {
        // If prozess with shouldListenForChangesOnWorkflowId was removed, remove all data.
        reset();
        setDisplayedValues([]);
        setSelectedValueFromGivenData(undefined);
      }
      givenDataApplied(prozess.workflowId!);
    }
  }, [
    givenData,
    givenDataApplied,
    isEditing,
    onProzessDataChanged,
    prozess.data,
    prozess.workflowId,
    reset,
    shouldEditProzessData,
  ]);

  /**
   * Applies selected value, modifies and creates PE.
   * @param value {@link IProzessDataDto}[].
   */
  const saveValues = useCallback(
    (value: IProzessDataDto[]) => {
      if (isEditing) {
        shouldEditProzessData(true);
      }
      if (value.length) {
        const sauInfo = createSauLifeStageDescriptor(value[0]);
        onProzessDataChanged(sauInfo);
        setDisplayedValues(value);
      } else {
        onProzessDataChanged(undefined);
        setDisplayedValues([]);
      }
    },
    [isEditing, onProzessDataChanged, shouldEditProzessData]
  );

  const createProzessEvent = useCallback(() => {
    const isRecordActive = prevRecordId === recordId;

    if (isEditing) {
      if (isRecordActive && state.isProzessEdited) {
        save(state.value);
      }
    } else {
      if (isRecordActive) {
        save(state.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const resetWhenRecordIsActive = useCallback(() => {
    const isRecordActive = prevRecordId === recordId;
    if (isRecordActive) {
      reset();
      if (!prozess.shouldKeepValue) {
        setDisplayedValues([]);
      }
    }
  }, [prevRecordId, prozess.shouldKeepValue, recordId, reset, setDisplayedValues]);

  useEffect(() => {
    init();
    setDisplayedValues([]);
  }, [init, isEditing, setDisplayedValues, isManuallyEmpty]);

  useEffect(() => {
    checkOnComparisonData();
  }, [checkOnComparisonData]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  useEffect(() => {
    createProzessEvent();
  }, [createProzessEvent]);

  useEffect(() => {
    resetWhenRecordIsActive();
  }, [resetWhenRecordIsActive]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  return (
    <div style={{ gridArea: prozess.position }} data-cy="SauSelectorComponent">
      <TextField
        label={prozess.label!}
        margin="normal"
        variant="outlined"
        fullWidth={true}
        onClick={onOpen}
        className={classnames(classes.formControl, {
          [classes.highlightDifferenceLabel]: isHighlighted,
          [classes.disabled]: prozess.isReadOnly,
        })}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
          classes: {
            notchedOutline: isHighlighted ? classes.highlightDifferenceInput : "",
          },
        }}
        required={prozess.isRequired}
        InputLabelProps={{ shrink: true }}
        value={revealDisplayValue() || ""}
        error={shouldValidate && state.isError}
        disabled={prozess.isReadOnly}
      />
      <SelectDialogContainer
        prozess={prozess}
        fullScreen={fullScreen}
        recordId={recordId}
        saveValues={saveValues}
        editedValue={editedValueToDisplayInDialog}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        nextValueToSelect={nextValue}
        setNextValue={setNextValue}
        selectedValueFromGivenData={selectedValueFromGivenData}
        buchtIdToFilter={filteredBy?.data}
      />
    </div>
  );
};

export default SauSelectorComponent;
