import { IProzessEventsWithAdditionalData } from "../../../pages/funktion/funktion.types";
import { ILocalSauLifeStageDto } from "../../../db/database";
import { ProzessType, ISauBuchtDto } from "../../../api/backend-api-v7";

export const buchtenProzessEventHandler = (
  lifeStage: ILocalSauLifeStageDto,
  prozessEvents: IProzessEventsWithAdditionalData[],
  next: (lifeStage: ILocalSauLifeStageDto, prozessEvents: IProzessEventsWithAdditionalData[]) => void
) => {
  const bucht = prozessEvents.find(pe => pe.prozessType === ProzessType.BUCHT);

  if (bucht) {
    const newBucht: ISauBuchtDto = {
      buchtId: +bucht.data[0],
      createdAtUtc: bucht.createdAtUtc.getTime(),
    };

    return { ...lifeStage, buchten: [newBucht] };
  }
  return lifeStage;
};
