import { FunctionComponent } from "react";
import useStyles from "./kontakt.styles";
import CloseButton from "../../components/common/close-button/close-button.component";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {}

const KontaktComponent: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}> {t("COMPONENTS.KONTAKT.TITLE")}</Typography>
        <CloseButton onCloseHandler={() => history.back()} />
      </div>
      <div>
        <Typography className={classes.contactDetails}>{t("COMPONENTS.KONTAKT.TEXT_1")}</Typography>
        <Typography className={classes.contactDetails}>{t("COMPONENTS.KONTAKT.TEXT_2")}:</Typography>
        <Typography className={classes.contactDetails}>
          IQ-Agrar Service GmbH
          <br />
          Iburger Straße 225
          <br />
          49082 Osnabrück
        </Typography>
        <Typography className={classes.contactDetails}>
          Tel.: +49 (0) 541 / 600 288 80
          <br />
          Fax: +49 (0) 541 / 600 288 90
          <br />
          E-Mail: info@iq-agrar.de
        </Typography>
      </div>
    </div>
  );
};

export default KontaktComponent;
