import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../store/app.state";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";

import SauSelectorComponent from "./sau-selector.component";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./sau-selector.types";

import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import {
  getComparisonByWorkflowId,
  getCurrentRecordId,
  getFilteredByWorkflowId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";

import {
  createFilteredBy,
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
  updateComparisonData,
} from "../../../store/prozess-events/prozess-events.actions";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    comparisonData: getComparisonByWorkflowId(workflowId)(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    filteredBy: getFilteredByWorkflowId(workflowId)(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
    givenData: getGivenDataByWorkflowId(workflowId)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
  createFilteredBy: (prozessInputData: ProzessInputData) => dispatch(createFilteredBy(prozessInputData)),
  updateComparisonData: () => dispatch(updateComparisonData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SauSelectorComponent);
