enum RoutePaths {
  Dashboard = "/dashboard",
  Funktionen = "/funktionen",
  Login = "/login",
  Kontakt = "/kontakt",
  Impressum = "/impressum",
  PrivacyPolicy = "/privacy-policy",
  TermsAndConditions = "/terms-and-conditions",
}

export default RoutePaths;
